import React from "react";
import classNames from "classnames";
import { Row } from "reactstrap";
import clock from "../../utils/clock"
import PlayerWaves from "../../shared/PlayerWaves"
import TimeControls from "../../shared/TimeControls"

const Controls = props => {
  const {
    showControls,

    fullscreen,
    toggleFullscreen,

    chroma,

    isPlaying,
    togglePlayer,
    stopPlayer,
    goToSecond,
    duration,
    setDuration,
    defaultDuration,
    audioUrl,
    setLoading,
    isLoading,
    currentTime,
    onMountAudio
  } = props
  return (
    <Row 
      className={classNames(
        "w-100 position-fixed align-items-center justify-content-end p-3 m-0",
        {"fbt-cycling-player-controls": !showControls && !chroma}
      )} 
      style={{
        backgroundColor: 'rgba(0,0,0,0.9)',
        gap: 20, 
        bottom: 0, 
        left: 0, 
        zIndex: 10,
        minHeight: 70,
        opacity: chroma && 0
      }}
    >
      <PlayerWaves
        audioUrl={audioUrl}
        seconds={currentTime.totalSeconds}
        isPlaying={isPlaying}
        stop={stopPlayer}
        goToSecond={goToSecond}
        setDuration={setDuration}
        defaultDuration={defaultDuration}
        setLoading={setLoading}
        isLoading={isLoading}
        onMount={onMountAudio}
      />
      <span className="text-white">{clock(currentTime, duration, true)}</span>
      <TimeControls 
        isPlaying={isPlaying} 
        toggle={togglePlayer}
        stop={stopPlayer}
        disabled={isLoading}
        fullscreen={fullscreen}
        toggleFullscreen={toggleFullscreen}
      />

    </Row>
  )
}

export default Controls;