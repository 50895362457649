import { useMemo } from "react";
import useTranslator from "../useTranslator";

const allPlanTypes = ["BASE", "REGULAR", "RENTAL", "SINGLE_PAYMENT", "PAY_IN_CASH"]
const useTranslatedPlanTypes = (planTypes = allPlanTypes) => {
  const t = useTranslator("planTypes")

  const translatedPlanTypes = useMemo(() => getTranslatedPlanTypes(t, planTypes), [t, planTypes])
  const planTypeOptions = useMemo(() => getPlanTypeOptions(t, planTypes), [t, planTypes])
  const getPlanTypeOption = (v) => findPlanTypeOption(t, v)

  return {
    planTypes: allPlanTypes,
    translatedPlanTypes,
    planTypeOptions,
    getPlanTypeOption
  }
}

const getTranslatedPlanTypes = (t, planTypes) => {
  return planTypes.reduce((translated, planType) => {
    translated[planType] = t(planType) || planType
    return translated
  }, {})
}

const getPlanTypeOptions = (t, planTypes) => {
  return planTypes.map((planType) => ({ value: planType, label: t(planType) || planType }))
}

const findPlanTypeOption = (t, value) => {
  return allPlanTypes.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedPlanTypes;
export { allPlanTypes, getTranslatedPlanTypes, getPlanTypeOptions, findPlanTypeOption }