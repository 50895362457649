import { useState } from "react";

const defaultParamsRequest = {
  pageNumber: 1,
  pageSize: 10000,
  sortBy: undefined,
  sort: undefined, // ASC OR DESC
  filterBy: undefined,
  filter: undefined,
}

const usePaginationParamRequest = (initialParamsRequest = defaultParamsRequest) => {
  const [paramsRequest, setParamsRequest] = useState({ ...defaultParamsRequest, ...initialParamsRequest });

  const { sortBy: iSortBy, sort: iSort } = initialParamsRequest
  const { filterBy: iFilterBy, filter: iFilter } = initialParamsRequest

  const changePageNumber = (pageNumber) => setParamsRequest({ ...paramsRequest, pageNumber })
  const changePageSize = (pageSize) => setParamsRequest({ ...paramsRequest, pageSize })
  const changeSort = (sortBy = iSortBy, sort = iSort) => setParamsRequest({ ...paramsRequest, sortBy, sort })
  const changeFilter = (filterBy = iFilterBy, filter = iFilter) => setParamsRequest({ ...paramsRequest, filterBy, filter })
  const changeParam = (key, value) => setParamsRequest({ ...paramsRequest, [key]: value })

  return {
    paramsRequest,
    setParamsRequest,

    changePageNumber,
    changePageSize,
    changeSort,
    changeFilter,
    changeParam

  }
}

export default usePaginationParamRequest