import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import ShowTrainerScheduleCard from "../../../../components/trainerSchedule/ShowTrainerScheduleCard";
import UploadAvailabilityModal from "../../../../components/trainerSchedule/UploadAvailabilityModal";
import { useParams } from "react-router";
import useMeetingTemplates from "../../../../components/meetings/hooks/useMeetingTemplates";
import useTrainerAvailabilitySchedule
  , { saveTrainerAvailabilitySchedule } from "../../../../components/trainerSchedule/hooks/useTrainerAvailabilitySchedule";
import useTrainerAvailability from "../../../../components/trainerSchedule/hooks/useTrainerAvailability";

const TrainerSchedule = () => {
  const { idTrainer } = useParams()

  const {
    availabilitySchedule,
    setAvailabilitySchedule,
    isLoading: isLoadingAvailabilitySchedule,
    refresh: refreshAvailabilitySchedule,
    idAvailabilitySchedule
  } = useTrainerAvailabilitySchedule(idTrainer)

  const {
    availability: availabilityToUpload,
    setAvailability: setAvailabilityToUpload
  } = useTrainerAvailability()

  const {
    meetingTemplates,
    isLoading: isLoadingMeetingTemplates
  } = useMeetingTemplates(idTrainer);

  return (
    <React.Fragment>

      <UploadAvailabilityModal
        availability={availabilityToUpload}
        setAvailability={setAvailabilityToUpload}
        templates={meetingTemplates}
        availabilitySchedule={availabilitySchedule}
        saveAvailabilitySchedule={async (newAvailabilitySchedule) => {
          setAvailabilitySchedule(newAvailabilitySchedule)
          return saveTrainerAvailabilitySchedule(newAvailabilitySchedule, idAvailabilitySchedule, idTrainer)
            .then(() => {
              refreshAvailabilitySchedule()
              setAvailabilityToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingMeetingTemplates || isLoadingAvailabilitySchedule}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowTrainerScheduleCard
              availabilitySchedule={availabilitySchedule}
              setAvailabilityToUpload={setAvailabilityToUpload}
              isLoading={isLoadingAvailabilitySchedule}
              saveAvailabilitySchedule={async (newAvailabilitySchedule) => {
                setAvailabilitySchedule(newAvailabilitySchedule)
                return saveTrainerAvailabilitySchedule(newAvailabilitySchedule, idAvailabilitySchedule, idTrainer)
                  .then(() => {
                    refreshAvailabilitySchedule()
                    setAvailabilityToUpload(null)
                  })
                  .catch(() => null)
              }}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TrainerSchedule;