import React, { useEffect, useMemo, useRef, useState } from "react";
import reactive from "reactablejs";
import interact from "interactjs";
import {
  colors,
  getDefaultNewSegment,
  getZoneByFtp,
  maxFtp,
  minDuration,
  minFtp, minGraphFtp, sizeGraphFtp
} from "../config";
import { Button, ButtonGroup } from "reactstrap";
import UploadTrainingCyclingStructureGraphSegmentModal from "./UploadTrainingCyclingStructureGraphSegmentModal";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { getProcessedStructure } from "../player/formatTraining"
import { RPMChangeTriangle, StandingTriangle } from "../shared/Triangles"


const UploadTrainingCyclingStructureGraphSegments = ({
                                                       trainingToUpload,
                                                       setTrainingToUpload,
                                                       pxPerSec = 1
                                                     }) => {
  const { workout } = (trainingToUpload || {});
  const { structure = [] } = (workout || {})

  const removeSegment = (segment = {}) => {
    if (!segment) return;
    setTrainingToUpload(({ workout = [], ...training }) => {
      const { structure = [] } = workout
      const newStructure = [];
      structure.forEach((iterSegment) => {
        if (iterSegment.id !== segment.id) newStructure.push(iterSegment);
      })
      return { ...training, workout: { ...workout, structure: [...newStructure] } }
    })
  }

  const saveSegment = (segment, index = undefined) => {
    if (!segment) return;
    const { appliedRPM, startDuration, finalDuration, groupIndex, ...cleanSegment } = segment;
    setTrainingToUpload(({ workout = [], ...training }) => {
      const { structure = [] } = workout
      const newStructure = structure.map((iterSegment) => {
        if (iterSegment.id === cleanSegment.id) return { ...iterSegment, ...cleanSegment };
        else return iterSegment;
      })
      if (!cleanSegment.id) {
        const indexNewSegment = (index || index === 0) ? index : newStructure.length
        newStructure.splice(indexNewSegment, 0, { ...cleanSegment, id: uuid() })
      }
      return { ...training, workout: { ...workout, structure: [...newStructure] } }
    })
  }

  const processedStructure = useMemo(() => getProcessedStructure(structure).processedStructure, [structure])

  return (
    <div className="position-relative h-100 w-100">
      <div className="d-flex align-items-end justify-content-start h-100 w-100 position-relative">
        <AnimatePresence>
          {processedStructure.map((segment, index) => (
            <Segment
              key={segment.id}
              segment={segment}
              pxPerSec={pxPerSec}
              onAdd={() => saveSegment(getDefaultNewSegment(), (index + 1))}
              onCopy={({ id, ...copy }) => saveSegment(copy, (index + 1))}
              onChange={saveSegment}
              onRemove={removeSegment}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}


const Segment = ({ segment, pxPerSec, onChange, onRemove, onAdd, onCopy }) => {
  const [actualSegment, setActualSegment] = useState(segment)
  const [segmentToUpload, setSegmentToUpload] = useState(null)
  const [isInfoVisible, setInfoVisible] = useState(false)

  const timeoutRef = useRef(null)

  const safeCopyOfActualSegment = JSON.parse(JSON.stringify({
    ...actualSegment,
    startDuration: segment.startDuration,
    finalDuration: segment.finalDuration
  }))
  const { id, ftp, duration, rpm, standing } = actualSegment;
  const [initFtp, endFtp] = (ftp || []);
  const [initRpm, endRpm] = (rpm || segment.appliedRPM || [])
  const diffFtp = Math.abs(endFtp - initFtp)
  const diffRpm = initRpm - endRpm
  const topFtp = Math.max(initFtp, endFtp)
  const topGraphFtp = (topFtp - minGraphFtp)

  const segmentFtp = (initFtp + endFtp) / 2
  const segmentZone = getZoneByFtp(segmentFtp);
  const hex = segmentZone.colors.main;
  let segmentClipPath = "";

  if (initFtp < endFtp) {
    segmentClipPath = `polygon(0 ${(diffFtp / topGraphFtp) * 100}%, 100% 0, 100% 100%, 0 100%)`
  } else if (initFtp > endFtp) {
    segmentClipPath = `polygon(0 0, 100% ${(diffFtp / topGraphFtp) * 100}%, 100% 100%, 0 100%)`
  }

  const isSmallHeight = (segmentFtp <= (maxFtp / 2))
  const isSmallWidth = (duration * pxPerSec) <= 160

  const isBigBox = !isSmallHeight && !isSmallWidth

  const onResize = (event, segment) => {
    const { ftp } = segment;
    const [initFtp, endFtp] = ftp;
    const newPercentHeight = (event.rect.height * 100) / event.target.parentElement.clientHeight
    const newTopFtp = Math.round(Math.min(maxFtp, ((newPercentHeight * sizeGraphFtp) / 100) + minGraphFtp));
    const diffTopFtp = (newTopFtp - Math.max(initFtp, endFtp))

    let newInitFpt = (initFtp + diffTopFtp)
    let newEndFpt = (endFtp + diffTopFtp)
    let newDuration = parseFloat((event.rect.width / pxPerSec).toFixed(3))

    if (newInitFpt < minFtp) newInitFpt = minFtp;
    if (newInitFpt > maxFtp) newInitFpt = maxFtp;
    if (newEndFpt < minFtp) newEndFpt = minFtp;
    if (newEndFpt > maxFtp) newEndFpt = maxFtp;
    if (newDuration < minDuration) newDuration = minDuration;

    setActualSegment({
      ...segment,
      ftp: [newInitFpt, newEndFpt],
      duration: newDuration
    });
  }

  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => onChange(actualSegment), 500)
    return () => {
      clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [actualSegment])


  return (
    <React.Fragment>

      <UploadTrainingCyclingStructureGraphSegmentModal
        segmentToUpload={segmentToUpload}
        setSegmentToUpload={setSegmentToUpload}
        onSave={setActualSegment}
      />

      <ReactiveBox
        key={id}
        initial={{
          height: 0,
          width: 0
        }}
        animate={{
          width: ((duration * pxPerSec) - 2),
          height: Math.round(((topGraphFtp * 100) / sizeGraphFtp)) + '%',
        }}
        transition={{
          duration: 0.2,
          ease: "easeOut"
        }}
        exit={{
          height: 0,
          width: 0
        }}
        style={{
          borderTop: `1px solid ${colors.dark}`,
          borderRight: `1px solid ${colors.dark}`,
          borderLeft: `1px solid ${colors.dark}`,
          backgroundColor: "rgba(255, 255, 255, 0.4)"
        }}
        className="training-cycling-structure-graph-segment"
        resizable={{
          edges: { left: false, right: true, bottom: false, top: true },
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: 3, y: 3 })
              ]
            })
          ],
        }}
        onMouseEnter={() => setInfoVisible(true)}
        onMouseLeave={() => setInfoVisible(false)}
        onMouseMove={() => setInfoVisible(true)}
        onDoubleTap={() => setSegmentToUpload(safeCopyOfActualSegment)}
        onResizeMove={(event) => onResize(event, safeCopyOfActualSegment)}
      >
        <div className="h-100"
             style={{
               clipPath: segmentClipPath,
               backgroundColor: hex,
               width: "inherit",
               fontWeight: 900
             }}
        />

        {isInfoVisible && (
          <SegmentTooltip
            position={isBigBox ? "inside" : (isSmallHeight ? "top" : "bottom")}
            onClickEdit={() => setSegmentToUpload(safeCopyOfActualSegment)}
            onClickAdd={() => onAdd(safeCopyOfActualSegment)}
            onClickRemove={() => onRemove(safeCopyOfActualSegment)}
            onClickCopy={() => onCopy(safeCopyOfActualSegment)}
            zone={segmentZone.zone}
            ftp={`${diffFtp === 0 ? initFtp : ftp.join("% - ")}%`}
            rpm={(segment.appliedRPM || rpm) ? (diffRpm === 0 ? initRpm : rpm.join(" - ")) : "---"}
            rpe={segmentZone.rpe}
            standing={standing}
            duration={moment.utc(duration * 1000).format('HH:mm:ss:S')}
            startDuration={moment.utc(segment.startDuration * 1000).format('HH:mm:ss:S')}
            finalDuration={moment.utc(segment.finalDuration * 1000).format('HH:mm:ss:S')}
          />
        )}

        {standing && <StandingTriangle style={{ fill: hex }} height={17} separation={2}/>}
        {diffRpm > 0 && <RPMChangeTriangle side="right"/>}
        {diffRpm < 0 && <RPMChangeTriangle side="left"/>}

      </ReactiveBox>

    </React.Fragment>
  )
}

const SegmentTooltip = ({
                          position,
                          onClickEdit,
                          onClickAdd,
                          onClickRemove,
                          onClickCopy,
                          zone,
                          ftp,
                          rpm,
                          rpe,
                          standing,
                          duration,
                          startDuration,
                          finalDuration
                        }) => {

  const styles = { top: 0, zIndex: 2, padding: 10, width: 160 };

  switch (position) {
    case "inside":
      styles.right = 0;
      break;
    case "top":
      styles.right = 0;
      styles.transform = `translate(100%, -80%)`
      break;
    case "bottom":
      styles.right = 0;
      styles.transform = `translateX(100%)`;
      break;
    default:
      break;
  }

  return (
    <div className="position-absolute" style={styles}>
      <div
        className="p-2 flex-column-content-center bg-light rounded-sm position-relative shadow">

        {position === "top" && (
          <i
            className="fas fa-caret-left position-absolute text-light shadow"
            style={{ bottom: 0, left: 0, transform: `translateX(-65%)`, fontSize: 30 }}
          />
        )}

        {position === "bottom" && (
          <i
            className="fas fa-caret-left position-absolute text-light shadow"
            style={{ left: 0, bottom: 0, transform: `translateX(-65%)`, fontSize: 30 }}
          />
        )}

        <ButtonGroup size={"sm"} className="my-1">
          <Button
            color="light"
            className="bg-transparent border-0"
            onClick={onClickEdit}>
            <i className="fas fa-edit"/>
          </Button>
          <Button
            color="light"
            className="bg-transparent border-0"
            onClick={onClickRemove}>
            <i className="fas fa-trash text-danger"/>
          </Button>
          <Button
            color="light"
            className="bg-transparent border-0"
            onClick={onClickCopy}>
            <i className="fas fa-copy"/>
          </Button>
          <Button
            color="light"
            className="bg-transparent border-0"
            onClick={onClickAdd}>
            <i className="fa fa-plus text-success"/>
          </Button>
        </ButtonGroup>

        <div className="rounded-sm bg-white shadow p-2 w-100" style={{ fontWeight: 900 }}>
          <LineSegmentTooltip left={"Zona:"} right={zone}/>
          <LineSegmentTooltip left={"Ftp:"} right={ftp}/>
          <LineSegmentTooltip left={"Rpm:"} right={rpm}/>
          <LineSegmentTooltip left={"Rpe:"} right={rpe}/>
          <LineSegmentTooltip left={"Estado:"} right={standing ? "de pie" : "sentado"}/>
          <LineSegmentTooltip left={"Duración:"} right={duration}/>
          <div className="mt-2" style={{ fontSize: 8 }}>
            <LineSegmentTooltip left={startDuration} middle={"-"} right={finalDuration}/>
          </div>

        </div>
      </div>

    </div>
  )
}

const LineSegmentTooltip = ({ left, middle, right }) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <div>
        {left}
      </div>
      {!!middle && (
        <div>
          {middle}
        </div>
      )}
      <div>
        {right}
      </div>
    </div>
  )
}

const ReactiveBoxBase = ({ getRef, ...props }) => {
  return <motion.div ref={getRef} {...props}/>
}
const ReactiveBox = reactive(ReactiveBoxBase)

export default UploadTrainingCyclingStructureGraphSegments;