import React, { useEffect, useState, useMemo } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import randomColor from "randomcolor"
import { notify, Card, FormInput, Loading, Modal, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import UploadStatsPeriodModal, {
  format,
  formatHuman,
  initialEndDate,
  initialStartDate
} from "../shared/UploadStatsPeriodModal";
import { statLarge } from "./config/sizes";
import classNames from "classnames";
import useTranslator from "../../hooks/useTranslator";

const createProductsOptions = (productVideoStatsList) => {
  return productVideoStatsList.map((prod) => ({
    label: prod?.title,
    value: prod?.productId,
    color: randomColor({ luminosity: 'dark' }),
    playTimeAndPctgList: prod.playTimeAndPctgList.sort((a, b) => (a.completionPercentage - b.completionPercentage)),
    ...prod
  }))
}

const StatsVideoTimeVisualization = () => {
  const t = useTranslator("components.stats.statsVideoTimeVisualization")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [{ startDate, endDate }, setLocalDates] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  const refresh = async () => {
    try {
      const { productVideoStatsList = [] } = await statsRequests.getAveragePlayTimeVideos(format(startDate), format(endDate))
      const options = createProductsOptions(productVideoStatsList)
      setOptions(options);
      setSelectedOptions(options[0] ? [options[0]] : null)
    } catch (e) {
      console.error(e)
      notify.error(e?.message || t("getStatsError"))
    }
    setLoading(false)
  }

  useEffect(() => {
    refresh().then(() => null);
    // eslint-disable-next-line
  }, [clubId, startDate, endDate])


  const object = useMemo(() => {
    const arrOptions = Array.isArray(selectedOptions) ? selectedOptions : [];
    const object = {
      labels: [],
      datasets: []
    }
    for (let i = 0; i <= 100; i++) {
      object.labels[i] = i;
    }
    arrOptions.forEach(({ playTimeAndPctgList = [], label, color }, index) => {
      const data = [{ y: 100, x: 0 }];
      const totalVisualizations = playTimeAndPctgList.length;
      let countVisualizations = 0;
      playTimeAndPctgList.forEach(({ completionPercentage }) => {
        if (!data.find(({ x }) => x === completionPercentage)) {
          const coincidences = playTimeAndPctgList.filter((lst) => lst.completionPercentage === completionPercentage)
          countVisualizations += coincidences.length;
          const visualizationsPending = (totalVisualizations - countVisualizations)
          if (visualizationsPending === 0) {
            data.push({ y: ((coincidences.length / totalVisualizations) * 100), x: completionPercentage })
          } else {
            data.push({ y: ((visualizationsPending / totalVisualizations) * 100), x: completionPercentage })
          }
        }
      })
      if (!data.find(({ x }) => x === 100)) data.push({ y: 0, x: 100 })
      object.datasets[index] = {
        label,
        borderColor: color,
        backgroundColor: color,
        tension: 0.3,
        radius: 0,
        data
      }
    })
    return object;
  }, [selectedOptions])

  const hasInfo = options.length > 0;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />

      <Modal.Info
        titleInfo={t("infoTitle")}
        closeText={t("infoCloseText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("infoContent")}
      </Modal.Info>

      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        <CardBody className="pb-0">
          {isLoading && <Loading.BouncingDots className="my-5"/>}
          {(!hasInfo && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          {(hasInfo && !isLoading) && (
            <FormInput.Select
              isMulti={true}
              options={options}
              value={selectedOptions}
              onChange={setSelectedOptions}
              styles={colourStyles}
              placeholder={t("selectProducts")}
            />
          )}
        </CardBody>
        <CardBody className={classNames("pt-0")}>
          {(hasInfo && !isLoading) && (
            <div style={{ position: "relative", height: statLarge }}>
              <Line
                type="line"
                data={object}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      suggestedMax: 100,
                      suggestedMin: 0,
                      grid: {
                        drawBorder: false
                      },
                      ticks: {
                        stepSize: 20,
                        callback: (value) => `${value}%`
                      }
                    },
                    x: {
                      ticks: {
                        callback: (value) => `${value}%`,
                        maxTicksLimit: 11
                      },
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    }
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: () => t("labelTitle"),
                        label: (tooltipItem) => {
                          const { parsed, dataset } = tooltipItem;
                          const { label } = dataset;
                          const { x, y } = parsed;
                          if (x === 100 && y > 0) return t("labelFinalized", label, y)
                          return t("labelAbandonment", label, y, x)
                        }
                      }
                    },
                    legend: {
                      display: false
                    }
                  },
                  interaction: {
                    intersect: false,
                    mode: 'nearest',
                  },
                }}
              />
            </div>
          )}
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

const colourStyles = {
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
};

export default StatsVideoTimeVisualization;