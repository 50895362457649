import React from "react";
import { Table, TableCard, TableOutput, utils } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowNavigationItemsCard = ({
                                   navigationItems = [],
                                   sortNavigationItems = utils.noop,
                                   setNavigationItemToUpload,
                                   setNavigationItemToRemove,
                                   onClickNavigationItem = utils.noop,
                                   isLoading
                                 }) => {
  const tLanguage = useTranslator()
  const t = useTranslator("components.navigation.showNavigationItemsCard")

  const onDataChange = async (sortedNavigationItems, trigger) => {
    if (trigger === "sortable") {
      sortNavigationItems(sortedNavigationItems)
    }
  }

  const columns = [
    {
      name: t("text"),
      property: `translations.${tLanguage("language")}`,
      isTh: true
    },
    {
      name: t("url"),
      formattedContent: ({ url }) => url.substring(0, 30).trim() + (url.length > 30 ? "..." : "")
    },
    {
      name: t("type"),
      formattedContent: ({ openTarget = "_self" }) => t("types")[openTarget],
    },
    {
      formattedContent: ({ active, predefined }) => {

        const bookmarks = [
          {
            icon: active ? <i className="fas fa-eye"/> : <i className="fas fa-eye-slash"/>,
            title: active ? t("published") : t("unpublished"),
            color: "primary"
          }
        ]

        if (predefined) bookmarks.push({
          icon: <i className="fas fa-infinity"/>,
          title: t("predefined"),
          color: "primary"
        })

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      formattedContent: (navigationItem) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setNavigationItemToUpload({ ...navigationItem }),
            description: t("modifyDescription")
          }
        ]

        if(!navigationItem.predefined){
          actions.push({
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setNavigationItemToRemove({ ...navigationItem })
          })
        }

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle")}
        buttons={[
          {
            children: <AddButtonChildren children={t("buttonAddText")}/>,
            onClick: () => setNavigationItemToUpload({})
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        sortable={true}
        onDataChange={onDataChange}
        columns={columns}
        rows={navigationItems}
        onRowClick={(navigationItem) => onClickNavigationItem({ ...navigationItem })}
        isLoading={isLoading}
        noContentMessage={t("emptyNavigationItems")}
        rowAmount={5}
      />
    </Card>
  );
}

export default ShowNavigationItemsCard;