import Api from "../Api";

const getProduct = (id) => Api.get(`/admin/products/admin/${id}`);
const getProductList = (params = {}) => Api.get(`/admin/products`, { params });
const putProduct = (id, body) => Api.put(`/admin/products/${id}`, body);
const postProduct = (body) => Api.post(`/admin/products`, body);
const getProductLiveStreams = () => Api.get(`/admin/products/videoLiveStream`);

const productRequests = {
  get: getProduct,
  getLiveStreams: getProductLiveStreams,
  getList: getProductList,
  put: putProduct,
  post: postProduct
}

export default productRequests