import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveNavigationItemModal = ({
                                   navigationItem,
                                   close,
                                   remove,
                                   isLoading
                                 }) => {
  const tLanguage = useTranslator()
  const t = useTranslator("components.navigation.removeNavigationItemModal")
  const { translations, id} = (navigationItem || {});

  const translatedText = translations ? translations[tLanguage("language")] : ""

  return (
    <Modal.DeleteConfirm
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", translatedText)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveNavigationItemModal;