import { useEffect, useState } from "react";
import { categoryRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useCategories = () => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = () => {
    setIsLoading(true)
    return getCategories()
      .then((categoryList) => {
        setCategories(categoryList)
        return categoryList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sort = async (sortedCategories = []) => {
    setCategories(sortedCategories)
    return sortCategories(sortedCategories)
      .then(() => {
        getCategories().then((categoryList) => setCategories(categoryList))
      })
  }


  return {
    categories,
    isLoading,
    refresh,
    sort
  }
}

export const getCategories = async () => {
  const t = hardTranslator("components.category.hooks.useCategories")
  try {
    const { content = [] } = await categoryRequests.getList({
      pageNumber: 1,
      pageSize: 10000,
      sortBy: "idx",
      sort: "ASC"
    })
    return content
  } catch (e) {
    const message = (e?.message || t("getCategoriesError"))
    notify.error(message)
    throw new Error(message)
  }
}

export const sortCategories = async (categories) => {
  const t = hardTranslator("components.category.hooks.useCategories")
  const notificationLoading = notify.loading(t("sortCategoriesLoading"))
  try {
    const firstCategory = categories[0]
    const itemOrderList = categories.map(({ id }) => id)
    await categoryRequests.put({ ...firstCategory, itemOrderList })
    notifyLoadingToSuccess(notificationLoading, t("sortCategoriesSucceed"))
  } catch (e) {
    const message = e?.message || t("sortCategoriesError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export default useCategories;