import React from "react";
import { Modal } from "@general-backoffice/core";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getDefaultTeamSection from "./shared/getDefaultTeamSection";
import { FormInput } from "@general-backoffice/core/index";


const UploadTeamSectionModal = ({
                                  teamSection,
                                  collectionsByCategory = [],
                                  setTeamSection,
                                  onSubmit,
                                  isLoading,
                                  setCollectionToCreate
                                }) => {
  const t = useTranslator("components.teamSection.uploadTeamSectionModal")
  const { onChangeInput: onChange, changeState } = useChangingComplexState(setTeamSection, isLoading)
  const {
    id,
    name,
    active,
    collectionIdList
  } = { ...getDefaultTeamSection(), ...(teamSection || {}) };

  const onSubmitForm = () => {
    onSubmit()
  }
  const onlyOptions = []
  const collectionOptions = collectionsByCategory.map(({ name, collectionList = [] }) => {
    const options = (collectionList || []).map(({ id, title }) => {
      const option = { value: id, label: title, categoryLabel: name }
      onlyOptions.push(option)
      return option
    })
    return { label: name, options: options, }
  });
  const selectedOptions = onlyOptions.filter(({ value }) => collectionIdList.includes(value))

  return (
    <Modal.FormContainer
      isOpen={!!teamSection}
      toggleOpen={() => setTeamSection(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >
      <FormInput.Input
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
      />
      <FormInput.Switch
        onChange={onChange}
        checked={active}
        name={"active"}
        label={t("isPublishedLabel")}
        labels={t("isPublishedAnswers")}
      />
      <FormInput.Select
        label={t("collectionsLabel")}
        name={"collectionIdList"}
        isMulti={true}
        icon={<i className="fas fa-boxes px-2"/>}
        onChange={(options = []) => changeState("collectionIdList", options.map(({ value }) => value))}
        options={collectionOptions}
        value={selectedOptions}
        isDisabled={isLoading}
        isCreatable={true}
        isLoading={isLoading}
        onCreateOption={(inputValue) => setCollectionToCreate({ title: inputValue })}
        formatCreateLabel={(inputValue) => t("collectionCreatingFormatText", inputValue)}
        noOptionsMessage={() => t("noCollectionOptionsText")}
        formatOptionLabel={({ label, categoryLabel }) => {
          return <div>
            {label} <small className="opacity-5">
            ( {categoryLabel} )
          </small>
          </div>
        }}
      />
    </Modal.FormContainer>
  );
}

export default UploadTeamSectionModal;