import React from "react";
import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject"
import clock from "../../../utils/clock"
import flowerSVG from "../utils/flowerSVG"
import { colors } from "../../config"



const getRemainingSeconds = (duration = 0, passedSeconds = 0) => {
  let time = duration - passedSeconds
  return time >= 0 ? time : null
}

const SegmentFlowerTimer = ({secondsBeforeStart, ...props}) => {
  const { currentTime, currentSegment } = props
  const { duration, start } = currentSegment || {}
  let remainingSeconds = getRemainingSeconds(duration, currentTime?.totalSeconds - start)

  return(
    <div className="position-relative d-flex h-100">
      <SegmentFlower {...props} className="mx-auto"/>
      {secondsBeforeStart >= 0 ?
        <Circle>
          <div style={{fontSize: 'calc(3vw + 40px)', marginTop: 7, width: '5.2vw'}}>
            {secondsBeforeStart}
          </div>
        </Circle>
      : remainingSeconds >= 0 &&
        <Circle>
          <div style={{margin: 6, fontSize: 'calc(0.8vw + 10px)'}}>TIEMPO</div>
          <span style={{fontSize: 'calc(1.4vw + 12px)'}}>
            {clock(formatSecondsToTimeObject(remainingSeconds), duration)}
          </span>
        </Circle>
      }
    </div>
  )
}

const Circle = ({children}) => 
  <div className="metropolis text-center" style={styles.timer}>
    <div className="position-absolute" style={styles.timerCircleContainer}>
      <div style={styles.timerCircle}/>
    </div>
    {children}
  </div>



const getDecimals = n => parseFloat((n - Math.floor(n)).toFixed(2)) || 1

const SegmentFlower = ({ currentTime = {}, currentSegment, className }) => {
  const {elements, component: DefaultComponent, ...flowerProps} = flowerSVG
  const { zone, duration, start } = currentSegment || {}
  const remainingSeconds = getRemainingSeconds(duration, currentTime?.totalSeconds - start)

  let percentagePlayed = Math.min(remainingSeconds / duration, 1)
  let petals = currentSegment ? (percentagePlayed * elements.length) : elements.length
  let currPetalIndex = Math.ceil(petals)
  let currPetalOpacity = getDecimals(petals)

  return(
    <svg {...flowerProps} width="100%" className={className}>
      {elements.map(({component: Component = DefaultComponent, props}, i) => {
        return <Component
          key={i} {...props} 
          fill={zone?.index > i ? zone?.colors?.main : colors.empty}
          initial={{ opacity: 1 }}
          animate={{ opacity: currPetalIndex > i ? 
              i === currPetalIndex-1 ?  
                currPetalOpacity
              : 
                1
            : 
              0 
          }}
          transition={{ duration: 1, ease: 'linear' }}
        />
      })}
    </svg>
  )
}


const styles = {
  timer: {
    color: colors.light,
    fontWeight: 600,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    lineHeight: 1
  },
  timerCircleContainer: {
    backgroundColor: colors.dark,
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    opacity: 0.6,
    width: '170%',
    zIndex: -1,
    borderRadius: 999
  },
  timerCircle: {
    borderRadius: 999,
    width: '100%',
    paddingTop: '100%',
  }
}

export {
  SegmentFlowerTimer, 
  SegmentFlower
}
export default SegmentFlower;