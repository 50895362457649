import { defaultAvailabilitySchedule, weekDays } from "../hooks/useTrainerAvailabilitySchedule";
import moment from "moment";

const convertAvailabilityToFullCalendarEvent = ({
                                                  id,
                                                  recurring = true,
                                                  start,
                                                  end,
                                                  templateId = null,
                                                  day,
                                                  ...other
                                                }) => {
  const theDay = ((day !== undefined && day !== null) ? day : moment().day())
  return ({
    id: id,
    title: (templateId ? "👥" : "👤") + (recurring ? "📆" : ""),
    eventDisplay: "auto",
    displayEventTime: false,
    displayEventEnd: false,
    durationEditable: false,
    editable: true,
    classNames: ["bg-gradient-light", "text-dark", "cursor-pointer"],
    extendedProps: {
      availability: true,
      recurring,
      daysOfWeek: (recurring ? [theDay] : undefined),
      templateId,
      ...other
    },
    ...(recurring ? { startTime: start, endTime: end, daysOfWeek: [theDay] } : { start, end })
  })
}

const convertAvailabilityScheduleToFullCalendarEvents = (availabilitySchedule = defaultAvailabilitySchedule) => {
  if (!availabilitySchedule) return [];
  const availabilitiesFullCalendarEvents = [];
  for (let [key, availabilities] of Object.entries(availabilitySchedule)) {
    availabilities?.forEach(({ start, end, templateId, lastUpdate, ...other }) => {
      const isOneTime = key === "oneTime";
      let startDate, endDate;
      if (!isOneTime) {
        startDate = moment(`${weekDays[key.toString()]} ${start} +0000`, "d HH:mm:ss ZZ").local()
        endDate = moment(`${weekDays[key.toString()]} ${end} +0000`, "d HH:mm:ss ZZ").local()
      }

      if (start && end) {
        availabilitiesFullCalendarEvents.push(convertAvailabilityToFullCalendarEvent({
          ...other,
          recurring: !isOneTime,
          start: isOneTime ? moment(start).local().format() : startDate.format("HH:mm:ss"),
          end: isOneTime ? moment(end).local().format() : endDate.format("HH:mm:ss"),
          day: isOneTime ? undefined : startDate.day(),
          lastUpdate: moment(lastUpdate).format()
        }));
      }
    })
  }
  return availabilitiesFullCalendarEvents
}

export default convertAvailabilityScheduleToFullCalendarEvents;