import { useEffect, useState } from "react";
import { trainerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const useTrainers = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [trainers, setTrainers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getTrainers()
      .then((trainers) => {
        setTrainers(trainers)
        return trainers
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    trainers,
    isLoading,
    refresh
  }
}

export const getTrainers = async () => {
  const t = hardTranslator("components.trainers.hooks.useTrainers")
  try {
    const { content = [] } = await trainerRequests.getList({ pageNumber: 1, pageSize: 10000 });
    return content
  } catch (e) {
    const message = (e?.message || t("getTrainers"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useTrainers;