import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { meetingRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { roles } from "../../../utils/roles";
import { hardTranslator } from "../../../hooks/useTranslator";

const useMeetings = () => {
  const { clubId, roleList = [] } = useSelector(({ sessionState }) => sessionState.club)
  const [meetings, setMeetings] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const isTrainer = (roleList || []).find((role) => role === roles.TRAINER)

  useEffect(() => {
    if (isTrainer) refresh()
    // eslint-disable-next-line
  }, [clubId, isTrainer])

  const refresh = async () => {
    setIsLoading(true)
    return getMeetings()
      .then((meetingList) => {
        setMeetings(meetingList)
        return meetingList
      })
      .catch((e) => {
        setMeetings([])
        throw e
      })
      .finally(() => setIsLoading(false))

  }

  return {
    meetings,
    isLoading,
    refresh
  }
}

export const getMeetings = async () => {
  const t = hardTranslator("components.meetings.hooks.useMeetings")

  try {
    const { meetingList = [] } = await meetingRequests.getList({})
    return meetingList
  } catch (e) {
    const message = e?.message || t("getMeetingsError")
    notify.error(message)
    throw new Error(message)
  }
}

export default useMeetings;