import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFormInputValidation } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getDefaultCollection from "./shared/getDefaultCollection";
import { Col, Row } from "reactstrap";
import useApiFileImages from "../../hooks/useApiFileImages";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import useTranslatedCollectionTypes from "../../hooks/translations/useTranslatedCollectionTypes";

const imagesStructure = {
  thumbnail: {
    size: [640, 960], // AR: 2:3 Portrait
    MB: 0.2
  },
  image: {
    size: [1920, 1080], // AR: 16:9
    MB: 0.6
  },
}

const imagesStructureProfile = {
  thumbnail: {
    size: [640, 640], // AR: 1:1 square
    MB: 0.2
  },
  image: {
    size: [1920, 1080], // AR: 16:9
    MB: 0.6
  },
}

const UploadCollectionModal = ({
                                 collection,
                                 setCollection,
                                 onSubmit,
                                 isLoading,
                                 categories: allCategories = [],
                                 setCategoryToUpload,
                               }) => {
  const t = useTranslator("components.collection.uploadCollectionModal")
  const { collectionTypeOptions, getCollectionTypeOption } = useTranslatedCollectionTypes()
  const { onChangeInput: onChange, changeState } = useChangingComplexState(setCollection, isLoading)

  const categories = allCategories.filter(({ type }) => type === "COLLECTION")

  const {
    id,
    description,
    image,
    subtitle,
    thumbnail,
    title,
    categoryIdList,
    type
  } = { ...getDefaultCollection(), ...(collection || {}) };

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image, thumbnail }, [id, !!collection])

  const {
    inputRef: titleInputRef,
    throwError: throwErrorTitle
  } = useFormInputValidation(title)

  const {
    inputRef: subtitleInputRef,
    throwError: throwErrorSubtitle
  } = useFormInputValidation(subtitle)

  const categoryOptions = categories.map(({ id, name }) => ({ value: id, label: name }));

  const onSubmitForm = () => {
    if ((title || "").length > 30) return throwErrorTitle(t("tooLongTitleError"))
    if ((subtitle || "").length > 30) return throwErrorSubtitle(t("tooLongSubtitleError"))
    onSubmit()
  }

  const someIsLoading = (isLoading || isLoadingFiles);
const imageStructureToUse = type === "personal" ? imagesStructureProfile : imagesStructure
  return (
    <React.Fragment>

      <ResizeImageFileModals
        structure={imageStructureToUse}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        isOpen={!!collection}
        toggleOpen={() => setCollection(null)}
        title={t("title", !!id, title)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
        size={"lg"}
      >
        <Row>
          <Col md={6}>
            <FormInput.Input
              innerRef={titleInputRef}
              onChange={onChange}
              value={title}
              name={"title"}
              label={t("titleLabel")}
              required={true}
              error={(title || "").length > 30 ? t("tooLongTitleErrorText") : ""}
            />
            <FormInput.Input
              innerRef={subtitleInputRef}
              onChange={onChange}
              value={subtitle}
              name={"subtitle"}
              label={t("subtitleLabel")}
              error={(subtitle || "").length > 50 ? t("tooLongSubtitleErrorText") : ""}
            />
          </Col>
          <Col md={6}>
            <FormInput.Select
              label={t("typeLabel")}
              value={getCollectionTypeOption(type)}
              options={collectionTypeOptions}
              onChange={({ value }) => changeState("type", value)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              // tooltip={hasContent ? t("tooltipTypeUpdateWithContent") : undefined}
            />
            <FormInput.Select
              label={t("categoriesLabel")}
              name={"categoryIdList"}
              isMulti={true}
              icon={<i className="fas fa-bars px-2"/>}
              onChange={(options = []) => changeState("categoryIdList", (options || []).map(({ value }) => value))}
              options={categoryOptions}
              value={categoryOptions.filter(({ value }) => categoryIdList.includes(value))}
              isCreatable={true}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              onCreateOption={(inputValue) => setCategoryToUpload({ name: inputValue, type: "COLLECTION" })}
              formatCreateLabel={(inputValue) => t("categoryCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noCategoryOptionsText")}
            />
          </Col>
        </Row>

        <FormInput.Quill
          onChange={(value) => changeState("description", value)}
          value={description}
          label={t("descriptionLabel")}
        />

        <Row>
          <Col md={6}>
            <FormInput.SingleDropzone
              label={t("thumbnailLabel") + " (JPG, PNG)"}
              tooltip={t("thumbnailTooltip")}
              smallLabel={imageStructureToUse.thumbnail.size.join(" x ")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnail}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnail: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, thumbnail: files.thumbnail })
                }
              ]}
              required
            />
          </Col>
          <Col md={6}>
            <FormInput.SingleDropzone
              label={t("imageLabel") + " (JPG, PNG)"}
              tooltip={t("imageTooltip")}
              smallLabel={imageStructureToUse.image.size.join(" x ")}
              accept={".jpg, .jpeg, .png"}
              file={files.image}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, image: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, image: files.image })
                }
              ]}
              required
            />
          </Col>
        </Row>

      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadCollectionModal;