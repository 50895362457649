import React, { useEffect, useState } from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useTeam from "../../../../components/team/hooks/useTeam";
import useCollection from "../../../../components/collection/hooks/useCollection";
import { useNavigate, useParams } from "react-router";
import UploadCollectionModal from "../../../../components/collection/UploadCollectionModal";
import useCategories from "../../../../components/category/hooks/useCategories";
import ShowTeamSectionListCard from "../../../../components/teamSections/ShowTeamSectionListCard";
import useTeamSection from "../../../../components/teamSections/hooks/useTeamSection";
import useCategory from "../../../../components/category/hooks/useCategory";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import RemoveModal from "../../../../components/shared/RemoveModal";
import { teamSectionRequests } from "../../../../api/requests";
import UploadTeamSectionModal from "../../../../components/teamSections/UploadTeamSectionModal";
import useCollectionsByCategory from "../../../../components/collection/hooks/useCollectionsByCategory";
import useTranslator from "../../../../hooks/useTranslator";

const TeamSections = () => {
  const navigate = useNavigate()
  const t = useTranslator("views.pages.dashboard.onDemand")
  const { idTeam } = useParams()
  const [teamSectionIdToRemove, setTeamSectionIdToRemove] = useState(null)

  useEffect(() => {
    if (!idTeam) navigate(-1)
    // eslint-disable-next-line
  }, [idTeam])

  const {
    collections: collectionsByCategory,
    refresh: refreshCollectionsByCategory,
    isLoading: isLoadingCollectionsByCategory
  } = useCollectionsByCategory()

  const {
    team,
    isLoading: isLoadingTeam,
    sortSections: sortTeamSections,
    refresh: refreshTeam
  } = useTeam(parseInt(idTeam))

  const {
    collection: collectionToCreate,
    isLoading: isLoadingCollectionToCreate,
    setCollection: setCollectionToCreate,
    save: saveCollectionToCreate,
  } = useCollection()

  const {
    categories,
    refresh: refreshCategories,
  } = useCategories();

  const {
    category: categoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    setCategory: setCategoryToUpload,
    save: saveCategoryToUpload,
  } = useCategory()

  const {
    teamSection: teamSectionToUpload,
    setTeamSection: setTeameSectionToUpload,
    isLoading: isLoadingTeameSectionToUpload,
    save: saveTeameSectionToUpload
  } = useTeamSection()

  return (
    <React.Fragment>

      <UploadCollectionModal
        collection={collectionToCreate}
        setCollection={setCollectionToCreate}
        isLoading={isLoadingCollectionToCreate}
        onSubmit={async () => {
          return saveCollectionToCreate()
            .then(() => {
              refreshCollectionsByCategory()
              refreshTeam()
              setCollectionToCreate(null)
            })
            .catch(() => null)
        }}
        categories={categories}
        setCategoryToUpload={setCategoryToUpload}
      />

      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <UploadTeamSectionModal
        teamSection={teamSectionToUpload}
        collectionsByCategory={collectionsByCategory}
        setCollectionToCreate={setCollectionToCreate}
        setTeamSection={setTeameSectionToUpload}
        isLoading={isLoadingTeameSectionToUpload || isLoadingCollectionsByCategory}
        onSubmit={async () => {
          await saveTeameSectionToUpload()
          await refreshTeam()
          setTeameSectionToUpload(null)
        }}
      />

      <RemoveModal
        isOpen={!!teamSectionIdToRemove}
        onClose={() => setTeamSectionIdToRemove(null)}
        onSubmit={async () => {
          await teamSectionRequests.delete(teamSectionIdToRemove)
          await refreshTeam()
          setTeamSectionIdToRemove(null)
        }}
      />

      <Header.BreadCrumb title={team?.name ? t("teamSections.title", team?.name) : undefined} color="secondary"
                         isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowTeamSectionListCard
              sections={team?.spdTeamSectionList || []}
              isLoading={isLoadingTeam}
              setSectionToUpload={(ts) => setTeameSectionToUpload({ ...ts, spdTeamId: team.id })}
              setSectionToRemove={({ id }) => setTeamSectionIdToRemove(id)}
              onClickSection={(ts) => setTeameSectionToUpload({ ...ts, spdTeamId: team.id })}
              sortSections={async (spdTeamSectionList = []) => {
                await sortTeamSections(spdTeamSectionList)
              }}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TeamSections;