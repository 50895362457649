const getDefaultUser = () => ({
  address: "",
  birthdate: null,
  city: "",
  country: "",
  firstName: "",
  lastName: "",
  genderType: undefined,
  phone: "",
  postalCode: "",
  roleList: [],
})

export default getDefaultUser;