import React, { useState } from "react";
import { Card as ReactstrapCard, CardBody, Col, Row } from "reactstrap";
import { FormInput, notify, utils, Card } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import { notifyLoadingToSuccess } from "../utils/notify/notifyLoadingUpdate";
import getDefaultClubConfig from "./shared/getDefaultClubConfig";
import useTranslator from "../../hooks/useTranslator";
import useApiFileImages from "../../hooks/useApiFileImages";

const ChangeClubLogosCard = ({ clubConfig, setClubConfig, isLoading }) => {
  const t = useTranslator("components.clubConfig.changeClubLogosCard")
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const { changeState } = useChangingComplexState(setClubConfig, isLoading)
  const { logosImageClub, clubId } = { ...getDefaultClubConfig(), ...(clubConfig || {}) }
  const { imageDark, imageLight, imageSquareDark, imageSquareLight } = logosImageClub

  const { files, setFiles, isLoadingFiles } = useApiFileImages({
    imageLight,
    imageDark,
    imageSquareLight,
    imageSquareDark
  }, [clubId])

  const onDropFile = async (file, key) => {
    setIsLoadingLocal(true)
    const notification = notify.loading(t("imageCompressionLoading"))
    setFiles((currFiles) => ({ ...currFiles, [key]: file }))
    const compressedFile = await utils.compressImage(file, 0.45)
    const image64 = await utils.fileToBase64(compressedFile)
    changeState(`logosImageClub.${key}`, { id: null, data: image64.split(',')[1] })
    setIsLoadingLocal(false)
    notifyLoadingToSuccess(notification, t("imageCompressionSucceed"))
  }

  const someIsLoading = isLoadingLocal || isLoading || isLoadingFiles

  return (
    <React.Fragment>
      <ReactstrapCard>
        <Card.Header title={t("title")} subtitle={t("subtitle")}/>
        <CardBody>
          <Row>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label={t("imageLight") + " (PNG)"}
                accept={".png"}
                file={files.imageLight}
                onDrop={async (file) => {
                  await onDropFile(file, "imageLight")
                }}
                disabled={someIsLoading}
                tooltip={t("imageLightTooltip")}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label={t("imageSquareLight") + " (PNG)"}
                accept={".png"}
                file={files.imageSquareLight}
                onDrop={async (file) => {
                  await onDropFile(file, "imageSquareLight")
                }}
                disabled={someIsLoading}
                tooltip={t("imageSquareLightTooltip")}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label={t("imageDark") + " (PNG)"}
                accept={".png"}
                file={files.imageDark}
                onDrop={async (file) => {
                  await onDropFile(file, "imageDark")
                }}
                disabled={someIsLoading}
                tooltip={t("imageDarkTooltip")}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormInput.SingleDropzone
                label={t("imageSquareDark") + " (PNG)"}
                accept={".png"}
                file={files.imageSquareDark}
                onDrop={async (file) => {
                  await onDropFile(file, "imageSquareDark")
                }}
                disabled={someIsLoading}
                tooltip={t("imageDarkLightTooltip")}
              />
            </Col>
          </Row>
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default ChangeClubLogosCard;