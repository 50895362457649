import Api from "../Api";

const getMyTrainer = () => Api.get(`admin/trainers/user`);
const getTrainer = (id) => Api.get(`admin/trainers/${id}`);
const getTrainerTemplates = (id) => Api.get(`admin/trainers/templates`, { params: { trainerId: id } });
const getTrainersList = (params) => Api.get(`admin/trainers`, { params });
const deleteTrainer = (id) => Api.delete(`admin/trainers/${id}`);
const putTrainer = (body) => Api.put(`admin/trainers`, body);
const postTrainer = (body) => Api.post(`admin/trainers`, body);

const trainerRequests = {
  getMyTrainer: getMyTrainer,
  get: getTrainer,
  getTrainerTemplates: getTrainerTemplates,
  getList: getTrainersList,
  delete: deleteTrainer,
  put: putTrainer,
  post: postTrainer
}

export default trainerRequests