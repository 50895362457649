import { useEffect, useState } from "react";
import { navigationRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import getDefaultNavigationItem from "../shared/getDefaultNavigationItem";

const useNavigationItem = (id) => {
  const [navigationItem, setNavigationItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigationItemId = navigationItem?.id || id

  useEffect(() => {
    if (!!navigationItemId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [navigationItemId])

  const refresh = async () => {
    setIsLoading(true)
    return getNavigationItem(navigationItemId)
      .then((navigationItem) => {
        setNavigationItem(navigationItem)
        return navigationItem
      })
      .catch((e) => {
        setNavigationItem(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveNavigationItem(navigationItem)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!navigationItem?.id) return;
    setIsLoading(true)
    return removeNavigationItem(navigationItem.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    navigationItem,
    setNavigationItem,
    save,
    remove,
    refresh,
    isLoading
  }
}

export const getNavigationItem = async (navigationItemId) => {
  const t = hardTranslator("components.navigation.hooks.useNavigationItem")
  try {
    return await navigationRequests.get(navigationItemId)
  } catch (e) {
    const message = e?.message || t("getNavigationItemError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveNavigationItem = async (navigationItem) => {
  const t = hardTranslator("components.navigation.hooks.useNavigationItem")
  const notificationLoading = notify.loading(t("saveNavigationItemLoading", !!navigationItem?.id))
  try {
    const cleanNavigationItem = { ...getDefaultNavigationItem(), ...navigationItem }
    if (!!navigationItem?.id) {
      await navigationRequests.put(cleanNavigationItem)
      notifyLoadingToSuccess(notificationLoading, t("saveNavigationItemSucceed", true))
    } else {
      await navigationRequests.post(cleanNavigationItem)
      notifyLoadingToSuccess(notificationLoading, t("saveNavigationItemSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveNavigationItemError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeNavigationItem = async (navigationItemId) => {
  const t = hardTranslator("components.navigation.hooks.useNavigationItem")
  const notification = notify.loading(t("removeNavigationItemLoading"))
  try {
    await navigationRequests.delete(navigationItemId)
    notifyLoadingToSuccess(notification, t("removeNavigationItemSucceed"))
  } catch (e) {
    const message = e?.message || t("removeNavigationItemError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useNavigationItem;