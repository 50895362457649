import React, { useEffect, useState } from "react";
import { Modal } from "@general-backoffice/core";
import { Form } from "reactstrap";
import { v4 as uuid } from "uuid";
import PreviewVideoModal from "../shared/PreviewVideoModal";
import { FormInput, notify } from "@general-backoffice/core/index";
import useTrans from "../trans/hooks/useTrans";
import { transRequests } from "../../api/requests";
import { useSelector } from "react-redux";
import moment from "moment"
import useTranslator from "../../hooks/useTranslator";

const idForm = uuid()

const UploadChannelEventModal = ({
                                   channelEvent,
                                   setChannelEvent,
                                   remove,
                                   submit
                                 }) => {
  const t = useTranslator("components.channels.uploadChannelEventModal")
  const {
    clubId: currentClubId,
    companyCode: currentCompanyCode
  } = useSelector(({ sessionState }) => sessionState.club)
  const [isLoading, setLoading] = useState(false)
  const { trans, isLoading: isLoadingTrans } = useTrans();
  const { trans: sharedTrans, isLoading: isLoadingSharedTrans } = useTrans({ clubId: 16 });
  const [isVideoPreview, setVideoPreview] = useState(false)
  const [duration, setDuration] = useState(0)

  const { creating, start = moment().format(), end, url } = (channelEvent || {});

  const completeTrans = [...trans, ...((currentClubId !== 16 && currentCompanyCode === "FBT") ? sharedTrans : [])]

  const optionsTrans = completeTrans.map(({ filename, url, ...other }) => ({
    value: url,
    label: filename, ...other
  }))
  const currentOptionTrans = url ? optionsTrans.find(({ value }) => value === url) : null

  useEffect(() => {
    if (!!channelEvent) {
      setChannelEvent({ ...channelEvent, start })
      setVideoPreview(false)
    }
    // eslint-disable-next-line
  }, [!!channelEvent])

  useEffect(() => {
    setDuration(0)
    if (!!currentOptionTrans?.path) {
      setLoading(true)
      transRequests.getDuration(currentOptionTrans.path)
        .then(setDuration)
        .catch((e) => notify.error(e?.message || t("getDurationError")))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [currentOptionTrans?.path])


  useEffect(() => {
    if (!!duration && !!start && !!channelEvent) {
      const newEnd = moment(start).add(parseInt(duration).toFixed(0), "s").utc().format();
      if (newEnd !== end) setChannelEvent({ ...channelEvent, end: newEnd })
    }
    // eslint-disable-next-line
  }, [start, duration])

  const clickPreview = () => {
    if (url) setVideoPreview(true)
  }

  const onSubmitForm = (event) => {
    event.preventDefault()
    submit()
  }

  return (
    <React.Fragment>
      <PreviewVideoModal
        url={isVideoPreview ? url : null}
        onClose={() => setVideoPreview(false)}
        allowCopyUrl={false}
        title={t("previewTitle")}
      />

      <Modal.Simple
        size="sm"
        isLoading={isLoading}
        isOpen={!!channelEvent}
        toggleOpen={setChannelEvent}
        title={t("title", !creating)}
        buttons={[
          { children: t("buttonSaveText"), form: idForm, type: "submit", color: "success" },
          ...(!creating ? [{ children: <i className="fas fa-trash"/>, color: "danger", onClick: remove }] : []),
        ]}
        closeText={t("closeText")}
      >
        <Form id={idForm} onSubmit={onSubmitForm}>

          <FormInput.Select
            label={t("videoLabel")}
            value={currentOptionTrans}
            icon={url && <i className="fas fa-play text-default px-2 cursor-pointer" onClick={clickPreview}/>}
            options={optionsTrans}
            isDisabled={isLoadingTrans || isLoadingSharedTrans || isLoading}
            onChange={({ value }) => setChannelEvent({ ...channelEvent, url: value })}
            required
          />

          <FormInput.Time
            label={t("initialTimeLabel")}
            value={start && moment(start)}
            onChange={(newStart) => setChannelEvent({ ...channelEvent, start: moment(newStart).utc().format() })}
            description={start && moment(start).format("DD/MM/YYYY HH:mm:ss")}
            required
          />

          <FormInput.Time
            label={t("finalTimeLabel")}
            value={end && moment(end)}
            onChange={(newEnd) => setChannelEvent({ ...channelEvent, end: moment(newEnd).utc().format() })}
            disabled={true}
            description={isLoading ? t("loadingFinalTimeText") : (end && moment(end).format("DD/MM/YYYY HH:mm:ss"))}
            required
          />

        </Form>
      </Modal.Simple>

    </React.Fragment>

  )
}

export default UploadChannelEventModal;