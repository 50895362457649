import { categoryTypes } from "../../../hooks/translations/useTranslatedCategoryTypes";

const getDefaultCategory = () => ({
  name: "",
  productIdList: [],
  collectionIdList: [],
  published: true,
  type: categoryTypes[0]
})

export default getDefaultCategory