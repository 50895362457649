import React, { useState } from "react";
import { Header, notify } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useUsers from "../../../../components/users/hooks/useUsers";
import ShowUsersCard from "../../../../components/users/ShowUsersCard";
import UploadUsersFiltersModal from "../../../../components/users/UploadUsersFiltersModal";
import UploadUserModal from "../../../../components/users/UploadUserModal";
import ShowUserHistoryModal from "../../../../components/users/ShowUserHistoryModal";
import StatsUsersLocation from "../../../../components/stats/StatsUsersLocation";
import StatsUsersTotalActiveNow from "../../../../components/stats/StatsUsersTotalActiveNow";
import StatsUsersTotalActive from "../../../../components/stats/StatsUsersTotalActive";
import useUser from "../../../../components/users/hooks/useUser";
import ShowManagersCard from "../../../../components/managers/ShowManagersCard";
import UploadManagerModal from "../../../../components/managers/UploadManagerModal";
import useManagers from "../../../../components/managers/hooks/useManagers";
import useManager from "../../../../components/managers/hooks/useManager";
import RemoveManagerModal from "../../../../components/managers/RemoveManagerModal";
import SubscribeUserModal from "../../../../components/users/SubscribeUserModal";
import usePlans from "../../../../components/plans/hooks/usePlans";
import useTranslator from "../../../../hooks/useTranslator";

// const UploadUsersCSVCard = React.lazy(() => import('../../../../components/users/UploadUsersCSVCard'));

const Users = () => {
  const t = useTranslator("views.pages.dashboard.users")
  const [isUploadingUsersFilters, setUploadingUsersFilters] = useState(false)

  const {
    users,
    filters: filtersUsers,
    setFilters: setFiltersUsers,
    pagination: paginationUsers,
    changePage: changePageUsers,
    totalAmount: totalAmountUsers,
    isLoading: isLoadingUsers,
    refresh: refreshUsers
  } = useUsers()

  const {
    managers,
    pagination: paginationManagers,
    changePage: changePageManagers,
    totalAmount: totalAmountManagers,
    isLoading: isLoadingManagers,
    refresh: refreshManagers
  } = useManagers()

  const {
    user: userToUpload,
    setUser: setUserToUpload,
    save: saveUserToUpload,
    isLoading: isLoadingUserToUpload
  } = useUser()

  const {
    manager: managerToUpload,
    setManager: setManagerToUpload,
    save: saveManagerToUpload,
    isLoading: isLoadingManagerToUpload
  } = useManager()

  const {
    manager: managerToRemove,
    setManager: setManagerToRemove,
    remove: removeManagerToRemove,
    isLoading: isLoadingManagerToRemove
  } = useManager()

  const {
    user: userToShowHistory,
    setUser: setUserToShowHistory,
    isLoading: isLoadingUserToShowHistory
  } = useUser()

  const {
    user: userToSubscribe,
    setUser: setUserToSubscribe,
    subscribe: subscribeUserToSubscribe,
    isLoading: isLoadingUserToSubscribe
  } = useUser()

  const {
    plans,
    isLoading: isLoadingPlans
  } = usePlans();

  return (
    <React.Fragment>

      <UploadUsersFiltersModal
        isOpen={isUploadingUsersFilters}
        close={() => setUploadingUsersFilters(false)}
        filters={filtersUsers}
        setFilters={setFiltersUsers}
      />

      <RemoveManagerModal
        manager={managerToRemove}
        close={() => setManagerToRemove(null)}
        isLoading={isLoadingManagerToRemove}
        remove={async () => {
          return removeManagerToRemove()
            .then(() => {
              setManagerToRemove(null)
              refreshManagers()
            })
            .catch(() => null)
        }}
      />

      <SubscribeUserModal
        user={userToSubscribe}
        close={() => setUserToSubscribe(null)}
        plans={plans.filter(({ planType }) => planType === "PAY_IN_CASH")}
        isLoading={isLoadingUserToSubscribe || isLoadingPlans}
        onSubscribe={async ({ planId, subscriptionEnd, subscriptionStart }) => {
          subscribeUserToSubscribe(planId, subscriptionStart, subscriptionEnd)
            .then(() => {
              setUserToSubscribe(null)
              refreshUsers()
              notify.success(t("userSubscribedSuccess"))
            })
            .catch(() => null)
        }}
      />

      <UploadManagerModal
        manager={managerToUpload}
        setManager={setManagerToUpload}
        onSubmit={async () => {
          return saveManagerToUpload()
            .then(() => {
              setManagerToUpload(null)
              refreshManagers();
            })
            .catch(() => null)
        }}
        isLoading={isLoadingManagerToUpload || isLoadingManagers}
      />

      <UploadUserModal
        user={userToUpload}
        setUser={setUserToUpload}
        onSubmit={async () => {
          return saveUserToUpload()
            .then(() => {
              setUserToUpload(null)
              refreshUsers();
            })
            .catch(() => null)
        }}
        isLoading={isLoadingUserToUpload}
      />

      <ShowUserHistoryModal
        user={userToShowHistory}
        close={() => setUserToShowHistory(null)}
        isLoading={isLoadingUserToShowHistory}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>
        <Col xs={12}>
          <StatsUsersLocation simple={true}/>
        </Col>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={5}>
            <ShowManagersCard
              managers={managers}
              setManagerToUpload={setManagerToUpload}
              setManagerToRemove={setManagerToRemove}
              onClickManager={setManagerToUpload}
              totalAmount={totalAmountManagers}
              page={paginationManagers.page}
              pageAmount={paginationManagers.amount}
              changePage={changePageManagers}
              isLoading={isLoadingManagers}
            />
            {/*<UploadUsersCSVCard onUpload={refreshUsers}/>*/}
          </Col>
          <Col xs={12} md={7}>
            <ShowUsersCard
              users={users}
              setUserToUpload={setUserToUpload}
              setUserToShowHistory={setUserToShowHistory}
              setUserToSubscribe={setUserToSubscribe}
              setFiltersToUpload={setUploadingUsersFilters}
              onClickUser={setUserToUpload}
              totalAmount={totalAmountUsers}
              page={paginationUsers.page}
              pageAmount={paginationUsers.amount}
              changePage={changePageUsers}
              isLoading={isLoadingUsers}
            />
          </Col>
          <Col xs={12} md={6}>
            <StatsUsersTotalActiveNow/>
          </Col>
          <Col xs={12} md={6}>
            <StatsUsersTotalActive/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Users;