import React from "react"
import { colors } from "../config"

const StandingTriangle = ({ style, height = 10, separation = 2 }) =>
  <svg
    viewBox="0 0 10 10"
    preserveAspectRatio="none"
    className='position-absolute w-100'
    style={{ ...styles.standingTriangle, ...style, height, top: (separation * -1) }}
  >
    <polygon points="0,10 10,10 5,0"/>
  </svg>


const RPMChangeTriangle = ({ side = 'left' }) =>
  <svg
    viewBox="0 0 10 10"
    preserveAspectRatio={side === 'right' ? "xMaxYMin meet" : "xMinYMin meet"}
    className="position-absolute"
    style={{ ...styles.rpmTriangle, padding: 2, [side]: 0 }}
  >
    <polygon
      style={{ fill: colors.dark }}
      points={`0,0 10,0 ${side === 'right' ? 10 : 0},10`}
    />
  </svg>


const styles = {
  standingTriangle: {
    left: 0,
    transform: 'translateY(-100%)'
  },
  rpmTriangle: {
    width: 25,
    height: 25,
    maxWidth: '100%',
    maxHeight: '100%',
    top: 0
  }
}

export { StandingTriangle, RPMChangeTriangle }