import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowClubsCard = ({ clubs, setClubToUpload, setClubToRemove, isLoading, changeUserClub }) => {
  const t = useTranslator("components.clubs.showClubsCard")

  const columns = [
    {
      name: t("club"),
      formattedContent: ({ name, clubProperties }) => {
        const { principalClub } = (clubProperties || {})
        return (
          <TableOutput.TitleSubtitle
            title={name}
            subtitle={(!!principalClub ? `(${t("principalClub")})` : "")}
          />
        )
      },
      isTh: true
    },
    {
      name: t("status"),
      formattedContent: ({ active }) => {
        return (
          <TableOutput.Status
            status={t("statusText", active)}
            color={active ? "success" : "danger"}
          />
        )
      }
    },
    {
      name: "",
      formattedContent: (club) => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-bolt"/>,
              onClick: () => changeUserClub({ ...club }),
              description: t("changeCurrentClubDescription")
            },
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setClubToUpload({ ...club }),
              description: t("modifyClubDescription")
            },
            {
              icon: <i className="fas fa-trash table-action-delete"/>,
              description: t("removeClubDescription"),
              onClick: () => setClubToRemove({ ...club })
            },
          ]}
        />
      }
    }
  ]

  return (
    <ReactstrapCard>
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle", clubs.length)}
        buttons={[
          { children: <AddButtonChildren children={t("buttonAddClub")}/>, onClick: () => setClubToUpload({}) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={clubs}
        onRowClick={(club) => setClubToUpload({ ...club })}
        isLoading={isLoading}
        noContentMessage={t("emptyClubs")}
        rowHeight={81}
      />
    </ReactstrapCard>
  );
}

export default ShowClubsCard;