import { defaultAvailabilitySchedule } from "../../components/trainerSchedule/hooks/useTrainerAvailabilitySchedule";
import { v4 as uuid } from "uuid";
import Api from "../Api";

const getProcessedApiSchedule = (schedule) => {
  const realSchedule = schedule ? JSON.parse(schedule) : defaultAvailabilitySchedule
  Object.keys(realSchedule).forEach((key) => realSchedule[key] = realSchedule[key].map((av) => ({
    id: uuid(),
    ...av
  })))
  return realSchedule;
}

const getSchedule = (id) => Api.get(`/admin/schedules/${id}`)
  .then(({ schedule = defaultAvailabilitySchedule, ...other }) => {
    return { schedule: getProcessedApiSchedule(schedule), ...other }
  });
const getScheduleTrainer = (idTrainer) => Api.get(`/admin/schedules/trainer/${idTrainer}`)
  .then(({ schedule = defaultAvailabilitySchedule, ...other }) => {
    return { schedule: getProcessedApiSchedule(schedule), ...other }
  });
const putSchedule = (id, body) => Api.put(`/admin/schedules/${id}`, body)
  .then(({ schedule = defaultAvailabilitySchedule, ...other }) => {
    return { schedule: getProcessedApiSchedule(schedule), ...other }
  });
const postSchedule = (body, trainerId) => Api.post(`/admin/schedules/trainer`, body, { params: { trainerId: trainerId } })
  .then(({ schedule = defaultAvailabilitySchedule, ...other }) => {
    return { schedule: getProcessedApiSchedule(schedule), ...other }
  });
const getMySchedule = () => Api.get(`/admin/schedules/trainer`)
  .then(({ schedule = defaultAvailabilitySchedule, ...other }) => {
    return { schedule: getProcessedApiSchedule(schedule), ...other }
  });
const deleteSchedule = (id) => Api.delete(`/admin/schedules/${id}`);

const scheduleRequests = {
  get: getSchedule,
  delete: deleteSchedule,
  put: putSchedule,
  postSchedule: postSchedule,
  getMySchedule: getMySchedule,
  getTrainerSchedule: getScheduleTrainer
}

export default scheduleRequests