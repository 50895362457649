import React from 'react';
import { Card } from "@general-backoffice/core";
import { Badge, Card as BCard, CardBody, Col, ListGroup, ListGroupItem, Row } from "reactstrap"
import useTranslator from "../../hooks/useTranslator";

const AdvertisementCard = ({
                             title,
                             subtitle,
                             image,
                             description,
                             urlRedirect,
                             onClick,
                             id,
                             onRemove,
                             active,
                             isLoading
                           }) => {
  const t = useTranslator("components.advertising.advertisementCard")

  const onClickRemove = (e) => {
    e.preventDefault();
    e.stopPropagation()
    if (onRemove) onRemove()
  }

  return (
    <BCard onClick={onClick} style={{ cursor: onClick ? "pointer" : "normal" }}>
      <Card.Header
        title={title}
        subtitle={subtitle}
        buttons={[
          { children: <><i className="fas fa-edit"/> {t("edit")}</>, hidden: !onClick, disabled: isLoading },
          {
            children: <><i className="fas fa-trash"/> {t("remove")}</>,
            color: "danger",
            hidden: !onRemove || !id,
            onClick: onClickRemove,
            disabled: isLoading
          }
        ]}
      />
      {isLoading && (
        <CardBody className="flex-center text-center">
          {t("loading")}
        </CardBody>
      )}
      {!isLoading && (
        <>
          <CardBody className="flex-center text-center" hidden={id}>
            {t("unused")}
          </CardBody>
          <CardBody hidden={!id}>
            <Row className="justify-content-center">
              <Col xs={"auto"}>
                <div className="rounded-circle overflow-hidden" style={{ width: 150, height: 150 }}>
                  <img style={{ objectFit: "cover", objectPosition: "center", height: "100%", width: "100%" }}
                       src={image}
                       alt="Advertisement"/>
                </div>
              </Col>
              <Col>
                <ListGroup flush>
                  <ListGroupItem>
                    <a href={urlRedirect} target="_blank" rel="noopener noreferrer">
                      {urlRedirect || " "}
                    </a>
                  </ListGroupItem>
                  <ListGroupItem>
                    {description || " "}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color={active ? "success" : "warning"}>
                      {t("statusText", active)}
                    </Badge>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </CardBody>
        </>
      )}
    </BCard>
  );
};

export default AdvertisementCard;