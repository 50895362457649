import React, { useState } from "react";
import { utils, Modal } from "@general-backoffice/core";
import getDefaultUser from "./shared/getDefaultUser";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import { FormInput } from "@general-backoffice/core/index";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";


const SubscribeUserModal = ({
                              user,
                              close,
                              plans,
                              onSubscribe = utils.noop,
                              isLoading
                            }) => {
  const t = useTranslator("components.users.subscribeUserModal")

  const [state, setState] = useState({ planId: null, subscriptionEnd: null, subscriptionStart: null })
  const { planId, subscriptionEnd, subscriptionStart } = state;
  const { changeState } = useChangingComplexState(setState, isLoading)
  const { id } = { ...getDefaultUser(), ...(user || {}) }


  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubscribe({ ...state, userId: id })
  }

  const plansOptions = plans.map(({ id, name }) => ({ value: id, label: name }));


  return (
    <Modal.FormContainer
      isOpen={!!user}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >

      <FormInput.Select
        label={t("planLabel")}
        name={"planId"}
        onChange={(option) => changeState("planId", option.value)}
        options={plansOptions}
        value={plansOptions.find(({ value }) => value === planId)}
        required={true}
      />

      <FormInput.DatePicker
        onChange={(value) => value && changeState("subscriptionStart", moment(value).utc().format())}
        value={subscriptionStart && moment(subscriptionStart)}
        name={"subscriptionStart"}
        label={t("subscriptionStart")}
        timeFormat={false}
        required={true}
      />

      <FormInput.DatePicker
        onChange={(value) => changeState("subscriptionEnd", moment(value).utc().format())}
        value={subscriptionEnd && moment(subscriptionEnd)}
        name={"subscriptionEnd"}
        label={t("subscriptionEnd")}
        timeFormat={false}
        required={true}
      />

    </Modal.FormContainer>
  );
}

export default SubscribeUserModal;