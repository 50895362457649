import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowChannelManagersCard = ({
                                   channelManagers,
                                   setChannelManagerToUpload,
                                   setChannelManagerToRemove,
                                   onClickChannelManager,
                                   totalAmount,
                                   page,
                                   changePage,
                                   pageAmount,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.channelManagers.showChannelManagersCard")
  const columns = [
    {
      name: t("email"),
      property: "email",
      isTh: true
    },
    {
      name: t("name"),
      formattedContent: ({ firstName = "", lastName }) => `${firstName || ""} ${lastName || ""}`,
    },
    {
      name: "",
      formattedContent: (manager) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setChannelManagerToUpload({ ...manager }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            onClick: () => setChannelManagerToRemove({ ...manager }),
            description: t("removeDescription")
          }
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card>
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("buttonAddAdminText")}/>, onClick: () => setChannelManagerToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={channelManagers}
        onRowClick={(user) => onClickChannelManager({ ...user })}
        isLoading={isLoading}
        noContentMessage={t("emptyAdmins")}
        rowAmount={pageAmount}
      />
      <TableCard.Footer
        amount={pageAmount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => changePage((page + 1))}
        isLoading={isLoading}
      />

    </Card>
  );
}

export default ShowChannelManagersCard;