import React, { useEffect, useState } from "react";
import getDefaultMeetingTemplate from "./shared/getDefaultMeetingTemplate";
import { Modal, FormInput, utils } from "@general-backoffice/core";
import ResizeImageFileModal from "../shared/ResizeImageFileModal";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useMeetingCategories from "./hooks/useMeetingCategories";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedIntensities from "../../hooks/translations/useTranslatedIntensities";

const imageSize = [1280, 720]
const UploadMeetingTemplateModal = ({
                                      meetingTemplate,
                                      setMeetingTemplate,
                                      onSubmit,
                                      isLoading
                                    }) => {
  const t = useTranslator("components.meetings.uploadMeetingTemplateModal")
  const {intensityOptions, getIntensityOption} = useTranslatedIntensities()

  const [isLoadingLocal, setLoadingLocal] = useState(false)
  const [file, setFile] = useState(null)
  const [resizeImage, setResizeImage] = useState(null)
  const { changeState, onChangeInput: onChange } = useChangingComplexState(setMeetingTemplate, isLoading)
  const { meetingCategories, isLoading: isLoadingMeetingCategories } = useMeetingCategories()

  const {
    id,
    category,
    description,
    extraDescription,
    level,
    maxParticipants,
    price,
    templateName,
    image,
    // trainerId
  } = { ...getDefaultMeetingTemplate(), ...(meetingTemplate || {}) };

  const someIsLoading = isLoading || isLoadingLocal || isLoadingMeetingCategories;
  const categoriesOptions = meetingCategories.map(({ id, name }) => ({ value: id, label: name }));


  useEffect(() => {
    if (!image?.url) return;
    setFile(null)
    setLoadingLocal(true)
    utils.urlToFile(image.url, image.url.split("/").pop(), image.contentType)
      .then((file) => setFile(file))
      .catch(() => setFile(null))
      .finally(() => setLoadingLocal(false))
    // eslint-disable-next-line
  }, [image?.url])


  const onSubmitForm = async (event) => {
    event.preventDefault();
    onSubmit()
  }

  return (
    <React.Fragment>
      <ResizeImageFileModal
        imageFile={resizeImage}
        setImageFile={setResizeImage}
        onSave={(file, image64) => {
          setFile(file)
          changeState(`image`, { id: null, data: image64 })
          setResizeImage(null)
        }}
        width={imageSize[0]}
        height={imageSize[1]}
        maxSizeMB={0.3}
      />

      <Modal.FormContainer
        isOpen={!!meetingTemplate}
        toggleOpen={() => setMeetingTemplate(null)}
        title={t("title", !!id, templateName)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
      >

        <FormInput.Input
          type={"text"}
          onChange={onChange}
          value={templateName}
          name={"templateName"}
          label={t("nameLabel")}
          required={true}
        />

        <FormInput.Select
          label={t("categoryLabel")}
          name={"categoryId"}
          onChange={(option) => changeState("category", option.value)}
          options={categoriesOptions}
          value={categoriesOptions.find(({ value }) => value === category)}
          required={true}
        />

        <FormInput.Select
          label={t("intensityLabel")}
          icon={<i className="fas fa-dumbbell mx-2"/>}
          onChange={(option) => changeState("level", option.value)}
          options={intensityOptions}
          value={getIntensityOption(level)}
          required={true}
        />

        <FormInput.Input
          type={"textarea"}
          onChange={onChange}
          value={description}
          name={"description"}
          label={t("descriptionLabel")}
        />

        <FormInput.Quill
          onChange={(value) => changeState("extraDescription", value)}
          value={extraDescription}
          label={t("complementaryDescriptionLabel")}
        />

        <FormInput.SingleDropzone
          label={t("imageLabel") + " (JPG, PNG)"}
          smallLabel={imageSize.join(" x ")}
          accept={".jpg, .jpeg, .png"}
          file={file}
          onDrop={(file) => setResizeImage(file)}
        />

        <FormInput.Input
          type={"number"}
          onChange={(e) => changeState("maxParticipants", (parseInt(e.target.value) + 1))}
          value={(maxParticipants - 1)}
          min={1}
          max={9}
          name={"maxParticipants"}
          label={t("numMaxParticipantsLabel")}
          required={true}
          description={t("numMaxParticipantsDescription", maxParticipants)}
        />

        <FormInput.Price
          onChange={onChange}
          value={price}
          name={"price"}
          label={t("priceLabel")}
          required={true}
        />

      </Modal.FormContainer>

    </React.Fragment>
  );
}

export default UploadMeetingTemplateModal;