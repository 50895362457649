import {
  CHANGE_CLUB,
  LOGIN,
  LOGOUT
} from "./actions";
import Api from "../../../api/Api";
import ApiUltrik from "../../../api/ApiUltrik";
import { sessionVersion as actualSessionVersion } from "../../../config/configuration";
import moment from "moment";

const initialState = {
  token: null,
  user: {
    id: null,
    verified: false,
    email: "",
    clubList: [],
  },
  club: null,
  sessionVersion: null
};

const state = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      Api.token = action.payload.token;
      ApiUltrik.token = action.payload.token;
      Api.params = action.payload.club ? { club: action.payload.club.clubId } : {}
      ApiUltrik.params = action.payload.club ? { clubId: action.payload.club.clubId } : {}
      moment.locale(action.payload.club.clubProperties?.language?.ietfTag)
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        club: action.payload.club,
        sessionVersion: actualSessionVersion
      };
    }
    case CHANGE_CLUB: {
      Api.params = { club: action.payload.club.clubId }
      ApiUltrik.params = { clubId: action.payload.club.clubId }
      ApiUltrik.token = action.payload.club?.transcodificationToken;
      moment.locale(action.payload.club.clubProperties?.language?.ietfTag)
      return {
        ...state,
        club: action.payload.club
      };
    }
    case LOGOUT: {
      Api.token = null;
      ApiUltrik.token = null;
      Api.params = {};
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default state;
