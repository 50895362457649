import React, { useEffect, useState, useRef } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { Card, Loading, TableNoContentMessage } from "@general-backoffice/core";
import moment from "moment";

import TimePerZoneBars from "../shared/TimePerZoneBars"
import { colors, maxFtp, zones } from "../config";


const StatsTrainingCyclingZones = ({
                                     structure = [],
                                     isLoading,
                                     title = "Gráfica de entrenamiento"
                                   }) => {
  const [formatedZones, setFormatedZones] = useState(null)
  const timeOutRef = useRef(null)

  let totalDuration = 0;
  let totalFTP = 0;
  structure.forEach(({duration, ftp: [initFtp, endFtp]}) => {
    totalDuration += duration
    totalFTP += (initFtp + endFtp) / 2
  })

  useEffect(() => {
    clearTimeout(timeOutRef.current)
    timeOutRef.current = setTimeout(() => {
      setFormatedZones(
        [...zones].map(({ ftp, zone, colors }, index) => {
          const nextZone = zones[index + 1];
          const { ftp: nextFtp = maxFtp + 1 } = (nextZone || {})

          const zoneSegments = structure.filter(segment => {
            const [initFtp, endFtp] = (segment.ftp || []);
            const segmentFtp = (initFtp + endFtp) / 2;
            return (segmentFtp >= ftp) && (segmentFtp < nextFtp)
          })
          
          let duration = 0;
          let standingDuration = 0;
          zoneSegments.forEach(({duration: segmentDuration, standing}) => {
            duration += segmentDuration || 0
            if(standing) standingDuration += segmentDuration
          })
          return {zone, color: colors?.main, duration, standingDuration}
        })
      )
    }, 100)

    return () => clearTimeout(timeOutRef.current)
    // eslint-disable-next-line
  }, [totalDuration, totalFTP, zones])

  const hasBars = totalDuration > 0 && formatedZones;

  return (
    <React.Fragment>
      <ReactstrapCard style={{ backgroundColor: colors.dark }}>
        <Card.Header
          isDark={true}
          title={title}
          subtitle={
            `Duración total: ${moment.utc(totalDuration * 1000).format('HH:mm:ss')}`
          }
        />
        <CardBody className="pt-0">
          {isLoading && <Loading.BouncingDots className="my-5" color={"light"}/>}
          {(!hasBars && !isLoading) &&  <TableNoContentMessage children={"No hay datos suficientes"}/>}
          {(hasBars && !isLoading) && 
            <TimePerZoneBars 
              zonesData={formatedZones} 
              totalDuration={totalDuration} 
              className="text-white metropolis"
            />
          }
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsTrainingCyclingZones;