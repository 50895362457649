import { useEffect, useState } from "react";
import { channelManagerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import getDefaultChannelManager from "../shared/getDefaultChannelManager";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useChannelManager = (id) => {

  const [channelManager, setChannelManager] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const channelManagerId = channelManager?.id || id

  useEffect(() => {
    if (!!channelManagerId) refresh()
    // eslint-disable-next-line
  }, [channelManagerId])

  const refresh = async () => {
    setIsLoading(true)
    return getChannelManager(channelManagerId)
      .then((channelManager) => {
        setChannelManager(channelManager)
        return channelManager
      })
      .catch((e) => {
        setChannelManager(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveChannelManager(channelManager)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    setIsLoading(true)
    return removeChannelManager(channelManager)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    channelManager,
    setChannelManager,
    refresh,
    save,
    remove,
    isLoading
  }
}

export const getChannelManager = async (channelManagerId) => {
  const t = hardTranslator("components.channelManagers.hooks.useChannelManager")
  try {
    return await channelManagerRequests.getInfo(channelManagerId)
  } catch (e) {
    const message = e?.message || t("getChannelManagerError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveChannelManager = async (channelManager) => {
  const t = hardTranslator("components.channelManagers.hooks.useChannelManager")
  const notification = notify.loading(t("saveChannelManagerLoading", channelManager?.id))
  try {
    const { subscriptionList, ...cleanManager } = { ...getDefaultChannelManager(), ...channelManager }
    if (!!channelManager?.id) {
      await channelManagerRequests.put(channelManager.id, cleanManager)
      notifyLoadingToSuccess(notification, t("saveChannelManagerSucceed", true))
    } else {
      await channelManagerRequests.post(cleanManager)
      notifyLoadingToSuccess(notification, t("saveChannelManagerSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveChannelManagerError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export const removeChannelManager = async (channelManager) => {
  const t = hardTranslator("components.channelManagers.hooks.useChannelManager")
  const notification = notify.loading(t("removeChannelManagerLoading"))
  try {
    await channelManagerRequests.delete(channelManager.id)
    notifyLoadingToSuccess(notification, t("removeChannelManagerSucceed"))
  } catch (e) {
    const message = e?.message || t("removeChannelManagerError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useChannelManager;