const getDefaultTrainer = () => ({
  active: true,
  publisher: false,
  email: "",
  description: "",
  image: { url: null, data: null, contentType: null },
  thumbnail: { url: null, data: null, contentType: null },
  name: "",
  type: "",
  templates: [],
})

export default getDefaultTrainer;