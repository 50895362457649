import React, { useEffect } from "react";
import { Modal, notify, FormInput, FormDecoration, useFormInputValidation } from "@general-backoffice/core";
import moment from "moment";
import classNames from "classnames";
import { Collapse, Badge, Row, Col, Progress } from "reactstrap";
import { useSelector } from "react-redux";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import getDefaultProduct from "./shared/getDefaultProduct";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useLiveStreams from "./hooks/useLiveStreams";
import SwitchWithChildren from "../shared/SwitchWithChildren";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedLevels from "../../hooks/translations/useTranslatedLevels";
import useApiFileImages from "../../hooks/useApiFileImages";
import useTrans from "../trans/hooks/useTrans";
import useProcessingTrans from "../trans/hooks/useProcessingTrans";
import { getFileIdentification, useTranscodeContext } from "../../contexts/TranscodeWrapper";
import usePrevious from "../../hooks/usePrevious";

const imagesStructure = {
  thumbnail: {
    size: [1040, 585], // AR: 16:9
    MB: 0.2
  },
  thumbnailPortrait: {
    size: [640, 960], // AR: 2:3
    MB: 0.2
  },
  image: {
    size: [1920, 1080], // AR: 16:9
    MB: 0.6
  },
  imagePortrait: {
    size: [1080, 1920], // AR: 9:16
    MB: 0.6
  },
}

const UploadProductModal = ({
                              product = null,
                              setProduct,
                              categories: allCategories = [],
                              tags = [],
                              plans = [],
                              collections = [],
                              setCollectionToUpload,
                              setCategoryToUpload,
                              setTagToUpload,
                              setPlanToUpload,
                              onSubmit,
                              isLoading
                            }) => {
  const t = useTranslator("components.products.uploadProductModal")
  const { levelOptions, getLevelOption } = useTranslatedLevels()
  const { club } = useSelector((state) => state.sessionState)
  const { liveStreams, isLoading: isLoadingLiveStreams } = useLiveStreams()
  const {
    changeState,
    onChangeInput: onChange,
    changeMultipleStates,
    forceChangeState
  } = useChangingComplexState(setProduct, isLoading)
  const {
    uploadFiles: uploadFilesToTranscodify,
    files: filesUploadingToTranscodify,
    pausedUploads: pausedFilesUploadingToTranscodify
  } = useTranscodeContext()
  const allFilesToTranscodify = [...filesUploadingToTranscodify, ...pausedFilesUploadingToTranscodify]
  const prevFilesUploadingToTranscodify = usePrevious(filesUploadingToTranscodify)

  const categories = allCategories.filter(({ type }) => type === "PRODUCT")

  const {
    id,
    code,
    published,
    premiere,
    collectionIdList,
    publicationDate,
    publicationEndDate,
    premiereEndDate,
    title,
    subtitle,
    description,
    level,
    mentalLevel,
    technicalLevel,
    physicalLevel,
    categoryIdList,
    tagList,
    subscriptionPlanIdList,
    price,
    image,
    imagePortrait,
    thumbnail,
    thumbnailPortrait,
    liveSchedule,
    video,
    trailer,
    clubId = null
  } = { ...getDefaultProduct(), ...(product || {}) };

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image, thumbnail, imagePortrait, thumbnailPortrait }, [id, !!product])

  const {
    trans,
    isLoading: isLoadingTrans,
    refresh: refreshTrans
  } = useTrans()

  const {
    trans: progressingTrans,
    isLoading: isLoadingProgressingTrans,
    refresh: refreshProgressingTrans
  } = useProcessingTrans()

  const allTrans = [...progressingTrans, ...trans]

  useEffect(() => {
    const prev = prevFilesUploadingToTranscodify || []
    const curr = filesUploadingToTranscodify || []
    if (prev.length > curr.length) {
      refreshProgressingTrans().then(() => null)
      refreshTrans().then(() => null)
    }
    // eslint-disable-next-line
  }, [filesUploadingToTranscodify]);

  const {
    inputRef: publicationDateInputRef,
    throwError: throwErrorPublicationDate
  } = useFormInputValidation(publicationDate)

  const {
    inputRef: publicationEndDateInputRef,
    throwError: throwErrorPublicationEndDate
  } = useFormInputValidation(publicationEndDate)

  const {
    inputRef: premiereEndDateInputRef,
    throwError: throwErrorPremiereEndDate
  } = useFormInputValidation(premiereEndDate)

  const plansOptions = plans.map(({ id, name, ...plan }) => ({ value: id, label: name, ...plan }));
  const categoryOptions = categories.map(({ id, name }) => ({ value: id, label: name }));
  const tagOptions = tags.map(({ id, name }) => ({ value: id, label: name }));
  const collectionOptions = collections.map(({ id, title }) => ({ value: id, label: title }));
  const transcodificationOptions = allTrans.map(({ uuid, filename }) => ({
    value: uuid,
    label: filename
  }));

  const liveStreamsOptions = liveStreams.map(({ liveStreamId, name, ...ls }) => ({
    value: liveStreamId,
    label: name,
    ...ls
  }));

  liveStreamsOptions.unshift({
    value: "FAKE",
    label: t("fakeLiveStreamOption"),
  })

  liveStreamsOptions.unshift({
    value: undefined,
    label: t("noLiveStreamOption"),
  })

  const liveStreamCamaraUrl = liveStreamsOptions.find(({ value }) => value === liveSchedule?.liveStreamId)?.cameraUrl;

  const isLoadingTranscodification = isLoadingTrans || isLoadingProgressingTrans
  const someIsLoading = (isLoading || isLoadingLiveStreams || isLoadingFiles);
  const isSameClub = clubId ? (club.clubId.toString() === clubId.toString()) : true

  const onSubmitForm = async () => {
    if (publicationDate && !moment(publicationDate).isValid()) return throwErrorPublicationDate("Fecha inválida")
    if (publicationEndDate && !moment(publicationEndDate).isValid()) return throwErrorPublicationEndDate("Fecha inválida")
    if (premiere && premiereEndDate && !moment(premiereEndDate).isValid()) return throwErrorPremiereEndDate("Fecha inválida")
    onSubmit();
  }

  const descriptionLive = <FormDecoration.TextToCopy
    text={liveStreamCamaraUrl} children={t("copyUrlCamText")}
    callback={() => notify.success(t("copyUrlCamSucceed"))}
  />
  const canUseCustomPublicationEndDate = published && isSameClub
  const isUsingCustomPublicationEndDate = Boolean(published && !!publicationEndDate)
  const canUseCustomPremiereDates = premiere && isSameClub
  const isUsingCustomPremiereDates = Boolean(premiere && premiereEndDate)

  let liveStreamSelected = liveStreamsOptions[0]

  if (!!liveSchedule) {
    const currentLiveStream = liveStreamsOptions.find(({ value }) => value === liveSchedule?.liveStreamId)
    liveStreamSelected = currentLiveStream || liveStreamsOptions[1]
  }

  const uuidVideoTranscodification = video?.transcodificationId
  const uuidTrailerTranscodification = trailer?.transcodificationId

  const fileVideoUploadingToTranscodify = allFilesToTranscodify.find((f) => f.uuid === uuidVideoTranscodification)
  const fileTrailerUploadingToTranscodify = allFilesToTranscodify.find((f) => f.uuid === uuidTrailerTranscodification)

  const isTranscodifyingVideo = !!video.transcodificationId && !video.hlsUrl
  const isTranscodifyingTrailer = !!trailer.transcodificationId && !trailer.hlsUrl

  return (
    <React.Fragment>

      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        size={"lg"}
        isOpen={!!product}
        toggleOpen={() => setProduct(null)}
        title={t("title", !!id, code)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
      >

        <div className="d-flex justify-content-end mb-4 mt--4" hidden={(isSameClub && !isTranscodifyingVideo) || isLoading}>
          {!isSameClub && <Badge children={t("badgeSharedProduct")} color={"warning"}/>}
          {isTranscodifyingVideo && <Badge children={t("badgeTranscodifyingVideo")} color={"warning"}/>}
          {isTranscodifyingTrailer && <Badge children={t("badgeTranscodifyingTrailer")} color={"warning"}/>}
        </div>

        <Row>
          <Col>
            <FormInput.Input
              type={"text"}
              onChange={onChange}
              value={code}
              name={"code"}
              label={t("codeLabel")}
              placeholder={t("codePlaceholder")}
              disabled={!!id}
              required
            />

            <FormInput.Input
              onChange={({ target: { value } }) => changeState("title", value)}
              value={title}
              label={t("titleLabel")}
              required
              disabled={!isSameClub}
              error={(title || "").length > 50 ? t("titleErrorNumMaxChars") : ""}
            />

            <FormInput.Input
              onChange={({ target: { value } }) => changeState("subtitle", value)}
              value={subtitle}
              label={t("subtitleLabel")}
              disabled={!isSameClub}
              error={(subtitle || "").length > 50 ? t("subtitleErrorNumMaxChar") : ""}
            />

          </Col>
          <Col xs={12} md={"auto"} className="px-0">
            <FormDecoration.Line vertical={true}/>
          </Col>
          <Col>
            <SwitchWithChildren
              onChange={({ target: { checked } }) => {
                if (checked) changeMultipleStates({
                  published: checked,
                  publicationEndDate: null,
                  publicationDate: moment().format("YYYY-MM-DD")
                })
                else changeMultipleStates({ published: checked, publicationEndDate: null, publicationDate: null })
              }}
              checked={published}
              label={t("isPublishedLabel")}
              labels={t("isPublishedAnswers")}
              disabled={!isSameClub}
            >
              <i
                title={t("endPublicationIconDescription")}
                className={classNames("fas fa-calendar-times cursor-pointer", {
                  "cursor-pointer": canUseCustomPublicationEndDate,
                  "text-primary": isUsingCustomPublicationEndDate
                })}
                style={{ opacity: canUseCustomPublicationEndDate ? 1 : 0.5 }}
                onClick={() => {
                  if (!canUseCustomPublicationEndDate) return;
                  if (!publicationEndDate) changeState("publicationEndDate", moment(publicationDate).add(1, "day").format("YYYY-MM-DD"))
                  else changeMultipleStates({ publicationEndDate: null })
                }}
              />
            </SwitchWithChildren>

            <Collapse isOpen={published}>
              <FormInput.DatePicker
                innerRef={publicationDateInputRef}
                onChange={(value) => value && changeState("publicationDate", moment(value).format("YYYY-MM-DD"))}
                value={publicationDate && moment(publicationDate, "YYYY-MM-DD")}
                name={"publicationDate"}
                label={t("publicationDateLabel")}
                timeFormat={false}
                disabled={!isSameClub}
                required={published}
              />
            </Collapse>

            <Collapse isOpen={isUsingCustomPublicationEndDate}>
              <FormInput.DatePicker
                innerRef={publicationEndDateInputRef}
                onChange={(value) => changeState("publicationEndDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                value={publicationEndDate && moment(publicationEndDate, "YYYY-MM-DD")}
                name={"publicationEndDate"}
                label={t("publicationEndDateLabel")}
                timeFormat={false}
                required={isUsingCustomPublicationEndDate}
                disabled={!isSameClub}
              />
            </Collapse>

            <SwitchWithChildren
              onChange={({ target: { checked } }) => {
                if (checked) changeState("premiere", checked)
                else changeMultipleStates({ premiere: checked, premiereEndDate: null })
              }}
              checked={premiere}
              label={t("isPremiereLabel")}
              labels={t("isPremiereAnswers")}
              disabled={!isSameClub}
            >
              <i
                title={t("endPremiereIconDescription")}
                className={classNames("fas fa-calendar-day cursor-pointer", {
                  "cursor-pointer": canUseCustomPremiereDates,
                  "text-primary": isUsingCustomPremiereDates
                })}
                style={{ opacity: canUseCustomPremiereDates ? 1 : 0.5 }}
                onClick={() => {
                  if (!canUseCustomPremiereDates) return;
                  if (!premiereEndDate) changeState("premiereEndDate", moment().add(1, "week").format("YYYY-MM-DD"))
                  else changeState("premiereEndDate", null)
                }}
              />
            </SwitchWithChildren>

            <Collapse isOpen={isUsingCustomPremiereDates}>
              <FormInput.DatePicker
                innerRef={premiereEndDateInputRef}
                onChange={(value) => changeState("premiereEndDate", value ? moment(value).format("YYYY-MM-DD") : null)}
                value={premiereEndDate && moment(premiereEndDate)}
                name={"premiereEndDate"}
                label={t("premiereEndDateLabel")}
                timeFormat={false}
                disabled={!isSameClub}
              />
            </Collapse>

            <FormInput.Switch
              onChange={({ target: { checked } }) => changeState("price", checked ? { amount: 5.00 } : null)}
              checked={price}
              name={"price"}
              label={t("isRentalLabel")}
              labels={t("isRentalAnswer")}
              disabled={!isSameClub}
            />

            <Collapse isOpen={!!price}>
              <FormInput.Price
                onChange={onChange}
                value={price?.amount}
                name={"price.amount"}
                label={t("priceRentalLabel")}
                disabled={!price || !isSameClub}
                required={!!price}
              />
            </Collapse>
          </Col>
        </Row>

        <FormDecoration.LineTitle title={t("live")}/>

        <Row>
          <Col xs={12}>
            <FormInput.Select
              label={t("cameraLabel")}
              tooltip={t("cameraTooltip")}
              name={"camera"}
              isMulti={false}
              icon={<i className="fas fa-video alt px-2"/>}
              onChange={(option) => {
                changeState("video.hlsUrl", "")
                if (!option.value) {
                  changeState("liveSchedule", null)
                } else {
                  changeState("liveSchedule", {
                    start: moment().add(1, "h").utc().format(),
                    end: moment().add(2, "h").utc().format(),
                    productId: id,
                    liveStreamId: option.value === "FAKE" ? null : option.value
                  })
                }
              }}
              options={liveStreamsOptions}
              value={liveStreamSelected}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              description={liveStreamCamaraUrl ? descriptionLive : null}
              error={(liveStreamsOptions.length <= 0) && t("isFakeLiveErrorNoServers")}
            />
          </Col>
          <Col xs={12} md={6}>
            <Collapse isOpen={!!liveSchedule}>
              <FormInput.DatePicker
                onChange={(value) => changeState("liveSchedule.start", moment(value).utc().format())}
                value={liveSchedule?.start ? moment(liveSchedule.start) : null}
                name={"liveSchedule.start"}
                label={t("liveScheduleStartLabel")}
                disabled={!liveSchedule || !isSameClub}
                required={!!liveSchedule}
              />
            </Collapse>
          </Col>
          <Col xs={12} md={6}>
            <Collapse isOpen={!!liveSchedule}>
              <FormInput.DatePicker
                onChange={(value) => changeState("liveSchedule.end", moment(value).utc().format())}
                value={liveSchedule?.end ? moment(liveSchedule.end) : null}
                name={"liveSchedule.end"}
                label={t("liveScheduleEndLabel")}
                disabled={!liveSchedule || !isSameClub}
              />
            </Collapse>
          </Col>
        </Row>

        <FormDecoration.LineTitle title={t("segmentation")}/>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("plansLabel")}
              tooltip={t("plansTooltip")}
              name={"subscriptionPlanIdList"}
              isMulti={true}
              icon={<i className="fas fa-calendar alt px-2"/>}
              onChange={(options) => changeState("subscriptionPlanIdList", options.map(({ value }) => value))}
              options={plansOptions}
              value={plansOptions.filter(({ value }) => subscriptionPlanIdList.includes(value))}
              isCreatable={true}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              onCreateOption={(inputValue) => setPlanToUpload({ name: inputValue })}
              formatCreateLabel={(inputValue) => t("planCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noPlanOptionsText")}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("categoriesLabel")}
              name={"categoryIdList"}
              isMulti={true}
              icon={<i className="fas fa-bars px-2"/>}
              onChange={(options = []) => changeState("categoryIdList", options.map(({ value }) => value))}
              options={categoryOptions}
              value={categoryOptions.filter(({ value }) => categoryIdList.includes(value))}
              isDisabled={!isSameClub || someIsLoading}
              isLoading={someIsLoading || someIsLoading}
              isCreatable={true}
              onCreateOption={(inputValue) => setCategoryToUpload({ name: inputValue, type: "PRODUCT" })}
              formatCreateLabel={(inputValue) => t("categoryCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noCategoryOptionsText")}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("collectionsLabel")}
              name={"collectionIdList"}
              isMulti={true}
              icon={<i className="fas fa-boxes px-2"/>}
              onChange={(options = []) => changeState("collectionIdList", options.map(({ value }) => value))}
              options={collectionOptions}
              value={collectionOptions.filter(({ value }) => collectionIdList.includes(value))}
              isDisabled={!isSameClub || someIsLoading}
              isCreatable={true}
              isLoading={someIsLoading || someIsLoading}
              onCreateOption={(inputValue) => setCollectionToUpload({ title: inputValue })}
              formatCreateLabel={(inputValue) => t("collectionCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noCollectionOptionsText")}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("tagsLabel")}
              name={"categoryId"}
              isMulti={true}
              icon={<i className="fas fa-tag px-2"/>}
              onChange={(options) => changeState("tagList", options.map(({ value }) => value))}
              options={tagOptions}
              value={tagOptions.filter(({ value }) => (tagList || []).includes(value))}
              isDisabled={!isSameClub || someIsLoading}
              isCreatable={true}
              isLoading={someIsLoading}
              onCreateOption={(inputValue) => setTagToUpload({ name: inputValue })}
              formatCreateLabel={(inputValue) => t("tagCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noTagOptionsText")}
            />
          </Col>
        </Row>

        <FormDecoration.LineTitle title={t("separatorLineImages")}/>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("thumbnailLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.thumbnail.size.join(" x ")}
              tooltip={t("thumbnailTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnail}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnail: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, thumbnail: files.thumbnail })
                }
              ]}
              disabled={!isSameClub}
              required
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("thumbnailPortraitLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.thumbnailPortrait.size.join(" x ")}
              tooltip={t("thumbnailPortraitTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnailPortrait}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnailPortrait: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, thumbnailPortrait: files.thumbnailPortrait })
                }
              ]}
              disabled={!isSameClub}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("imageLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.image.size.join(" x ")}
              tooltip={t("imageTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.image}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, image: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, image: files.image })
                }
              ]}
              disabled={!isSameClub}
              required
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("imagePortraitLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.imagePortrait.size.join(" x ")}
              tooltip={t("imagePortraitTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.imagePortrait}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, imagePortrait: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, imagePortrait: files.imagePortrait })
                }
              ]}
              disabled={!isSameClub}
            />
          </Col>
        </Row>

        <FormDecoration.LineTitle title={t("separatorLineDescription")}/>

        <FormInput.Quill
          onChange={(value) => {
            !!product && setProduct((currentProduct) => (!!currentProduct
                ? { ...currentProduct, description: value }
                : currentProduct
            ))
          }}
          value={description}
          label={t("descriptionLabel")}
          disabled={!isSameClub}
        />

        <Row>
          <Col>
            <FormInput.Select
              label={t("generalLevelLabel")}
              icon={<i className="fas fa-dumbbell mx-2"/>}
              onChange={(option) => changeState("level", option?.value || null)}
              options={levelOptions}
              value={getLevelOption(level)}
              isClearable={true}
              isDisabled={!isSameClub}
            />
          </Col>
          <Col xs={12} md={"auto"} className="px-0">
            <FormDecoration.Line vertical={true}/>
          </Col>
          <Col>
            <FormInput.Select
              label={t("technicalLevelLabel")}
              value={getLevelOption(technicalLevel)}
              options={levelOptions}
              onChange={(option) => changeState("technicalLevel", option?.value || null)}
              isClearable={true}
              isDisabled={!isSameClub}
            />

            <FormInput.Select
              label={t("mentalLevelLabel")}
              value={getLevelOption(mentalLevel)}
              options={levelOptions}
              onChange={(option) => changeState("mentalLevel", option?.value || null)}
              isClearable={true}
              isDisabled={!isSameClub}
            />

            <FormInput.Select
              label={t("physicalLevelLabel")}
              value={getLevelOption(physicalLevel)}
              options={levelOptions}
              onChange={(option) => changeState("physicalLevel", option?.value || null)}
              isClearable={true}
              isDisabled={!isSameClub}
            />
          </Col>
        </Row>

        <FormDecoration.LineTitle title={t("separatorLineContent")}/>


        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              className="mb--3"
              label={t("videoUrlLabel")}
              value={transcodificationOptions.find(({ value }) => value === video.transcodificationId)}
              options={transcodificationOptions}
              onChange={(option) => changeState("video", { transcodificationId: option?.value || null })}
              isClearable={true}
              isDisabled={!isSameClub || isLoadingTranscodification}
              isLoading={isLoadingTranscodification}
            />
            <FormInput.SingleDropzone
              file={fileVideoUploadingToTranscodify}
              disabled={isLoading}
              accept={"video/*"}
              files={[]}
              description={fileVideoUploadingToTranscodify && !fileVideoUploadingToTranscodify?.progress ? t("progressTranscodePending") : ""}
              onDrop={(file) => {
                const fileIdentification = getFileIdentification(file)
                const coincidence = allTrans.find((t) => t.uuid === fileIdentification.uuid)
                if (!coincidence) uploadFilesToTranscodify([file])
                forceChangeState("video", { transcodificationId: fileIdentification.uuid })
              }}
            />
            {fileVideoUploadingToTranscodify?.progress && (
              <Progress
                className="w-100 mt--3"
                style={{ height: '20px' }}
                value={fileVideoUploadingToTranscodify?.progress || 0}
                children={`${fileVideoUploadingToTranscodify?.progress || "0.00"}%`}
              />
            )}
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              className="mb--3"
              label={t("trailerUrlLabel")}
              value={transcodificationOptions.find(({ value }) => value === trailer.transcodificationId)}
              options={transcodificationOptions}
              onChange={(option) => changeState("trailer", { transcodificationId: option?.value || null })}
              isClearable={true}
              isDisabled={!isSameClub || isLoadingTranscodification}
              isLoading={isLoadingTranscodification}
            />
            <FormInput.SingleDropzone
              file={fileTrailerUploadingToTranscodify}
              disabled={isLoading}
              accept={"video/*"}
              description={fileTrailerUploadingToTranscodify && !fileTrailerUploadingToTranscodify?.progress ? t("progressTranscodePending") : ""}
              onDrop={(file) => {
                const fileIdentification = getFileIdentification(file)
                const coincidence = allTrans.find((t) => t.uuid === fileIdentification.uuid)
                if (!coincidence) uploadFilesToTranscodify([file])
                forceChangeState("trailer", { transcodificationId: fileIdentification.uuid })
              }}
            />
            {fileTrailerUploadingToTranscodify?.progress && (
              <Progress
                className="w-100 mt--3"
                style={{ height: '20px' }}
                value={fileTrailerUploadingToTranscodify?.progress || 0}
                children={`${fileTrailerUploadingToTranscodify?.progress || "0.00"}%`}
              />
            )}
          </Col>
        </Row>

      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadProductModal;