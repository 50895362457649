import { useState } from "react";

const useTrainerAvailability = () => {
  const [availability, setAvailability] = useState(null)
  // const [isLoading, setIsLoading] = useState(false)

  return {
    availability,
    setAvailability,
    // isLoading,
  }
}
export default useTrainerAvailability;