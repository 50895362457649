import { useEffect, useState } from "react";
import { utils } from "@general-backoffice/core";

const useApiFileImages = (images, deps = []) => {
  const initialFiles = getInitialFiles(images)
  const [files, setFiles] = useState({ ...initialFiles })
  const [imagesToResize, setImagesToResize] = useState({ ...initialFiles })
  const [isLoadingFiles, setIsLoadingFiles] = useState(false)

  const refreshFiles = async () => {
    const needsRefresh = Object.values(images).find((image) => !!image?.url)
    if (!needsRefresh) return;
    setIsLoadingFiles(true)
    const files = { ...initialFiles };
    for await (const [key, image] of Object.entries(images)) {
      if (!!image?.url) {
        files[key] = await utils.urlToFile(image?.url, image?.url.split("/").pop(), image?.contentType)
      }
    }
    setFiles(files)
    setIsLoadingFiles(false)
  }

  useEffect(() => {
    setFiles({ ...initialFiles })
    refreshFiles()
    // eslint-disable-next-line
  }, deps)

  return {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  }
}

const getInitialFiles = (images) => {
  const initialFiles = {}
  Object.keys(images).map((key) => initialFiles[key] = null)
  return initialFiles
}

export default useApiFileImages;