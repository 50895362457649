import Api from "../Api";

const getChannelManager = (id) => Api.get(`/admin/users/${id}`);
const getChannelManagerInfo = (id) => Api.get(`/admin/users/${id}/info`);
const putChannelManager = (id, body) => Api.put(`/admin/users/${id}/admin-edit`, body);
const getChannelManagersList = () => Api.get(`/admin/users/channel-manager/list`);
const deleteChannelManager = (id) => Api.delete(`/admin/users/channel-manager/${id}`);
const postChannelManager = (body) => Api.post(`/admin/users/channel-manager`, body);

const channelManagerRequests = {
  get: getChannelManager,
  getInfo: getChannelManagerInfo,
  getList: getChannelManagersList,
  delete: deleteChannelManager,
  put: putChannelManager,
  post: postChannelManager
}

export default channelManagerRequests;