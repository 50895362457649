import { useEffect, useState } from "react";
import { trainerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { useSelector } from "react-redux";
import { roles } from "../../../utils/roles";
import { hardTranslator } from "../../../hooks/useTranslator";

const useMeetingTemplates = (trainerId) => {
  const { roleList = [] } = useSelector(({ sessionState }) => sessionState.club)
  const [meetingTemplates, setMeetingTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const isTrainer = (roleList || []).find((role) => role === roles.TRAINER)

  useEffect(() => {
    if (!!trainerId || isTrainer) refresh()
    // eslint-disable-next-line
  }, [trainerId, isTrainer])

  const refresh = async () => {
    setIsLoading(true)
    return getMeetingTemplates(trainerId)
      .then((meetingTemplates) => {
        setMeetingTemplates(meetingTemplates)
        return meetingTemplates
      })
      .finally(() => setIsLoading(false))
  }

  return {
    meetingTemplates,
    refresh,
    isLoading
  }
}

export const getMeetingTemplates = async (trainerId) => {
  const t = hardTranslator("components.meetings.hooks.useMeetingsTemplates")

  try {
    const { templates = [] } = await trainerRequests.getTrainerTemplates(trainerId)
    return templates
  } catch (e) {
    const message = e?.message || t("getMeetingTemplatesError")
    notify.error(message)
    throw message
  }
}

export default useMeetingTemplates;