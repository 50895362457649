import Api from "../Api";

const getProduct = (id) => Api.get(`/admin/spd-product/${id}`);
const putProduct = (id, body) => Api.put(`/admin/spd-product`, body);
const postProduct = (body) => Api.post(`/admin/spd-product`, body);
const getCameras = () => Api.get(`/admin/spd-product/camera/list`);
const getStoryboards = () => Api.get(`/admin/spd-product/storyboard/list`);
const getGraphicPackages = (sport) => Api.get(`/admin/spd-product/graphic-package/list`, { params: { gameType: sport } });

const productSPDRequests = {
  get: getProduct,
  getCameras: getCameras,
  getStoryboards: getStoryboards,
  getGraphicPackages: getGraphicPackages,
  put: putProduct,
  post: postProduct
}

export default productSPDRequests