import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";
import useRequestControl from "../../hooks/useRequestControl";

const RemoveModal = ({ isOpen, onClose, onSubmit }) => {
  const t = useTranslator("components.shared.removeModal")
  const {
    isLoading,
    completeRequest,
    initiateRequest
  } = useRequestControl()

  const remove = async () => {
    initiateRequest()
    await onSubmit()
    completeRequest()
  }

  return (
    <Modal.DeleteConfirm
      isOpen={isOpen}
      isLoading={isLoading}
      toggleOpen={onClose}
      titleDelete={t("title")}
      descriptionDelete={t("content")}
      closeText={t("cancel")}
      buttonDelete={{ children: t("accept"), onClick: remove }}
    />
  );
}

export default RemoveModal;