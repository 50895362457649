import { useMemo } from "react";
import useTranslator from "../useTranslator";

const currencies = ["EUR", "USD"]
const useTranslatedCurrencies = () => {
  const t = useTranslator("complexCurrencies")

  const translatedCurrencies = useMemo(() => getTranslatedCurrencies(t), [t])
  const currencyOptions = useMemo(() => getCurrencyOptions(t), [t])
  const getCurrencyOption = (v) => findCurrencyOption(t, v)

  return {
    currencies,
    translatedCurrencies,
    currencyOptions,
    getCurrencyOption
  }
}

const getTranslatedCurrencies = (t) => {
  return currencies.reduce((translated, currency) => {
    translated[currency] = t(currency) || currency
    return translated
  }, {})
}

const getCurrencyOptions = (t) => {
  return currencies.map((currency) => ({ value: currency, label: t(currency) || currency }))
}

const findCurrencyOption = (t, value) => {
  return currencies.includes(value) ? {value: value, label: t(value) || value} : undefined
}

export default useTranslatedCurrencies;
export { currencies, getTranslatedCurrencies, getCurrencyOptions, findCurrencyOption }