import React from "react";
import { Modal, FormInput, Form } from "@general-backoffice/core";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { defaultAvailabilitySchedule, weekDays } from "./hooks/useTrainerAvailabilitySchedule";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";

const idForm = uuid()

const UploadAvailabilityModal = ({
                                   availabilitySchedule,
                                   saveAvailabilitySchedule,
                                   templates = [],
                                   availability,
                                   setAvailability,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.trainerSchedule.uploadAvailabilityModal")

  const { changeState } = useChangingComplexState(setAvailability, isLoading)
  const {
    id,
    // start = moment().format(),
    // end = moment().format(),
    focusedDateTime = moment().format(),
    templateId,
    isRecurrent = false
  } = (availability || {});
  const templatesOptions = templates.map(({ id, templateName }) => ({ value: id, label: templateName }))

  const getNewAvailabilityScheduleWithoutAvailability = () => {
    const newAvailabilitiesSchedule = JSON.parse(JSON.stringify(defaultAvailabilitySchedule));
    for (let [key, availabilities] of Object.entries(availabilitySchedule)) {
      newAvailabilitiesSchedule[key] = availabilities.filter(av => !id || id.toString() !== av.id.toString())
    }
    return newAvailabilitiesSchedule
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    const { focusedDateTime, isRecurrent, ...cleanAvailabilityToUpload } = availability
    const newAvailabilitiesSchedule = getNewAvailabilityScheduleWithoutAvailability();
    let newKey = !isRecurrent ? "oneTime" : weekDays[moment(focusedDateTime).utc().day().toString()];
    newAvailabilitiesSchedule[newKey].push({ id: uuid(), ...cleanAvailabilityToUpload })
    saveAvailabilitySchedule(newAvailabilitiesSchedule)
  }

  const removeEvent = () => saveAvailabilitySchedule(getNewAvailabilityScheduleWithoutAvailability())

  return (
    <Modal.Simple
      idForm={idForm}
      isOpen={!!availability}
      toggleOpen={() => setAvailability(null)}
      title={t("title", id)}
      closeText={t("closeText")}
      buttons={[
        { children: t("buttonSaveText", id), form: idForm, color: "success", type: "submit" },
        ...(!!id ? [{ children: t("buttonRemoveText"), onClick: removeEvent, color: "danger" }] : [])
      ]}
      isLoading={isLoading}
    >
      <Form id={idForm} onSubmit={onSubmit}>

        <FormInput.Select
          label={t("templateLabel")}
          name={"templateId"}
          onChange={(option) => changeState("templateId", option?.value || option)}
          options={templatesOptions}
          value={templatesOptions.find(({ value }) => value === templateId)}
          isClearable={true}
        />

        <FormInput.Switch
          onChange={(e) => {
            const isRecurrent = e.target.checked;
            const timeFormat = isRecurrent ? "HH:mm:ss" : undefined
            const start = moment(focusedDateTime).utc().format(timeFormat);
            const end = moment(focusedDateTime).add(1, "hour").utc().format(timeFormat);
            setAvailability({ ...availability, start, end, isRecurrent })
          }}
          checked={isRecurrent}
          name={"recurring"}
          label={t("isRecurringLabel")}
          labels={t("isRecurringAnswers")}
        />

      </Form>
    </Modal.Simple>
  );
}


export default UploadAvailabilityModal;