import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFormInputValidation } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getDefaultTeam from "./shared/getDefaultTeam";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import useApiFileImages from "../../hooks/useApiFileImages";
import { Col, Row } from "reactstrap";

const imagesStructure = {
  image: {
    size: [1920, 1920],
    MB: 0.6
  }
}

const UploadTeamModal = ({ team, setTeam, onSubmit, isLoading }) => {
  const t = useTranslator("components.team.uploadTeamModal")
  const { onChangeInput: onChange, changeState } = useChangingComplexState(setTeam, isLoading)
  const {
    id,
    name,
    shortName,
    image,
    jerseyHome,
    jerseyAway
  } = { ...getDefaultTeam(), ...(team || {}) };

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image }, [id, !!team])

  const {
    inputRef: nameInputRef,
    throwError: throwErrorName
  } = useFormInputValidation(name)

  const onSubmitForm = () => {
    if ((shortName || "").length > 5) return throwErrorName(t("tooLongShortNameError"))
    onSubmit()
  }

  return (
    <React.Fragment>

      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        isOpen={!!team}
        toggleOpen={() => setTeam(null)}
        title={t("title", !!id, name)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={isLoading || isLoadingFiles}
      >
        <FormInput.Input
          onChange={onChange}
          value={name}
          name={"name"}
          label={t("nameLabel")}
          required={true}
        />

        <FormInput.Input
          innerRef={nameInputRef}
          onChange={onChange}
          value={shortName}
          name={"shortName"}
          label={t("shortNameLabel")}
          required={true}
          error={(shortName || "").length > 5 ? t("tooLongShortNameErrorText") : ""}
        />

        <FormInput.SingleDropzone
          label={t("imageLabel") + " (JPG, PNG)"}
          smallLabel={imagesStructure.image.size.join(" x ")}
          accept={".jpg, .jpeg, .png"}
          file={files.image}
          onDrop={(file) => setImagesToResize({ image: file })}
          buttons={[
            {
              children: <i className="fas fa-crop-alt"/>,
              onClick: () => setImagesToResize({ image: files.image })
            }
          ]}
          required
        />

        <Row>
          <Col>
            <FormInput.ColorChrome
              label={t("jerseyHomeColorLabel")}
              // tooltip={t("jerseyHomeColorTooltip")}
              color={jerseyHome}
              onChangeComplete={(finalColor) => changeState("jerseyHome", finalColor.hex)}
              disableAlpha={true}
            />
          </Col>
          <Col>
            <FormInput.ColorChrome
              label={t("jerseyAwayColorLabel")}
              // tooltip={t("jerseyAwayColorTooltip")}
              color={jerseyAway}
              onChangeComplete={(finalColor) => changeState("jerseyAway", finalColor.hex)}
              disableAlpha={true}
            />
          </Col>
        </Row>

      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadTeamModal;