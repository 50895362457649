import moment from "moment";

const fixChannelEventsOverlap = (events = []) => {
  let count = 0;
  const sortedEvents = events.sort((prevEv, Ev) => moment(prevEv.start).isBefore(moment(Ev.start)) ? -1 : 1)

  const separatedEvents = sortedEvents.reduce((acc = [], curEvent) => {
    const lastSortedEvent = acc[acc.length - 1];
    if (lastSortedEvent) {
      lastSortedEvent.start = lastSortedEvent.start || lastSortedEvent.end
      lastSortedEvent.end = lastSortedEvent.end || lastSortedEvent.start
      const lastSortedEventDurationSeconds = moment(lastSortedEvent.end).diff(moment(lastSortedEvent.start), "seconds")
      const eventDurationSeconds = moment(curEvent.end).diff(moment(curEvent.start), "seconds")
      const isEarlier = moment(curEvent.start).isBefore(lastSortedEvent.end, "seconds")

      if (isEarlier) {
        const isOnFirstHalf = moment(curEvent.start).isBefore(moment(lastSortedEvent.end).subtract((lastSortedEventDurationSeconds / 2), "seconds"), "seconds") && !moment(curEvent.start).isSame(lastSortedEvent.start)
        const newStart = isOnFirstHalf ? moment(lastSortedEvent.start).format() : moment(lastSortedEvent.end).format()
        const newEnd = isOnFirstHalf ? moment(lastSortedEvent.start).add(eventDurationSeconds, "seconds").format() : moment(lastSortedEvent.end).add(eventDurationSeconds, "seconds").format()
        if (curEvent.start !== newStart || curEvent.end !== newEnd) count += 1;
        curEvent.start = newStart
        curEvent.end = newEnd
        if (isOnFirstHalf) {
          const lastEvent = acc.pop();
          const newLastEvent = {
            ...lastEvent,
            start: newEnd,
            end: moment(newEnd).add(lastSortedEventDurationSeconds, "seconds").format()
          }
          return acc.concat([curEvent, newLastEvent]);
        } else {
          acc.push(curEvent)
          return acc;
        }
      }
    }
    acc.push(curEvent)
    return acc;
  }, [])
  return { numFixed: count, events: separatedEvents }
}

export { fixChannelEventsOverlap };