import React from "react";
import { Col, Container, Row } from "reactstrap";
import StatsTrainingCyclingZones from "./stats/StatsTrainingCyclingZones";
import { zones } from "./config";
import StatsTrainingCyclingPreview from "./stats/StatsTrainingCyclingPreview";
import StatsTrainingCyclingSegments from "./stats/StatsTrainingCyclingSegments";

const Preview = ({ training, isLoading }) => {
  const { title } = training;
  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <StatsTrainingCyclingPreview
            structure={training?.workout?.structure}
            isLoading={isLoading}
            title={`Resumen: ${title}`}
          />
        </Col>
        <Col xs={12}>
          <StatsTrainingCyclingSegments
            structure={training?.workout?.structure}
            isLoading={isLoading}
          />
        </Col>
        <Col xs={12}>
          <StatsTrainingCyclingZones
            structure={training?.workout?.structure}
            isLoading={isLoading}
            zones={zones}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Preview;