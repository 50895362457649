import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowPlansCard = ({
                         plans,
                         setPlanToUpload,
                         setPlanToRemove,
                         onClickPlan,
                         isLoading
                       }) => {
  const t = useTranslator("components.plans.showPlansCard")

  const columns = [
    {
      name: t("name"),
      formattedContent: ({ name }) => {
        return <TableOutput.TitleSubtitle title={name}/>
      },
      isTh: true,
    },
    {
      name: t("price"),
      formattedContent: ({ price, interval, currency }) => {
        return <TableOutput.TitleSubtitle title={`${price.toFixed(2)} ${t("priceCurrency", currency)}`}
                                          subtitle={t("priceInterval", interval)}/>
      }
    },
    {
      name: t("type"),
      formattedContent: ({ planType }) => {
        return (
          <TableOutput.Status
            color={planType === "BASE" ? "primary" : "success"}
            status={t("typeText", planType)}
          />
        )
      }
    },
    {
      name: "",
      formattedContent: (plan) => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setPlanToUpload({ ...plan }),
              description: t("modifyDescription")
            },
            {
              icon: <i className="fas fa-trash table-action-delete"/>,
              description: t("removeDescription"),
              onClick: () => setPlanToRemove({ ...plan })
            }
          ]}
        />
      }
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          {
            children: <AddButtonChildren children={t("addPlanButtonText")}/>,
            onClick: () => setPlanToUpload({}),
            title: t("addPlanButtonDescription")
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={plans}
        onRowClick={(plan) => onClickPlan({ ...plan })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
      />
    </Card>
  );
}

export default ShowPlansCard;