
import React from "react"
import { Row } from "reactstrap"
import classNames from "classnames"
import { colors } from "../../config"
import SegmentBar from "../displays/SegmentBar"
import DarkContainer from "../displays/shared/DarkContainer"
import TrainingPreview from "../../shared/TrainingPreview"

import BigTimer from "../displays/shared/BigTimer"
import SegmentFlower from "../displays/SegmentFlower"
import GroupTime from "../displays/GroupTime"
import GroupTimeBar from "../displays/GroupTimeBar"



import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject"
import clock from "../../../utils/clock"


const height = 'calc(3vw + 3vh)'
const scale = 0.92


const CaptureRow = ({countingdown, ...props}) => {
  const { currentSegment, currentTime } = props
  return(
    <Row 
      style={{gap: '0.7vw', padding: '0.8vw 0.7vw'}}
      className={classNames(
        "m-0 justify-content-between d-flex", 
        {'hide-contents': countingdown}
      )} 
    >
    
      <FlowerBar {...props} 
        className="justify-content-around flex-nowrap"
        style={{height, gap: 5, padding: '0 0.9vw', backgroundColor: colors.dark, flex: 1}} 
        scale={scale}
      />

      <div style={{flex: 1.4}}/>

      <SegmentBar 
        className="justify-content-around flex-nowrap"
        style={{height, gap: 5, padding: '0.7vw 0.9vw', backgroundColor: colors.dark, flex: 1}} 
        segment={currentSegment}
        scale={scale}
      />

      <DarkContainer className="w-100" style={{height, padding: '0.6vw 1.5% 0.8vw', backgroundColor: colors.dark}}>
        <TrainingPreview 
          {...props} useFullHeight 
          currentTime={currentTime?.totalSeconds} />
      </DarkContainer>
     
    </Row>
  )
}

export default CaptureRow




const FlowerBar = ({className, scale = 1, ...props}) => {
  const { currentTime, currentSegment } = props

  const { duration, start } = currentSegment || {}
  let remainingSeconds = duration - (currentTime?.totalSeconds - start)
  remainingSeconds = remainingSeconds >= 0 ? remainingSeconds : null

  return(
    <DarkContainer {...props} className={classNames({"hide-contents": !currentSegment}, className)}>

      <BigTimer 
        text="TIEMPO SEG."
        time={clock(formatSecondsToTimeObject(remainingSeconds), duration)}
        className="flex-column" style={{gap: 4, padding: '0.7vw 0'}} scale={scale}
      />
      
      <SegmentFlower 
        {...{currentTime, currentSegment}} 
        className="h-100"
        style={{padding: 2}}
      />

      <div className="flex-column-content-center ml-2" style={{padding: '0.7vw 0'}}>
        <div className="mb-1">
          <GroupTime {...props} countdown scale={scale}/>
        </div>
        <GroupTimeBar {...props} width={'105%'} height={8}/>
      </div>

    </DarkContainer>
  )
}
