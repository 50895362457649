import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFormInputValidation } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import { Col, Row } from "reactstrap";
import useApiFileImages from "../../hooks/useApiFileImages";


const imagesStructure = {
  image: {
    size: [1920, 1080], // AR: 16:9
    MB: 0.6
  },
  imagePortrait: {
    size: [1080, 1920], // AR: 9:16
    MB: 0.6
  },
}

const UploadSlideModal = ({ slide, setSlide, onSubmit, isLoading }) => {
  const t = useTranslator("components.slider.uploadSlideModal")
  const { changeState } = useChangingComplexState(setSlide, isLoading)
  const { id, title, subtitle, image, imagePortrait, externalUrl } = (slide || {});

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image, imagePortrait }, [id])

  const {
    inputRef: titleInputRef,
    throwError: throwErrorTitle
  } = useFormInputValidation(title)

  const {
    inputRef: subtitleInputRef,
    throwError: throwErrorSubtitle
  } = useFormInputValidation(subtitle)

  const onSubmitForm = () => {
    if ((title || "").length > 30) return throwErrorTitle(t("tooLongTitleError"))
    if ((subtitle || "").length > 50) return throwErrorSubtitle(t("tooLongSubtitleError"))
    onSubmit()
  }

  const someIsLoading = isLoading || isLoadingFiles

  return (
    <React.Fragment>
      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        isOpen={!!slide}
        toggleOpen={() => setSlide(null)}
        title={t("title", !!id, title)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
      >

        <FormInput.Input
          innerRef={titleInputRef}
          onChange={({ target: { value } }) => changeState("title", value)}
          value={title}
          label={t("titleLabel")}
          required
          error={(title || "").length > 50 ? t("titleErrorNumMaxChars") : ""}
        />

        <FormInput.Input
          innerRef={subtitleInputRef}
          onChange={({ target: { value } }) => changeState("subtitle", value)}
          value={subtitle}
          label={t("subtitleLabel")}
          error={(subtitle || "").length > 50 ? t("subtitleErrorNumMaxChar") : ""}
        />

        <FormInput.Input
          onChange={({ target: { value } }) => changeState("externalUrl", value)}
          value={externalUrl}
          label={t("urlLabel")}
        />

        <Row>
          <Col xs={12}>
            <FormInput.SingleDropzone
              label={t("imageLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.image.size.join(" x ")}
              tooltip={t("imageTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.image}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, image: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, image: files.image })
                }
              ]}
              required
            />
          </Col>
          <Col xs={12}>
            <FormInput.SingleDropzone
              label={t("imagePortraitLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.imagePortrait.size.join(" x ")}
              tooltip={t("imagePortraitTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.imagePortrait}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, imagePortrait: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, imagePortrait: files.imagePortrait })
                }
              ]}
            />
          </Col>
        </Row>

      </Modal.FormContainer>

    </React.Fragment>
  );
}

export default UploadSlideModal;