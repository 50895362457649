import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveChannelModal = ({
                              remove,
                              channel,
                              close,
                              isLoading
                            }) => {
  const t = useTranslator("components.channels.removeChannelModal")
  const { name } = (channel || {})

  return (
    <Modal.DeleteConfirm
      isOpen={!!name}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", name)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveChannelModal;