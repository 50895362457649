import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowManagersCard = ({
                            managers,
                            setManagerToUpload,
                            setManagerToRemove,
                            onClickManager,
                            totalAmount,
                            page,
                            changePage,
                            pageAmount,
                            isLoading
                          }) => {
  const t = useTranslator("components.managers.showManagersCard")

  const columns = [
    {
      name: t("email"),
      property: "email",
      isTh: true
    },
    {
      name: t("name"),
      formattedContent: ({ firstName = "", lastName }) => `${firstName || ""} ${lastName || ""}`,
    },
    {
      name: "",
      formattedContent: (manager) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setManagerToUpload({ ...manager }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            onClick: () => setManagerToRemove({ ...manager }),
            description: t("removeDescription")
          }
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card>
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("buttonAddText")}/>, onClick: () => setManagerToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={managers}
        onRowClick={(user) => onClickManager({ ...user })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={pageAmount}
      />
      <TableCard.Footer
        amount={pageAmount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => changePage((page + 1))}
        isLoading={isLoading}
      />

    </Card>
  );
}

export default ShowManagersCard;