

// const getCurrentValue = (range, duration, current) => {
//   if(range === null) return null
//   if(current > duration) return range[1] || null // Segment has finished
//   if(0 > duration) return range[0] || null // Segment hasn't started

//   let rangeGap = range[1] - range[0]

//   if(rangeGap === 0) return range[0] // If no change just return

//   let currPercent = parseFloat((current / duration).toFixed(2))
//   let currentValue = Math.ceil((rangeGap * currPercent) + range[0])

//   return currentValue
// }

// const getCurrentFTP = (point, currentTime) => 
//   getCurrentValue(point.ftp, point.duration, currentTime - point.start)

// const getCurrentRPM = (point, currentTime) =>
//   getCurrentValue(point.rpm, point.duration, currentTime - point.start)


const getCurrentSegment = (segments, currTime) => {
  if(segments?.length > 0) {
    const segmentIndex = segments.findIndex(({start, end}) => {
      return currTime >= start && currTime < end
    })

    if(segmentIndex >= 0) {
      // Set current FTP
      // segment.currentFTP = getCurrentFTP(segment, currTime)
      // segment.currentFTP = segment.ftp[0]

      // Set current RPM
      // segment.currentRPM = getCurrentRPM(segment, currTime)

      return { ...segments[segmentIndex] }
    } else return null
  }
}
// TODO: Se podría hacer un sistema que gurdará el siguientre point para no tener que hacer el find en casa render





export default getCurrentSegment