import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core/index";
import { meetingRequests } from "../../../api/requests";
import { useNavigate } from "react-router";
import { hardTranslator } from "../../../hooks/useTranslator";

const useMeeting = (meetingId) => {
  const navigate = useNavigate();
  const [meeting, setMeeting] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const id = meeting?.id || meetingId

  useEffect(() => {
    if (!!id) refresh();
    // eslint-disable-next-line
  }, [id])

  const refresh = async () => {
    setIsLoading(true)
    return getMeeting(id)
      .then((meeting) => {
        setMeeting(meeting)
        return meeting
      })
      .finally(() => setIsLoading(false))
  }

  const join = () => navigate(`/dashboard/meeting/${id}`)

  return {
    meeting,
    setMeeting,
    refresh,
    join,
    isLoading
  }
}

export const getMeeting = async (id) => {
  const t = hardTranslator("components.meetings.hooks.useMeeting")

  try {
    return await meetingRequests.get(id)
  } catch (e) {
    const message = e?.message || t("getMeetingError")
    notify.error(message)
    throw new Error(message)
  }
}

export default useMeeting;