import { useEffect, useState } from "react";
import { managerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import getDefaultManager from "../shared/getDefaultManager";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useManager = (id) => {

  const [manager, setManager] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const managerId = manager?.id || id

  useEffect(() => {
    if (!!managerId) refresh()
    // eslint-disable-next-line
  }, [managerId])

  const refresh = async () => {
    setIsLoading(true)
    return getManager(managerId)
      .then((manager) => {
        setManager(manager)
        return manager
      })
      .catch((e) => {
        setManager(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveManager(manager)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    setIsLoading(true)
    return removeManager(manager)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    manager,
    setManager,
    refresh,
    save,
    remove,
    isLoading
  }
}

export const getManager = async (managerId) => {
  const t = hardTranslator("components.managers.hooks.useManager")

  try {
    return await managerRequests.getInfo(managerId)
  } catch (e) {
    const message = e?.message || t("getManagerError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveManager = async (manager) => {
  const t = hardTranslator("components.managers.hooks.useManager")
  const notification = notify.loading(t("saveManagerLoading", manager?.id))
  try {
    const { subscriptionList, ...cleanManager } = { ...getDefaultManager(), ...manager }
    if (!!manager?.id) {
      await managerRequests.put(cleanManager)
      notifyLoadingToSuccess(notification, t("saveManagerSucceed", true))
    } else {
      await managerRequests.post(cleanManager)
      notifyLoadingToSuccess(notification, t("saveManagerSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveManagerError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export const removeManager = async (manager) => {
  const t = hardTranslator("components.managers.hooks.useManager")
  const notification = notify.loading(t("removeManagerLoading"))
  try {
    await managerRequests.delete(manager.id)
    notifyLoadingToSuccess(notification, t("removeManagerSucceed"))
  } catch (e) {
    const message = e?.message || t("removeManagerError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useManager;