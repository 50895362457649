import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowAdvertisementsCard = ({
                                  advertisements,
                                  setAdvertisementToUpload,
                                  setAdvertisementToRemove,
                                  onClickAdvertisement,
                                  totalAmount,
                                  page,
                                  setPage,
                                  amount,
                                  isLoading
                                }) => {
  const t = useTranslator("components.advertising.showAdvertisementsCard")

  const columns = [
    {
      name: t("advertisement"),
      formattedContent: ({ title, image }) => {
        return <TableOutput.ImageText text={title} src={image?.url}/>
      },
      isTh: true,
    },
    {
      name: t("location"),
      formattedContent: ({ location }) => t("locationValue", location)
    },
    {
      name: t("type"),
      formattedContent: ({ type }) => type
    },
    {
      name: t("status"),
      formattedContent: ({ active }) => {
        return (
          <TableOutput.Status
            status={t("statusText", active)}
            color={active ? "success" : "danger"}
          />
        )
      }
    },
    {
      name: t("description"),
      formattedContent: ({ description }) => {
        return description.substring(0, 50).trim() + (description.length > 50 ? "..." : "")
      },
    },
    {
      name: "",
      compressed: true,
      formattedContent: (advertisement) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setAdvertisementToUpload({ ...advertisement }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setAdvertisementToRemove({ ...advertisement })
          },
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("addButtonText")}/>, onClick: () => setAdvertisementToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={advertisements}
        onRowClick={(product) => onClickAdvertisement({ ...product })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={amount}
      />
      <TableCard.Footer
        amount={amount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowAdvertisementsCard;