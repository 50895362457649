import React from "react";
import classNames from "classnames";
import { Container, Row, Col, Label } from "reactstrap";
import { FormInput } from "@general-backoffice/core";
import { useSelector } from "react-redux";

import TotalTime from "../../displays/TotalTime";
import TrainingPreview from "../../../shared/TrainingPreview";
import { SegmentFlowerTimer } from "../../displays/SegmentFlower";
import GroupTimeBar from "../../displays/GroupTimeBar";
import GroupTime from "../../displays/GroupTime";
import SegmentBar from "../../displays/SegmentBar";
import BarTitle from "./BarTitle";

const clubsWithChroma = [16, 20]

const LargePlayer = props => {
  const { club } = useSelector((state) => state.sessionState)
  const chromaEnabled = club && clubsWithChroma.includes(club.clubId)
  const { chroma, toggleChroma, structure, secondsBeforeStart, currentSegment, currentTime } = props
  const nextSegment = structure?.[currentSegment?.index + 1]
  let starting = secondsBeforeStart >= 0

  return(
    <Container fluid className={classNames("px-4 d-flex flex-column h-100 metropolis", chroma ? 'pb-1' : 'pb-5')}>

      <Row className={classNames("m-0 justify-content-between", chroma ? 'mb-4' : 'mb-5')}>

        <Col xs='auto' className={classNames("d-flex flex-column justify-content-end align-items-end pt-2 mb-0", {'invisible': starting})}>
          <TotalTime {...props} countdown/>
        </Col>

        <TrainingPreview 
          {...props} className="mx-4"
          currentTime={currentTime.totalSeconds} 
          style={{ flex: 1, height: '13vh', minHeight: 65 }}
        />

        <Col xs='1' className={classNames("d-flex justify-content-end", {'invisible': starting || !chromaEnabled})}>
          <div>
            <div className="d-flex align-items-center justify-content-center" style={{marginBottom: -24}}>
              <Label className="form-control-label mr-2 text-white form-group">Croma</Label>
              <FormInput.Switch
                color="white"
                onChange={toggleChroma}
                checked={!chroma}
                disabled={!chromaEnabled}
                name={"chroma"}
                labels={["Off", "On"]}
              />
            </div>
          </div>
        </Col>

      </Row>


      <Row className="m-0 d-flex justify-content-between h-100 overflow-hidden" style={{flex: 1}}>

        <Col xs='auto' className={classNames('p-0 mr-3', {'invisible': starting})}>
          <SegmentBar 
            segment={currentSegment} 
            style={{gap: '2.5vh'}} 
            className="flex-column px-2 py-4"
            title={<BarTitle 
              text={<>SEGMENTO<br/>ACTUAL</>}
              style={{marginTop: '-5%'}}
            />}
          />
        </Col>

        <Col className="d-flex flex-column h-100">
          <div className="overflow-hidden" style={{flex: 1}}>
            <SegmentFlowerTimer {...props} currentSegment={currentSegment}/>
          </div>
          <div 
            style={{maxWidth: 400, gap: 20}}
            className={classNames(
              "flex-column-content-center w-100 mx-auto mt-5", 
              {'invisible': starting}
            )}
          >
            <GroupTimeBar {...props}/>
            <GroupTime {...props} countdown/>
          </div>
        </Col>

        <Col xs='auto' className={classNames('p-0 ml-3', {'invisible': starting})}>
          <SegmentBar 
            segment={nextSegment} 
            style={{gap: '2.5vh'}}
            className="flex-column px-2 py-4" 
            showDuration 
            title={<BarTitle 
              text={<>SIGUIENTE<br/>SEGMENTO</>}
              style={{marginTop: '-5%'}}
            />}
          />
        </Col>
        
      </Row>

    </Container>
  )
}

export default LargePlayer;