import Api from "../Api";

const getCoupon = (couponId) => Api.get(`/admin/coupon/${couponId}`);
const getCoupons = (params) => Api.get(`admin/coupon/list`, { params });
const deleteCoupon = (couponId) => Api.delete(`/admin/coupon/${couponId}`);
const putCoupon = (body) => Api.put(`/admin/coupon`, body);
const postCoupon = (body) => Api.post(`/admin/coupon`, body);

const couponRequests = {
  get: getCoupon,
  getList: getCoupons,
  delete: deleteCoupon,
  put: putCoupon,
  post: postCoupon,
}

export default couponRequests