import { useEffect, useMemo, useState } from "react";
import { userRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const defaultPagination = { page: 1, amount: 10 }
export const defaultUsersFilters = {
  "entry-date-end": undefined,
  "entry-date-start": undefined,
  "order": undefined,
  "search": undefined,
  "sort": undefined,
  "subscription-end-date-end": undefined,
  "subscription-end-date-start": undefined,
  "subscription-plan": undefined,
  "verification-status": undefined
}

const useUsers = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination)
  const [totalAmount, setTotalAmount] = useState(0);
  const [filters, setFilters] = useState(defaultUsersFilters);
  const [isLoading, setIsLoading] = useState(true);


  const paramsRequest = useMemo(() => ({
    page: pagination.page,
    size: pagination.amount,
    ...filters
  }), [pagination, filters])


  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [paramsRequest, clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getUsers(paramsRequest)
      .then(({ users, totalAmount }) => {
        setUsers(users)
        setTotalAmount(totalAmount)
        return users
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const changePage = (page) => setPagination((currentPagination) => ({ ...currentPagination, page }))
  const changeAmount = (amount) => setPagination((currentPagination) => ({ ...currentPagination, amount }))

  return {
    users,
    pagination,
    changePage,
    changeAmount,
    filters,
    setFilters,
    totalAmount,
    isLoading,
    refresh
  }
}

export const getUsers = async (paramsRequest) => {
  const t = hardTranslator("components.users.hooks.useUsers")

  try {
    const { userList = [], count = 0 } = await userRequests.getList(paramsRequest)
    return { users: userList, totalAmount: count }
  } catch (e) {
    const message = (e?.message || t("getUsersError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useUsers;