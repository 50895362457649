import React from "react";
import LargePlayer from "./LargePlayer"
import SmallPlayerHorizontal from "./SmallPlayerHorizontal"
import SmallPlayerVertical from "./SmallPlayerVertical"


const DefaultPlayer = ({windowSize, ...props}) => 
  windowSize === 'lg' ? <LargePlayer {...props} /> : 
  windowSize === 'sm-h' ? <SmallPlayerHorizontal {...props} /> : 
  windowSize === 'sm-v' && <SmallPlayerVertical {...props} /> 

export default DefaultPlayer;