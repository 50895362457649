export const colors = {
  dark: "#2c2c2c",
  light: "#FFFFFF",
  chroma: "#e0146d",
  empty: "#9C9B9B",
  red: "#AF0000"
}


export const maxFtp = 160
export const minFtp = 40
export const minDuration = 10
export const defDuration = 60

export const maxGraphFtp = 170
export const minGraphFtp = 30
export const sizeGraphFtp = maxGraphFtp - minGraphFtp

export const zones = [
  {
    zone: "1",
    name: "ACTIVACIÓN",
    ftp: 0,
    rpe: "1-2",
    colors: {
      main: "#FFFFFF",
      contrast: colors.empty
    }
  },
  {
    zone: "2",
    name: "RESISTENCIA",
    ftp: 56,
    rpe: "3-4",
    colors: {
      main: "#00AFFF",
      contrast: colors.light
    }
  },
  {
    zone: "3",
    name: "RITMO",
    ftp: 76,
    rpe: "5-6",
    colors: {
      main: "#96E100",
      contrast: colors.light
    }
  },
  {
    zone: "4",
    name: "FTP",
    ftp: 91,
    rpe: "7-8",
    colors: {
      main: "#FFD700",
      contrast: colors.empty
    }
  },
  {
    zone: "5",
    name: "UMBRAL",
    ftp: 106,
    rpe: ">8",
    colors: {
      main: "#F29100",
      contrast: colors.light
    }
  },
  {
    zone: "5MAX",
    name: "PAM",
    ftp: 111,
    rpe: "9",
    colors: {
      main: "#FF4B00",
      contrast: colors.light
    }
  },
  {
    zone: "6",
    name: "CAPACIDAD ANAERÓBICA",
    ftp: 121,
    rpe: ">9",
    colors: {
      main: "#FF0000",
      contrast: colors.light
    }
  },
  {
    zone: "7",
    name: "POTENCIA ANAERÓBICA",
    ftp: 151,
    rpe: "10",
    colors: {
      main: colors.red,
      contrast: colors.light
    }
  }
]

const getZoneByFtp = (ftp) => {
  return [...zones].reverse().find((z) => z.ftp <= ftp)
};

const getDefaultNewSegment = (props = {}) => ({
  ftp: [Math.round((maxFtp / 2)), Math.round((maxFtp / 2))],
  duration: defDuration,
  rpm: null,
  standing: false,
  ...props
})

export { getZoneByFtp, getDefaultNewSegment };