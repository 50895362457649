import React from "react";
import Redirect from "./Redirect";

const index = (t) => [
  require("./auth").default,
  require("./dashboard").default(t),
  require("./cycling").default,
  {
    path: "*",
    component: <Redirect authRedirect={"/dashboard"} unAuthRoute={"/auth"}/>,
  },
]

export default index;