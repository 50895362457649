import React from "react";
import { Navigate } from "react-router";
import Trainers from "../../../../views/pages/dashboard/trainers/Trainers";
import TrainerSchedule from "../../../../views/pages/dashboard/trainers/TrainerSchedule";


const subRoutes = (t) => [
  {
    name: t("trainers"),
    miniName: "T",
    path: "",
    component: <Trainers/>,
  },
  {
    name: t("trainerSchedule"),
    miniName: "C",
    path: ":idTrainer/schedule",
    component: <TrainerSchedule/>,
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard/services/trainers"} replace={true}/>,
  }
]

export { subRoutes };