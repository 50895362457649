import Api from "../Api";

const getCategoriesStates = (start, end) => Api.get(`/admin/stats/categories/${start}/${end}`);
const getChannelsStates = (start, end) => Api.get(`/admin/stats/channels/${start}/${end}`);
const getTop10Likes = (start, end, page = 1) => Api.get(`/admin/stats/products/likes/${start}/${end}`, {
  params: {
    page,
    size: 10
  }
});
const getTop10Products = (start, end, page = 1) => Api.get(`/admin/stats/products/${start}/${end}`, {
  params: {
    page,
    size: 10
  }
});

const getUsersPerPlan = (start, end) => Api.get(`/admin/stats/plan-subscriptions`, {
  params: {
    startDate: start,
    endDate: end
  }
});

const getClubActiveUsers = (start, end) => Api.get(`/admin/stats/active-club-users`, {
  params: {
    startDate: start,
    endDate: end
  }
});
const getClubConnectedUsers = () => Api.get(`/admin/stats/club-calls`);
const getClubConnectedUsersInTime = (start, end) => Api.get(`/admin/stats/club-calls`, {
  params: {
    startDate: start || undefined,
    endDate: end
  }
});
const getUsersRegistered = (start, end, interval = "MONTH") => Api.get(`/admin/stats/club-user-register-period`, {
  params: {
    startDate: start,
    endDate: end,
    interval: interval
  }
});
const getTrainersIncome = (start, end) => Api.get(`/admin/stats/club-trainers-income`, {
  params: {
    startDate: start,
    endDate: end
  }
});
const getAveragePlayTimeVideos = (start, end) => Api.get(`/admin/stats/products/average-playtime`, {
  params: {
    startDate: start,
    endDate: end
  }
});
const getUsersPerCountry = () => Api.get(`/admin/stats/club-user-country`);

const statsRequests = {
  getCategoriesStates,
  getChannelsStates,
  getTop10Likes,
  getTop10Products,
  getUsersPerPlan,
  getClubActiveUsers,
  getClubConnectedUsers,
  getClubConnectedUsersInTime,
  getUsersRegistered,
  getTrainersIncome,
  getAveragePlayTimeVideos,
  getUsersPerCountry
}

export default statsRequests