import React, { useEffect, useMemo, useRef, useState } from "react";
import { Loading, notify, TableCard } from "@general-backoffice/core";
import { Card, CardBody } from "reactstrap";
import UploadTrainingCyclingStructureGraphSegments from "./UploadTrainingCyclingStructureGraphSegments";
import AudioWaves from "../../../shared/AudioWaves";
import { colors, getDefaultNewSegment, maxFtp, maxGraphFtp, minFtp, minGraphFtp, sizeGraphFtp, zones } from "../config";
import { v4 as uuid } from "uuid";
import { ZoneSquareButton } from "../shared/ZoneSquare";
import useEventListener from "../../../../hooks/useEventListener";
import useTraining, { saveTraining } from "../../hooks/useTraining";
import { getProcessedStructure } from "../player/formatTraining";
import moment from "moment";

const heightTrainingProgramBuilderContainerSegments = "60vh";
const widthZonesIndicator = 30;
const heightSegmentZonesIndicator = 11;
const heightZonesIndicator = heightTrainingProgramBuilderContainerSegments;
const defaultPxPerSec = 0.5

const UploadTrainingCyclingWorkoutCard = ({
                                            training,
                                            setTraining,
                                            isLoading
                                          }) => {
  const {
    training: trainingToEdit,
    setTraining: setTrainingToEdit
  } = useTraining()
  const [isLoadingAudio, setIsLoadingAudio] = useState(false)
  const [pxPerSec, setPxPerSec] = useState(defaultPxPerSec)
  const [audioDuration, setAudioDuration] = useState(0)
  const [isPlaying, setPlaying] = useState(false)

  const audioWavesRef = useRef(null);

  const { audioUrl, title, workout } = (training || {});
  const { structure = [] } = (workout || {})
  const trainingProgramBuilderContainer = document.getElementById("training-program-builder-container")
  const trainingProgramBuilderContainerWidth = (trainingProgramBuilderContainer?.clientWidth || 0) - widthZonesIndicator;

  const someIsLoading = (isLoadingAudio || isLoading);
  const groups = useMemo(() => getProcessedStructure(structure).groups, [structure])

  const structureDuration = useMemo(() => structure.reduce((accumulator, { duration }) => (accumulator + parseFloat(duration)), 60), [structure])
  const duration = useMemo(() => Math.max(audioDuration, structureDuration), [audioDuration, structureDuration])

  const onMountAudioWaves = (waveSurfer) => {
    audioWavesRef.current = waveSurfer;
    audioWavesRef.current?.on("ready", () => {
      const trainingProgramBuilderContainerAudio = document.getElementById("training-program-builder-container-audio")
      trainingProgramBuilderContainerAudio.style.display = '';
      audioWavesRef.current?.drawBuffer();
      const duration = audioWavesRef.current?.getDuration();
      setAudioDuration(duration)
      setPxPerSec(() => {
        audioWavesRef.current?.zoom(defaultPxPerSec)
        return defaultPxPerSec
      })
      setIsLoadingAudio(false)
    });
    audioWavesRef.current?.on("seek", (percent) => {
      const elapsedAudioDuration = document.getElementById("elapsedAudioDuration")
      const duration = audioWavesRef.current?.getDuration();
      elapsedAudioDuration.innerText = moment.utc((percent * duration) * 1000).format('HH:mm:ss:S')
    });
    audioWavesRef.current?.on("audioprocess", (processedDuration) => {
      const elapsedAudioDuration = document.getElementById("elapsedAudioDuration")
      elapsedAudioDuration.innerText = moment.utc(processedDuration * 1000).format('HH:mm:ss:S')
    });
    audioWavesRef.current?.on("play", () => {
      setPlaying(true)
    });
    audioWavesRef.current?.on("pause", () => {
      setPlaying(false)
    });
    audioWavesRef.current?.on("error", (e) => {
      notify.error(e)
      setIsLoadingAudio(false)
    });
    audioWavesRef.current?.on("finish", () => setPlaying(false));
  };

  useEffect(() => {
    if (audioWavesRef.current && !!audioUrl) {
      setIsLoadingAudio(true)

      const myAudio = new Audio(audioUrl);
      myAudio.crossOrigin = 'myAudio';

      fetch(`${audioUrl}.json`)
        .then(response => {
          // eslint-disable-next-line
          if (!response.ok) throw "El audio no se ha podido cargar";
          return response.json();
        })
        .then(peaks => audioWavesRef.current?.load(myAudio, peaks.data))
        .catch(e => notify.error(e));
    }

    return () => audioWavesRef.current?.empty();
    // eslint-disable-next-line
  }, [audioUrl])

  useEffect(() => {
    audioWavesRef.current?.zoom(pxPerSec);
    // eslint-disable-next-line
  }, [pxPerSec])

  useEventListener("keydown",
    (event) => {
      const { key } = event;
      if (!trainingToEdit && key === " " && !someIsLoading && audioDuration) {
        event.preventDefault()
        audioWavesRef.current?.playPause();
      }
    }
  )

  const addBar = (bar) => {
    if (!bar) return;
    setTraining(({ workout = [], ...training }) => {
      const { structure = [] } = workout
      const { ftp: [initFtp, endFtp] } = bar;

      if (initFtp < minFtp) bar.ftp[0] = minFtp;
      if (endFtp < minFtp) bar.ftp[1] = minFtp;
      if (initFtp > maxFtp) bar.ftp[0] = maxFtp
      if (endFtp > maxFtp) bar.ftp[1] = maxFtp

      structure.push({ ...bar, id: uuid() })
      return { ...training, workout: { ...workout, structure: [...structure] } }
    })
  }

  const trainingProgramBuilderWidth = (duration * pxPerSec)
  let nextZoomIn = Math.floor((pxPerSec + 1));
  let nextZoomOut = Math.floor((pxPerSec - 1));

  if (trainingProgramBuilderContainerWidth > (duration * nextZoomOut)) {
    nextZoomOut = (trainingProgramBuilderContainerWidth / duration)
  }

  return (
    <React.Fragment>

      <Card className="scroll-white" style={{ backgroundColor: colors.dark }}>
        <TableCard.Header
          isDark={true}
          title={title}
          subtitle={"Redimensiona elementos en las distintas zonas. Haz doble clic para editar"}
          buttons={[
            {
              children: <ButtonElapsedAudioDuration isPlaying={isPlaying}/>,
              style: { backgroundColor: colors.red, border: colors.red },
              onClick: () => audioWavesRef.current?.playPause(),
              className: !audioUrl ? "d-none" : "",
              disabled: someIsLoading || !audioUrl
            },
            {
              children: <i className="fas fa-eye"/>,
              onClick: () => setPxPerSec((trainingProgramBuilderContainerWidth / duration)),
              disabled: someIsLoading || (trainingProgramBuilderContainerWidth === trainingProgramBuilderWidth),
              style: { backgroundColor: colors.red, border: colors.red }
            },
            {
              children: <i className="fas fa-search-minus"/>,
              onClick: () => setPxPerSec(nextZoomOut),
              disabled: someIsLoading || (trainingProgramBuilderContainerWidth >= trainingProgramBuilderWidth),
              style: { backgroundColor: colors.red, border: colors.red }
            },
            {
              children: <i className="fas fa-search-plus"/>,
              onClick: () => setPxPerSec(nextZoomIn),
              disabled: someIsLoading,
              style: { backgroundColor: colors.red, border: colors.red }
            },
            {
              children: <i className="fas fa-edit"/>,
              onClick: () => setTrainingToEdit({ ...training }),
              disabled: someIsLoading,
              style: { backgroundColor: colors.red, border: colors.red }
            },
            {
              children: "Guardar",
              style: { backgroundColor: colors.red, border: colors.red },
              disabled: someIsLoading,
              onClick: () => saveTraining(training).catch(() => null)
            }
          ]}
          isLoading={someIsLoading}
        />

        <CardBody
          className="position-relative p-0"
          id="training-program-builder-container"
        >

          <ZonesFloatingButtons onClick={addBar}/>
          <ZonesIndicators/>

          <div className="overflow-auto pb-1 mb-1" style={{ paddingLeft: widthZonesIndicator }}>
            <div className="position-relative" style={{ width: trainingProgramBuilderWidth }}>
              <SegmentZonesIndicators groups={groups} pxPerSec={pxPerSec}/>
              <div className="w-100" style={{ height: heightTrainingProgramBuilderContainerSegments }}>
                <UploadTrainingCyclingStructureGraphSegments
                  trainingToUpload={training}
                  setTrainingToUpload={setTraining}
                  pxPerSec={pxPerSec}
                />
              </div>
              <div
                id="training-program-builder-container-audio"
                className="mt-2"
                style={{
                  display: ((someIsLoading || !audioUrl) ? "none" : "block"),
                  width: (audioDuration * pxPerSec)
                }}
              >
                {!!audioUrl && (
                  <AudioWaves
                    onMount={onMountAudioWaves}
                    useCursor={true}
                    useTimeLine={true}
                    isDark={false}
                  />
                )}
              </div>
            </div>
          </div>
        </CardBody>

        {someIsLoading && <Loading.BouncingDots className="my-5" color={"light"}/>}

      </Card>
    </React.Fragment>
  )
}


const ZonesFloatingButtons = ({ onClick }) => {
  return (
    <div className="position-absolute mt-3 p-1 flex-column-content-center rounded-sm"
         style={{ top: 0, right: 5, zIndex: 3, backgroundColor: "rgba(250, 250, 250, 0.9)" }}>
      {[...zones].reverse().map(({ ftp, zone }) => {
        return (
          <ZoneSquareButton
            key={zone}
            size={"sm"}
            zone={zone}
            className="mx-0 my-1"
            onClick={() => {
              onClick(getDefaultNewSegment({ ftp: [ftp, ftp] }))
            }}
          />
        )
      })}
    </div>
  )
}

const ButtonElapsedAudioDuration = ({ isPlaying }) => {
  return (
    <div className="flex-center">
      {isPlaying && <i className="fas fa-pause mr-2"/>}
      {!isPlaying && <i className="fas fa-play mr-2"/>}
      <div id="elapsedAudioDuration">
        00:00:00:0
      </div>
    </div>
  )
}

const SegmentZonesIndicators = ({ groups, pxPerSec }) => {
  return (
    <div className="position-absolute d-flex w-100"
         style={{ height: heightSegmentZonesIndicator, zIndex: 2 }}>

      {groups.map(({ zone: { ftp, zone, colors }, start, end }, index) => {
        const duration = end - start;
        const width = pxPerSec * duration;
        const formatDuration = moment.utc(duration * 1000).format('HH:mm:ss:S')
        return (
          <div
            className="d-flex"
            key={index}
            title={formatDuration}
            style={{
              height: heightSegmentZonesIndicator,
              width: width,
              backgroundColor: colors.main,
              color: colors.contrast,
            }}
          >
            {width >= 60 && (
              <span
                className="position-sticky px-2"
                style={{ fontSize: 9, left: 0, fontWeight: 600 }}
                children={formatDuration}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

const ZonesIndicators = () => {
  return (
    <React.Fragment>
      <div className="bg-light position-absolute d-flex flex-column flex-column-reverse"
           style={{
             height: heightZonesIndicator,
             width: widthZonesIndicator,
             zIndex: 3
           }}>

        {zones.map(({ ftp, zone, colors }, index) => {
          const { ftp: nextFtp = maxGraphFtp } = (zones[index + 1] || {})
          const calculatedFtp = Math.max(ftp, minGraphFtp)
          const percent = ((nextFtp - calculatedFtp) * 100) / sizeGraphFtp
          return (
            <div
              key={index}
              className="d-flex align-items-end opacity-7"
              style={{
                width: widthZonesIndicator,
                height: `${percent}%`,
                backgroundColor: colors.main,
                color: colors.contrast
              }}
            >
              <strong
                className="text-center w-100"
                style={{ fontSize: 9 }}
                children={`${calculatedFtp}%`}
              />
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default UploadTrainingCyclingWorkoutCard;
