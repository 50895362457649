import React from "react";
import { Header, StatsCard } from "@general-backoffice/core";
import { Col, Container } from "reactstrap";
import useCategories from "../../../../components/category/hooks/useCategories";
import ShowCategoriesCard from "../../../../components/category/ShowCategoriesCard";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import RemoveCategoryModal from "../../../../components/category/RemoveCategoryModal";
import useCategory from "../../../../components/category/hooks/useCategory";
import useTranslator from "../../../../hooks/useTranslator";
import { useNavigate } from "react-router";


const Categories = () => {
  const navigate = useNavigate()
  const t = useTranslator("views.pages.dashboard.onDemand")

  const {
    categories,
    isLoading: isLoadingCategories,
    refresh: refreshCategories,
    sort: sortCategories
  } = useCategories();

  const {
    category: categoryToUpload,
    setCategory: setCategoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    save: saveCategoryToUpload
  } = useCategory()

  const {
    category: categoryToRemove,
    setCategory: setCategoryToRemove,
    isLoading: isLoadingCategoryToRemove,
    remove: removeCategoryToRemove
  } = useCategory()

  return (
    <React.Fragment>

      <RemoveCategoryModal
        category={categoryToRemove}
        close={() => setCategoryToRemove(null)}
        isLoading={isLoadingCategoryToRemove}
        remove={async () => {
          return removeCategoryToRemove()
            .then(() => {
              refreshCategories()
              setCategoryToRemove(null)
            })
            .catch(() => null)
        }}
      />

      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>
        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="primary"
            isDark={true}
            number={categories.length}
            suffix={t("stats.numberOfCategories.suffix", categories.length)}
            title={t("stats.numberOfCategories.title")}
            icon="fas fa-box-open"
            description={t("stats.numberOfCategories.description")}
          />
        </Col>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <ShowCategoriesCard
          categories={categories}
          sortCategories={sortCategories}
          setCategoryToUpload={setCategoryToUpload}
          onClickCategoryToShowContent={({ type, id }) => {
            if (type === "PRODUCT") navigate(`/dashboard/on-demand/categories/${id}/products`)
            if (type === "COLLECTION") navigate(`/dashboard/on-demand/categories/${id}/collections`)
          }}
          setCategoryToRemove={setCategoryToRemove}
          isLoading={isLoadingCategories}
          onClickCategory={setCategoryToUpload}
        />
      </Container>
    </React.Fragment>
  );
}

export default Categories;