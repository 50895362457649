import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { notify, Card, Loading, Modal, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { successColor } from './config/colors'
import UploadStatsPeriodModal, {
  format,
  formatHuman,
  initialEndDate, initialInterval,
  initialStartDate
} from "../shared/UploadStatsPeriodModal";
import { statMedium, statSmall } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";
import moment from "moment";

const StatsUsersSubscriptions = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsUsersSubscriptions")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [{ startDate, endDate }, setLocalDates] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate
  })
  const [interval, setLocalInterval] = useState(initialInterval)
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const refresh = async () => {
    setLoading(true)
    try {
      const { clubRegisterUserList } = await statsRequests.getUsersRegistered(format(startDate), format(endDate), interval)
      console.log(clubRegisterUserList)
      setData(clubRegisterUserList || [])
    } catch (e) {
      notify.error(e?.message || t("getStatsError"))
    }
    setLoading(false)
  }

  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [clubId, startDate, endDate, interval])

  const hasInfo = data.length > 0;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        interval={interval}
        changeInterval={setLocalInterval}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />

      <Modal.Info
        titleInfo={t("infoTitle")}
        closeText={t("infoCloseText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("infoContent")}
      </Modal.Info>


      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        {isLoading && <Loading.Line/>}
        <CardBody>
          {(!hasInfo && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          <div style={{ position: "relative", height: simple ? statSmall : statMedium }}>
            <Bar
              type={"bar"}
              data={{
                labels: data.map(({ dateStart, dateEnd }) => {
                  const start = moment(dateStart)
                  const end = moment(dateEnd)
                  if (interval === "YEAR") return start.format("YYYY")
                  if (interval === "MONTH") return start.format("MMM YYYY")
                  if (interval === "WEEK") return `${start.format("DD MMM")} - ${end.format("DD MMM")}`
                  if (interval === "DAY") return start.format("DD MMM")
                  return `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`
                }),
                datasets: [
                  {
                    label: t("labelY"),
                    data: data.map(({ numUsersRegistered }) => numUsersRegistered),
                    borderColor: successColor,
                    backgroundColor: successColor
                  }
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  y: {
                    suggestedMax: 10,
                    suggestedMin: 0,
                    grid: {
                      drawBorder: false
                    }
                  }
                },
                interaction: {
                  intersect: false,
                  mode: 'nearest',
                },
              }}/>
          </div>
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsUsersSubscriptions;