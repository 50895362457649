import Api from "../Api";

const getTeam = (id) => Api.get(`/admin/team/${id}`);
const getTeamList = (params = {}) => Api.get(`/admin/team/list`, { params });
const putTeam = (body) => Api.put(`/admin/team`, body);
const postTeam = (body) => Api.post(`/admin/team`, body);
const deleteTeam = (id) => Api.delete(`/admin/team/${id}`);

const sortSectionsTeam = (body) => Api.put(`/admin/team/section/order`, body);

const teamRequests = {
  get: getTeam,
  getList: getTeamList,
  put: putTeam,
  post: postTeam,
  delete: deleteTeam,
  sortSections: sortSectionsTeam
}

export default teamRequests