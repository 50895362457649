import React from "react";
import { TableOutput, Table, TableCard, notify } from "@general-backoffice/core";
import { Button, Card } from "reactstrap";
import moment from "moment";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";
import getProductFrontUrl from "./shared/getProductFrontUrl";
import { useSelector } from "react-redux";

const orderTranslate = {
  "DESC": "down",
  "ASC": "up",
  "down": "DESC",
  "up": "ASC"
}

const ShowProductsCard = ({
                            products,
                            setProductToUpload,
                            setProductToSeeQr,
                            onClickProduct,
                            totalAmount,
                            search,
                            changeSearch,
                            page,
                            sort,
                            changeSort,
                            changePage,
                            pageAmount,
                            changeAmount,
                            isLoading
                          }) => {
  const t = useTranslator("components.products.showProductsCard")
  const { club } = useSelector((state) => state.sessionState)
  const { clubProperties } = (club || {});

  const columns = [
    {
      name: t("code"),
      sort: (newStatus) => !!newStatus ? changeSort("code", orderTranslate[newStatus]) : changeSort(),
      sortStatus: (sort.sort === "code" && sort.order) ? orderTranslate[sort.order] : null,
      property: "code",
      isTh: true
    },
    {
      name: t("product"),
      sort: (newStatus) => !!newStatus ? changeSort("title", orderTranslate[newStatus]) : changeSort(),
      sortStatus: (sort.sort === "title" && sort.order) ? orderTranslate[sort.order] : null,
      formattedContent: ({ title = "" }) => {
        const titleEllipsis = title.substring(0, 30).trim() + (title.length > 30 ? "..." : "")
        return <TableOutput.TitleSubtitle title={titleEllipsis}/>
      },
      isTh: true
    },
    {
      name: t("images"),
      formattedContent: ({ thumbnail, image, imagePortrait, thumbnailPortrait }) => {
        const images = [thumbnail, image, imagePortrait, thumbnailPortrait].map((i) => ({ src: i?.url }))
        return <TableOutput.Images images={images}/>
      },
    },
    {
      name: t("productUrl"),
      formattedContent: (product) => {
        const path = getProductFrontUrl(product)
        const url = `https://${clubProperties.baseUrl}/${path}`
        const pathEllipsis = path.substring(0, 30).trim() + (path.length > 30 ? "..." : "")
        return <div className="d-flex align-items-center">

          <TableOutput.TitleSubtitle title={pathEllipsis}/>
          <Button className="ml-3" size="sm" children={t("copyUrlButtonText")} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigator.clipboard.writeText(url)
              .then(() => notify.success(t("copiedUrl")))
          }}/>
        </div>
      }
    },
    {
      formattedContent: ({ premiere, published, publicationDate, publicationEndDate }) => {
        const bookmarks = []

        // Publication
        if (published) {
          let title = t("published")
          if (!!publicationDate || !!publicationEndDate) {
            const initial = publicationDate ? moment(publicationDate).format("DD/MM/YYYY") : t("unspecifiedPublication")
            const final = publicationEndDate ? moment(publicationEndDate).format("DD/MM/YYYY") : t("unspecifiedPublication")
            title = `${initial} - ${final}`
          }
          bookmarks.push({ icon: <i className="fas fa-eye"/>, title: title, color: "primary" })
        } else bookmarks.push({ icon: <i className="fas fa-eye-slash"/>, title: t("unpublished"), color: "primary" })

        // Premiere
        if (premiere) bookmarks.push({ icon: <i className="fas fa-star"/>, title: t("premiere"), color: "primary" })
        else bookmarks.push({ icon: <i className="far fa-star"/>, title: t("noPremiere"), color: "primary" })

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      formattedContent: (product) => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setProductToUpload({ ...product }),
              description: t("modifyDescription")
            },
            {
              icon: <i className="fas fa-qrcode"/>,
              onClick: () => setProductToSeeQr({ ...product }),
              description: t("qrDescription")
            }
          ]}
        />
      }
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          {
            children: <AddButtonChildren children={t("addButtonText")}/>,
            onClick: () => setProductToUpload({})
          },
        ]}
        search={{
          value: search,
          onChange: (e) => changeSearch(e.target.value),
          msToChange: 1000,
          placeholder: t("searchPlaceholder")
        }}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={products}
        onRowClick={(product) => onClickProduct({ ...product })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={pageAmount}
        rowHeight={81}
      />
      <TableCard.Footer
        amount={pageAmount}
        setAmount={changeAmount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => changePage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowProductsCard;