import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import { authRequests } from "../../../api/requests";
import useTranslator from "../../../hooks/useTranslator";

const ForgottenPassword = () => {
  const t = useTranslator("views.pages.auth.forgottenPassword")
  const navigate = useNavigate()

  const onSubmit = async (email) => {
    try {
      await authRequests.forgottenPassword(email)
      notify.success(t("success"))
      navigate("/auth/login")
    } catch (e) {
      notify.error(t("error"))
    }
  }

  return <Page.ForgottenPassword
    emailPlaceholder={t("emailPlaceholder")}
    invalidEmailFeedbackText={t("invalidEmailFeedbackText")}
    lead={t("lead")}
    loginText={t("loginText")}
    onClickLogin={() => navigate("/auth/login")}
    onClickRegister={() => navigate("/auth/register")}
    onSubmit={onSubmit}
    registerText={t("registerText")}
    submitText={t("submitText")}
    title={t("title")}
  />;
}

export default ForgottenPassword;