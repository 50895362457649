import { useMemo } from "react";
import useTranslator from "../useTranslator";

const levels = [null, "LOW", "MEDIUM", "HIGH"]
const useTranslatedLevels = () => {
  const t = useTranslator("levels")

  const translatedLevels = useMemo(() => getTranslatedLevels(t), [t])
  const levelOptions = useMemo(() => getLevelOptions(t), [t])
  const getLevelOption = (v) => findLevelOption(t, v)

  return {
    levels,
    translatedLevels,
    levelOptions,
    getLevelOption
  }
}

const getTranslatedLevels = (t) => {
  return levels.reduce((translated, level) => {
    translated[level] = t(level) || level
    return translated
  }, {})
}

const getLevelOptions = (t) => {
  return levels.map((level) => ({ value: level, label: t(level) || level }))
}

const findLevelOption = (t, value) => {
  return levels.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedLevels;
export { levels, getTranslatedLevels, getLevelOptions, findLevelOption }