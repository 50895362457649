import React from "react";
import { Table, Modal } from "@general-backoffice/core";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";

const ShowUserHistoryModal = ({
                                user,
                                close,
                                isLoading
                              }) => {
  const t = useTranslator("components.users.showUserHistoryModal")

  const { subscriptionList = [] } = (user || {})


  const columns = [
    {
      name: t("plan"),
      property: "plan.name",
      isTh: true
    },
    {
      name: t("duration"),
      formattedContent: ({ startDate, endDate }) => {
        const start = startDate ? moment(startDate).format("DD/MM/YYYY") : t("durationWithoutInit");
        const end = endDate ? moment(endDate).format("DD/MM/YYYY") : t("durationWithoutEnd")
        return `${start} - ${end}`
      }
    },
  ]

  return (
    <Modal.Simple
      isOpen={!!user}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      isLoading={isLoading}
    >
      <div className="overflow-hidden mb-0 mx--4 mb--4">
        <Table
          columns={columns}
          rows={subscriptionList}
          isLoading={isLoading}
          noContentMessage={t("empty")}
        />
      </div>
    </Modal.Simple>
  );
}

export default ShowUserHistoryModal;