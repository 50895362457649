import { useEffect, useState } from "react";
import { categoryRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import { useSelector } from "react-redux";
import getDefaultCategory from "../shared/getDefaultCategory";

const useCategory = (id) => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const categoryId = category?.id || id
  const existsCategory = !!category

  useEffect(() => {
    if (!!categoryId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [categoryId])

  useEffect(() => {
    if (existsCategory) {
      setCategory((category) => ({ clubId, ...category }))
    }
  }, [clubId, existsCategory])

  const refresh = async () => {
    setIsLoading(true)
    return getCategory(categoryId)
      .then((category) => {
        setCategory(category)
        return category
      })
      .catch((e) => {
        setCategory(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveCategory(category)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!category?.id) return;
    setIsLoading(true)
    return removeCategory(category.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sortProducts = async (productIdList = []) => {
    if (!category?.id) return;
    const oldCategory = category
    setCategory({ ...category, productIdList })
    return sortCategoryProducts({ ...category, productIdList })
      .catch(() => setCategory(oldCategory))
  }

  const sortCollections = async (collectionIdList = []) => {
    if (!category?.id) return;
    const oldCategory = category
    setCategory({ ...category, collectionIdList })
    return sortCategoryCollections({ ...category, collectionIdList })
      .catch(() => setCategory(oldCategory))
  }

  return {
    category,
    setCategory,
    save,
    remove,
    refresh,
    sortProducts,
    sortCollections,
    isLoading
  }
}

export const getCategory = async (categoryId) => {
  const t = hardTranslator("components.category.hooks.useCategory")
  try {
    return await categoryRequests.get(categoryId)
  } catch (e) {
    const message = e?.message || t("getCategoryError")
    notify.error(message)
    throw new Error(message)
  }
}

export const sortCategoryProducts = async (category) => {
  const t = hardTranslator("components.category.hooks.useCategory")
  const notificationLoading = notify.loading(t("sortCategoryProductsLoading"))
  const { id, productIdList, collectionIdList, type } = { ...getDefaultCategory(), ...category }
  const cleanCategory = { categoryId: id, productIdList, collectionIdList, type }
  try {
    await categoryRequests.sortContent(cleanCategory)
    notifyLoadingToSuccess(notificationLoading, t("sortCategoryProductsSucceed"))
  } catch (e) {
    const message = e?.message || t("sortCategoryProductsError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const sortCategoryCollections = async (category) => {
  const t = hardTranslator("components.category.hooks.useCategory")
  const notificationLoading = notify.loading(t("sortCategoryCollectionsLoading"))
  const { id, productIdList, collectionIdList, type } = { ...getDefaultCategory(), ...category }
  const cleanCategory = { categoryId: id, productIdList, collectionIdList, type }
  try {
    await categoryRequests.sortContent(cleanCategory)
    notifyLoadingToSuccess(notificationLoading, t("sortCategoryCollectionsSucceed"))
  } catch (e) {
    const message = e?.message || t("sortCategoryCollectionsError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const saveCategory = async (category) => {
  const t = hardTranslator("components.category.hooks.useCategory")
  const notificationLoading = notify.loading(t("saveCategoryLoading", !!category?.id))
  try {
    const cleanCategory = { ...getDefaultCategory(), ...category }
    if (!!category?.id) {
      await categoryRequests.put(cleanCategory)
      notifyLoadingToSuccess(notificationLoading, t("saveCategorySucceed", true))
    } else {
      await categoryRequests.post(cleanCategory)
      notifyLoadingToSuccess(notificationLoading, t("saveCategorySucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveCategoryError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeCategory = async (categoryId) => {
  const t = hardTranslator("components.category.hooks.useCategory")
  const notification = notify.loading(t("removeCategoryLoading"))
  try {
    await categoryRequests.delete(categoryId)
    notifyLoadingToSuccess(notification, t("removeCategorySucceed"))
  } catch (e) {
    const message = e?.message || t("removeCategoryError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useCategory;