import { useEffect, useState } from "react";
import { collectionRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const useCollections = () => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = () => {
    setIsLoading(true)
    return getCollections()
      .then((collectionList) => {
        setCollections(collectionList)
        return collectionList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return {
    collections,
    isLoading,
    refresh
  }
}

export const getCollections = async () => {
  const t = hardTranslator("components.collection.hooks.useCollections")
  try {
    const { content = [] } = await collectionRequests.getList({ pageNumber: 1, pageSize: 10000 })
    return content
  } catch (e) {
    const message = (e?.message || t("getCollectionsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useCollections;