import { useEffect, useState } from "react";
import { planRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultPlan from "../shared/getDefaultPlan";
import { hardTranslator } from "../../../hooks/useTranslator";

const usePlan = (id) => {

  const [plan, setPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const planId = plan?.id || id

  useEffect(() => {
    if (!!planId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [planId])

  const refresh = async () => {
    setIsLoading(true)
    return getPlan(planId)
      .then((companyPlan) => {
        setPlan(companyPlan)
        return companyPlan
      })
      .catch((e) => {
        setPlan(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return savePlan(plan)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!plan?.id) return;
    setIsLoading(true)
    return removePlan(plan.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    plan,
    setPlan,
    refresh,
    save,
    remove,
    isLoading
  }
}

export const getPlan = async (planId) => {
  const t = hardTranslator("components.plans.hooks.usePlan")

  try {
    return await planRequests.get(planId)
  } catch (e) {
    const message = e?.message || t("getPlanError")
    notify.error(message)
    throw new Error(message)
  }
}

export const removePlan = async (planId) => {
  const t = hardTranslator("components.plans.hooks.usePlan")

  const notification = notify.loading(t("removeManagerLoading"))
  try {
    const companyPlan = await planRequests.delete(planId)
    notifyLoadingToSuccess(notification, t("removeManagerSucceed"))
    return companyPlan
  } catch (e) {
    const message = e?.message || t("removeManagerError")
    notifyLoadingToError(notification, message)
    throw message
  }
}


export const savePlan = async (plan) => {
  const t = hardTranslator("components.plans.hooks.usePlan")

  const notification = notify.loading(t("saveManagerLoading", plan?.id))
  try {
    const companyPlanToUpload = { ...getDefaultPlan(), ...plan }

    if (plan?.id) {
      await planRequests.put(plan?.id, companyPlanToUpload)
      notifyLoadingToSuccess(notification, t("saveManagerSucceed", true))
    } else {
      await planRequests.post(companyPlanToUpload)
      notifyLoadingToSuccess(notification, t("saveManagerSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveManagerError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default usePlan;