import { useEffect, useState } from "react";
import { clubRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { login } from "../../../redux/reducers/session/actions";
import { notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { useDispatch, useSelector } from "react-redux";
import useTranslator, { hardTranslator } from "../../../hooks/useTranslator";

const useClubs = () => {
  const t = useTranslator("components.clubs.hooks.useClubs")
  const dispatch = useDispatch()
  const { token, user, club: userClub } = useSelector((state) => state.sessionState)
  const [clubs, setClubs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [])


  const refresh = async () => {
    setIsLoading(true)
    return getClubs()
      .then((clubList) => {
        setClubs(clubList)
        return clubList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const changeActualUserClub = async (id) => {
    if (id.toString() !== userClub.clubId.toString()) {
      const notification = notify.loading(t("changeUserClubLoading"))
      const { clubList = [] } = await clubRequests.getClubsUser();
      user.clubList = (clubList || [])
      const initialClub = user.clubList.find((iterClub) => iterClub?.clubId.toString() === id.toString()) || user.clubList[0]
      dispatch(login(token, user, initialClub));
      notifyLoadingToSuccess(notification, t("changeUserClubSucceed", initialClub.clubName))
    }
  }

  return {
    clubs,
    isLoading,
    refresh,
    changeActualUserClub
  }
}

export const getClubs = async () => {
  const t = hardTranslator("components.clubs.hooks.useClubs")
  try {
    const { clubList = [] } = await clubRequests.getList()
    return clubList
  } catch (e) {
    const message = (e?.message || t("getClubsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useClubs;