import { useEffect, useState } from "react";
import { channelManagerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const defaultPagination = { page: 1, amount: 5 }

const useChannelManagers = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [channelManagers, setChannelManagers] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination)
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [clubId, pagination])

  const refresh = async () => {
    setIsLoading(true)
    return getChannelManagers({ page: pagination.page, size: pagination.amount })
      .then(({ channelManagers, totalAmount }) => {
        setChannelManagers(channelManagers)
        setTotalAmount(totalAmount)
        return channelManagers
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const changePage = (page) => setPagination((currentPagination) => ({ ...currentPagination, page }))
  const changeAmount = (amount) => setPagination((currentPagination) => ({ ...currentPagination, size: amount }))

  return {
    channelManagers,
    pagination,
    changePage,
    changeAmount,
    totalAmount,
    isLoading,
    refresh
  }
}

export const getChannelManagers = async (paramsRequest) => {
  const t = hardTranslator("components.channelManagers.hooks.useChannelManagers")
  try {
    const { userList = [], count = 0 } = await channelManagerRequests.getList(paramsRequest)
    return { channelManagers: userList, totalAmount: count }
  } catch (e) {
    const message = (e?.message || t("getChannelManagersError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useChannelManagers;