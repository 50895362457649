import Api from "../Api";

const getManager = (id) => Api.get(`/admin/users/${id}`);
const getManagerInfo = (id) => Api.get(`/admin/users/${id}/info`);
const getManagersList = () => Api.get(`/admin/users/manager/list`);
const deleteManager = (id) => Api.delete(`/admin/users/manager/${id}`);
const postManager = (body) => Api.post(`/admin/users/manager`, body);
const putManager = (body) => Api.put(`/admin/users/manager`, body);

const managerRequests = {
  get: getManager,
  getInfo: getManagerInfo,
  getList: getManagersList,
  delete: deleteManager,
  put: putManager,
  post: postManager
}


export default managerRequests
