import React from "react";
import { Table, TableCard } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import useTranslator from "../../hooks/useTranslator";
import { TableOutput } from "@general-backoffice/core/index";
import AddButtonChildren from "../shared/AddButtonChildren";

const ShowTeamsCard = ({
                            title,
                            teams = [],
                            pagination,
                            setTeamToUpload,
                            setTeamToRemove,
                            onClickTeamToShowContent,
                            onClickTeam,
                            isLoading
                          }) => {
  const t = useTranslator("components.team.showTeamsCard")

  const columns = [
    {
      name: t("team"),
      formattedContent: ({ image, shortName }) => {
        return <TableOutput.ImageText src={image?.url} text={shortName}/>
      },
      compressed: true,
      isTh: true
    },
    {
      name: t("name"),
      property: "name",
      isTh: true
    },
    {
      name: t("colors"),
      formattedContent: ({ jerseyAway, jerseyHome }) => {
        return <TableOutput.Colors colors={[jerseyHome, jerseyAway]}/>
      }
    },
    {
      compressed: true,
      formattedContent: ({ spdTeamSectionIdList = [] }) => {
        const numSections = (spdTeamSectionIdList || []).length
        const bookmarks = [
          {
            icon: numSections,
            title: t("numberSections", numSections),
            color: "primary"
          }
        ]

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      compressed: true,
      formattedContent: (team) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setTeamToUpload({ ...team }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-th-list"/>,
            onClick: () => onClickTeamToShowContent({ ...team }),
            description: t("modifyContentDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setTeamToRemove({ ...team })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <ReactstrapCard className="overflow-hidden">
      <TableCard.Header
        title={title || t("title")}
        subtitle={t("subtitle")}
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children={t("addTeamButtonText")}/>,
            onClick: () => setTeamToUpload({}),
            title: t("addTeamButtonDescription")
          }
        ]}
        search={{
          value: pagination?.search,
          onChange: (e) => pagination.changeFilter("all", e.target.value),
          msToChange: 1000,
          placeholder: t("searchPlaceholder")
        }}
      />
      <Table
        columns={columns}
        rows={teams}
        onRowClick={(team) => onClickTeam({ ...team })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={pagination?.pageSize}
        rowHeight={81}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={pagination?.changePageSize}
        totalEntries={pagination?.totalElements}
        page={(pagination?.pageNumber - 1)}
        onChangePage={(page) => pagination?.changePageNumber((page + 1))}
        isLoading={isLoading}
      />
    </ReactstrapCard>
  );
}

export default ShowTeamsCard;