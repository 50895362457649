const getDefaultNavigationItem = () => ({
  url: "/",
  openTarget: "_self",
  active: true,
  translations: {
    es: "",
    en: ""
  },
  predefined: false
})

export default getDefaultNavigationItem