import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";

import { channelRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";

const useChannels = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getChannels()
      .then((channels) => {
        setChannels(channels)
        return channels
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return {
    channels,
    isLoading,
    refresh
  }
}

export const getChannels = async () => {
  const t = hardTranslator("components.channels.hooks.useChannels")
  try {
    await channelRequests.debug()
    return await channelRequests.getList()
  } catch (e) {
    const message = (e?.message || t("getChannelsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useChannels;