const getDefaultPlan = () => ({
  active: true,
  code: "",
  description: "",
  extendable: true,
  interval: "MONTH",
  meetingTokens: 0,
  planType: "REGULAR",
  currency: "EUR",
  trialDays: 0,
  name: "",
  price: 0,
})

export default getDefaultPlan;