
const twoDigits = n => n.toLocaleString(undefined, {minimumIntegerDigits: 2})

const clock = (currentTime = {}, duration = 0, zerosOnMinutes = false) => {
  const { hours = 0, minutes = 0, seconds = 0 } = currentTime
  let showHours = duration >= 3600 || hours > 0
  return (
    (showHours ? `${hours}:` : '') +
    ((zerosOnMinutes || showHours) ? twoDigits(minutes) : minutes) + ":" + 
    twoDigits(parseInt(seconds))
  )
}

export default clock;