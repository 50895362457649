import React from "react";
import { Card, Loading, notify } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import getDefaultMeeting from "./shared/getDefaultMeeting";
import VideoCall from "../shared/VideoCall";
import useTranslator from "../../hooks/useTranslator";

const ShowMeetingCallCard = ({ meetingToShowCall, getOffTheCall, isLoading }) => {
  const t = useTranslator("components.meetings.showMeetingCallCard")

  const finalMeetingToShowCall = { ...getDefaultMeeting(), ...(meetingToShowCall || {}) }
  const { level, participants, start, url } = finalMeetingToShowCall;

  const onError = () => {
    notify.error(t("errorText"))
    getOffTheCall()
  }

  return (
    <ReactstrapCard style={{ height: "80vh" }}>
      <Card.Header
        title={t("title")}
        subtitle={t("subtitle", participants.length - 1, level, start)}
        buttons={[
          {
            children: t("leaveMeetingButtonText"),
            color: "danger",
            onClick: getOffTheCall
          }
        ]}
      />
      {isLoading && (
        <div className="h-100 w-100 flex-content-center">
          <Loading.BouncingDots className="my-5"/>
        </div>
      )}
      <VideoCall
        idVideoCall={url}
        userName={t("userName")}
        onError={onError}
        onLeft={getOffTheCall}
        isLoading={isLoading}
      />
    </ReactstrapCard>
  );
}

export default ShowMeetingCallCard;