import Api from "../Api";

const getCollection = (id) => Api.get(`/admin/collections/${id}`);
const getCollectionList = (params = {}) => Api.get(`/admin/collections/list`, { params });
const putCategory = (body) => Api.put(`/admin/collections/update`, body);
const postCategory = (body) => Api.post(`/admin/collections/create`, body);
const deleteCategory = (id) => Api.delete(`/admin/collections/${id}`);

const getCollectionListByCategory = (params = {}) => Api.get(`/admin/collections/by-category/list`, { params });

const collectionRequests = {
  get: getCollection,
  getList: getCollectionList,
  put: putCategory,
  post: postCategory,
  delete: deleteCategory,
  getListByCategory: getCollectionListByCategory
}

export default collectionRequests