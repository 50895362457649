import React from "react";
import { Table, TableCard, TableOutput, Card } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import useTranslator from "../../hooks/useTranslator";
import AddButtonChildren from "../shared/AddButtonChildren";

const ShowTeamSectionListCard = ({
                                   title,
                                   sections = [],
                                   setSectionToUpload,
                                   setSectionToRemove,
                                   onClickSection,
                                   sortSections,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.teamSection.showTeamSectionListCard")

  const columns = [
    {
      name: t("name"),
      property: "name",
      isTh: true
    },
    {
      compressed: true,
      formattedContent: ({ active, collectionIdList }) => {
        const numCollections = (collectionIdList || []).length
        const bookmarks = [
          {
            icon: numCollections,
            title: t("numberCollections", numCollections),
            color: "primary"
          }
        ]

        // Publication
        if (active) bookmarks.push({ icon: <i className="fas fa-eye"/>, title: t("published"), color: "primary" })
        else bookmarks.push({ icon: <i className="fas fa-eye-slash"/>, title: t("unpublished"), color: "primary" })

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      compressed: "true",
      formattedContent: (section) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setSectionToUpload({ ...section }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setSectionToRemove({ ...section })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      }
    },
  ]

  const onDataChange = async (sortedSections, trigger) => {
    if (trigger === "sortable") {
      sortSections(sortedSections)
    }
  }

  return (
    <ReactstrapCard className="overflow-hidden">
      <TableCard.Header
        title={title || t("title")}
        subtitle={t("subtitle")}
        buttons={[
          {
            children: <AddButtonChildren children={t("addButtonText")}/>,
            onClick: () => setSectionToUpload({})
          },
        ]}
      />
      <Table
        columns={columns}
        sortable={true}
        onDataChange={onDataChange}
        rows={sections}
        onRowClick={(section) => onClickSection({ ...section })}
        isLoading={isLoading}
        noContentMessage={t("emptySections")}
        rowAmount={10}
      />
      <Card.Footer/>
    </ReactstrapCard>
  );
}

export default ShowTeamSectionListCard;