import { getZoneByFtp, zones } from "../config"


const getProcessedStructure = (structure) => {
  const groups = [];
  let structureDuration = 0;
  const processedStructure = structure.map((segment, i) => {
    const { ftp } = segment;
    const [initFtp, endFtp] = ftp;
    const segmentFtp = (initFtp + endFtp) / 2
    const segmentZone = getZoneByFtp(segmentFtp)

    // APPLIED RPM
    segment.appliedRPM = getSegmentRPM(structure, i) || null;

    //STRUCTURE DURATIONS
    const startDuration = structureDuration
    const finalDuration = structureDuration + segment.duration;
    structureDuration = finalDuration;
    segment.startDuration = startDuration;
    segment.finalDuration = finalDuration;

    //GROUPS
    const lastGroup = (groups.length > 0) ? groups[groups.length - 1] : null
    if (!!lastGroup && lastGroup.zone.zone === segmentZone.zone) {
      const indexLastGroup = groups.length - 1
      groups[indexLastGroup].end = finalDuration
      segment.groupIndex = indexLastGroup
    } else {
      const groupsLength = groups.push({ zone: segmentZone, start: startDuration, end: finalDuration })
      segment.groupIndex = (groupsLength - 1)
    }
    return segment
  })
  return { processedStructure, groups };
}

const getSegmentRPM = (segments, currentSegmentIndex) => {
  let segment = segments[currentSegmentIndex]
  if(!Array.isArray(segment.rpm)) {
    let prevRPM = segments.slice(0, currentSegmentIndex).reverse().find(p => Array.isArray(p.rpm))
    if(prevRPM) return [prevRPM.rpm[1], prevRPM.rpm[1]]
  }
  return segment.rpm
}

const getZoneFromFTP = ftp => {
  if(ftp){
    let index = zones.findIndex((zone, i) => {
      if(i >= zones.length-1) return true
      return zone.ftp <= ftp && zones[i+1].ftp > ftp
    }) 
    return index >= 0 ? {index: index+1, ...zones[index] } : null
  } else return null
}


const mountGroups = structure => {
  let groups = [];
  structure.forEach((segment, i) => {
    let lastGroup = groups?.[groups.length-1]

    if(lastGroup && lastGroup.zone.zone === segment.zone.zone) {
      structure[i].groupIndex = groups.length-1
      lastGroup.end = segment.end
    } else {
      const { zone, end, start } = segment
      let groupIndex = groups.push({ zone, start, end })
      structure[i].groupIndex = groupIndex-1
    }
  })
  return { structure, groups }
}


const formatTraining = (training = {}) => {
  const { workout } = training
  const structure = workout?.structure
  
  let newStructure = []
  structure.reduce((r, segment, i) => {
    let zone = getZoneFromFTP(segment.ftp[0])
    const end = r + segment.duration
    newStructure.push({
      ...segment, 
      zone, end, 
      start: r,
      rpm: getSegmentRPM(structure, i),
      index: i
    })
    return end
  }, 0)

  return {
    ...training, 
    workout: {
      ...workout, 
      ...mountGroups([...newStructure])
    }
  }
}

export { getZoneFromFTP, getSegmentRPM, getProcessedStructure }
export default formatTraining;