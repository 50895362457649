import React from "react";
import { Header, Loading } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import useTranslator from "../../../../hooks/useTranslator";
import ChangeClubSeoCard from "../../../../components/clubConfig/ChangeClubSeoCard";
import useClubSeo from "../../../../components/clubConfig/hooks/useClubSeo";

const SeoConfig = () => {
  const t = useTranslator("views.pages.dashboard.clubConfig")
  const { club: userClub } = useSelector((state) => state.sessionState)

  const {
    clubSeo,
    setClubSeo,
    isLoading,
    save
  } = useClubSeo(userClub.clubId)

  return (
    <React.Fragment>
      {isLoading && <Loading.Line/>}
      <Header.BreadCrumb
        color="secondary"
        isDark={false}
        buttons={[{
          children: t("save"),
          color: "success",
          size: "md",
          onClick: () => save().catch(() => null),
          disabled: isLoading
        }]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={6}>
            <ChangeClubSeoCard clubSeo={clubSeo} setClubSeo={setClubSeo} isLoading={isLoading}/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default SeoConfig;