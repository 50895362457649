import Api from "../Api";

const getNew = (id) => Api.get(`/admin/news/${id}`);
const getNewsList = (params) => Api.get(`/admin/news`, { params });
const deleteNew = (id) => Api.delete(`/admin/news/${id}`);
const putNew = (id, body) => Api.put(`/admin/news/${id}`, body);
const postNew = (body) => Api.post(`/admin/news`, body);

const newRequests = {
  get: getNew,
  getList: getNewsList,
  delete: deleteNew,
  put: putNew,
  post: postNew
}

export default newRequests