import React from "react";
import { Col, Container, Row } from "reactstrap";

// Components
import StatsTrainersIncome from "../../../../components/stats/StatsTrainersIncome"
import { Header } from "@general-backoffice/core/index";
import StatsTrainersMeetings from "../../../../components/stats/StatsTrainersMeetings";


const TrainersStats = () => {
  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} xl={6}>
            <StatsTrainersIncome/>
          </Col>
          <Col xs={12} xl={6}>
            <StatsTrainersMeetings/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TrainersStats;