import { useEffect, useState } from "react";
import { sliderRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";

const useSlides = () => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = () => {
    setIsLoading(true)
    return getSlides()
      .then((tagList) => {
        setSlides(tagList)
        return tagList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sort = async (sortedSlides = []) => {
    const oldSlides = [...slides]
    setSlides(sortedSlides)
    return sortSlides(sortedSlides)
      .then(() => {
        getSlides().then((slidesList) => setSlides(slidesList))
      })
      .catch(() => {
        setSlides(oldSlides)
      })
  }


  return {
    slides,
    isLoading,
    sort,
    refresh,
  }
}

export const getSlides = async () => {
  const t = hardTranslator("components.slider.hooks.useSlides")
  try {
    const slidesList = await sliderRequests.getList()
    return slidesList || []
  } catch (e) {
    const message = (e?.message || t("getSlidesError"))
    notify.error(message)
    throw new Error(message)
  }
}

export const sortSlides = async (slides) => {
  const t = hardTranslator("components.slider.hooks.useSlides")
  const notificationLoading = notify.loading(t("sortSlidesLoading"))
  try {
    const itemOrderList = slides.map(({ id }) => id)
    await sliderRequests.sort(itemOrderList)
    notifyLoadingToSuccess(notificationLoading, t("sortSlidesSucceed"))
  } catch (e) {
    const message = e?.message || t("sortSlidesError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export default useSlides;