import { useEffect, useState } from "react";
import { clubRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import getDefaultClubSeo from "../shared/getDefaultClubSeo";

const useClubSeo = (id) => {
  const [clubSeo, setClubSeo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clubId = id || clubSeo?.clubId

  useEffect(() => {
    if (!!clubId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getClubSeo(clubId)
      .then((clubSeo) => {
        if (!clubSeo) throw new Error()
        setClubSeo(clubSeo)
        return clubSeo
      })
      .catch((e) => {
        setClubSeo(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveClubSeo(clubSeo)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    clubSeo,
    setClubSeo,
    isLoading,
    save
  }
}

export const getClubSeo = async (clubId) => {
  const t = hardTranslator("components.clubConfig.hooks.useClubConfig")
  try {
    const seo = await clubRequests.getSeo()
    return { ...getDefaultClubSeo(), ...seo, clubId }
  } catch (e) {
    const message = e?.message || t("getClubConfigError")
    notify.error(message)
    throw new Error(message)
  }
}


export const saveClubSeo = async (clubSeo) => {
  const t = hardTranslator("components.clubConfig.hooks.useClubConfig")
  const notification = notify.loading(t("saveClubConfigLoading"))
  try {
    await clubRequests.changeSeo(clubSeo)
    notifyLoadingToSuccess(notification, t("saveClubConfigSucceed"))
  } catch (e) {
    const message = e?.message || t("saveClubConfigError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useClubSeo;