import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import classNames from "classnames";
import { Loading } from "@general-backoffice/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useNavigate } from 'react-router-dom';

import useStopwatch from '../../../../hooks/useStopwatch';
import useCountdown from "../../../../hooks/useCountdown";
import useEventListener from "../../../../hooks/useEventListener"

import getCurrentSegment from "./utils/getCurrentSegment"
import DefaultPlayer from "./interfaces/default"
import CaptureRow from "./interfaces/CaptureRow"
import Intro from "./interfaces/Intro";
import Outro from "./interfaces/Outro";
import Controls from "./Controls"
import bgImage from "../media/training-player-bg.jpg"
import logo from "../media/logoOverDark.png";
import { colors } from "../config"


const getWindowSize = () => { 
  const width = window.innerWidth
  const height = window.innerHeight 
  let ratio = width / height

  return(
    (width >= 1200) || (width >= 992 && ratio < 1.65) ? 'lg' :
    ratio > 1 ? 'sm-h' : 'sm-v'
  )
}

const TrainingPlayerCard = ({ trainingToPlay }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize())
  useEventListener("resize", () => setWindowSize(getWindowSize()))

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true)
  const [showIntro, setShowIntro] = useState(false)
  const [showOutro, setShowOutro] = useState(false)
  const [chroma, setChroma] = useState(false)
  const [showControls, setShowControls] = useState(false)
  const fullscreen = useFullScreenHandle();

  const { workout, audioUrl } = trainingToPlay || {};
  const { structure = [], groups = [] } = workout || {};
  const defaultDuration = (structure?.[structure.length - 1]?.time || 0)
  const [duration, setDuration] = useState(defaultDuration) 

  const audioWavesRef = useRef()

  //Time
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    isPlaying,
    toggle,
    stop,
    goToSecond
  } = useStopwatch({ duration, callback: () => setShowOutro(true) });

  const currSegment = getCurrentSegment(structure, totalSeconds)

  // Unmount timeouts
  useEffect(() => {
    if(!audioUrl) setLoading(false);
    // eslint-disable-next-line
  }, []);

  // Countdown on play
  const { startCountdown, countdownSeconds, countingdown } = useCountdown({ callback: toggle })
  const customToggle = () => !isPlaying ? startCountdown() : toggle()

  const startTraining = () => {
    audioWavesRef.current.play()
    audioWavesRef.current.pause()
    setShowIntro(false)
    fullscreen.enter()
    customToggle()
  }


  // Controls move
  let timeoutMove;
  const mouseMoved = () => {
    setShowControls(true);
    timeoutMove = setTimeout(() => {
      setShowControls(false)
    }, 3000)
  }

  // Unmount timeouts
  useEffect(() => () => {
    clearTimeout(timeoutMove);
    // eslint-disable-next-line
  }, []);


  // When finished loading play intro
  const customSetLoading = loading => {
    if(!loading) setShowIntro(true)
    setLoading(loading)
  }

  
  
  useEventListener("keydown", 
    ({ key }) => {
      if(key === "Escape") navigate(-1)
      if(key === " ") {
        if(!isLoading && !showOutro) {
          if(showIntro) startTraining()
          else customToggle()          
        }
      }
    }
  )



  const currentTime = {
    totalSeconds,
    seconds,
    minutes,
    hours
  }
  let cutscene = isLoading || showIntro || showOutro
  return (
    <FullScreen handle={fullscreen}>
      <Container
        fluid={true}
        className="vh-100 overflow-hidden px-0 position-relative d-flex flex-column"
        style={(windowSize === 'lg' && chroma && !cutscene) ?
          { backgroundColor: colors.chroma } 
        :
          {
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }
        }
        onMouseMove={mouseMoved}
      >
        <Row className="py-3 mb--5 ml-2">
          <Col xs={"auto"}>
            <img src={logo} style={{width: '15vw', maxWidth: 190, minWidth: 130}} alt="InfinityRide logo"/>
          </Col>
        </Row>

        {cutscene && 
          <Row className="m-0 vh-100 w-100 position-fixed flex-content-center" style={{top: 0}}>
            {showIntro ?
              <Intro training={trainingToPlay} startTraining={startTraining}/>
            : showOutro ?
              <Outro training={trainingToPlay} onClose={() => navigate(-1)}/>
            :
              <Col xs={"auto"}>
                <Loading.BouncingDots className="my-5" color="light" size="lg"/>
              </Col>
            }
          </Row>
        }


        <div style={{ flex: 1 }}
          className={classNames(
            "p-0 w-100 overflow-hidden", 
            {'invisible': cutscene}
          )} 
        >
          <Controls
            fullscreen={fullscreen.active}
            toggleFullscreen={() => 
              fullscreen.active ? 
                fullscreen.exit() : 
                fullscreen.enter()
            }
            showControls={showControls}
            chroma={chroma && windowSize === 'lg'}
            isPlaying={isPlaying}
            togglePlayer={customToggle}
            stopPlayer={stop}
            goToSecond={goToSecond}
            currentTime={currentTime}
            duration={duration}
            setDuration={setDuration}
            defaultDuration={defaultDuration}
            audioUrl={audioUrl}
            setLoading={customSetLoading}
            isLoading={isLoading}
            onMountAudio={ref => audioWavesRef.current = ref}
          />
        
          <DefaultPlayer
            windowSize={windowSize}
            chroma={chroma}
            toggleChroma={() => setChroma(!chroma)}
            structure={structure}
            groups={groups}
            currentSegment={currSegment}
            duration={duration}
            currentTime={currentTime}
            secondsBeforeStart={countdownSeconds}
          />
        </div>

        
        {(!cutscene && chroma && windowSize === 'lg') && 
          <CaptureRow
            countingdown={countingdown}
            structure={structure}
            groups={groups}
            currentSegment={currSegment}
            duration={duration}
            currentTime={currentTime}
            secondsBeforeStart={countdownSeconds}
          />
        }
        
      </Container>
    </FullScreen>
  )
}

export default TrainingPlayerCard;