import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import getDefaultPlan from "./shared/getDefaultPlan";
import { Collapse } from "reactstrap";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedIntervals from "../../hooks/translations/useTranslatedIntervals";
import useTranslatedCurrencies from "../../hooks/translations/useTranslatedCurrencies";
import useTranslatedPlanTypes from "../../hooks/translations/useTranslatedPlanTypes";
import { useSelector } from "react-redux";


const UploadPlanModal = ({
                           plan,
                           setPlan,
                           isLoading,
                           onSubmit
                         }) => {
  const t = useTranslator("components.plans.uploadPlanModal")
  const { club: userClub } = useSelector((state) => state.sessionState)
  const allowedPlanTypes = ["REGULAR", "SINGLE_PAYMENT"]
  if (userClub?.clubProperties?.allowedToPayInCash) allowedPlanTypes.push("PAY_IN_CASH")
  const { planTypeOptions, getPlanTypeOption } = useTranslatedPlanTypes(allowedPlanTypes)
  const { intervalOptions, getIntervalOption } = useTranslatedIntervals(["MONTH", "YEAR"])
  const { currencyOptions, getCurrencyOption } = useTranslatedCurrencies()

  const { changeState, onChangeInput: onChange, changeMultipleStates } = useChangingComplexState(setPlan, isLoading)

  const {
    id,
    planType,
    description,
    interval,
    currency,
    // meetingTokens,
    name,
    price,
    trialDays
  } = { ...getDefaultPlan(), ...(plan || {}) }

  const isCash = planType === "PAY_IN_CASH"
  const isSingle = planType === "SINGLE_PAYMENT"

  return (
    <Modal.FormContainer
      isOpen={!!plan}
      toggleOpen={() => setPlan(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >

      <FormInput.Input
        type={"text"}
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
      />

      <FormInput.Select
        label={t("planTypeLabel")}
        name={"planType"}
        onChange={(option) => {
          const isSinglePayment = option.value === "SINGLE_PAYMENT"
          changeMultipleStates({
            planType: option.value,
            interval: isSinglePayment ? "LIFE" : "MONTH"
          })
        }}
        options={planTypeOptions}
        value={getPlanTypeOption(planType)}
        required={true}
        isDisabled={!!id}
      />

      <Collapse isOpen={!isCash}>
        <FormInput.Input
          type={"textarea"}
          onChange={onChange}
          value={description}
          name={"description"}
          label={t("descriptionLabel")}
          placeholder={t("descriptionPlaceholder")}
          style={{ minHeight: 100 }}
        />
      </Collapse>

      <Collapse isOpen={!isCash}>
        <FormInput.Input
          onChange={onChange}
          value={trialDays}
          name={"trialDays"}
          label={t("trialDaysLabel")}
          required={true}
          disabled={!!id}
          step={1}
          min={0}
          type={"number"}
          description={t("trialDaysDescription", trialDays)}
        />
      </Collapse>

      <Collapse isOpen={!isCash}>
        <FormInput.Select
          label={t("currencyLabel")}
          name={"currency"}
          icon={<i className="fas fa-money-bill-wave mx-2"/>}
          onChange={(option) => changeState("currency", option.value)}
          options={currencyOptions}
          value={getCurrencyOption(currency)}
          required={true}
          isDisabled={!!id}
        />
      </Collapse>

      <Collapse isOpen={!isCash && !isSingle}>
        <FormInput.Select
          label={t("intervalLabel")}
          name={"interval"}
          icon={<i className="fas fa-hourglass-half mx-2"/>}
          onChange={(option) => changeState("interval", option.value)}
          options={intervalOptions}
          value={getIntervalOption(interval)}
          required={true}
          isDisabled={!!id}
        />
      </Collapse>

      <Collapse isOpen={!isCash}>
        <FormInput.Price
          currency={currency === "EUR" ? "euro" : "dollar"}
          onChange={onChange}
          value={price}
          name={"price"}
          label={t("priceLabel")}
          required={true}
          disabled={!!id}
        />
      </Collapse>
    </Modal.FormContainer>
  );
}

export default UploadPlanModal;