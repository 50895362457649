import React from "react";
import { Header, FormInput, notify } from "@general-backoffice/core";
import { Card, Col, Container, Row } from "reactstrap";
import useRequest from "../../../../hooks/useRequest";
import advertisingRequests from "../../../../api/requests/advertisingRequests";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useTranslator from "../../../../hooks/useTranslator";
import useChangingComplexState from "../../../../hooks/useChangingComplexState";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../../../components/utils/notify/notifyLoadingUpdate";
import useApiFileImages from "../../../../hooks/useApiFileImages";
import ResizeImageFileModal from "../../../../components/shared/ResizeImageFileModal";
import useCategories from "../../../../components/category/hooks/useCategories";
import useCollections from "../../../../components/collection/hooks/useCollections";
import useProducts from "../../../../components/product/hooks/useProducts";

const imageStructures = {
  login: {
    size: [1080, 1080], // 1:1
    MB: 0.6
  },
  footer: {
    size: [1456, 180], // ultra panoramic banner
    MB: 0.5
  },
  product: {
    size: [336, 280], // 6:5
    MB: 0.5
  },
  category: {
    size: [728, 90], // ultra panoramic banner
    MB: 0.5
  },
  collection: {
    size: [336, 280], // 6:5
    MB: 0.5
  }
}

const getRoute = (advertisementId = "new", location = undefined) => {
  const url = new URL(`/dashboard/services/advertisements/${advertisementId}`, window.location.origin)
  if (location) url.searchParams.append("location", location);
  return url.pathname + url.search;
};

const getDefaultAdvertisement = (adv = {}) => ({
  title: "",
  description: "",
  urlRedirect: "",
  script: undefined,
  active: true,
  location: "login",
  image: { url: null, data: null, contentType: null },
  type: "image",
  productIdList: [],
  categoryIdList: [],
  collectionIdList: [],
  ...adv
})

const defaultLocations = ["product", "category", "collection"]
const defaultLocationOptions = defaultLocations.map((l) => {
  return { label: l, value: l }
})


const Advertisement = () => {
  const t = useTranslator("views.pages.dashboard.advertisement")
  const { advertisementId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const forcedLocation = searchParams.get("location")

  const {
    state: advertisement,
    setState: setAdvertisement,
    isLoading: isLoadingAdvertisement
  } = useRequest(() => {
    if (!advertisementId) return getDefaultAdvertisement({ location: forcedLocation || defaultLocations[0] });
    return advertisingRequests.get(advertisementId)
  }, [advertisementId])

  const { changeState, onChangeInput: onChange } = useChangingComplexState(setAdvertisement, isLoadingAdvertisement)

  const {
    title,
    description,
    urlRedirect,
    script,
    active,
    id,
    location,
    image,
    type,
    productIdList,
    categoryIdList,
    collectionIdList,
  } = advertisement || {}

  const {
    products,
    isLoading: isLoadingProducts,
  } = useProducts();

  const {
    categories,
    isLoading: isLoadingCategories,
  } = useCategories();

  const {
    collections,
    isLoading: isLoadingCollections,
  } = useCollections()

  const locationOptions = [
    ...defaultLocationOptions,
    ...(forcedLocation ? [{ value: forcedLocation, label: forcedLocation }] : [])
  ]
  const productOptions = products.map(({ id, title }) => ({ value: id, label: title }))
  const categoryOptions = categories.map(({ id, name }) => ({ value: id, label: name }));
  const collectionOptions = collections.map(({ id, title }) => ({ value: id, label: title }));

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles
  } = useApiFileImages({ image }, [id])

  const onSubmit = async (e) => {
    e.preventDefault()
    const notification = notify.loading(t("saving"))
    const body = advertisement
    try {
      if (id) await advertisingRequests.put(body)
      else await advertisingRequests.post(body)
      notifyLoadingToSuccess(notification, t("savingSuccess"))
      navigate("/dashboard/services/advertisements")
    } catch (e) {
      notifyLoadingToError(notification, t("savingError"))
    }
  }

  const imageStructureToUse = imageStructures[location || "login"]

  return (
    <React.Fragment>

      <ResizeImageFileModal
        imageFile={imagesToResize.image}
        setImageFile={(file) => setImagesToResize({ image: file })}
        onSave={(file, image64) => {
          setFiles({ ...files, image: file })
          changeState("image", { id: null, data: image64 })
          setImagesToResize({ image: null })
        }}
        width={imageStructureToUse.size[0]}
        height={imageStructureToUse.size[1]}
        maxSizeMB={imageStructureToUse.MB}
      />

      <form onSubmit={onSubmit}>
        <Header.BreadCrumb
          title={title}
          color="secondary"
          isDark={false}
          buttons={[
            {
              children: t("save"),
              size: "md",
              type: "submit"
            }
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs={12} md={6}>
              <Card body>
                <FormInput.Input
                  type={"text"}
                  onChange={onChange}
                  value={title}
                  name={"title"}
                  label={t("titleLabel")}
                  required={true}
                />
                <FormInput.Input
                  type={"textarea"}
                  onChange={onChange}
                  value={description}
                  name={"description"}
                  label={t("descriptionLabel")}
                  required={true}
                />
                <FormInput.Select
                  label={t("advertisementPositionLabel")}
                  icon={<i className="fas fa-map-marker-alt mx-2"/>}
                  onChange={(option) => changeState("location", option?.value || null)}
                  options={locationOptions}
                  value={locationOptions.find(({ value }) => value === location)}
                  isDisabled={!!forcedLocation}
                  formatOptionLabel={({ label }) => t("advertisementPosition")[label]}
                />
                {location === "product" && (
                  <FormInput.Select
                    label={t("productsLabel")}
                    name={"productIdList"}
                    isMulti={true}
                    icon={<i className="fas fa-boxes px-2"/>}
                    onChange={(options = []) => changeState("productIdList", options.map(({ value }) => value))}
                    options={productOptions}
                    value={productOptions.filter(({ value }) => productIdList.includes(value))}
                    isDisabled={isLoadingProducts || isLoadingAdvertisement}
                    isLoading={isLoadingProducts || isLoadingAdvertisement}
                    noOptionsMessage={() => t("noProductOptionsText")}
                  />
                )}
                {location === "category" && (
                  <FormInput.Select
                    label={t("categoriesLabel")}
                    name={"categoryIdList"}
                    isMulti={true}
                    icon={<i className="fas fa-bars px-2"/>}
                    onChange={(options = []) => changeState("categoryIdList", options.map(({ value }) => value))}
                    options={categoryOptions}
                    value={categoryOptions.filter(({ value }) => categoryIdList.includes(value))}
                    isDisabled={isLoadingCategories || isLoadingAdvertisement}
                    isLoading={isLoadingCategories || isLoadingAdvertisement}
                    noOptionsMessage={() => t("noCategoryOptionsText")}
                  />
                )}
                {location === "collection" && (
                  <FormInput.Select
                    label={t("collectionsLabel")}
                    name={"collectionIdList"}
                    isMulti={true}
                    icon={<i className="fas fa-boxes px-2"/>}
                    onChange={(options = []) => changeState("collectionIdList", options.map(({ value }) => value))}
                    options={collectionOptions}
                    value={collectionOptions.filter(({ value }) => collectionIdList.includes(value))}
                    isDisabled={isLoadingCollections || isLoadingAdvertisement}
                    isLoading={isLoadingCollections || isLoadingAdvertisement}
                    noOptionsMessage={() => t("noCollectionOptionsText")}
                  />
                )}
                <FormInput.Switch
                  onChange={onChange}
                  checked={active}
                  name={"active"}
                  label={t("isActiveLabel")}
                  labels={t("isActiveAnswers")}
                />
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card body>
                <FormInput.Switch
                  onChange={(e) => changeState("type", e.target.checked ? "script" : "image")}
                  checked={type === "script"}
                  name={"type"}
                  label={t("isScriptLabel")}
                  labels={t("isScriptAnswers")}
                />
                {type === "image" && (
                  <FormInput.Input
                    type={"text"}
                    onChange={onChange}
                    value={urlRedirect}
                    name={"urlRedirect"}
                    label={t("urlRedirectLabel")}
                    required={true}
                  />
                )}
                {type === "image" && (
                  <FormInput.SingleDropzone
                    file={files.image}
                    label={t("advertisementImage")}
                    smallLabel={imageStructureToUse.size.join(" x ")}
                    accept={"image/*"}
                    onDrop={(file) => setImagesToResize({ image: file })}
                    buttons={[
                      {
                        children: <i className="fas fa-crop-alt"/>,
                        onClick: () => setImagesToResize({ ...imagesToResize, image: files.image })
                      }
                    ]}
                  />
                )}
                {type === "script" && (
                  <FormInput.Input
                    type={"textarea"}
                    onChange={onChange}
                    value={script}
                    style={{ height: 300 }}
                    name={"script"}
                    label={t("scriptLabel")}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </form>
    </React.Fragment>
  );
}

export default Advertisement;
export { getRoute }