import React from "react";
import { FormInput, Modal } from "@general-backoffice/core";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";

const UploadChannelModal = ({
                              channel,
                              setChannel,
                              isLoading,
                              onSubmit
                            }) => {
  const t = useTranslator("components.channels.uploadChannelModal")
  const { name, placeholder, url, isLoop } = (channel || {})
  const { onChangeInput: onChange } = useChangingComplexState(setChannel, isLoading)

  const onSubmitForm = async (event) => {
    event.preventDefault();
    onSubmit()
  }

  return (
    <Modal.FormContainer
      isOpen={!!channel}
      toggleOpen={() => setChannel(null)}
      title={t("title", !!url)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
    >
      <FormInput.Input
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
      />

      <FormInput.Input
        onChange={onChange}
        value={placeholder}
        name={"placeholder"}
        label={t("placeholderUrlLabel")}
      />

      {!url && (
        <FormInput.Switch
          onChange={onChange}
          checked={isLoop}
          name={"isLoop"}
          label={t("isLoopLabel")}
          labels={t("isLoopAnswer")}
        />
      )}
    </Modal.FormContainer>
  );
}

export default UploadChannelModal;