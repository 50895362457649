const permissions = {
  clubManager: "CLUB_MANAGER",
  onDemand: "ON_DEMAND",
  transcodification: "TRANSCODIFICATION",
  magazine: "MAGAZINE",
  users: "USERS",
  linealChannel: "LINEAL_CHANNEL",
  advertising: "ADVERTISING",
  trainers: "TRAINERS",
  trainings: "TRAININGS",
  trainingsRecord: "TRAININGS_RECORD",
  schedule: "TRAINER_SCHEDULE",
  meeting: "TRAINER_MEETING",
  notifications: "NOTIFICATIONS",
  publishTraining: "PUBLISH_TRAINING",
  statistics: "STATISTICS",
  clubConfig: "CLUB_CONFIGURATION",
  teams: "TEAMS"
}

export default permissions;