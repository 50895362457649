import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import getDefaultNew from "./shared/getDefaultNew";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import moment from "moment";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import useApiFileImages from "../../hooks/useApiFileImages";

const imagesStructure = {
  image: {
    size: [1280, 720],
    MB: 0.4
  }
}

const UploadNewModal = ({
                          theNew,
                          setNew,
                          onSubmit,
                          isLoading
                        }) => {
  const t = useTranslator("components.news.uploadNewModal")

  const { changeState, onChangeInput: onChange } = useChangingComplexState(setNew, isLoading)

  const {
    id,
    description,
    publicationDate,
    published,
    title,
    video,
    image
  } = { ...getDefaultNew(), ...(theNew || {}) }

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image }, [id, !!theNew])

  const someIsLoading = (isLoading || isLoadingFiles);

  return (
    <React.Fragment>
      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        isOpen={!!theNew}
        toggleOpen={() => setNew(null)}
        title={t("title", !!id, title)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmit}
        isLoading={someIsLoading}
      >
        <FormInput.Input
          type={"text"}
          onChange={onChange}
          value={title}
          name={"title"}
          label={t("titleLabel")}
          required={true}
        />

        <FormInput.Switch
          onChange={onChange}
          checked={published}
          name={"published"}
          label={t("isPublishedLabel")}
          labels={t("isPublishedAnswers")}
        />

        <FormInput.DatePicker
          onChange={(value) => changeState("publicationDate", moment(value).format("YYYY-MM-DD"))}
          value={moment(publicationDate)}
          name={"publicationDate"}
          label={t("publicationDateLabel")}
          timeFormat={false}
        />

        <FormInput.Quill
          onChange={(value) => changeState("description", value)}
          value={description}
          label={t("contentLabel")}
        />

        <FormInput.SingleDropzone
          label={t("imageLabel") + " (JPG, PNG)"}
          smallLabel={imagesStructure.image.size.join(" x ")}
          accept={".jpg, .jpeg, .png"}
          file={files.image}
          onDrop={(file) => setImagesToResize({ image: file })}
          buttons={[
            {
              children: <i className="fas fa-crop-alt"/>,
              onClick: () => setImagesToResize({ image: files.image })
            }
          ]}
          required
        />

        <FormInput.Input
          onChange={onChange}
          value={video?.hlsUrl}
          name={"video.hlsUrl"}
          label={t("videoLabel")}
          placeholder={"https://..."}
        />
      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadNewModal;