import React from "react";
import { Modal, Loading } from "@general-backoffice/core";
import getDefaultMeeting from "./shared/getDefaultMeeting";
import { Container, Row } from "reactstrap";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";

const ShowMeetingModal = ({ meeting, close, isLoading, join }) => {
  const t = useTranslator("components.meetings.showMeetingModal")

  const {
    id,
    level,
    maxParticipants,
    participants,
    image,
    start,
    end,
    url
  } = { ...getDefaultMeeting(), ...(meeting || {}) }

  const countParticipants = participants.length ? (participants.length - 1) : 0

  return (
    <Modal.Simple
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      buttons={[
        {
          children: t("enterButtonText"),
          color: "success",
          disabled: (!url || isLoading),
          onClick: join
        }
      ]}
    >
      {isLoading && <Loading.BouncingDots className="my-5"/>}
      {!isLoading && (
        <Container fluid className="mt--4">
          {image?.url && (
            <Row className="justify-content-center mb-3">
              <div className="rounded" style={{
                width: "100%",
                height: 150,
                background: `url(${image.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}/>
            </Row>
          )}
          <RowList title={t("start")} children={moment(start).format("DD/MM/YYYY HH:mm")}/>
          <RowList title={t("end")} children={moment(end).format("DD/MM/YYYY HH:mm")}/>
          <RowList title={t("participants")} children={`${countParticipants}/${maxParticipants - 1}`}/>
          <RowList title={t("level")} children={t("levelText", level)}/>
          {!url && (
            <div className="text-sm mt-4 w-100 text-center mb--4">
              {t("accessTimeInfo")}
            </div>
          )}
        </Container>
      )}
    </Modal.Simple>
  );
}

const RowList = ({ title, children }) => {
  return (
    <Row className="align-items-center my-2">
      <div style={{ height: 7, width: 7 }} className="rounded-circle bg-default"/>
      <h4 className="mx-2 p-0 my-0">
        {title}:
      </h4>
      <div className="text-sm">
        {children}
      </div>
    </Row>
  )
}

export default ShowMeetingModal;