import React, { useState, useEffect, useMemo } from 'react';
import { notify } from "@general-backoffice/core/index";
import { v4 as uuid } from "uuid"
import useTranslator from "../../hooks/useTranslator";


const getOptionsVideoCall = (options = {}) => ({
  roomName: null,
  parentNode: document.getElementById("containerId"),
  userInfo: {
    displayName: 'Trainer'
  },
  configOverwrite: {
    enableWelcomePage: false,
    prejoinPageEnabled: false,
    enableInsecureRoomNameWarning: false
  },
  interfaceConfigOverwrite: {
    APP_NAME: 'NowallTV',
    BRAND_WATERMARK_LINK: '',
    DEFAULT_LOGO_URL: '',
    DEFAULT_WELCOME_PAGE_LOGO_URL: '',
    SHOW_CHROME_EXTENSION_BANNER: false,
    MOBILE_APP_PROMO: false,
    SHOW_JITSI_WATERMARK: true,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_POWERED_BY: false,
    VIDEO_QUALITY_LABEL_DISABLED: true,
    TOOLBAR_BUTTONS: [
      'desktop',
      'microphone',
      'camera',
      'fullscreen',
      'fodeviceselection',
      'videoquality',
    ],
  },
  ...options
})

function VideoCall({ idVideoCall, userName, isLoading, onError, onLeft }) {
  const t = useTranslator("components.shared.videoCall")

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);

  // eslint-disable-next-line
  const idContainer = useMemo(() => uuid(), [idVideoCall])

  const startVideoCall = async () => {
    return new Promise((resolve, reject) => {
      try {
        const domain = 'meet.moonkey.one';
        const options = getOptionsVideoCall({
          parentNode: document.getElementById(idContainer),
          roomName: idVideoCall,
          userInfo: { displayName: userName }
        });
        const api = new window.JitsiMeetExternalAPI(domain, options);
        api.addEventListener('videoConferenceJoined', () => resolve());
        api.addEventListener('videoConferenceLeft', () => onLeft());
        api.addEventListener('errorOccurred', () => onError());
      } catch (error) {
        reject(t("accessVideoCallError"))
      }
    })
  }

  useEffect(() => {
    if(idVideoCall){
      setIsLoadingLocal(true)
      const meetingLoading = notify.loading(t("accessVideoCallLoading"))
      startVideoCall()
        .catch((e) => notify.error(e || t("accessVideoCallError")))
        .finally(() => {
          notify.dismiss(meetingLoading)
          setIsLoadingLocal(false)
        })
    }
    // eslint-disable-next-line
  }, [idVideoCall]);

  const someIsLoading = (isLoadingLocal || isLoading)
  return <div id={idContainer} style={{ display: (someIsLoading ? 'none' : 'block'), width: '100%', height: '100%' }}/>;
}

export default VideoCall;
