const getDefaultCollection = () => ({
  categoryIdList: [],
  description: "",
  image: { url: null, data: null, contentType: null },
  productIdList: [],
  subtitle: "",
  thumbnail: { url: null, data: null, contentType: null },
  title: "",
  type: "product"
})

export default getDefaultCollection