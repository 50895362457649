import React from "react";
import { Header, StatsCard } from "@general-backoffice/core";
import useProducts from "../../../../components/product/hooks/useProducts";
import { Col, Container } from "reactstrap";
import ShowProductsCard from "../../../../components/product/ShowProductsCard";
import UploadProductModal from "../../../../components/product/UploadProductModal";
import useCategories from "../../../../components/category/hooks/useCategories";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import usePlans from "../../../../components/plans/hooks/usePlans";
import UploadPlanModal from "../../../../components/plans/UploadPlanModal";
import useProduct from "../../../../components/product/hooks/useProduct";
import usePlan from "../../../../components/plans/hooks/usePlan";
import useCategory from "../../../../components/category/hooks/useCategory";
import useTranslator from "../../../../hooks/useTranslator";
import useTags from "../../../../components/tags/hooks/useTags";
import useTag from "../../../../components/tags/hooks/useTag";
import UploadTagModal from "../../../../components/tags/UploadTagModal";
import useCollections from "../../../../components/collection/hooks/useCollections";
import UploadCollectionModal from "../../../../components/collection/UploadCollectionModal";
import useCollection from "../../../../components/collection/hooks/useCollection";
import QrModal from "../../../../components/shared/QrModal";
import getProductFrontUrl from "../../../../components/product/shared/getProductFrontUrl";
import { useSelector } from "react-redux";
import UploadSPDProductModal from "../../../../components/productSPD/UploadSPDProductModal";
import useCollectionsByCategory from "../../../../components/collection/hooks/useCollectionsByCategory";


const Products = () => {
  const { club } = useSelector((state) => state.sessionState)
  const { clubProperties } = (club || {});
  const t = useTranslator("views.pages.dashboard.onDemand")

  const isGPT = club.companyCode === "GPT"

  const {
    products,
    changePageNumber: changePageProducts,
    changePageSize: changeAmountProducts,
    changeSearch: changeSearchProducts,
    changeSort: changeSortProducts,
    totalAmount: totalAmountProducts,
    paramsRequest: paramsRequestProducts,
    refresh: refreshProducts,
    isLoading: isLoadingProducts,
  } = useProducts({
    pageNumber: 1,
    pageSize: 10,
    sortBy: "id",
    sort: "DESC"
  });

  const {
    product: productToUpload,
    setProduct: setProductToUpload,
    isLoading: isLoadingProductToUpload,
    save: saveProductToUpload
  } = useProduct(undefined, isGPT)

  const {
    product: productToSeeQr,
    setProduct: setProductToSeeQr,
    isLoading: isLoadingProductToSeeQr,
  } = useProduct(undefined, isGPT)

  const {
    plans,
    isLoading: isLoadingPlans,
    refresh: refreshPlans
  } = usePlans();

  const {
    plan: planToUpload,
    setPlan: setPlanToUpload,
    isLoading: isLoadingPlanToUpload,
    save: savePlanToUpload
  } = usePlan();

  const {
    categories: allCategories,
    isLoading: isLoadingCategories,
    refresh: refreshCategories,
  } = useCategories();

  const categories = allCategories.filter(({ type }) => type === "PRODUCT")

  const {
    category: categoryToUpload,
    setCategory: setCategoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    save: saveCategoryToUpload
  } = useCategory()

  const {
    tags,
    isLoading: isLoadingTags,
    refresh: refreshTags
  } = useTags()

  const {
    tag: tagToUpload,
    save: saveTagToUpload,
    setTag: setTagToUpload,
    isLoading: isLoadingTagToUpload
  } = useTag()

  const {
    collections,
    isLoading: isLoadingCollections,
    refresh: refreshCollections
  } = useCollections()

  const {
    collection: collectionToUpload,
    isLoading: isLoadingCollectionToUpload,
    save: saveCollectionToUpload,
    setCollection: setCollectionToUpload
  } = useCollection()

  const {
    collections: collectionsByCategory,
    refresh: refreshCollectionsByCategory,
    isLoading: isLoadingCollectionsByCategory
  } = useCollectionsByCategory()

  const isLoading = isLoadingProducts || isLoadingCollections || isLoadingTags || isLoadingCategories || isLoadingPlans || isLoadingCollectionsByCategory

  return (
    <React.Fragment>

      <QrModal
        isLoading={isLoadingProductToSeeQr}
        allowDownload={true}
        fileName={productToSeeQr?.code}
        url={!!productToSeeQr ? `https://${clubProperties.baseUrl}/${getProductFrontUrl(productToSeeQr)}` : null}
        onClose={() => setProductToSeeQr(null)}
      />

      <UploadPlanModal
        plan={planToUpload}
        setPlan={setPlanToUpload}
        isLoading={isLoadingPlanToUpload}
        onSubmit={async () => {
          return savePlanToUpload()
            .then(() => {
              refreshPlans()
              setPlanToUpload(null)
            })
            .catch(() => null)
        }}
      />

      {isGPT && (
        <UploadSPDProductModal
          product={productToUpload}
          setProduct={setProductToUpload}
          plans={plans}
          setPlanToUpload={setPlanToUpload}
          isLoading={isLoadingProductToUpload || isLoadingCollectionsByCategory || isLoadingPlans}
          collectionsByCategory={collectionsByCategory}
          setCollectionToUpload={setCollectionToUpload}
          onSubmit={async () => {
            return saveProductToUpload()
              .then(() => {
                setProductToUpload(null)
                refreshProducts()
              })
              .catch(() => null)
          }}
        />
      )}

      {!isGPT && (
        <UploadProductModal
          product={productToUpload}
          setProduct={setProductToUpload}
          categories={categories}
          tags={tags}
          plans={plans}
          setCategoryToUpload={setCategoryToUpload}
          setTagToUpload={setTagToUpload}
          setPlanToUpload={setPlanToUpload}
          isLoading={isLoadingProductToUpload}
          collections={collections}
          setCollectionToUpload={setCollectionToUpload}
          onSubmit={async () => {
            return saveProductToUpload()
              .then(() => {
                setProductToUpload(null)
                refreshProducts()
              })
              .catch(() => null)
          }}
        />
      )}

      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <UploadTagModal
        tag={tagToUpload}
        setTag={setTagToUpload}
        onSubmit={async () => {
          return saveTagToUpload()
            .then(() => {
              refreshTags()
              setTagToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTagToUpload}
      />

      <UploadCollectionModal
        collection={collectionToUpload}
        setCollection={setCollectionToUpload}
        isLoading={isLoadingCollectionToUpload}
        onSubmit={async () => {
          return saveCollectionToUpload()
            .then(() => {
              refreshCollections()
              refreshCollectionsByCategory()
              setCollectionToUpload(null)
            })
            .catch(() => null)
        }}
        categories={categories}
        setCategoryToUpload={setCategoryToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>
        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="default"
            isDark={true}
            number={totalAmountProducts}
            suffix={t("stats.numberOfProducts.suffix", totalAmountProducts)}
            title={t("stats.numberOfProducts.title")}
            icon="fas fa-video"
            description={t("stats.numberOfProducts.description")}
          />
        </Col>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <ShowProductsCard
          products={products}
          setProductToUpload={setProductToUpload}
          setProductToSeeQr={setProductToSeeQr}
          onClickProduct={setProductToUpload}
          pageAmount={paramsRequestProducts.pageSize}
          page={paramsRequestProducts.pageNumber}
          search={paramsRequestProducts?.search}
          sort={{ sort: paramsRequestProducts?.sortBy, order: paramsRequestProducts?.sort }}
          changeSearch={changeSearchProducts}
          changeSort={changeSortProducts}
          totalAmount={totalAmountProducts}
          changePage={changePageProducts}
          changeAmount={changeAmountProducts}
          isLoading={isLoading}
        />
      </Container>
    </React.Fragment>
  );
}

export default Products;