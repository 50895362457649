import React, { useState } from "react";
import { Card as ReactstrapCard, CardBody, Progress } from "reactstrap";
import { FormInput, Card } from "@general-backoffice/core";
import { useTranscodeContext } from "../../contexts/TranscodeWrapper";
import useTranslator from "../../hooks/useTranslator";
import { Modal, Table, TableOutput } from "@general-backoffice/core/index";

function fileSizeInMB(file) {
  const fileSizeInBytes = file.size;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // 1 MB = 1024 bytes * 1024 bytes
  return fileSizeInMB.toFixed(2); // Round to 2 decimal places
}

const TransVideoCard = () => {
  const t = useTranslator("components.trans.uploadVideoCard")
  const { isLoading, files, uploadFiles, removeFile, pauseFile, pausedUploads } = useTranscodeContext()
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)

  const columns = [
    {
      name: t("file"),
      property: "name",
      isTh: true
    },
    {
      name: t("size"),
      formattedContent: (file) => {
        return `${fileSizeInMB(file)} MB`
      }
    },
    {
      name: t("status"),
      formattedContent: ({ progress: fileProgress }) => {
        return <TableOutput.Status
          status={t("statusText", !!fileProgress)}
          color={!!fileProgress ? "success" : "warning"}
        />
      }
    },
    {
      name: t("percentage"),
      formattedContent: ({ progress: fileProgress }) => {
        if (!fileProgress) {
          return <div className="resume-trans-input-container">
            <FormInput.SingleDropzone
              maxFiles={1}
              accept={["video/*"]}
              multiple={false}
              onDrop={(file) => uploadFiles([file])}
              description={t("progressPending")}
            />
          </div>
        }
        return <Progress
          className="w-100"
          style={{ height: '20px' }}
          value={fileProgress || 0}
          children={`${fileProgress || "0.00"}%`}
        />
      }
    },
    {
      name: "",
      formattedContent: (file, index) => {
        if (!file?.progress) return null

        const actions = [
          {
            icon: <i className="fas fa-pause"/>,
            onClick: () => pauseFile(index),
            description: t("pauseDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            onClick: () => removeFile(index),
            description: t("removeDescription")
          }
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
    },
  ]


  return (
    <React.Fragment>

      <Modal.Info
        titleInfo={t("infoTitle")}
        closeText={t("infoCloseText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("infoContent")}
      </Modal.Info>

      <ReactstrapCard className="overflow-hidden">
        <Card.Header
          title={t("title")}
          buttons={[
            { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
          ]}
        />
        <CardBody className="pb-0">
          <FormInput.MultiDropzone
            disabled={isLoading}
            accept={"video/*"}
            files={[]}
            onDrop={(acceptedFiles) => uploadFiles(acceptedFiles)}
          />
        </CardBody>
        <Table
          columns={columns}
          rows={[...files, ...pausedUploads]}
        />
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default TransVideoCard;