import React from "react";
import { Header } from "@general-backoffice/core";
import { Container } from "reactstrap";
import useCategories from "../../../../components/category/hooks/useCategories";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import useCategory from "../../../../components/category/hooks/useCategory";
import useCollections from "../../../../components/collection/hooks/useCollections";
import ShowCollectionsCard from "../../../../components/collection/ShowCollectionsCard";
import UploadCollectionModal from "../../../../components/collection/UploadCollectionModal";
import useCollection from "../../../../components/collection/hooks/useCollection";
import RemoveCollectionModal from "../../../../components/collection/RemoveCollectionModal";
import { useNavigate } from "react-router";


const Collections = () => {
  const navigate = useNavigate()

  const {
    categories,
    isLoading: isLoadingCategories,
    refresh: refreshCategories,
  } = useCategories();

  const {
    category: categoryToUpload,
    setCategory: setCategoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    save: saveCategoryToUpload
  } = useCategory()

  const {
    collections,
    isLoading: isLoadingCollections,
    refresh: refreshCollections
  } = useCollections()

  const {
    collection: collectionToUpload,
    isLoading: isLoadingCollectionToUpload,
    save: saveCollectionToUpload,
    setCollection: setCollectionToUpload
  } = useCollection()

  const {
    collection: collectionToRemove,
    isLoading: isLoadingCollectionToRemove,
    remove: removeCollectionToRemove,
    setCollection: setCollectionToRemove
  } = useCollection()

  const isLoading = isLoadingCollections || isLoadingCategories

  return (
    <React.Fragment>
      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <UploadCollectionModal
        collection={collectionToUpload}
        setCollection={setCollectionToUpload}
        isLoading={isLoadingCollectionToUpload}
        onSubmit={async () => {
          return saveCollectionToUpload()
            .then(() => {
              refreshCollections()
              setCollectionToUpload(null)
            })
            .catch(() => null)
        }}
        categories={categories}
        setCategoryToUpload={setCategoryToUpload}
      />

      <RemoveCollectionModal
        collection={collectionToRemove}
        close={() => setCollectionToRemove(null)}
        isLoading={isLoadingCollectionToRemove}
        remove={async () => {
          return removeCollectionToRemove()
            .then(() => {
              refreshCollections()
              setCollectionToRemove(null)
            })
            .catch(() => null)
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <ShowCollectionsCard
          collections={collections}
          isLoading={isLoading}
          setCollectionToUpload={setCollectionToUpload}
          onClickCollection={setCollectionToUpload}
          setCollectionToRemove={setCollectionToRemove}
          onClickCollectionToShowProducts={({ id }) => navigate(`/dashboard/on-demand/collections/${id}/products`)}
        />
      </Container>
    </React.Fragment>
  );
}

export default Collections;