import React from "react";
import { Table, TableCard, utils, TableOutput } from "@general-backoffice/core";
import { Button, Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";
import configuration from "../../config/configuration";
import { notify } from "@general-backoffice/core/index";

const { apiUltrikUrl } = configuration

const ShowChannelsCard = ({
                            channels = [],
                            onClickChannel,
                            setChannelToPlay,
                            setChannelToUpload = utils.noop,
                            setChannelToRemove = utils.noop,
                            isLoading
                          }) => {
  const t = useTranslator("components.channels.showChannelsCard")
  const columns = [
    {
      name: t("name"),
      formattedContent: ({ name, url }) => {
        return <TableOutput.TitleSubtitle title={name} subtitle={!url ? `( ${t("nameNew")} )` : ""}/>
      },
      isTh: true
    },
    {
      name: "URL",
      formattedContent: ({ name, url: channelUrl }) => {
        if (!!channelUrl) {
          const url = name && `${apiUltrikUrl}/live/${channelUrl}`
          return (
            <Button size="sm" children={t("copyUrlButtonText")} onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigator.clipboard.writeText(url)
                .then(() => notify.success(t("copiedUrl")))
            }}/>
          )
        }
        return <TableOutput.TitleSubtitle title={t("saveToContinue")}/>
      }
    },
    {
      name: "",
      formattedContent: (channel) => {

        const previewChannelOption = {
          icon: <i className="fas fa-play"/>,
          onClick: () => setChannelToPlay({ ...channel }),
          description: t("playDescription")
        }

        const actions = [
          {
            icon: <i className="fas fa-cog"/>,
            onClick: () => setChannelToUpload({ ...channel }),
            description: t("modifyDescription")
          },
          ...(!!channel.url ? [previewChannelOption] : []),
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setChannelToRemove({ ...channel })
          },
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle")}
        buttons={[
          { children: <AddButtonChildren children={t("buttonAddChannel")}/>, onClick: () => setChannelToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={channels}
        onRowClick={(channel) => onClickChannel({ ...channel })}
        isLoading={isLoading}
        noContentMessage={t("emptyChannels")}
      />
    </Card>
  );
}

export default ShowChannelsCard;