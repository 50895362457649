import React from "react";
import ReactPlayer from "react-player";

const IOS = typeof navigator !== 'undefined' && (/iPad|iPhone|iPod/.test(navigator.userAgent) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent));


const VideoPlayer = ({ url, ...props }) => {
  if (!url) return null;

  return (
    <ReactPlayer
      className="m-auto"
      url={url}
      playing={true}
      controls
      width="100%"
      height="100%"
      config={{
        file: {
          forceHLS: !IOS,
          hlsOptions: { levelLoadingMaxRetry: Infinity, levelLoadingMaxRetryTimeout: 5000 }
        }
      }}
      stopOnUnmount={true}
      {...props}
    />
  );
}

export default VideoPlayer;