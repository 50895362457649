import Api from "../Api";

const getNotification = (id) => Api.get(`/admin/notifications/${id}`);
const getNotificationsList = (params) => Api.get(`/admin/notifications/list`, { params });
const deleteNotification = (id) => Api.delete(`/admin/notifications/${id}`);
const putNotification = (id, body) => Api.put(`/admin/notifications`, body);
const postNotification = (body) => Api.post(`/admin/notifications`, body);
const sendNotification = (id, body) => Api.post(`/admin/notifications/${id}`, body);
const sendNotificationToEmail = (id, email) => Api.get(`/admin/notifications/launchNotification/${id}/${email}`);

const notificationRequests = {
  get: getNotification,
  send: sendNotification,
  getList: getNotificationsList,
  delete: deleteNotification,
  put: putNotification,
  post: postNotification,
  sendNotificationToEmail: sendNotificationToEmail
}

export default notificationRequests