const getDefaultClub = () => ({
  name: "",
  active: true,
  contactEmail: "",
  contactPhone: "",
  clubProperties: {
    principalClub: false,
    showShareContent: false,
    transcodificationMaxTime: null,
    language: "en",
    baseUrl: "",
    noReplyMailAddress: ""
  },
  planList: []
})

export default getDefaultClub;