import React, { useMemo, useState } from "react";
import { Table, TableCard, notify, TableOutput } from "@general-backoffice/core";
import { Card } from "reactstrap";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";

const ShowTransCard = ({ trans, setTransToRemove, setTransToPreview, isLoading, refresh }) => {
  const t = useTranslator("components.trans.showTransCard")

  const [search, setSearch] = useState("")
  const [amount, setAmount] = useState(20)
  const [page, setPage] = useState(1)
  const [sortedProperty, setSortedProperty] = useState(null)

  const sortedTrans = useMemo(() => {
    if (sortedProperty?.property === "filename") {
      if (sortedProperty.status === "up") return [...trans].sort((a, b) => (a.filename > b.filename) ? 1 : -1)
      if (sortedProperty.status === "down") return [...trans].sort((a, b) => (a.filename > b.filename) ? -1 : 1)
    }
    if (sortedProperty?.property === "created_at") {
      if (sortedProperty.status === "up") return [...trans].sort((a, b) => (moment(a.created_at).isBefore(b.created_at)) ? 1 : -1)
      if (sortedProperty.status === "down") return [...trans].sort((a, b) => (moment(a.created_at).isBefore(b.created_at)) ? -1 : 1)
    }
    return trans
  }, [trans, sortedProperty])

  const copyUrlTrans = async ({ url }) => {
    await navigator.clipboard.writeText(url)
    notify.success(t("urlCopySucceed"))
  }


  const columns = [
    {
      name: t("file"),
      property: "filename",
      sort: (newStatus) => setSortedProperty({ property: "filename", status: newStatus }),
      sortStatus: sortedProperty?.property === "filename" && sortedProperty?.status,
      isTh: true
    },
    {
      name: t("uploadDate"),
      sort: (newStatus) => setSortedProperty({ property: "created_at", status: newStatus }),
      sortStatus: sortedProperty?.property === "created_at" && sortedProperty?.status,
      formattedContent: ({ created_at }) => {
        return moment(created_at).format("DD/MM/YYYY HH:mm")
      }
    },
    {
      name: t("status"),
      formattedContent: ({ url }) => {
        const isProgressing = !url
        return <TableOutput.Status
          status={t("statusText", isProgressing)}
          color={isProgressing ? "warning" : "success"}
        />
      }
    },
    {
      name: t("url"),
      formattedContent: ({ url }) => {
        if (!url) return t("urlNotAvailable")
        return <div children={url} style={{ width: 200, textOverflow: "ellipsis", overflow: "hidden" }}/>
      }
    },
    {
      name: "",
      formattedContent: (trans) => {
        const actions = [
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            onClick: () => setTransToRemove({ ...trans }),
            description: t("removeDescription")
          }
        ]
        if (trans.url) {
          actions.unshift({
            icon: <i className="fas fa-copy"/>,
            onClick: () => copyUrlTrans({ ...trans }),
            description: t("copyDescription")
          }, {
            icon: <i className="fas fa-play"/>,
            onClick: () => setTransToPreview({ ...trans }),
            description: t("previewDescription")
          })
        }

        return <TableOutput.CustomActions actions={actions}/>
      },
    },
  ]

  const entriesToShow = sortedTrans.filter(({ filename }) => filename.includes(search))
  const paginatedEntries = entriesToShow.slice(((page - 1) * amount), ((page - 1) * amount) + amount)

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle")}
        isLoading={isLoading}
        buttons={[
          {
            children: <i className="fas fa-sync-alt"/>,
            title: t("buttonRefreshDescription"),
            onClick: refresh,
          }
        ]}
        search={{
          value: search,
          onChange: (e) => setSearch(e.target.value),
          msToChange: 1000,
          placeholder: t("searchPlaceholder")
        }}
      />
      <Table
        columns={columns}
        rows={paginatedEntries}
        onRowClick={copyUrlTrans}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={amount}
      />
      <TableCard.Footer
        amount={amount}
        totalEntries={entriesToShow.length}
        page={(page - 1)}
        setAmount={setAmount}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowTransCard;