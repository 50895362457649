import React, { useEffect, useState } from "react";
import { utils, Modal, FormInput, FormDecoration } from "@general-backoffice/core";
import moment from "moment";
import { planRequests } from "../../api/requests";
import { Button } from "reactstrap";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";

const UploadUsersFiltersModal = ({
                                   isOpen = false,
                                   close = utils.noop,
                                   filters = {},
                                   setFilters = utils.noop,
                                 }) => {
  const t = useTranslator("components.users.uploadUsersFiltersModal")

  const [isLoading, setLoading] = useState(true)
  const [actualFilters, setActualFilters] = useState({})
  const [subscriptions, setSubscriptions] = useState([])

  const { changeState, onChangeInput: onChange } = useChangingComplexState(setActualFilters, isLoading)

  const verificationStatusOptions = [
    { label: t("verificationStateAll"), value: undefined },
    { label: t("verificationStateChecked"), value: true },
    { label: t("verificationStateUnchecked"), value: false }
  ]

  const search = actualFilters["search"] || ""
  const entryDateStart = actualFilters["entry-date-start"]
  const entryDateEnd = actualFilters["entry-date-end"]
  // const notificationStatus = actualFilters["notification-status"]
  const subscriptionEndDateStart = actualFilters["subscription-end-date-start"]
  const subscriptionEndDateEnd = actualFilters["subscription-end-date-end"]
  const subscriptionPlan = actualFilters["subscription-plan"]
  const verificationStatus = actualFilters["verification-status"]

  const subscriptionsOptions = subscriptions.filter(({ id }) => id > 0).map(({ id, name, ...plan }) => ({
    value: id,
    label: name,
    ...plan
  }))
  subscriptionsOptions.unshift({ value: undefined, label: t("subscriptionAny") })

  const refreshStates = async () => {
    const { planList = [] } = await planRequests.getListForFilters();
    setSubscriptions(planList)
    setLoading(false)
  }

  useEffect(() => {
    refreshStates().then(() => null)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isOpen) setActualFilters(filters)
    // eslint-disable-next-line
  }, [isOpen])

  const onSubmit = async (event) => {
    event.preventDefault();
    setFilters(actualFilters);
    close()
  }

  const reset = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setActualFilters({})
  }

  return (
    <Modal.FormContainer
      isOpen={isOpen}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <div className="d-flex justify-content-end mb-2 mt--4">
        <Button color="warning" onClick={reset} size="sm">
          <i className="fas fa-broom"/> {t("reset")}
        </Button>
      </div>

      <FormInput.Input
        type={"text"}
        onChange={onChange}
        value={search}
        name={"search"}
        label={t("searchLabel")}
      />

      <FormDecoration.Line/>

      <FormInput.DatePicker
        key={entryDateStart}
        onChange={(value) => changeState("entry-date-start", moment(value).format("YYYY-MM-DD"))}
        value={entryDateStart ? moment(entryDateStart) : null}
        timeFormat={false}
        name={"entry-date-start"}
        label={t("entryDateStartLabel")}
        description={t("entryDateStartDescription")}
      />

      <FormInput.DatePicker
        key={entryDateEnd}
        onChange={(value) => changeState("entry-date-end", moment(value).format("YYYY-MM-DD"))}
        value={entryDateEnd ? moment(entryDateEnd) : null}
        timeFormat={false}
        name={"entry-date-end"}
        label={t("entryDateEndLabel")}
        description={t("entryDateEndDescription")}
      />

      <FormDecoration.Line/>

      <FormInput.DatePicker
        key={subscriptionEndDateStart}
        onChange={(value) => changeState("subscription-end-date-start", moment(value).format("YYYY-MM-DD"))}
        value={subscriptionEndDateStart ? moment(subscriptionEndDateStart) : null}
        timeFormat={false}
        name={"subscription-end-date-start"}
        label={t("subscriptionEndDateStartLabel")}
        description={t("subscriptionEndDateStartDescription")}
      />

      <FormInput.DatePicker
        key={subscriptionEndDateEnd}
        onChange={(value) => changeState("subscription-end-date-end", moment(value).format("YYYY-MM-DD"))}
        value={subscriptionEndDateEnd ? moment(subscriptionEndDateEnd) : null}
        timeFormat={false}
        name={"subscription-end-date-end"}
        label={t("subscriptionEndDateEndLabel")}
        description={t("subscriptionEndDateEndDescription")}
      />

      <FormDecoration.Line/>

      <FormInput.Select
        label={t("registerStateLabel")}
        name={"verification-status"}
        icon={<i className="fas fa-check mx-2"/>}
        onChange={(option) => changeState("verification-status", option.value)}
        options={verificationStatusOptions}
        value={verificationStatusOptions.find(({ value }) => value === verificationStatus)}
      />

      <FormInput.Select
        label={t("subscriptionPlanLabel")}
        name={"subscription-plan"}
        icon={<i className="fas fa-tag mx-2"/>}
        onChange={(option) => changeState("subscription-plan", option.value)}
        options={subscriptionsOptions}
        value={subscriptionsOptions.find(({ value }) => value === subscriptionPlan)}
      />

    </Modal.FormContainer>
  );
}

export default UploadUsersFiltersModal;