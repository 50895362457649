import Api from "../Api";

const getNavigationItem = (id) => Api.get(`/admin/menu/${id}`);
const getNavigationItemList = (params = {}) => Api.get(`/admin/menu/list`, { params });
const putNavigationItem = (body) => Api.put(`/admin/menu/edit`, body);
const postNavigationItem = (body) => Api.post(`/admin/menu/add`, body);
const deleteNavigationItem = (id) => Api.delete(`/admin/menu/delete/${id}`);
const sortNavigationItems = (body) => Api.put("/admin/menu/orderlist", body);

const navigationRequests = {
  get: getNavigationItem,
  getList: getNavigationItemList,
  put: putNavigationItem,
  post: postNavigationItem,
  delete: deleteNavigationItem,
  sort: sortNavigationItems
}

export default navigationRequests