import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { VectorMap } from "@react-jvectormap/core";
import worldMill from "@react-jvectormap/world/dist/worldMill.json";
import { notify, Card, Loading, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { statLarge, statSmall } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";

const StatsUsersLocation = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsUsersLocation")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [countries, setCountries] = useState([]);
  const [isLoading, setLoading] = useState(true)

  const refresh = () => {
    return statsRequests.getUsersPerCountry()
      .then(({ clubUserCountryList = [] }) =>
        setCountries(clubUserCountryList.reduce(
          (r, { country, users }) => {
            r[country] = users
            return r
          }, {})))
      .catch((e) => notify.error(e?.message || t("getStatsError")))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [clubId])

  const unknowns = countries["UNKNOWN"] || 0
  const hasCountries = Object.keys(countries).length > 0;

  const height = simple ? statSmall : statLarge

  return (
    <ReactstrapCard>
      <Card.Header
        title={t("title")}
        subtitle={t("subtitle", unknowns)}
        isLoading={isLoading}
      />
      <CardBody className="p-0">
        {isLoading && <Loading.BouncingDots className="my-5"/>}
        {(!hasCountries && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
        {(hasCountries && !isLoading) &&
        <div style={{ height: height }}>
          <VectorMap
            focusOn={simple ? "ES" : undefined}
            zoomOnScroll={false}
            map={worldMill}
            zoomMin={simple ? 0.5 : 0}
            containerClassName="vector-map"
            normalizeFunction="polynomial"
            hoverColor={false}
            backgroundColor="transparent"
            regionStyle={{
              initial: {
                fill: "#c8cacc",
                stroke: "none",
                "stroke-width": 0,
                "stroke-opacity": 1,
              },
              hover: {
                fill: "#a2a5a8"
              }
            }}
            onRegionTipShow={(e, el, code) =>
              el.html(`${el.html()}: ${countries[code] || 0}`)}
            series={{
              regions: [
                {
                  values: countries,
                  scale: ["#9d9fa1", "#585a5c"],
                  normalizeFunction: "polynomial",
                },
              ],
            }}
          />
        </div>
        }
      </CardBody>
    </ReactstrapCard>
  );
}

export default StatsUsersLocation;