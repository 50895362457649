import lodash from "lodash";

const useChangingComplexState = (setState, preventChanges) => {
  const changeState = (name, value) => {
    if (preventChanges) return;
    setState((currentState) => {
      if (!currentState) return currentState;
      lodash.set(currentState, name, value)
      return { ...currentState }
    })
  }

  const forceChangeState = (name, value) => {
    setState((currentState) => {
      if (!currentState) return currentState;
      lodash.set(currentState, name, value)
      return { ...currentState }
    })
  }

  const changeMultipleStates = (changes) => {
    if (preventChanges) return;
    setState((currentState) => {
      Object.entries(changes).forEach(([name, value]) =>
        lodash.set(currentState, name, value)
      )
      return { ...currentState }
    })
  }
  const onChangeInput = (event) => {
    const { name, value, checked, type } = event.target
    const realValue = type === "checkbox" ? checked : value;
    changeState(name, realValue)
  }
  return { onChangeInput, changeState, changeMultipleStates, forceChangeState }
}

export default useChangingComplexState;