import { useEffect, useState } from "react";
import { clubRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/reducers/session/actions";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultClub from "../shared/getDefaultClub";
import { defaultLanguage, hardTranslator } from "../../../hooks/useTranslator";

const useClub = (id) => {
  const dispatch = useDispatch()
  const { token, user, club: userClub } = useSelector((state) => state.sessionState)
  const [club, setClub] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clubId = club?.id || club?.clubId || id

  useEffect(() => {
    if (!!clubId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getClub(clubId)
      .then((club) => {
        setClub(club)
        return club
      })
      .catch((e) => {
        setClub(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveClub(club)
      .finally(() => {
        refreshUserClubs()
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!club?.id) return;
    setIsLoading(true)
    return removeClub(club.id)
      .finally(() => {
        refreshUserClubs()
        setIsLoading(false)
      })
  }


  const refreshUserClubs = async () => {
    const { clubList = [] } = await clubRequests.getClubsUser();
    user.clubList = (clubList || [])
    const initialClub = user.clubList.find((iterClub) => iterClub?.clubId.toString() === userClub?.clubId.toString()) || user.clubList[0]
    dispatch(login(token, user, initialClub));
  }

  return {
    club,
    setClub,
    isLoading,
    save,
    remove
  }
}

export const getClub = async (clubId) => {
  const t = hardTranslator("components.clubs.hooks.useClub")
  try {
    const club = await clubRequests.get(clubId)
    if(typeof club?.clubProperties?.language !== "string"){
      club.clubProperties.language = club?.clubProperties?.language?.ietfTag || defaultLanguage
    }
    return club
  } catch (e) {
    const message = e?.message || t("getClubError")
    notify.error(message)
    throw new Error(message)
  }
}

export const removeClub = async (clubId) => {
  const t = hardTranslator("components.clubs.hooks.useClub")
  const notification = notify.loading(t("removeClubLoading"))
  try {
    const companyPlan = await clubRequests.delete(clubId)
    notifyLoadingToSuccess(notification, t("removeClubSucceed"))
    return companyPlan
  } catch (e) {
    const message = e?.message || t("removeClubError")
    notifyLoadingToError(notification, message)
    throw message
  }
}


export const saveClub = async (club) => {
  const t = hardTranslator("components.clubs.hooks.useClub")
  const notification = notify.loading(t("saveClubLoading", club?.id))
  try {
    const clubToUpload = { ...getDefaultClub(), ...club }

    if (club?.id) {
      await clubRequests.put(clubToUpload)
      notifyLoadingToSuccess(notification, t("saveClubSucceed", true))
    } else {
      await clubRequests.post(clubToUpload)
      notifyLoadingToSuccess(notification, t("saveClubSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveClubError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useClub;