import { useEffect, useState } from "react";
import { productRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const useLiveStreams = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [liveStreams, setLiveStreams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getLiveStreams()
      .then(({ videoLiveStreamRSList = [] }) => {
        setLiveStreams(videoLiveStreamRSList)
      })
      .catch((e) => {
        setLiveStreams([])
        throw e
      })
      .finally(() => setIsLoading(false))
  }

  return {
    liveStreams,
    isLoading,
    refresh
  }
}

export const getLiveStreams = async () => {
  const t = hardTranslator("components.products.hooks.useLiveStreams")

  try {
    const { videoLiveStreamRSList = [] } = await productRequests.getLiveStreams()
    return { videoLiveStreamRSList }
  } catch (e) {
    const message = (e?.message || t("getLiveStreamsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useLiveStreams;