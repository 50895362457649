import React, { useMemo } from "react";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import PlayHeadPlugin from "wavesurfer.js/dist/plugin/wavesurfer.playhead.min";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min";
import moment from "moment";

const AudioWaves = ({ onMount, usePlayHead, useTimeLine, useCursor, useScrollbar, isDark = true, ...props }) => {
  const darkColor = getComputedStyle(document.documentElement).getPropertyValue('--dark').trim();
  const lightColor = getComputedStyle(document.documentElement).getPropertyValue('--light').trim();
  const whiteColor = getComputedStyle(document.documentElement).getPropertyValue('--white').trim();
  const dangerColor = getComputedStyle(document.documentElement).getPropertyValue('--danger').trim();

  const plugins = useMemo(() => {
    const arrayOfPlugins = [];

    if (usePlayHead) {
      arrayOfPlugins.push({
        plugin: PlayHeadPlugin,
        options: { returnOnPause: true, moveOnSeek: true, draw: true }
      })
    }
    if (useTimeLine) {
      arrayOfPlugins.push({
        plugin: TimelinePlugin,
        options: {
          container: "#timeline",
          primaryFontColor: (isDark ? darkColor : whiteColor),
          secondaryFontColor: (isDark ? darkColor : whiteColor)
        }
      })
    }
    if (useCursor) {
      arrayOfPlugins.push({
        plugin: CursorPlugin,
        options: {
          color: (isDark ? darkColor : lightColor),
          showTime: true,
          opacity: 0.9,
          formatTimeCallback: (seconds) => moment.utc(seconds * 1000).format('HH:mm:ss:S'),
          customShowTimeStyle: {
            'background-color': (isDark ? darkColor : whiteColor),
            color: (isDark ? '#ffffff' : "#000000"),
            padding: '4px',
            'font-size': '12px'
          }
        }
      })
    }

    return arrayOfPlugins;
    // eslint-disable-next-line
  }, [usePlayHead, useTimeLine, useCursor, isDark])


  return (
    <WaveSurfer plugins={plugins} onMount={onMount}>
      <WaveForm
        waveColor={'#ffff'}
        progressColor={lightColor}
        cursorColor={dangerColor}
        height={75}
        fillParent={true}
        hideScrollbar={!useScrollbar}
        scrollParent={useScrollbar}
        normalize={true}
        partialRender={true}
        backend={"MediaElementWebAudio"}
        {...props}
      />
      <div id="timeline"/>
    </WaveSurfer>
  );
}

export default AudioWaves;