import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import { Collapse } from "reactstrap";
import useTranslatedCurrencies from "../../hooks/translations/useTranslatedCurrencies";


const UploadCouponModal = ({
                             coupon,
                             setCoupon,
                             isLoading,
                             onSubmit
                           }) => {
  const t = useTranslator("components.coupons.uploadCouponModal")
  const { changeState, onChangeInput: onChange, changeMultipleStates } = useChangingComplexState(setCoupon, isLoading)
  const { currencyOptions, getCurrencyOption } = useTranslatedCurrencies()

  const {
    id,
    code,
    name,
    amountOff,
    percentOff,
    currency,
    duration,
    durationInMonths,
    maxRedemptions
  } = { ...(coupon || {}) }

  const durationOptions = [
    { value: "forever", label: t("foreverDuration") },
    { value: "once", label: t("onceDuration") },
    { value: "repeating", label: t("repeatingDuration") }
  ]

  console.log(coupon)

  return (
    <Modal.FormContainer
      isOpen={!!coupon}
      toggleOpen={() => setCoupon(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >

      <FormInput.Input
        type={"text"}
        onChange={onChange}
        value={code}
        name={"code"}
        label={t("codeLabel")}
        required={true}
        disabled={!!id}
      />
      <FormInput.Input
        type={"text"}
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
      />
      <FormInput.Switch
        onChange={({ target: { checked } }) => {
          changeMultipleStates({
            amountOff: checked ? 0 : 5,
            percentOff: checked ? 50 : 0
          })
        }}
        checked={percentOff}
        label={t("isPercentageLabel")}
        labels={t("isPercentageAnswer")}
        disabled={!!id}
      />

      <Collapse isOpen={!percentOff}>

        <FormInput.Select
          label={t("currencyLabel")}
          name={"currency"}
          icon={<i className="fas fa-money-bill-wave mx-2"/>}
          onChange={(option) => changeState("currency", option.value)}
          options={currencyOptions}
          value={getCurrencyOption(currency)}
          required={!percentOff}
          isDisabled={!!id}
        />

        <FormInput.Price
          currency={currency === "EUR" ? "euro" : "dollar"}
          onChange={onChange}
          value={amountOff}
          name={"amountOff"}
          label={t("amountOffLabel")}
          required={!percentOff}
          min={0}
          step={1}
          disabled={!!id}
        />
      </Collapse>

      <Collapse isOpen={!!percentOff}>
        <FormInput.Input
          type={"number"}
          onChange={onChange}
          value={percentOff}
          name={"percentOff"}
          label={t("percentOffLabel")}
          required={!!percentOff}
          min={0}
          step={1}
          disabled={!!id}
        />
      </Collapse>

      <FormInput.Select
        label={t("durationLabel")}
        name={"duration"}
        icon={<i className="fas fa-times mx-2"/>}
        onChange={(option) => changeState("duration", option.value)}
        options={durationOptions}
        value={durationOptions.find(({ value }) => value === duration)}
        required={true}
        isDisabled={!!id}
      />

      <Collapse isOpen={duration === "repeating"}>
        <FormInput.Input
          type={"number"}
          onChange={onChange}
          value={durationInMonths}
          name={"durationInMonths"}
          label={t("durationInMonthsLabel")}
          required={duration === "repeating"}
          min={0}
          disabled={!!id}
        />
      </Collapse>

      <FormInput.Input
        type={"number"}
        onChange={onChange}
        value={maxRedemptions}
        name={"maxRedemptions"}
        label={t("maxRedemptionsLabel")}
        required={true}
        min={1}
        step={1}
        disabled={!!id}
      />

    </Modal.FormContainer>
  );
}

export default UploadCouponModal;