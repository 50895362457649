import React, { useState, useEffect } from "react";
import { Modal, notify, FormInput } from "@general-backoffice/core";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";

const initialStartDate = moment().year(2018).month(0).day(0)
const initialEndDate = moment()
const initialInterval = "YEAR"
const formatTime = "YYYY-MM-DD";

const format = (date) => moment(date).format(formatTime)
const formatHuman = (date) => moment(date).format("DD/MM/YYYY")


const UploadStatsPeriodModal = ({ isOpen, setOpen, changeDates, dates, interval, changeInterval }) => {
  const t = useTranslator("components.shared.uploadStatsPeriodModal")

  const periodOptions = [
    { label: t("lastWeek"), value: { startDate: moment().subtract(1, "week"), endDate: initialEndDate } },
    { label: t("last10Days"), value: { startDate: moment().subtract(10, "days"), endDate: initialEndDate } },
    { label: t("last2Weeks"), value: { startDate: moment().subtract(2, "weeks"), endDate: initialEndDate } },
    { label: t("last3Weeks"), value: { startDate: moment().subtract(3, "weeks"), endDate: initialEndDate } },
    { label: t("last4Weeks"), value: { startDate: moment().subtract(4, "weeks"), endDate: initialEndDate } },
    { label: t("lastMonth"), value: { startDate: moment().subtract(1, "month"), endDate: initialEndDate } },
    { label: t("lastYear"), value: { startDate: moment().subtract(1, "year"), endDate: initialEndDate } },
    { label: t("initOfTimes"), value: { startDate: initialStartDate, endDate: moment() } }
  ]

  const intervalOptions = [
    { label: t("DAY"), value: "DAY" },
    { label: t("WEEK"), value: "WEEK" },
    { label: t("MONTH"), value: "MONTH" },
    { label: t("YEAR"), value: "YEAR" }
  ]

  const getShortCutOptionByDates = (dates) => {
    const option = periodOptions.find(({ value: { startDate, endDate } }) => {
      return startDate.isSame(dates.startDate, 'day') && endDate.isSame(dates.endDate, 'day');
    })
    return option || { label: t("customPeriod"), value: null }
  }

  const [{ startDate, endDate }, setPeriod] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [localInterval, setInterval] = useState(initialInterval)

  useEffect(() => {
    setPeriod(dates)
    setInterval(interval)
    // eslint-disable-next-line
  }, [isOpen])

  const onSubmit = async (event) => {
    event.preventDefault();
    changeDates({ startDate, endDate })
    if (!!changeInterval && !!interval) changeInterval(localInterval)
    notify.success(t("periodAppliedSucceed"))
    setOpen(false)
  }

  return (
    <Modal.FormContainer
      isOpen={!!isOpen}
      toggleOpen={() => setOpen(false)}
      title={t("title")}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
    >
      {!!interval && (
        <FormInput.Select
          label={t("intervalLabel")}
          onChange={({ value }) => setInterval(value)}
          options={intervalOptions}
          value={intervalOptions.find(({ value }) => value === localInterval)}
          isDisabled={!changeInterval}
        />
      )}
      <FormInput.Select
        label={t("periodLabel")}
        onChange={({ value }) => setPeriod(value)}
        options={periodOptions}
        value={getShortCutOptionByDates({ startDate, endDate })}
      />
      <FormInput.DatePicker
        label={t("initLabel")}
        value={startDate}
        onChange={(date) => setPeriod((actual) => ({ ...actual, startDate: date }))}
        dateFormat="DD-MM-YYYY"
        timeFormat={false}
        locale="es"
      />
      <FormInput.DatePicker
        label={t("endLabel")}
        value={endDate}
        onChange={(date) => setPeriod((actual) => ({ ...actual, endDate: date }))}
        dateFormat="DD-MM-YYYY"
        timeFormat={false}
        locale="es"
      />

    </Modal.FormContainer>
  );
}

export default UploadStatsPeriodModal;
export { format, formatHuman, initialStartDate, initialEndDate, initialInterval }