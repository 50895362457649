import { useState } from "react";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { transRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";


const useTrans = () => {
  const [trans, setTrans] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const remove = () => {
    setIsLoading(true)
    return removeTrans(trans)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    trans,
    setTrans,
    isLoading,
    remove
  }
}

export const removeTrans = async (trans) => {
  const t = hardTranslator("components.trans.hooks.useOneTrans")

  const notification = notify.loading(t("removeTransLoading"))
  try {
    await transRequests.remove(trans?.id)
    notifyLoadingToSuccess(notification, t("removeTransSucceed"))
  } catch (e) {
    const message = e?.message || t("removeTransError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useTrans;