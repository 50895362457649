import { useEffect, useState } from "react";
import { productRequests, productSPDRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import getDefaultProduct from "../shared/getDefaultProduct";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import getSPDDefaultProduct from "../../productSPD/shared/getSPDDefaultProduct";

const useProduct = (id = undefined, isGPT = false) => {

  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const productId = product?.id || id

  useEffect(() => {
    if (!!productId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [productId])

  const refresh = async () => {
    setIsLoading(true)
    return getProduct(productId, isGPT)
      .then((product) => {
        setProduct(product)
        return product
      })
      .catch((e) => {
        setProduct(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveProduct(product, isGPT)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    product,
    setProduct,
    refresh,
    save,
    isLoading
  }
}

export const getProduct = async (productId, isGPT) => {
  const t = hardTranslator("components.products.hooks.useProduct")

  try {
    const product = await (isGPT ? productSPDRequests : productRequests).get(productId)
    const { categoryList, tagList, collectionList, trainerRS, ...cleanProduct } = product
    const categoryIdList = Array.isArray(categoryList) ? categoryList.map(({ id }) => id) : undefined;
    const tagIdList = Array.isArray(tagList) ? tagList.map(({ id }) => id) : undefined;
    const collectionIdList = Array.isArray(collectionList) ? collectionList.map(({ id }) => id) : undefined;
    const trainerIdList = Array.isArray(trainerRS) ? trainerRS.map(({ id }) => id) : undefined;
    return {
      ...cleanProduct,
      ...(categoryIdList ? { categoryIdList } : {}),
      ...(tagIdList ? { tagList: tagIdList } : {}),
      ...(collectionIdList ? { collectionIdList } : {}),
      ...(trainerIdList ? { trainerIdList } : {}),
    };
  } catch (e) {
    const message = e?.message || t("getProductError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveProduct = async (product, isGPT) => {
  const t = hardTranslator("components.products.hooks.useProduct")
  const notification = notify.loading(t("saveProductLoading", product?.id))
  try {
    const cleanProduct = { ...(isGPT ? getSPDDefaultProduct() : getDefaultProduct()), ...product }
    if (!!product?.id) {
      await (isGPT ? productSPDRequests : productRequests).put(product.id, cleanProduct)
      notifyLoadingToSuccess(notification, t("saveProductSucceed", true))
    } else {
      await (isGPT ? productSPDRequests : productRequests).post(cleanProduct)
      notifyLoadingToSuccess(notification, t("saveProductSucceed", true))
    }
  } catch (e) {
    const message = e?.message || t("saveProductError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useProduct;