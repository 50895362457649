import { useEffect, useState } from "react";
import { meetingRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultMeetingTemplate from "../shared/getDefaultMeetingTemplate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useMeetingTemplate = (id) => {
  const [meetingTemplate, setMeetingTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const meetingTemplateId = meetingTemplate?.id || id

  useEffect(() => {
    if (!!meetingTemplateId) refresh()
    // eslint-disable-next-line
  }, [meetingTemplateId])

  const refresh = async () => {
    setIsLoading(true)
    return getMeetingTemplate(meetingTemplateId)
      .then((meetingTemplate) => {
        setMeetingTemplate(meetingTemplate)
        return meetingTemplate
      })
      .finally(() => setIsLoading(false))
  }

  const save = async () => {
    setIsLoading(true)
    return saveMeetingTemplate(meetingTemplate)
      .finally(() => setIsLoading(false))
  }

  return {
    meetingTemplate,
    setMeetingTemplate,
    refresh,
    save,
    isLoading
  }
}

export const getMeetingTemplate = async (id) => {
  const t = hardTranslator("components.meetings.hooks.useMeetingsTemplate")

  try {
    const { category, ...meeting } = await meetingRequests.get(id)
    return { category: category.id, ...meeting }
  } catch (e) {
    const message = e?.message || t("getMeetingTemplateError")
    notify.error(message)
    throw message
  }
}

export const saveMeetingTemplate = async (meetingTemplate) => {
  const t = hardTranslator("components.meetings.hooks.useMeetingsTemplate")

  const notification = notify.loading(t("saveMeetingTemplateLoading", meetingTemplate?.id))
  try {
    if (meetingTemplate?.id) {
      await meetingRequests.put(meetingTemplate?.id, { ...getDefaultMeetingTemplate(), ...meetingTemplate })
      notifyLoadingToSuccess(notification, t("saveMeetingTemplateSucceed", true))
    } else {
      await meetingRequests.postTemplate({ ...getDefaultMeetingTemplate(), ...meetingTemplate })
      notifyLoadingToSuccess(notification, t("saveMeetingTemplateSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveMeetingTemplateError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useMeetingTemplate;