import React from "react";
import { Header } from "@general-backoffice/core";
import { Container } from "reactstrap";
import usePlans from "../../../../components/plans/hooks/usePlans";
import ShowPlansCard from "../../../../components/plans/ShowPlansCard";
import RemovePlanModal from "../../../../components/plans/RemovePlanModal";
import UploadPlanModal from "../../../../components/plans/UploadPlanModal";
import usePlan from "../../../../components/plans/hooks/usePlan";


const Plans = () => {

  const {
    plans,
    isLoading: isLoadingPlans,
    refresh: refreshPlans
  } = usePlans();

  const {
    plan: planToUpload,
    setPlan: setPlanToUpload,
    isLoading: isLoadingPlanToUpload,
    save: savePlanToUpload
  } = usePlan();

  const {
    plan: planToRemove,
    setPlan: setPlanToRemove,
    isLoading: isLoadingPlanToRemove,
    remove: removePlanToRemove
  } = usePlan();

  return (
    <React.Fragment>
      <RemovePlanModal
        plan={planToRemove}
        isLoading={isLoadingPlanToRemove}
        remove={async () => {
          return removePlanToRemove()
            .then(() => {
              refreshPlans()
              setPlanToRemove(null)
            })
            .catch(() => null)
        }}
        close={() => setPlanToRemove(null)}
      />
      <UploadPlanModal
        plan={planToUpload}
        setPlan={setPlanToUpload}
        isLoading={isLoadingPlanToUpload}
        onSubmit={async () => {
          return savePlanToUpload()
            .then(() => {
              refreshPlans()
              setPlanToUpload(null)
            })
            .catch(() => null)
        }}
      />
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <ShowPlansCard
          plans={plans}
          setPlanToUpload={setPlanToUpload}
          setPlanToRemove={setPlanToRemove}
          onClickPlan={setPlanToUpload}
          isLoading={isLoadingPlans}
        />
      </Container>
    </React.Fragment>
  );
}

export default Plans;