import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  ButtonDropdown,
  ButtonGroup, DropdownItem,
  DropdownMenu, DropdownToggle
} from "reactstrap";
import { notify, StatsCard } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import useTranslator from "../../hooks/useTranslator";


const initTime = moment().format("YYYY-MM-DD");
const actualTime = moment().format("YYYY-MM-DD");

const StatsUsersTotalActive = () => {
  const t = useTranslator("components.stats.statsUsersTotalActive")

  const shortCuts = [
    { title: t("shortCutToday"), startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") },
    {
      title: t("shortCut7Days"),
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    },
    {
      title: t("shortCut14Days"),
      startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    },
    {
      title: t("shortCut21Days"),
      startDate: moment().subtract(21, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    },
    {
      title: t("shortCut30Days"),
      startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    },
  ]

  const initialShortCut = t("shortCutToday")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [{ startDate, endDate, shortCut }, setDates] = useState({
    startDate: initTime,
    endDate: actualTime,
    shortCut: initialShortCut
  })
  const [number, setNumber] = useState(0);
  const [total, setTotal] = useState(0);

  const intervalRef = useRef();

  const refresh = () => {
    statsRequests.getClubActiveUsers(startDate, endDate)
      .then(({ activeUsers, totalUsers }) => {
        setNumber(activeUsers)
        setTotal(totalUsers)
      })
      .catch((e) => notify.error(e?.message || t("getStatsError")))
  }

  useEffect(() => {
    refresh();
    intervalRef.current = setInterval(refresh, 10000)
    return (() => {
      clearInterval(intervalRef.current)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubId, startDate, endDate])

  return (
    <div className="position-relative">
      <StatsCard.Number
        color="warning"
        isDark={true}
        number={number}
        suffix={t("suffix", number)}
        title={t("title", total)}
        icon="fas fa-users"
        description={t("description", shortCut)}/>
      <div className="position-absolute" style={{ top: 26, right: 90 }}>
        <Dropdown {...{ shortCuts, shortCut }} changeDates={setDates}/>
      </div>
    </div>
  )
}

const Dropdown = ({ shortCut, changeDates, shortCuts }) => {
  const [isOpenShorts, setOpenShorts] = useState(false);
  return (
    <ButtonGroup className="shadow-lg">
      <ButtonDropdown isOpen={isOpenShorts} toggle={() => setOpenShorts(!isOpenShorts)}>
        <DropdownToggle caret className="bg-white">
          {shortCut}
        </DropdownToggle>
        <DropdownMenu right positionFixed>
          {shortCuts.map(({ title, startDate, endDate }, index) => {
            return (
              <DropdownItem key={index} onClick={() => changeDates(() => ({
                startDate,
                endDate,
                shortCut: title
              }))}>{title}</DropdownItem>
            )
          })}
        </DropdownMenu>
      </ButtonDropdown>
    </ButtonGroup>
  )
}


export default StatsUsersTotalActive;