import { useEffect, useState } from "react";
import { sliderRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useSlide = (id) => {

  const [slide, setSlide] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const slideId = slide?.id || id

  useEffect(() => {
    if (!!slideId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [slideId])

  const refresh = async () => {
    setIsLoading(true)
    return getSlide(slideId)
      .then((slide) => {
        setSlide(slide)
        return slide
      })
      .catch((e) => {
        setSlide(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveSlide(slide)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!slide?.id) return;
    setIsLoading(true)
    return removeSlide(slide.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    slide,
    setSlide,
    save,
    remove,
    refresh,
    isLoading
  }
}

export const getSlide = async (slideId) => {
  const t = hardTranslator("components.slider.hooks.useSlide")
  try {
    const list = await sliderRequests.getList()
    const coincidence = (list || []).find(({id}) => id === slideId)
    if(!coincidence) throw new Error()
    return coincidence
  } catch (e) {
    const message = e?.message || t("getSlideError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveSlide = async (slide) => {
  const t = hardTranslator("components.slider.hooks.useSlide")
  const notificationLoading = notify.loading(t("saveSlideLoading", !!slide?.id))
  try {
    if (!!slide?.id) {
      await sliderRequests.put(slide)
      notifyLoadingToSuccess(notificationLoading, t("saveSlideSucceed", true))
    } else {
      await sliderRequests.post(slide)
      notifyLoadingToSuccess(notificationLoading, t("saveSlideSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveSlideError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeSlide = async (slideId) => {
  const t = hardTranslator("components.slider.hooks.useSlide")
  const notification = notify.loading(t("removeSlideLoading"))
  try {
    await sliderRequests.delete(slideId)
    notifyLoadingToSuccess(notification, t("removeSlideSucceed"))
  } catch (e) {
    const message = e?.message || t("removeSlideError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useSlide;