import { useEffect, useState } from "react";
import { teamRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";
import usePaginationParamRequest from "../../../hooks/usePaginationParamRequest";

const useTeams = (initialParamsRequest = {}) => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const { paramsRequest, ...ppr } = usePaginationParamRequest(initialParamsRequest)


  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId, paramsRequest])

  const refresh = () => {
    setIsLoading(true)
    return getTeams(paramsRequest)
      .then(({ teamList, count }) => {
        setTeams(teamList)
        setTotalElements(count)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return {
    teams,
    isLoading,
    refresh,
    totalElements,
    paramsRequest,
    ...ppr
  }
}

export const getTeams = async (paramsRequest) => {
  const t = hardTranslator("components.team.hooks.useTeams")
  try {
    const { content = [], totalElements = 0 } = await teamRequests.getList(paramsRequest)
    return { teamList: content, count: totalElements }
  } catch (e) {
    const message = (e?.message || t("getTeamsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useTeams;