import React, { useEffect, useState } from "react";
import { Modal, Loading } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";
import QRCode from "react-qr-code";


const QrModal = ({
                   url,
                   onClose,
                   fileName,
                   allowDownload = true,
                   isLoading
                 }) => {
  const t = useTranslator("components.shared.qrModal")

  const [isLoadingLocally, setLoadingLocally] = useState(true)
  const someIsLoading = isLoadingLocally || isLoading

  useEffect(() => {
    if (!!url) {
      setLoadingLocally(true)
      setTimeout(() => setLoadingLocally(false), 1000)
    }
  }, [url])

  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = fileName || "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Modal.Simple
      isOpen={!!url}
      toggleOpen={onClose}
      isLoading={someIsLoading}
      buttons={allowDownload ? [{
        children: t("downloadQr"),
        onClick: onImageDownload
      }] : []}
    >
      <div className="flex-center">
        {
          (someIsLoading || !url) ?
            <Loading.BouncingDots className="my-5"/> :
            <QRCode id="QRCode" value={url}/>
        }
      </div>
    </Modal.Simple>
  );
}

export default QrModal;