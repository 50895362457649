import isoCountriesLanguages from "@hotosm/iso-countries-languages";
import { defaultLanguage } from "../hooks/useTranslator";
import allCountries from 'country-region-data/data.json';

const getCountries = (lang = defaultLanguage) => {
  let countries = isoCountriesLanguages.getCountries(lang)
  countries.GN = `${countries.GV}`
  countries.GW = `${countries.PU}`
  delete countries.GV
  delete countries.PU


  return Object.entries(countries)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([shortCode, name]) => {
      const country = allCountries.find(({ countryShortCode }) => countryShortCode === shortCode)

      return { ...country, shortCode, name }
    })
}

export default getCountries