import { useEffect, useState } from "react";
import { tagRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const useTags = () => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = () => {
    setIsLoading(true)
    return getTags()
      .then((tagList) => {
        setTags(tagList)
        return tagList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  return {
    tags,
    isLoading,
    refresh,
  }
}

export const getTags = async () => {
  const t = hardTranslator("components.tag.hooks.useTags")
  try {
    const tagList = await tagRequests.getList()
    return tagList || []
  } catch (e) {
    const message = (e?.message || t("getTagsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useTags;