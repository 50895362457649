const roleList = ["OWNER", "MANAGER", "UPLOADER", "TRAINER", "TRAINER_PUBLISHER", "CHANNEL_MANAGER"]
const roles = {
  [roleList[0]]: "OWNER",
  [roleList[1]]: "MANAGER",
  [roleList[2]]: "UPLOADER",
  [roleList[3]]: "TRAINER",
  [roleList[4]]: "TRAINER_PUBLISHER",
  [roleList[5]]: "CHANNEL_MANAGER"
}

export { roles, roleList }