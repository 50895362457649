import React, { useState } from "react";
import { Header } from "@general-backoffice/core";
import { Container } from "reactstrap";
import useCoupons from "../../../../components/coupon/hooks/useCoupons";
import ShowCouponListCard from "../../../../components/coupon/ShowCouponListCard";
import useCoupon from "../../../../components/coupon/hooks/useCoupon";
import UploadCouponModal from "../../../../components/coupon/UploadCouponModal";
import RemoveModal from "../../../../components/shared/RemoveModal";
import { couponRequests } from "../../../../api/requests";


const Coupons = () => {

  const [couponIdToRemove, setCouponIdToRemove] = useState();

  const {
    coupons,
    isLoading: isLoadingCoupons,
    paramsRequest,
    changePageNumber,
    changePageSize,
    totalElements,
    refresh: refreshCoupons
  } = useCoupons()

  const {
    coupon: couponToUpdate,
    isLoading: isLoadingCouponToUpdate,
    save: saveCouponToUpdate,
    setCoupon: setCouponToUpdate
  } = useCoupon()

  return (
    <React.Fragment>

      <UploadCouponModal
        coupon={couponToUpdate}
        isLoading={isLoadingCouponToUpdate}
        setCoupon={setCouponToUpdate}
        onSubmit={async () => {
          try {
            await saveCouponToUpdate()
            await refreshCoupons()
            setCouponToUpdate(null)
          } catch (e) {
            console.error(e)
          }
        }}
      />

      <RemoveModal
        isOpen={couponIdToRemove}
        onClose={() => setCouponIdToRemove(null)}
        onSubmit={async () => {
          try {
            await couponRequests.delete(couponIdToRemove)
            await refreshCoupons()
            setCouponIdToRemove(null)
          } catch (e) {
            console.error(e)
          }
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <ShowCouponListCard
          isLoading={isLoadingCoupons}
          coupons={coupons}
          setCouponToUpload={setCouponToUpdate}
          onClickCoupon={setCouponToUpdate}
          setCouponToRemove={({ id }) => setCouponIdToRemove(id)}
          pagination={{
            ...paramsRequest,
            changePageNumber,
            changePageSize,
            totalElements
          }}
        />
      </Container>
    </React.Fragment>
  );
}

export default Coupons;