const environment = process.env.NODE_ENV || "production";

const sessionVersion = "1.0.5";

const stripeKeys = {
  infinityTest: "pk_test_51HluRJE7xgqXMZUsho74mfa6lJwESvzHfidi4kn9X3pgdF1Jpc4dntBIQflXBgRhOMwdhLWzgUa1tlBTNvDJPHHX00D9PvEO4u",
  infinityProd: "pk_live_51HluRJE7xgqXMZUscNWXNA6ZoErOJXIFarmKWHN6ZVR2r6FtOq1Uqsf6tLMLqaJnJeK5oR34gZl7y9B9Ueql1T1L00Q0PR7SdG",
  myClubTest: "pk_test_51H3HJKF6h5sdqz38diH55AVdewc7KI875QDrFdiyoKnDVQDGECjHbWLqPFsvY6nQ0mJiOylwU2cYvqzpldDNPZAN00Ndu2oftU",
  myClubProd: "pk_live_51H3HJKF6h5sdqz38YDazV7sSEmWZ3fvb6fXp2VlR58SIflGuhHWgsfLLRr75QMc2RC2ZGBgMrRaa8mASFZsRbUyx002p8zUOAo"
}

const getConfiguration = () => {
  switch (environment) {
    case "production":
      return {
        apiUrl: process.env.REACT_APP_API_URL || "https://api.myclub.tv",
        apiUltrikUrl: "https://media1.ultrik.com",
        apiUltrikUrlAds: "https://media1.ultrik.com/ads",
        basename: "admin",
        stripeKey: stripeKeys.infinityProd
      }
    case "development":
      return {
        // apiUrl: "https://1dba-47-63-190-133.ngrok-free.app",
        // apiUrl: "http://localhost:8085",
        apiUrl: "https://api.myclub.tv",
        // apiUrl: "http://192.168.1.39:8085",
        apiUltrikUrl: "https://media1.ultrik.com",
        apiUltrikUrlAds: "https://media1.ultrik.com/ads",
        basename: "",
        stripeKey: stripeKeys.infinityTest
      }
    default:
      return {
        apiUrl: "https://api.myclub.tv",
        apiUltrikUrl: "https://media1.ultrik.com",
        apiUltrikUrlAds: "https://media1.ultrik.com/ads",
        basename: "admin",
        stripeKey: stripeKeys.infinityProd
      }
  }
}

export default getConfiguration();
export { sessionVersion }