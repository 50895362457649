import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveNewModal = ({ thwNew, remove, isLoading, close }) => {
  const t = useTranslator("components.news.removeNewModal")

  const { id, title } = (thwNew || {})


  return (
    <Modal.DeleteConfirm
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", title)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveNewModal;