import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getDefaultNavigationItem from "./shared/getDefaultNavigationItem";
import { Col, Row } from "reactstrap";
import useTranslatedNavigationItemTypes from "../../hooks/translations/useTranslatedNavigationItemTypes";


const UploadNavigationItemModal = ({ navigationItem, setNavigationItem, onSubmit, isLoading }) => {
  const tLanguage = useTranslator()
  const t = useTranslator("components.navigation.uploadNavigationItemModal")
  const { onChangeInput: onChange, changeState} = useChangingComplexState(setNavigationItem, isLoading)
  const {navigationItemTypeOptions, getNavigationItemTypeOption} = useTranslatedNavigationItemTypes()
  const {
    id,
    active,
    translations,
    url,
    openTarget,
    predefined
  } = { ...getDefaultNavigationItem(), ...(navigationItem || {}) };

  const onSubmitForm = () => {
    onSubmit()
  }

  const translatedText = translations[tLanguage("language")]

  return (
    <Modal.FormContainer
      isOpen={!!navigationItem}
      toggleOpen={() => setNavigationItem(null)}
      title={t("title", !!id, translatedText)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >

      <FormInput.Input
        onChange={onChange}
        value={url}
        name={"url"}
        label={t("urlLabel")}
        required={true}
        disabled={predefined}
      />

      <Row>
        <Col>
          <FormInput.Input
            onChange={onChange}
            value={translations.es}
            name={"translations.es"}
            label={t("textLabel") + " (es)"}
            required={tLanguage("language") === "es"}
          />
        </Col>
        <Col>
          <FormInput.Input
            onChange={onChange}
            value={translations.en}
            name={"translations.en"}
            label={t("textLabel") + " (en)"}
            required={tLanguage("language") === "en"}
          />
        </Col>
      </Row>

      <FormInput.Select
        label={t("typeLabel")}
        value={getNavigationItemTypeOption(openTarget)}
        options={navigationItemTypeOptions}
        onChange={({ value }) => changeState("openTarget", value)}
        isDisabled={isLoading}
        isLoading={isLoading}
      />

      <FormInput.Switch
        onChange={onChange}
        checked={active}
        name={"active"}
        label={t("isPublishedLabel")}
        labels={t("isPublishedAnswers")}
      />
    </Modal.FormContainer>
  );
}

export default UploadNavigationItemModal;