import React from "react";
import { utils } from "@general-backoffice/core";
import configuration from "../../config/configuration"
import PreviewVideoModal from "../shared/PreviewVideoModal";
import useTranslator from "../../hooks/useTranslator";

const { apiUltrikUrl } = configuration


const PreviewChannelModal = ({
                               channel,
                               close = utils.noop,
                               isLoading
                             }) => {
  const t = useTranslator("components.channels.previewChannelModal")
  const { url: channelUrl, name } = (channel || {})

  const url = name && `${apiUltrikUrl}/live/${channelUrl}`

  return (
    <PreviewVideoModal
      url={url}
      title={t("title", name)}
      allowCopyUrl={true}
      onClose={close}
      isLoading={isLoading}
    />
  );
}

export default PreviewChannelModal;