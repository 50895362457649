import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { transRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";


const useTrans = ({ clubId, companyCode } = {}) => {
  const {
    clubId: currentClubId,
    companyCode: currentCompanyCode
  } = useSelector(({ sessionState }) => sessionState.club)
  const [trans, setTrans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const finalClubId = clubId || currentClubId
  const finalCompanyCode = companyCode || currentCompanyCode

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [finalClubId])

  const refresh = async () => {
    setIsLoading(true)
    return getTrans({ companyCode: finalCompanyCode, clubId: finalClubId })
      .then((transList) => {
        setTrans(transList)
        return transList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    trans,
    isLoading,
    refresh
  }
}

export const getTrans = async ({ companyCode, clubId }) => {
  const t = hardTranslator("components.trans.hooks.useTrans")
  try {
    return await transRequests.getList({ companyCode, clubId })
  } catch (e) {
    const message = e?.message || t("getTransError")
    notify.error(message)
    throw new Error(message)
  }
}

export default useTrans;