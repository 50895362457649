import { motion } from 'framer-motion/dist/framer-motion'

const flowerSVG = {
  viewBox: "0 0 87.446342 87.446342",
  component: motion.path,
  elements: [
    {
      props: {
        d: "m 49.200044675,32.05504439 15.187083142,-15.372291473 c 5.238749934,-5.185833268 1.984374975,-12.382499844 -0.952499988,-14.975416478 l -16.959791453,17.224374783 c -8.307916562,8.069791565 -1.640416646,21.669374727 -1.640416646,21.669374727 0,0 0.502708327,-4.683124941 4.365624945,-8.546041559"
      }
    },
    {
      props: {
        d: "m 55.894002924,39.569210962 21.616458061,-0.132291665 c 7.381874907,0.026458333 10.159999872,-7.355416574 9.921874875,-11.271249858 l -24.156458029,0.185208331 c -11.588749854,-0.185208331 -16.483541459,14.155208155 -16.483541459,14.155208155 0,0 3.624791621,-2.936874963 9.101666552,-2.936874963"
      }
    },
    {
      props: {
        d: "m 55.338377931,49.332335839 15.372291473,15.187083142 c 5.185833268,5.238749934 12.382499844,1.984374975 14.975416478,-0.952499988 l -17.19791645,-16.959791453 c -8.069791565,-8.307916562 -21.669374727,-1.640416646 -21.669374727,-1.640416646 0,0 4.656666608,0.502708327 8.519583226,4.365624945"
      }
    },
    {
      props: {
        d: "m 48.035878023,55.894002423 0.132291665,21.616458061 c -0.026458333,7.381874907 7.355416574,10.159999872 11.271249858,9.921874875 L 59.254211215,63.27587733 c 0.185208331,-11.588749854 -14.155208155,-16.483541459 -14.155208155,-16.483541459 0,0 2.936874963,3.624791621 2.936874963,9.101666552"
      }
    },
    {
      props: {
        d: "m 38.272753146,55.364835763 -15.187083142,15.372291473 c -5.238749934,5.185833268 -1.984374975,12.382499844 0.952499988,14.975416478 l 16.959791453,-17.224374783 c 8.307916562,-8.069791565 1.640416646,-21.669374727 1.640416646,-21.669374727 0,0 -0.502708327,4.683124941 -4.365624945,8.546041559"
      }
    },
    {
      props: {
        d: "m 31.552336564,48.035877522 -21.616458061,0.132291665 c -7.381874907,-0.026458333 -10.159999872,7.355416574 -9.921874875,11.271249858 l 24.156458029,-0.185208331 c 11.588749854,0.185208331 16.483541459,-14.155208155 16.483541459,-14.155208155 0,0 -3.598333288,2.936874963 -9.101666552,2.936874963"
      }
    },
    {
      props: {
        d: "m 32.13441989,38.087544314 -15.372291473,-15.187083142 c -5.185833268,-5.238749934 -12.382499844,-1.984374975 -14.975416478,0.952499988 l 17.224374783,16.959791453 c 8.069791565,8.307916562 21.669374727,1.640416646 21.669374727,1.640416646 0,0 -4.683124941,-0.502708327 -8.546041559,-4.365624945"
      }
    },
    {
      props: {
        d: "M 39.436919798,31.552336063 39.304628133,9.935878002 C 39.331086466,2.554003095 31.949211559,-0.22412187 28.033378275,0.014003127 l 0.185208331,24.156458029 c -0.185208331,11.588749854 14.155208155,16.483541459 14.155208155,16.483541459 0,0 -2.936874963,-3.624791621 -2.936874963,-9.101666552"
      }
    }
  ]
}

export default flowerSVG;