import React from "react";
import { Table, Modal, notify } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { changeClub } from "../../redux/reducers/session/actions";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedRoles from "../../hooks/translations/useTranslatedRoles";

const ShowUserClubsModal = ({ isOpen, setOpen }) => {
  const t = useTranslator("components.clubs.showUserClubsModal")
  const {translatedRoles} = useTranslatedRoles()
  const dispatch = useDispatch()
  const { user: { clubList = [] }, club } = useSelector((state) => state.sessionState)
  const columns = [
    {
      name: t("name"),
      property: "clubName",
      isTh: true,
    },
    {
      name: t("roles"),
      formattedContent: ({ roleList }) => roleList.map((r) => translatedRoles[r]).join(", ")
    },
    {
      name: "",
      formattedContent: ({ clubId }) => (clubId === club?.clubId) && <i className="fas fa-check"/>,
    },
  ]

  const onClubClick = (clubToChange) => {
    if (club?.clubId !== clubToChange.clubId) {
      dispatch(changeClub(clubToChange))
      setOpen(false)
      notify(t("changeCurrentClubSucceed", clubToChange.clubName))
    }
  };

  return (
    <Modal.Simple
      size={"lg"}
      isOpen={isOpen}
      toggleOpen={() => setOpen(!isOpen)}
      title={t("title")}
      closeText={t("closeText")}
    >
      <div className="overflow-hidden mb-0 mx--4 mb--4">
        <Table columns={columns} rows={clubList} onRowClick={onClubClick}/>
      </div>
    </Modal.Simple>
  );
}

export default ShowUserClubsModal;