import React from "react";
import { Navigate } from "react-router";
import CollectionProducts from "../../../views/pages/dashboard/onDemand/CollectionProducts";
import Products from "../../../views/pages/dashboard/onDemand/Products";
import Categories from "../../../views/pages/dashboard/onDemand/Categories";
import Collections from "../../../views/pages/dashboard/onDemand/Collections";
import Tags from "../../../views/pages/dashboard/onDemand/Tags";
import Slider from "../../../views/pages/dashboard/onDemand/Slider";
import CategoryProducts from "../../../views/pages/dashboard/onDemand/CategoryProducts";
import CategoryCollections from "../../../views/pages/dashboard/onDemand/CategoryCollections";
import permissions from "../../../utils/permissions";
import Teams from "../../../views/pages/dashboard/onDemand/Teams";
import TeamSections from "../../../views/pages/dashboard/onDemand/TeamSections";


const subRoutes = (t) => [
  {
    name: t("slider"),
    miniName: t("slider").charAt(0).toUpperCase(),
    path: "slider",
    showOnNav: true,
    component: <Slider/>,
  },
  {
    name: t("categories"),
    miniName: t("categories").charAt(0).toUpperCase(),
    path: "categories",
    showOnNav: true,
    component: <Categories/>,
  },
  {
    name: t("categories"),
    miniName: t("categories").charAt(0).toUpperCase(),
    path: "categories",
    subRoutes: [
      {
        name: t("categoryProducts"),
        path: ":idCategory/products",
        component: <CategoryProducts/>,
      },
      {
        name: t("categoryCollections"),
        path: ":idCategory/collections",
        component: <CategoryCollections/>,
      },
    ]
  },
  {
    name: t("collections"),
    miniName: t("collections").charAt(0).toUpperCase(),
    path: "collections",
    showOnNav: true,
    component: <Collections/>,
  },
  {
    name: t("collections"),
    miniName: t("collections").charAt(0).toUpperCase(),
    path: "collections",
    subRoutes: [
      {
        name: t("collectionProducts"),
        path: ":idCollection/products",
        component: <CollectionProducts/>,
      },
    ]
  },
  {
    name: t("teams"),
    miniName: t("teams").charAt(0).toUpperCase(),
    permissions: [permissions.teams],
    path: "teams",
    showOnNav: true,
    component: <Teams/>,
  },
  {
    name: t("teams"),
    miniName: t("teams").charAt(0).toUpperCase(),
    permissions: [permissions.teams],
    path: "teams",
    subRoutes: [
      {
        name: t("teamSections"),
        path: ":idTeam/sections",
        component: <TeamSections/>,
      },
    ]
  },
  {
    name: t("products"),
    miniName: t("products").charAt(0).toUpperCase(),
    path: "",
    showOnNav: true,
    component: <Products/>,
  },
  {
    name: t("tags"),
    miniName: t("tags").charAt(0).toUpperCase(),
    path: "tags",
    showOnNav: true,
    component: <Tags/>,
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard/on-demand"} replace={true}/>,
  }
]

export { subRoutes };