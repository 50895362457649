import React from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { FormInput, Card } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import getDefaultClubConfig from "./shared/getDefaultClubConfig";
import useTranslator from "../../hooks/useTranslator";

const ChangeClubColorsCard = ({ clubConfig, setClubConfig }) => {
  const t = useTranslator("components.clubConfig.changeClubColorsCard")
  const { changeState } = useChangingComplexState(setClubConfig)
  const { primaryColor, clubTheme } = { ...getDefaultClubConfig(), ...(clubConfig || {}) }

  return (
    <React.Fragment>
      <ReactstrapCard>
        <Card.Header title={t("title")} subtitle={t("subtitle")}/>
        <CardBody>
          <FormInput.Switch
            onChange={({ target: { checked } }) => changeState("clubTheme", checked ? "dark" : "light")}
            checked={clubTheme === "dark"}
            name={"clubTheme"}
            label={t("useDarkModeLabel")}
            labels={t("useDarkModeAnswer")}
          />
          <FormInput.ColorChrome
            label={t("primaryColorLabel")}
            tooltip={t("primaryColorTooltip")}
            color={primaryColor}
            onChangeComplete={(finalColor) => changeState("primaryColor", finalColor.hex)}
            disableAlpha={true}
          />
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default ChangeClubColorsCard;