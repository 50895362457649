import { useMemo } from "react";
import useTranslator from "../useTranslator";

const categoryTypes = [
  "PRODUCT",
  "COLLECTION",
  // "AUTOMATIC"
]
const useTranslatedCategoryTypes = () => {
  const t = useTranslator("categoryTypes")

  const translatedCategoryTypes = useMemo(() => getTranslatedCategoryTypes(t), [t])
  const categoryTypeOptions = useMemo(() => getCategoryTypeOptions(t), [t])
  const getCategoryTypeOption = (v) => findCategoryTypeOption(t, v)

  return {
    categoryTypes,
    translatedCategoryTypes,
    categoryTypeOptions,
    getCategoryTypeOption
  }
}

const getTranslatedCategoryTypes = (t) => {
  return categoryTypes.reduce((translated, categoryType) => {
    translated[categoryType] = t(categoryType) || categoryType
    return translated
  }, {})
}

const getCategoryTypeOptions = (t) => {
  return categoryTypes.map((categoryType) => ({ value: categoryType, label: t(categoryType) || categoryType }))
}

const findCategoryTypeOption = (t, value) => {
  return categoryTypes.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedCategoryTypes;
export { categoryTypes, getTranslatedCategoryTypes, getCategoryTypeOptions, findCategoryTypeOption }