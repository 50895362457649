import React from "react";
import { Table, TableCard } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import useTranslator from "../../hooks/useTranslator";
import { TableOutput } from "@general-backoffice/core/index";
import AddButtonChildren from "../shared/AddButtonChildren";

const ShowCouponListCard = ({
                              title,
                              coupons = [],
                              pagination,
                              setCouponToUpload,
                              setCouponToRemove,
                              onClickCoupon,
                              isLoading
                            }) => {
  const t = useTranslator("components.coupons.showCouponListCard")
  const tCurrencies = useTranslator("currencies")

  const columns = [
    {
      name: t("code"),
      property: "id",
      isTh: true
    },
    {
      name: t("name"),
      property: "name",
    },
    {
      name: t("discount"),
      formattedContent: ({ percentOff, amountOff, currency }) => {
        if (amountOff) return `${amountOff} ${tCurrencies(currency)}`
        else return `${percentOff} %`
      }
    },
    {
      name: t("duration"),
      formattedContent: ({ duration, durationInMonths }) => {
        switch (duration) {
          case "forever":
            return t("durationForever")
          case "once":
            return t("durationOnce")
          case "repeating":
            return t("durationRepeating", durationInMonths)
          default:
            return "Unknown"
        }
      }
    },
    {
      name: t("timesRedeemed"),
      formattedContent: ({ maxRedemptions, timesRedeemed }) => {
        return `${timesRedeemed} / ${maxRedemptions}`
      }
    },
    {
      name: "",
      formattedContent: (coupon) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setCouponToUpload({ ...coupon }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setCouponToRemove({ ...coupon })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <ReactstrapCard className="overflow-hidden">
      <TableCard.Header
        title={title || t("title")}
        subtitle={t("subtitle")}
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children={t("addCouponButtonText")}/>,
            onClick: () => setCouponToUpload({}),
            title: t("addPlanButtonDescription")
          }
        ]}
      />
      <Table
        columns={columns}
        rows={coupons}
        onRowClick={(coupon) => onClickCoupon({ ...coupon })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={pagination?.pageSize}
        rowHeight={81}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={pagination?.changePageSize}
        totalEntries={pagination?.totalElements}
        page={(pagination?.pageNumber - 1)}
        onChangePage={(page) => pagination?.changePageNumber((page + 1))}
        isLoading={isLoading}
      />
    </ReactstrapCard>
  );
}

export default ShowCouponListCard;