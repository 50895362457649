import { useEffect } from 'react';
import { useStopwatch as useLibraryStopwatch } from 'react-timer-hook';

const useStopwatch = ({duration, callback = () => null}) => {
  const {
    seconds,
    minutes,
    hours,
    isRunning: isPlaying,
    start,
    pause,
    reset
  } = useLibraryStopwatch({autoStart: false});
  const totalSeconds = seconds + (minutes*60) + (hours*3600)

  const stop = () => reset(undefined, false)

  useEffect(() => {
    if(duration && totalSeconds >= duration) {
      callback()
      stop()
    }
    // eslint-disable-next-line
  }, [totalSeconds, isPlaying])

  const toggle = () => {
    if(duration && totalSeconds >= duration) reset()
    else isPlaying ? pause() : start()
  }

  const goToSecond = (seconds) => {
    const stopwatchOffset = new Date(); 
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + seconds);
    reset(stopwatchOffset, isPlaying)
  }

  return {
    totalSeconds,
    seconds,
    minutes,
    hours,
    isPlaying,
    toggle,
    reset,
    stop,
    goToSecond
  }
}

export default useStopwatch;