import React from "react";
import { Header } from "@general-backoffice/core";
import useTrainers from "../../../../components/trainers/hooks/useTrainers";
import { Col, Container, Row } from "reactstrap";
import ShowTrainersCard from "../../../../components/trainers/ShowTrainersCard";
import RemoveTrainerModal from "../../../../components/trainers/RemoveTrainerModal";
import UploadTrainerModal from "../../../../components/trainers/UploadTrainerModal";
import UploadMeetingTemplateModal from "../../../../components/meetings/UploadMeetingTemplateModal";
import { useNavigate } from "react-router";
import useTrainer from "../../../../components/trainers/hooks/useTrainer";
import useMeetingTemplate from "../../../../components/meetings/hooks/useMeetingTemplate";
import ShowMeetingTemplatesModal from "../../../../components/meetings/ShowMeetingTemplatesModal";
import useMeetingTemplates from "../../../../components/meetings/hooks/useMeetingTemplates";

const Trainers = () => {
  const navigate = useNavigate();

  const {
    trainers,
    isLoading: isLoadingTrainers,
    refresh: refreshTrainers
  } = useTrainers()

  const {
    trainer: trainerToRemove,
    setTrainer: setTrainerToRemove,
    isLoading: isLoadingTrainerToRemove,
    remove: removeTrainerToRemove
  } = useTrainer()

  const {
    trainer: trainerToShowMeetingTemplates,
    setTrainer: setTrainerToShowMeetingTemplates,
    isLoading: isLoadingTrainerToShowMeetingTemplates,
  } = useTrainer()

  const {
    trainer: trainerToUpload,
    setTrainer: setTrainerToUpload,
    isLoading: isLoadingTrainerToUpload,
    save: saveTrainerToUpload
  } = useTrainer()

  const {
    meetingTemplate: meetingTemplateToUpload,
    setMeetingTemplate: setMeetingTemplateToUpload,
    isLoading: isLoadingMeetingTemplateToUpload,
    save: saveMeetingTemplateToUpload
  } = useMeetingTemplate()

  const trainerToShowMeetingTemplatesId = trainerToShowMeetingTemplates?.id

  const {
    meetingTemplates: meetingTemplatesTrainerToShowMeetingTemplates,
    isLoading: meetingTemplatesIsLoadingMeetingTemplates,
    refresh: refreshMeetingTemplatesIsLoadingMeetingTemplates
  } = useMeetingTemplates(trainerToShowMeetingTemplatesId)

  return (
    <React.Fragment>

      <ShowMeetingTemplatesModal
        meetingTemplates={meetingTemplatesTrainerToShowMeetingTemplates}
        isOpen={!!trainerToShowMeetingTemplates}
        close={() => setTrainerToShowMeetingTemplates(null)}
        isLoading={meetingTemplatesIsLoadingMeetingTemplates || isLoadingTrainerToShowMeetingTemplates}
        onClickMeetingTemplate={(mt) => {
          setMeetingTemplateToUpload({ ...mt, trainerId: trainerToShowMeetingTemplatesId })
        }}
        setMeetingTemplateToUpload={(mt) => {
          setMeetingTemplateToUpload({ ...mt, trainerId: trainerToShowMeetingTemplatesId })
        }}
      />

      <RemoveTrainerModal
        trainer={trainerToRemove}
        close={() => setTrainerToRemove(null)}
        remove={async () => {
          return removeTrainerToRemove()
            .then(() => {
              refreshTrainers()
              setTrainerToRemove(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTrainerToRemove}
      />

      <UploadTrainerModal
        trainer={trainerToUpload}
        setTrainer={setTrainerToUpload}
        isLoading={isLoadingTrainerToUpload}
        onSubmit={async () => {
          return saveTrainerToUpload()
            .then(() => {
              refreshTrainers()
              setTrainerToUpload(null)
            })
            .catch(() => null)
        }}
      />

      <UploadMeetingTemplateModal
        meetingTemplate={meetingTemplateToUpload}
        setMeetingTemplate={setMeetingTemplateToUpload}
        onSubmit={async () => {
          return saveMeetingTemplateToUpload()
            .then(() => {
              refreshTrainers()
              refreshMeetingTemplatesIsLoadingMeetingTemplates()
              setMeetingTemplateToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingMeetingTemplateToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>

      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowTrainersCard
              trainers={trainers}
              setTrainerToUpload={setTrainerToUpload}
              setTrainerToShowMeetingTemplates={setTrainerToShowMeetingTemplates}
              setTrainerToRemove={setTrainerToRemove}
              setTrainerToShowSchedule={({ id }) => navigate(`${id}/schedule`)}
              onClickTrainer={setTrainerToUpload}
              isLoading={isLoadingTrainers}
            />
          </Col>
        </Row>
      </Container>

    </React.Fragment>
  );
}

export default Trainers;