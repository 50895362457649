import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { notify, StatsCard } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import useTranslator from "../../hooks/useTranslator";

const StatsUsersTotalActiveNow = () => {
  const t = useTranslator("components.stats.statsUsersTotalActiveNow")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [firstNumber, setFirstNumber] = useState(0);

  const intervalRef = useRef();

  const refresh = () => {
    statsRequests.getClubConnectedUsers()
      .then(({ connections }) => {
        setFirstNumber(connections)
      })
      .catch((e) => notify.error(e?.message || t("getStatsError")))
  }

  useEffect(() => {
    refresh();
    intervalRef.current = setInterval(refresh, 10000)
    return (() => {
      clearInterval(intervalRef.current)
    })
    // eslint-disable-next-line
  }, [clubId])

  return (
    <StatsCard.Number
      color="primary"
      isDark={true}
      number={firstNumber}
      suffix={t("suffix", firstNumber)}
      title={t("title")}
      icon="fas fa-user-clock"
      description={t("description")}/>
  )
}

export default StatsUsersTotalActiveNow;