import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import getDefaultUser from "../users/shared/getDefaultUser";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";


const UploadManagerModal = ({
                              manager,
                              setManager,
                              onSubmit,
                              isLoading,
                            }) => {
  const t = useTranslator("components.managers.uploadManagerModal")

  const {
    id,
    firstName,
    lastName,
    email
  } = { ...getDefaultUser(), ...(manager || {}) }

  const { onChangeInput: onChange } = useChangingComplexState(setManager, isLoading)


  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubmit()
  }


  return (
    <Modal.FormContainer
      isOpen={!!manager}
      toggleOpen={() => setManager(null)}
      title={t("title", !!id, email)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >
      <FormInput.Email
        onChange={onChange}
        value={email}
        name={"email"}
        label={t("emailLabel")}
        disabled={!!id}
        required
      />

      <FormInput.Input
        onChange={onChange}
        value={firstName}
        name="firstName"
        label={t("nameLabel")}
        required
      />

      <FormInput.Input
        onChange={onChange}
        value={lastName}
        name="lastName"
        label={t("lastName")}
        required
      />

    </Modal.FormContainer>
  );
}

export default UploadManagerModal;