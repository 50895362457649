import { useEffect, useState } from "react";
import { teamRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import { useSelector } from "react-redux";
import getDefaultTeam from "../shared/getDefaultTeam";

const useTeam = (id) => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [team, setTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const teamId = team?.id || id
  const existsTeam = !!team

  useEffect(() => {
    if (!!teamId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [teamId])

  useEffect(() => {
    if (existsTeam) {
      setTeam((team) => ({ clubId, ...team }))
    }
  }, [clubId, existsTeam])

  const refresh = async () => {
    setIsLoading(true)
    return getTeam(teamId)
      .then((team) => {
        setTeam(team)
        return team
      })
      .catch((e) => {
        setTeam(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveTeam(team)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!team?.id) return;
    setIsLoading(true)
    return removeTeam(team.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sortSections = async (spdTeamSectionList = []) => {
    console.log(spdTeamSectionList)
    if (!team?.id) return;
    const oldTeam = team
    const newTeam = { ...team, spdTeamSectionList, spdTeamSectionIdList: spdTeamSectionList.map(({ id }) => id) }
    setTeam(newTeam)
    return sortTeamSections(newTeam)
      .catch(() => setTeam(oldTeam))
  }

  return {
    team,
    setTeam,
    save,
    remove,
    refresh,
    sortSections,
    isLoading
  }
}

export const getTeam = async (teamId) => {
  const t = hardTranslator("components.team.hooks.useTeam")
  try {
    return await teamRequests.get(teamId)
  } catch (e) {
    const message = e?.message || t("getTeamError")
    notify.error(message)
    throw new Error(message)
  }
}

export const sortTeamSections = async (team) => {
  console.log(team)
  const t = hardTranslator("components.team.hooks.useTeam")
  const notificationLoading = notify.loading(t("sortTeamSectionsLoading"))
  const { id, spdTeamSectionIdList } = { ...getDefaultTeam(), ...team }
  const cleanTeam = { teamId: id, spdTeamSectionIdList }
  try {
    await teamRequests.sortSections(cleanTeam)
    notifyLoadingToSuccess(notificationLoading, t("sortTeamSectionsSucceed"))
  } catch (e) {
    const message = e?.message || t("sortTeamSectionsError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const saveTeam = async (team) => {
  const t = hardTranslator("components.team.hooks.useTeam")
  const notificationLoading = notify.loading(t("saveTeamLoading", !!team?.id))
  try {
    const cleanTeam = { ...getDefaultTeam(), ...team }
    if (!!team?.id) {
      await teamRequests.put(cleanTeam)
      notifyLoadingToSuccess(notificationLoading, t("saveTeamSucceed", true))
    } else {
      await teamRequests.post(cleanTeam)
      notifyLoadingToSuccess(notificationLoading, t("saveTeamSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveTeamError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeTeam = async (teamId) => {
  const t = hardTranslator("components.team.hooks.useTeam")
  const notification = notify.loading(t("removeTeamLoading"))
  try {
    await teamRequests.delete(teamId)
    notifyLoadingToSuccess(notification, t("removeTeamSucceed"))
  } catch (e) {
    const message = e?.message || t("removeTeamError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useTeam;