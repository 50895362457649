import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { meetingRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";


const useMeetingCategories = () => {
  const [meetingCategories, setMeetingCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [])

  const refresh = async () => {
    setIsLoading(true)
    return getMeetingCategories()
      .then((meetingCategoryList) => {
        setMeetingCategories(meetingCategoryList)
        return meetingCategoryList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    meetingCategories,
    isLoading,
    refresh
  }
}

export const getMeetingCategories = async () => {
  const t = hardTranslator("components.meetings.hooks.useMeetingCategories")

  try {
    const { meetingCategoryList = [] } = await meetingRequests.getCategories()
    return meetingCategoryList
  } catch (e) {
    const message = e?.message || t("getMeetingCategoriesError")
    notify.error(message)
    throw new Error(message)
  }
}

export default useMeetingCategories;