import React, { createContext, useContext, useEffect, useState } from "react";
import { RoutesInside } from "@general-backoffice/core";
import Evaporate from "evaporate";
import SparkMD5 from "spark-md5";
import crypto from "crypto";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToLoading } from "../components/utils/notify/notifyLoadingUpdate";
import Api from "../api/ApiUltrik";
import { useSelector } from "react-redux";
import useTranslator from "../hooks/useTranslator";
import { v5 as uuid } from "uuid";

const defaultContextValue = {
  files: [],
  uploadFiles: () => undefined,
  currentFile: undefined,
  removeFile: () => undefined,
  pauseFile: () => undefined,
  isLoading: false,
  pausedUploads: [],
}

const TranscodeContext = createContext(defaultContextValue);
const useTranscodeContext = () => useContext(TranscodeContext)

const TranscodeWrapper = ({ children }) => {
  const { transcodificationToken } = useSelector(({ sessionState }) => sessionState.club || {})
  // const { transcodificationMaxTime } = (clubProperties || {})
  const t = useTranslator("components.trans.contexts.transcodeWrapper")
  const [evaporate, setEvaporate] = useState(null)
  const [files, setFiles] = useState([])

  useEffect(() => {
    Evaporate.create({
      logging: false,
      computeContentMd5: true,
      "cryptoMd5Method": (data) => btoa(SparkMD5.ArrayBuffer.hash(data, true)),
      "cryptoHexEncodedHash256": (data) => crypto.createHash('sha256').update(data).digest('hex'),
      awsRegion: "us-west-002",
      bucket: "video-upload-dev",
      aws_key: "002f512e5077a460000000009",
      aws_url: "https://s3.us-west-002.backblazeb2.com",
      signerUrl: "https://media1.ultrik.com/b2/sign_auth",
    }).then((evaporate) => setEvaporate(evaporate))
  }, [])

  const removeOfFiles = (index) => {
    setFiles((currentFiles) => {
      const newFiles = [...currentFiles]
      newFiles.splice(index, 1)
      return newFiles
    })
  }

  const addFile = async (file) => {
    const notification = notify.loading(t("uploadingFile"))
    evaporate.add({
      name: file.uniqueName,
      file: file,
      "beforeSigner": function (xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${transcodificationToken}`);
      },
      progress: (p) => {
        const percentage = (p * 100).toFixed(2)
        file.progress = percentage
        setFiles(([_, ...cf]) => [file, ...cf])
        notifyLoadingToLoading(notification, `${file.name}: ${percentage}%`)
      },
      error: () => {
        notify.dismiss(notification)
        notify.error(t("fileUploadedError", file.name))
        setFiles(([_, ...files]) => ([...files]))
      },
      cancelled: () => {
        notify.dismiss(notification)
        notify.warning(t("fileUploadedCancelled", file.name))
      },
      paused: () => {
        notify.dismiss(notification)
        notify.warning(t("fileUploadedPaused", file.name))
      },
      warn: console.log,
      nameChanged: console.log,
      complete: async ({ responseURL }) => {
        await Api.post("/upload/b2", {
          url: responseURL.split("?uploadId")[0],
          filename: file.name,
          uuid: file.uuid
        })
        notify.dismiss(notification)
        notify.success(t("fileUploadedSucceed", file.name))
        setFiles((currentFiles) => currentFiles.filter((f) => f.uuid !== file.uuid))
      }
    })
  }

  const removeFile = async (index) => {
    const file = files[index]
    if (index === 0) {
      await evaporate.cancel(`video-upload-dev/${file.uniqueName}`)
    }
    removeOfFiles(index)
  }

  const pauseFile = async (index) => {
    const file = files[index]
    if (index === 0) {
      await evaporate.pause(`video-upload-dev/${file.uniqueName}`, { force: true })
    }
    removeOfFiles(index)
  }

  useEffect(() => {
    if (files[0]) addFile(files[0])
    // eslint-disable-next-line
  }, [files[0]?.name])

  const uploadFiles = (files) => {
    files.forEach((file) => {
      if (!file.name) return notify.error(t("errorNameVideo"))
    })


    // Create unique name for files and add progress
    files.forEach((f) => {
      const identification = getFileIdentification(f)
      f.uniqueName = identification.uniqueName
      f.uuid = identification.uuid
      f.progress = 0.01
    })

    setFiles((currentFiles) => ([...currentFiles, ...files]))
    return files
  }

  const isLoading = !evaporate
  const ls = localStorage.getItem("awsUploads")
  const parseLs = JSON.parse(ls) || {}
  const awsUploads = Object.values(parseLs).reverse()
  const allUploads = awsUploads.map((awsUpload) => {
    const separatorIndex = awsUpload.awsKey.indexOf("_") + 1
    const awsName = awsUpload.awsKey.substring(separatorIndex)
    const name = decodeURI(awsName)
    const size = awsUpload.fileSize
    return {
      name,
      size,
      ...awsUpload,
      ...getFileIdentification({ name, size })
    }
  })
  const pausedUploads = allUploads.filter(({ completedAt, name }) => {
    const isProcessing = (evaporate?.filesInProcess || []).find(({ file }) => file.name === name)
    return !completedAt && !isProcessing
  })

  return (
    <TranscodeContext.Provider
      value={{ files, uploadFiles, removeFile, pauseFile, isLoading, pausedUploads }}>
      {children || <RoutesInside/>}
    </TranscodeContext.Provider>
  );
}

const getFileIdentification = (file) => {
  const uniqueName = `${file.size}_${file.name}`
  return {
    uniqueName: uniqueName,
    uuid: uuid(uniqueName, uuid.URL)
  }
}

export default TranscodeWrapper;
export { useTranscodeContext, getFileIdentification }