import React from "react";
import { Loading, NotificationWrapper, PersistAndProvider } from "@general-backoffice/core";
import sessionStatePersistConfig from "./redux/reducers/session/statePersistConfig";
import sessionState from "./redux/reducers/session/state";
import { login, logout } from "./redux/reducers/session/actions";
import Router from "./Router";
import { clubRequests } from "./api/requests";
import { sessionVersion as actualSessionVersion } from "./config/configuration";
import TranscodeWrapper from "./contexts/TranscodeWrapper";

const stores = {
  sessionState: {
    persistConfig: sessionStatePersistConfig,
    baseReducer: sessionState
  }
}

const afterRehydrate = async (store) => {
  const state = store.getState();
  const { sessionState: { token, user, club, sessionVersion } } = state;
  const isSameSessionVersion = sessionVersion === actualSessionVersion
  try {
    if (!user || !token || !isSameSessionVersion) throw new Error("Datos de sesión inválida")
    store.dispatch(login(token, user, club));
    const { clubList = [] } = await clubRequests.getClubsUser();
    user.clubList = (clubList || []);
    const actualClub = clubList.find((iterClub) => iterClub?.clubId.toString() === club?.clubId?.toString())
    const initialClub = actualClub || clubList[0]
    store.dispatch(login(token, user, (initialClub || {})));
  } catch (e) {
    store.dispatch(logout())
  }
}


function App() {

  const loadingPage = (
    <div className="vh-100 flex-center">
      <Loading.BouncingDots size={"lg"}/>
    </div>
  )

  return (
    <PersistAndProvider stores={stores} afterRehydrate={afterRehydrate} loadingScreen={loadingPage}>
      <React.Suspense fallback={loadingPage}>
        <NotificationWrapper>
          <TranscodeWrapper>
            <Router/>
          </TranscodeWrapper>
        </NotificationWrapper>
      </React.Suspense>
    </PersistAndProvider>
  );
}

export default App;
