import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { notify, Card, Loading, Modal, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { successColor, infoColor, infoColorOp } from './config/colors'
import UploadStatsPeriodModal, {
  format,
  formatHuman,
  initialEndDate,
  initialStartDate
} from "../shared/UploadStatsPeriodModal";
import { statMedium, statSmall } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";

const StatsCategoriesVisualizations = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsCategoriesVisualizations")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [isLoading, setLoading] = useState(true)
  const [{ startDate, endDate }, setLocalDates] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [categoryStatsList, setCategoryStatsList] = useState([])
  // const [numViews, setNumViews] = useState(0)

  const refresh = () => {
    statsRequests.getCategoriesStates(format(startDate), format(endDate))
      .then(({ categoryStatsList }) => {
        setCategoryStatsList(categoryStatsList)
        // setNumViews(aggregateStats?.numViews || 0)
      })
      .catch((e) => notify.error(e?.message || t("getStatsError")))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId, startDate, endDate])

  const hasInfo = categoryStatsList.length > 0;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />

      <Modal.Info
        titleInfo={t("infoTitle")}
        closeText={t("infoCloseText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("infoContent")}
      </Modal.Info>

      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        <CardBody>
          {isLoading && <Loading.BouncingDots className="my-5"/>}
          {(!hasInfo && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          {(hasInfo && !isLoading) && (
            <div style={{ position: "relative", height: simple ? statSmall : statMedium }}>
              <Bar
                type={"bar"}
                data={{
                  labels: categoryStatsList.map(({ category }) => category.name),
                  datasets: [{
                    label: t("visualizationsLabel"),
                    data: categoryStatsList.map(({ stats }) => stats.numViews || 0),
                    backgroundColor: successColor,
                    borderRadius: 5,
                    yAxisID: 'y',
                    order: 1
                  }, {
                    label: t("timeLabel"),
                    data: categoryStatsList.map(({ stats }) => stats.averageTime || 0),
                    backgroundColor: infoColor,
                    type: 'line',
                    yAxisID: 'y1',
                    order: 0
                  }
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  indexAxis: 'x',
                  responsive: true,
                  scales: {
                    y: {
                      display: !simple,
                      grid: {
                        drawBorder: false
                      },
                      ticks: {
                        suggestedMin: 0,
                        stepSize: 10,
                      }
                    },
                    y1: {
                      type: 'linear',
                      display: !simple,
                      grid: {
                        color: infoColorOp,
                        display: false,
                        drawBorder: false
                      },
                      position: 'right',
                      ticks: {
                        suggestedMin: 0,
                        stepSize: 1000,
                        color: infoColor,
                        callback: (value) => `${value} min.`
                      }
                    },
                    x: {
                      display: !simple,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  interaction: {
                    intersect: false,
                    mode: 'index',
                  },
                }}/>
            </div>
          )}
        </CardBody>

      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsCategoriesVisualizations;