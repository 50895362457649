import { useEffect, useState } from "react";
import { tagRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useTag = (id) => {

  const [tag, setTag] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const tagId = tag?.id || id

  useEffect(() => {
    if (!!tagId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [tagId])

  const refresh = async () => {
    setIsLoading(true)
    return getTag(tagId)
      .then((tag) => {
        setTag(tag)
        return tag
      })
      .catch((e) => {
        setTag(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveTag(tag)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!tag?.id) return;
    setIsLoading(true)
    return removeTag(tag.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    tag,
    setTag,
    save,
    remove,
    refresh,
    isLoading
  }
}

export const getTag = async (tagId) => {
  const t = hardTranslator("components.tag.hooks.useTag")
  try {
    const list = await tagRequests.getList()
    const coincidence = (list || []).find(({id}) => id === tagId)
    if(!coincidence) throw new Error()
    return coincidence
  } catch (e) {
    const message = e?.message || t("getTagError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveTag = async (tag) => {
  const t = hardTranslator("components.tag.hooks.useTag")
  const notificationLoading = notify.loading(t("saveTagLoading", !!tag?.id))
  try {
    if (!!tag?.id) {
      await tagRequests.put(tag)
      notifyLoadingToSuccess(notificationLoading, t("saveTagSucceed", true))
    } else {
      await tagRequests.post(tag)
      notifyLoadingToSuccess(notificationLoading, t("saveTagSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveTagError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeTag = async (tagId) => {
  const t = hardTranslator("components.tag.hooks.useTag")
  const notification = notify.loading(t("removeTagLoading"))
  try {
    await tagRequests.delete(tagId)
    notifyLoadingToSuccess(notification, t("removeTagSucceed"))
  } catch (e) {
    const message = e?.message || t("removeTagError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useTag;