import Api from "../Api";

const getCategory = (id) => Api.get(`/admin/categories/${id}`);
const getCategoryList = (params = {}) => Api.get(`/admin/categories/list`, { params });
const putCategory = (body) => Api.put(`/admin/categories`, body);
const postCategory = (body) => Api.post(`/admin/categories`, body);
const deleteCategory = (id) => Api.delete(`/admin/categories/${id}`);
const sortCategoryContent = (body) => Api.put("/admin/categories/orderlist", body)

const categoryRequests = {
  get: getCategory,
  getList: getCategoryList,
  put: putCategory,
  post: postCategory,
  delete: deleteCategory,
  sortContent: sortCategoryContent
}

export default categoryRequests