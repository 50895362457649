import React, { useEffect, useState } from "react";
import { Header, notify } from "@general-backoffice/core/index";
import { Col, Container, Row } from "reactstrap";
import ShowMeetingCallCard from "../../../../components/meetings/ShowMeetingCallCard";
import { useNavigate, useParams } from "react-router";
import { meetingRequests } from "../../../../api/requests";
import useTranslator from "../../../../hooks/useTranslator";

const Meeting = () => {
  const t = useTranslator("views.pages.dashboard.meeting")
  const navigate = useNavigate()
  const { idMeeting } = useParams()
  const [meeting, setMeeting] = useState(null);
  const [isLoading, setLoading] = useState(true)

  const exit = () => {
    notify.warning(t("leavingWarning"))
    navigate(-1)
  }

  useEffect(() => {
    if (!idMeeting) return;
    setLoading(true)
    meetingRequests.get(idMeeting)
      .then((meeting) => setMeeting(meeting))
      .catch((e) => {
        exit()
        notify.error(e || t("connectionError"))
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [idMeeting])

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}>

      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>

            <ShowMeetingCallCard
              meetingToShowCall={meeting}
              getOffTheCall={exit}
              isLoading={isLoading}
            />

          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Meeting;