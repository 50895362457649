import React, { useEffect, useState } from "react";
import { Modal, FormInput, notify } from "@general-backoffice/core";
import getSPDDefaultProduct from "./shared/getSPDDefaultProduct";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import moment from "moment/moment";
import useTeams from "../team/hooks/useTeams";
import { Col, Row } from "reactstrap";
import { productSPDRequests } from "../../api/requests";
import { FormDecoration } from "@general-backoffice/core/index";
import useApiFileImages from "../../hooks/useApiFileImages";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";

const sports = ["football", "handball"]
const maxTimeProduct = 210

const imagesStructure = {
  thumbnail: {
    size: [1040, 585], // AR: 16:9
    MB: 0.2
  },
  thumbnailPortrait: {
    size: [640, 960], // AR: 2:3
    MB: 0.2
  },
  image: {
    size: [1920, 1080], // AR: 16:9
    MB: 0.6
  },
  imagePortrait: {
    size: [1080, 1920], // AR: 9:16
    MB: 0.6
  },
}

const UploadSPDProductModal = ({
                                 product = null,
                                 setProduct,
                                 plans = [],
                                 collectionsByCategory = [],
                                 setCollectionToUpload,
                                 setPlanToUpload,
                                 onSubmit,
                                 isLoading
                               }) => {
  const t = useTranslator("components.products.uploadSPDProductModal")
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [isLoadingPG, setIsLoadingPG] = useState(false)
  const {
    changeState,
    changeMultipleStates,
    onChangeInput: onChange
  } = useChangingComplexState(setProduct, isLoading || isLoadingLocal || isLoadingPG)
  const [things, setThings] = useState({ cameras: [], storyboards: [], graphicPackages: [] })
  const { cameras, graphicPackages, storyboards } = things

  const {
    teams,
    isLoading: isLoadingTeams
  } = useTeams()

  const {
    id,
    title,
    description,
    collectionId,
    subscriptionPlanIdList,
    homeTeam: { id: homeTeamId = null } = {},
    awayTeam: { id: awayTeamId = null } = {},
    cameraId,
    storyBoardId,
    graphicPackageId,
    eventStartTime,
    eventMinutesBeforeStart,
    eventMinutesAfterEnd,
    sportEnum,
    image,
    imagePortrait,
    thumbnail,
    thumbnailPortrait
  } = { ...getSPDDefaultProduct(), ...(product || {}) };

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image, thumbnail, imagePortrait, thumbnailPortrait }, [id, !!product])

  const refreshThings = async () => {
    setIsLoadingLocal(true)
    try {
      const cameras = await productSPDRequests.getCameras()
      const storyboards = await productSPDRequests.getStoryboards()
      const graphicPackages = await productSPDRequests.getGraphicPackages(sportEnum)
      setThings({ storyboards, graphicPackages, cameras })
    } catch (e) {
      notify.error("Oops! Something went wrong. Please try again later")
    }
    setIsLoadingLocal(false)
  }

  const refreshPg = async () => {
    setIsLoadingPG(true)
    try {
      const graphicPackages = await productSPDRequests.getGraphicPackages(sportEnum)
      setThings({ ...things, graphicPackages })
    } catch (e) {
      notify.error("Oops! Something went wrong. Please try again later")
    }
    setIsLoadingPG(false)
  }

  useEffect(() => {
    if (!product) return;
    refreshThings().finally(() => null)
    // eslint-disable-next-line
  }, [!product, id]);

  useEffect(() => {
    if (!product || !sportEnum) return;
    refreshPg().finally(() => null)
    // eslint-disable-next-line
  }, [sportEnum]);

  const sportOptions = sports.map((name) => ({ value: name, label: t(name) }));
  const cameraOptions = cameras.map(({ id, name }) => ({ value: id, label: name }));
  const storyboardOptions = storyboards.map(({ id, title }) => ({ value: id, label: title }));
  const gpOptions = graphicPackages.map(({ id, name, ...others }) => ({ ...others, value: id, label: name }));
  const teamOptions = teams.map(({ id, name, ...plan }) => ({ value: id, label: name, ...plan }));
  const plansOptions = plans.map(({ id, name, ...plan }) => ({ value: id, label: name, ...plan }));

  const onlyOptions = []
  const collectionOptions = collectionsByCategory.map(({ name, collectionList = [] }) => {
    const options = (collectionList || []).map(({ id, title }) => {
      const option = { value: id, label: title, categoryLabel: name }
      onlyOptions.push(option)
      return option
    })
    return { label: name, options: options, }
  });
  const selectedCollectionOption = onlyOptions.find(({ value }) => value === collectionId)

  const someIsLoading = isLoading || isLoadingTeams || isLoadingLocal || isLoadingPG || isLoadingFiles;

  const onSubmitForm = async () => {
    onSubmit();
  }

  return (
    <React.Fragment>

      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        size={"lg"}
        isOpen={!!product}
        toggleOpen={() => setProduct(null)}
        title={t("title", !!id, title)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
      >

        <Row>
          <Col>
            <FormInput.Select
              label={t("homeTeamLabel")}
              name={"homeTeamId"}
              onChange={({ value }) => changeState("homeTeam.id", value)}
              options={teamOptions}
              value={teamOptions.find(({ value }) => value === homeTeamId)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              required
            />
          </Col>
          <Col>
            <FormInput.Select
              label={t("awayTeamLabel")}
              name={"awayTeamId"}
              onChange={({ value }) => changeState("awayTeam.id", value)}
              options={teamOptions}
              value={teamOptions.find(({ value }) => value === awayTeamId)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              required
            />
          </Col>
        </Row>

        <FormDecoration.LineTitle title={"Programación"}/>

        <FormInput.Date
          onChange={(value) => changeState("eventStartTime", moment(value).utc().format())}
          value={moment(eventStartTime)}
          name={"eventStartTime"}
          label={t("startDateLabel")}
          disabled={someIsLoading}
          required
        />

        <Row>
          <Col>
            <FormInput.Time
              onChange={(value) => changeState("eventStartTime", moment(value).utc().format())}
              value={moment(eventStartTime)}
              name={"eventStartTime"}
              label={t("startTimeLabel")}
              disabled={someIsLoading}
              required
              hiddenSeconds
            />
          </Col>
          <Col xs={12} md={"auto"} className="px-0 pb-3">
            <FormDecoration.Line vertical={true}/>
          </Col>
          <Col>
            <FormInput.Time
              onChange={() => null}
              value={moment(eventStartTime).add(eventMinutesAfterEnd, "minutes")}
              label={t("endTimeLabel")}
              disabled={true}
              hiddenSeconds
            />
          </Col>
        </Row>

        <FormInput.Input
          label={`${t("beforeStartLabel")} (${eventMinutesBeforeStart} min)`}
          value={eventMinutesBeforeStart}
          onChange={({ target: { value } }) => {
            const proposedTime = parseInt(value) + eventMinutesAfterEnd
            const isOverTime = proposedTime > maxTimeProduct
            changeMultipleStates({
              eventMinutesBeforeStart: parseInt(value),
              eventMinutesAfterEnd: isOverTime ? eventMinutesAfterEnd - (proposedTime - maxTimeProduct) : eventMinutesAfterEnd
            })
          }}
          name="eventMinutesBeforeStart"
          min={0}
          max={60}
          type="range"
        />

        <FormInput.Input
          label={`${t("durationLabel")} (${eventMinutesAfterEnd} min)`}
          value={eventMinutesAfterEnd}
          onChange={({ target: { value } }) => {
            const proposedTime = eventMinutesBeforeStart + parseInt(value)
            const isOverTime = proposedTime > maxTimeProduct
            changeMultipleStates({
              eventMinutesBeforeStart: isOverTime ? eventMinutesBeforeStart - (proposedTime - maxTimeProduct) : eventMinutesBeforeStart,
              eventMinutesAfterEnd: parseInt(value)
            })
          }}
          name="eventMinutesAfterEnd"
          min={5}
          max={maxTimeProduct}
          type="range"
        />

        <FormDecoration.Line/>

        <FormInput.Input
          onChange={onChange}
          value={title}
          name={"title"}
          label={t("titleLabel")}
          required
        />

        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("sportLabel")}
              name={"sportEnum"}
              onChange={({ value }) => changeState("sportEnum", value)}
              options={sportOptions}
              value={sportOptions.find(({ value }) => value === sportEnum)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              required
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("collectionLabel")}
              name={"collectionIdList"}
              icon={<i className="fas fa-boxes px-2"/>}
              onChange={({ value }) => changeState("collectionId", value)}
              options={collectionOptions}
              value={selectedCollectionOption}
              isDisabled={someIsLoading}
              isCreatable={true}
              isLoading={someIsLoading}
              onCreateOption={(inputValue) => setCollectionToUpload({ title: inputValue })}
              formatCreateLabel={(inputValue) => t("collectionCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noCollectionOptionsText")}
              formatOptionLabel={({ label, categoryLabel }) => {
                return <div>
                  {label} <small className="opacity-5">
                  ( {categoryLabel} )
                </small>
                </div>
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("cameraLabel")}
              name={"cameraId"}
              onChange={({ value }) => changeState("cameraId", value)}
              options={cameraOptions}
              value={cameraOptions.find(({ value }) => value === cameraId)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              required
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("storyboardLabel")}
              name={"storyBoardId"}
              onChange={({ value }) => changeState("storyBoardId", value)}
              options={storyboardOptions}
              value={storyboardOptions.find(({ value }) => value === storyBoardId)}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              required
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("graphicPackageLabel")}
              name={"graphicPackageId"}
              onChange={({ value }) => changeState("graphicPackageId", value)}
              options={gpOptions}
              value={gpOptions.find(({ value }) => value === graphicPackageId)}
              isDisabled={isLoadingPG}
              isLoading={isLoadingPG}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.Select
              label={t("plansLabel")}
              tooltip={t("plansTooltip")}
              name={"subscriptionPlanIdList"}
              isMulti={true}
              icon={<i className="fas fa-calendar alt px-2"/>}
              onChange={(options) => changeState("subscriptionPlanIdList", options.map(({ value }) => value))}
              options={plansOptions}
              value={plansOptions.filter(({ value }) => subscriptionPlanIdList.includes(value))}
              isCreatable={true}
              isDisabled={someIsLoading}
              isLoading={someIsLoading}
              onCreateOption={(inputValue) => setPlanToUpload({ name: inputValue })}
              formatCreateLabel={(inputValue) => t("planCreatingFormatText", inputValue)}
              noOptionsMessage={() => t("noPlanOptionsText")}
            />
          </Col>
        </Row>

        <FormDecoration.Line/>

        <FormInput.Quill
          onChange={(value) => changeState("description", value)}
          value={description}
          label={t("descriptionLabel")}
        />

        <FormDecoration.LineTitle title={"Imágenes"}/>

        <Row>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("thumbnailLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.thumbnail.size.join(" x ")}
              tooltip={t("thumbnailTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnail}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnail: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, thumbnail: files.thumbnail })
                }
              ]}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("thumbnailPortraitLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.thumbnailPortrait.size.join(" x ")}
              tooltip={t("thumbnailPortraitTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnailPortrait}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnailPortrait: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, thumbnailPortrait: files.thumbnailPortrait })
                }
              ]}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("imageLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.image.size.join(" x ")}
              tooltip={t("imageTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.image}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, image: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, image: files.image })
                }
              ]}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormInput.SingleDropzone
              label={t("imagePortraitLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.imagePortrait.size.join(" x ")}
              tooltip={t("imagePortraitTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.imagePortrait}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, imagePortrait: file })}
              buttons={[
                {
                  children: <i className="fas fa-crop-alt"/>,
                  onClick: () => setImagesToResize({ ...imagesToResize, imagePortrait: files.imagePortrait })
                }
              ]}
            />
          </Col>
        </Row>

      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadSPDProductModal;