import { useEffect, useState } from "react";
import { collectionRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import getDefaultCollection from "../shared/getDefaultCollection";
import { useSelector } from "react-redux";

const useCollection = (id) => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [collection, setCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const collectionId = collection?.id || id
  const existsCollection = !!collection

  useEffect(() => {
    if (!!collectionId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [collectionId])

  useEffect(() => {
    if (existsCollection) {
      setCollection((collection) => ({ clubId, ...collection }))
    }
  }, [clubId, existsCollection])

  const refresh = async () => {
    setIsLoading(true)
    return getCollection(collectionId)
      .then((collection) => {
        setCollection(collection)
        return collection
      })
      .catch((e) => {
        setCollection(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveCollection(collection)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!collection?.id) return;
    setIsLoading(true)
    return removeCollection(collection.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sortProducts = async (productIdList = []) => {
    if (!collection?.id) return;
    const oldCollection = collection
    setCollection({ ...collection, productIdList })
    return saveCollection({ ...collection, productIdList })
      .catch(() => setCollection(oldCollection))
  }


  return {
    collection,
    setCollection,
    save,
    remove,
    refresh,
    sortProducts,
    isLoading
  }
}

export const getCollection = async (collectionId) => {
  const t = hardTranslator("components.collection.hooks.useCollection")
  try {
    const collection = await collectionRequests.get(collectionId)
    const { categoryList, productList, ...cleanCollection } = collection
    const categoryIdList = Array.isArray(categoryList) ? categoryList.map(({ id }) => id) : undefined;
    const productIdList = Array.isArray(productList) ? productList.map(({ id }) => id) : undefined;
    return {
      ...cleanCollection,
      ...(categoryIdList ? { categoryIdList } : {}),
      ...(productIdList ? { productIdList } : {}),
    }
  } catch (e) {
    const message = e?.message || t("getCollectionError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveCollection = async (collection) => {
  const t = hardTranslator("components.collection.hooks.useCollection")
  const notificationLoading = notify.loading(t("saveCollectionLoading", !!collection?.id))
  try {
    const { numberOfProducts, ...cleanCollection } = { ...getDefaultCollection(), ...collection }
    if (!!collection?.id) {
      await collectionRequests.put(cleanCollection)
      notifyLoadingToSuccess(notificationLoading, t("saveCollectionSucceed", true))
    } else {
      await collectionRequests.post(cleanCollection)
      notifyLoadingToSuccess(notificationLoading, t("saveCollectionSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveCollectionError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeCollection = async (collectionId) => {
  const t = hardTranslator("components.collection.hooks.useCollection")
  const notification = notify.loading(t("removeCollectionLoading"))
  try {
    await collectionRequests.delete(collectionId)
    notifyLoadingToSuccess(notification, t("removeCollectionSucceed"))
  } catch (e) {
    const message = e?.message || t("removeCollectionError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useCollection;