import { useEffect, useState } from "react";
import { notificationRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultNotification from "../shared/getDefaultNotification";
import { hardTranslator } from "../../../hooks/useTranslator";

const useNotification = (id) => {

  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const notificationId = notification?.id || id

  useEffect(() => {
    if (!!notificationId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [notificationId])

  const refresh = async () => {
    setIsLoading(true)
    return getNotification(notificationId)
      .then((notification) => {
        setNotification(notification)
        return notification
      })
      .catch((e) => {
        setNotification(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveNotification(notification)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const send = async (filters) => {
    if (!notification?.id) return;
    setIsLoading(true)
    return sendNotification(notification.id, filters)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!notification?.id) return;
    setIsLoading(true)
    return removeNotification(notification.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    notification,
    setNotification,
    save,
    remove,
    send,
    isLoading
  }
}

export const getNotification = async (notificationId) => {
  const t = hardTranslator("components.notifications.hooks.useNotification")

  try {
    return await notificationRequests.get(notificationId)
  } catch (e) {
    const message = e?.message || t("getNotificationError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveNotification = async (notification) => {
  const t = hardTranslator("components.notifications.hooks.useNotification")
  const notificationLoading = notify.loading(t("saveNotificationLoading", notification?.id))
  try {
    const cleanNotification = { ...getDefaultNotification(), ...notification }
    if (!!notification?.id) {
      await notificationRequests.put(notification.id, cleanNotification)
      notifyLoadingToSuccess(notificationLoading, t("saveNotificationSucceed", true))
    } else {
      await notificationRequests.post(cleanNotification)
      notifyLoadingToSuccess(notificationLoading, t("saveNotificationSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveNotificationError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const sendNotification = async (notificationId, filters) => {
  const t = hardTranslator("components.notifications.hooks.useNotification")
  const notificationLoading = notify.loading(t("sendNotificationLoading"))
  try {
    await notificationRequests.send(notificationId, filters)
    notifyLoadingToSuccess(notificationLoading, t("sendNotificationSucceed"))
  } catch (e) {
    const message = e?.message || t("sendNotificationError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeNotification = async (notificationId) => {
  const t = hardTranslator("components.notifications.hooks.useNotification")
  const notification = notify.loading(t("removeNotificationLoading"))
  try {
    const companyPlan = await notificationRequests.delete(notificationId)
    notifyLoadingToSuccess(notification, t("removeNotificationSucceed"))
    return companyPlan
  } catch (e) {
    const message = e?.message || t("removeNotificationError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useNotification;