import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useNews from "../../../../components/news/hooks/useNews";
import ShowNewsCard from "../../../../components/news/ShowNewsCard";
import RemoveNewModal from "../../../../components/news/RemoveNewModal";
import UploadNewModal from "../../../../components/news/UploadNewModal";
import useNew from "../../../../components/news/hooks/useNew";

const Magazine = () => {
  const {
    news,
    totalAmount: totalNews,
    changePage: changePageNews,
    isLoading: isLoadingNews,
    refresh: refreshNews,
    pagination: newsPagination
  } = useNews()

  const {
    theNew: newToUpload,
    setTheNew: setNewToUpload,
    save: saveNewToUpload,
    isLoading: isLoadingNewToUpload,
  } = useNew()

  const {
    theNew: newToRemove,
    setTheNew: setNewToRemove,
    remove: removeNewToRemove,
    isLoading: isLoadingNewToRemove,
  } = useNew()

  return (
    <React.Fragment>

      <RemoveNewModal
        thwNew={newToRemove}
        remove={async () => {
          return removeNewToRemove()
            .then(() => {
              refreshNews()
              setNewToRemove(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingNewToRemove}
        close={() => setNewToRemove(null)}
      />

      <UploadNewModal
        theNew={newToUpload}
        setNew={setNewToUpload}
        onSubmit={async () => {
          return saveNewToUpload()
            .then(() => {
              refreshNews()
              setNewToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingNewToUpload}
      />


      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowNewsCard
              news={news}
              setNewToUpload={setNewToUpload}
              setNewToRemove={setNewToRemove}
              onClickNew={setNewToUpload}
              isLoading={isLoadingNews}
              setPage={changePageNews}
              page={newsPagination?.page}
              totalAmount={totalNews}
              amount={newsPagination?.amount}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Magazine;