import React from "react";
import { Card as ReactstrapCard } from "reactstrap";
import { Card, Loading, Table, TableNoContentMessage } from "@general-backoffice/core";
import moment from "moment";
import { getSegmentRPM } from "../player/formatTraining";
import ZoneSquare from "../shared/ZoneSquare";
import { getZoneByFtp } from "../config";


const getStructureWithAppliedRPMs = structure =>
  structure.map((segment, i) => ({ ...segment, appliedRPM: getSegmentRPM(structure, i) || null }))

const StatsTrainingCyclingPreview = ({ structure = [], isLoading, title = "Segmentos" }) => {

  let totalDuration = 0;
  structure.forEach(({ duration }) => {
    totalDuration += duration
  })

  const hasPreview = totalDuration > 0;


  const columns = [
    {
      name: "Zona",
      formattedContent: ({ ftp }) => <ZoneSquare zone={getZoneByFtp((ftp[0] + ftp[1]) / 2).zone} style={{ height: 30 }}/>
    },
    {
      name: "Tiempo",
      formattedContent: ({ duration }) => moment.utc(duration * 1000).format('HH:mm:ss')
    },
    {
      name: "%FTP",
      formattedContent: ({ ftp: [initFtp, endFtp] }) => ((initFtp + endFtp) / 2)
    },
    {
      name: "RPM",
      formattedContent: ({ rpm, appliedRPM }) => {
        if (rpm) return (rpm[0] - rpm[1] === 0 ? rpm[0] : rpm.join(" - "));
        if (appliedRPM) return (appliedRPM[0] - appliedRPM[1] === 0 ? appliedRPM[0] : appliedRPM.join(" - "))
        return "---"
      }
    },
    {
      name: "Posición",
      formattedContent: ({ standing }) => standing ? "De pie" : "Sentado"
    },
  ]

  return (
    <React.Fragment>
      <ReactstrapCard className="bg-default">
        <Card.Header
          isDark={true}
          title={title}
        />
        {isLoading && <Loading.BouncingDots className="my-5" color={"light"}/>}
        {(!hasPreview && !isLoading) && <TableNoContentMessage children={"No hay datos suficientes"}/>}
        <Table
          isDark={true}
          columns={columns}
          rows={getStructureWithAppliedRPMs(structure)}
        />
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsTrainingCyclingPreview;