import { useEffect, useState } from "react";
import { teamSectionRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";
import { useSelector } from "react-redux";
import getDefaultTeamSection from "../shared/getDefaultTeamSection";

const useTeamSection = (id) => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [teamSection, setTeamSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const teamSectionId = teamSection?.id || id

  useEffect(() => {
    if (!!teamSectionId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [teamSectionId, clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getTeamSection(teamSectionId)
      .then((teamSection) => {
        setTeamSection(teamSection)
        return teamSection
      })
      .catch((e) => {
        setTeamSection(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveTeamSection(teamSection)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!teamSection?.id) return;
    setIsLoading(true)
    return removeTeamSection(teamSection.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    teamSection,
    setTeamSection,
    save,
    remove,
    refresh,
    isLoading
  }
}

export const getTeamSection = async (teamSectionId) => {
  const t = hardTranslator("components.teamSection.hooks.useTeamSection")
  try {
    return await teamSectionRequests.get(teamSectionId)
  } catch (e) {
    const message = e?.message || t("getTeamSectionError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveTeamSection = async (teamSection) => {
  const t = hardTranslator("components.teamSection.hooks.useTeamSection")
  const notificationLoading = notify.loading(t("saveTeamSectionLoading", !!teamSection?.id))
  try {
    const cleanTeamSection = { ...getDefaultTeamSection(), ...teamSection }
    if (!!teamSection?.id) {
      await teamSectionRequests.put(cleanTeamSection)
      notifyLoadingToSuccess(notificationLoading, t("saveTeamSectionSucceed", true))
    } else {
      await teamSectionRequests.post(cleanTeamSection)
      notifyLoadingToSuccess(notificationLoading, t("saveTeamSectionSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveTeamSectionError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export const removeTeamSection = async (teamSectionId) => {
  const t = hardTranslator("components.teamSection.hooks.useTeamSection")
  const notification = notify.loading(t("removeTeamSectionLoading"))
  try {
    await teamSectionRequests.delete(teamSectionId)
    notifyLoadingToSuccess(notification, t("removeTeamSectionSucceed"))
  } catch (e) {
    const message = e?.message || t("removeTeamSectionError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useTeamSection;