import Api from "../ApiUltrik";

const getTransList = async ({ userId, clubId, companyCode }) => {
  try {
    const completed = await Api.get("/queue/completed", { params: { userId, clubId, companyCode } })
    return (completed || []).map((trans) => ({
      ...trans,
      url: `https://media1.ultrik.com/vod${trans.path}`
    })).reverse()
  } catch (e) {
    // eslint-disable-next-line
    throw "No se ha podido recuperar las transcodificaciones"
  }
}

const getProcessingTransList = async ({ userId, clubId, companyCode }) => {
  try {
    const completed = await Api.get("/queue/processing", { params: { userId, clubId, companyCode } })
    return (completed || []).map((trans) => ({
      ...trans,
      url: null,
    })).reverse()
  } catch (e) {
    // eslint-disable-next-line
    throw "No se ha podido recuperar las transcodificaciones"
  }
}

const deleteTrans = (id) => Api.delete(`/queue/completed/${id}`);

const getDurationOfTrans = (pathOnServer) => Api.get(`/vod/duration${pathOnServer}`, { params: { clubId: undefined } }).then(({ duration }) => duration);

const getS3SignedUrl = (file) => Api.post("/b2/sign", {
  key: file.name,
  contentType: file.type,
  operation: "putObject"
})

const transRequests = {
  getList: getTransList,
  getProcessing: getProcessingTransList,
  remove: deleteTrans,
  getDuration: getDurationOfTrans,
  getS3SignedUrl: getS3SignedUrl
}

export default transRequests