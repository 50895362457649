import React from "react";
import { FormInput } from "@general-backoffice/core/index";
import { Col, Row } from "reactstrap";

const SwitchWithChildren = ({ children, ...props }) => {
  return (
    <Row className="no-gutters">
      <Col>
        <FormInput.Switch {...props}/>
      </Col>
      <Col xs={"auto"} className="ml-2">
        {children}
      </Col>
    </Row>
  );
}

export default SwitchWithChildren;