import React from "react";
import { Table, TableCard, TableOutput, Card } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import useTranslator from "../../hooks/useTranslator";
import { FormInput } from "@general-backoffice/core/index";

const ShowCollectionListCard = ({
                                  title,
                                  collections = [],
                                  collectionOptions = [],
                                  setCollectionToUpload,
                                  onClickCollectionToShowProducts,
                                  onClickCollection,
                                  sortCollections,
                                  isLoading
                                }) => {
  const t = useTranslator("components.collection.showCollectionListCard")

  const collectionIdList = collections.map(({ id }) => id)
  const options = collectionOptions.map(({ id, title }) => ({ value: id, label: title }))

  const columns = [
    {
      name: t("collectionTitle"),
      formattedContent: ({ title, thumbnail }) => <TableOutput.ImageText src={thumbnail?.url} text={title}/>,
      isTh: true
    },
    {
      name: "",
      formattedContent: (collection) => {
        const collectionIdListWithoutThis = (collectionIdList || []).filter((id) => id !== collection.id)
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setCollectionToUpload({ ...collection }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-th-list"/>,
            onClick: () => onClickCollectionToShowProducts({ ...collection }),
            description: t("showProductsDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => sortCollections(collectionIdListWithoutThis)
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  const onDataChange = async (sortedCollections, trigger) => {
    if (trigger === "sortable") {
      sortCollections(sortedCollections.map(({ id }) => id))
    }
  }

  return (
    <ReactstrapCard className="overflow-hidden">
      <TableCard.Header
        title={t("title", title)}
        subtitle={t("subtitle")}
      >
        <div className="mb--4" style={{ width: 250, maxWidth: "100%" }}>
          <FormInput.Select
            value={null}
            onChange={({ value }) => sortCollections([value, ...(collectionIdList || [])])}
            options={options}
            isDisabled={isLoading}
            onCreateOption={(inputValue) => setCollectionToUpload({ title: inputValue })}
            isCreatable={true}
            formatCreateLabel={(inputValue) => t("collectionCreatingFormatText", inputValue)}
            noOptionsMessage={() => t("noCollectionOptionsText")}
            placeholder={t("selectCollectionPlaceholder")}
            icon={<i className="fas fa-plus px-2 text-primary"/>}
          />
        </div>
      </TableCard.Header>
      <Table
        columns={columns}
        sortable={true}
        onDataChange={onDataChange}
        rows={collections}
        onRowClick={(collection) => onClickCollection({ ...collection })}
        isLoading={isLoading}
        noContentMessage={t("emptyCollections")}
        rowAmount={10}
      />
      <Card.Footer/>
    </ReactstrapCard>
  );
}

export default ShowCollectionListCard;