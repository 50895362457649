import React from "react";
import classNames from "classnames";
import { Container, Row, Col } from "reactstrap";

import TotalTime from "../../displays/TotalTime";
import TrainingPreview from "../../../shared/TrainingPreview";
import { SegmentFlowerTimer } from "../../displays/SegmentFlower";
import GroupTimeBar from "../../displays/GroupTimeBar";
import GroupTime from "../../displays/GroupTime";
import SegmentBar from "../../displays/SegmentBar";
import BarTitle from "./BarTitle";


const SmallPlayerVertical = props => {
  const { structure, secondsBeforeStart, currentSegment, currentTime } = props
  const nextSegment = structure?.[currentSegment?.index + 1]
  let starting = secondsBeforeStart >= 0

  return(
    <Container fluid className="px-2 pb-3 d-flex flex-column h-100 metropolis">

      <Row className="m-0 mb-2 justify-content-between">

        <Col xs='12' className={classNames("d-flex flex-column justify-content-end align-items-end pt-2 mb-3", {'invisible': starting})}>
          <TotalTime {...props} countdown/>
        </Col>

        <TrainingPreview {...props} 
          currentTime={currentTime.totalSeconds} 
          style={{ flex: 1, height: '13vh', minHeight: 65 }}
        />

      </Row>


      <Row className="m-0 flex-column" style={{flex: 1}}>

        <div className={classNames('p-0 mb-2', {'invisible': starting})}>
          <SegmentBar 
            segment={currentSegment} 
            style={{gap: 'calc(1vw + 1.2vh)'}} 
            className="px-2 pb-3 pt-2 justify-content-center"
            showDuration
            title={
              <BarTitle text="SEGMENTO ACTUAL" className="w-100 mb--2" style={{lineHeight: 1, letterSpacing: 1.5}}/>
            }
          />
        </div>

        <div className={classNames('p-0 mb-2', {'invisible': starting})}>
          <SegmentBar 
            segment={nextSegment} 
            style={{gap: 'calc(1vw + 1.2vh)'}}
            className="px-2 pb-3 pt-2 justify-content-center" 
            showDuration 
            title={
              <BarTitle text="SIGUIENTE SEGMENTO" className="w-100 mb--2" style={{lineHeight: 1, letterSpacing: 1.5}} />
            }
          />
        </div>

        <div className="m-2 d-flex flex-column" style={{flex: 1}}>
          <div className="overflow-hidden" style={{flex: 1}}>
            <SegmentFlowerTimer {...props} currentSegment={currentSegment}/>
          </div>
          <div 
            style={{maxWidth: 400, gap: 20}}
            className={classNames(
              "flex-column-content-center w-100 mx-auto mt-4",
              {'invisible': starting}
            )}
          >
            <GroupTimeBar {...props}/>
            <GroupTime {...props} countdown/>
          </div>
        </div>
      </Row>

    </Container>
  )
}



export default SmallPlayerVertical;