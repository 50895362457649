import Api from "../Api";

const getTeamSection = (id) => Api.get(`/admin/team/section/${id}`);
const putTeamSection = (body) => Api.put(`/admin/team/section`, body);
const postTeamSection = (body) => Api.post(`/admin/team/section`, body);
const deleteTeamSection = (id) => Api.delete(`/admin/team/section/${id}`);

const teamSectionRequests = {
  get: getTeamSection,
  put: putTeamSection,
  post: postTeamSection,
  delete: deleteTeamSection
}

export default teamSectionRequests