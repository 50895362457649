import React from "react";
import { Navigate } from "react-router";
import OnDemandStats from "../../../views/pages/dashboard/stats/OnDemandStats";
import RealTimeStats from "../../../views/pages/dashboard/stats/RealTimeStats";
import UsersStats from "../../../views/pages/dashboard/stats/UsersStats";
import TrainersStats from "../../../views/pages/dashboard/stats/TrainersStats";
import permissions from "../../../utils/permissions";


const subRoutes = (t) => [
  {
    name: t("statsOnDemand"),
    miniName: t("statsOnDemand").charAt(0).toUpperCase(),
    path: "on-demand",
    component: <OnDemandStats/>,
    showOnNav: true,
  },
  {
    name: t("statsInRealTime"),
    miniName: t("statsInRealTime").charAt(0).toUpperCase(),
    path: "real-time",
    component: <RealTimeStats/>,
    showOnNav: true,
  },
  {
    name: t("statsUsers"),
    miniName: t("statsUsers").charAt(0).toUpperCase(),
    path: "users",
    component: <UsersStats/>,
    showOnNav: true,
  },
  {
    name: t("statsTrainers"),
    permissions: [permissions.trainers],
    miniName: t("statsTrainers").charAt(0).toUpperCase(),
    path: "trainers",
    component: <TrainersStats/>,
    showOnNav: true,
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard/stats/on-demand"} replace={true}/>,
  }
]

export { subRoutes };