import React, { useEffect, useState } from "react";
import { Header, Modal } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import ShowChannelsCard from "../../../../components/channels/ShowChannelsCard";
import useChannels from "../../../../components/channels/hooks/useChannels";
import PreviewChannelModal from "../../../../components/channels/PreviewChannelModal";
import RemoveChannelModal from "../../../../components/channels/RemoveChannelModal";
import UploadChannelEventModal from "../../../../components/channels/UploadChannelEventModal";
import SaveChannelModal from "../../../../components/channels/SaveChannelModal";
import ShowChannelEventsCalendarCard from "../../../../components/channels/ShowChannelEventsCalendarCard";
import ShowChannelManagersCard from "../../../../components/channelManagers/ShowChannelManagersCard";
import useChannelManagers from "../../../../components/channelManagers/hooks/useChannelManagers";
import useChannelManager from "../../../../components/channelManagers/hooks/useChannelManager";
import RemoveChannelManagerModal from "../../../../components/channelManagers/RemoveChannelManagerModal";
import UploadChannelManagerModal from "../../../../components/channelManagers/UploadChannelManagerModal";
import UploadChannelModal from "../../../../components/channels/UploadChannelModal";
import useChannel, { loadingEventChannel, saveChannel } from "../../../../components/channels/hooks/useChannel";
import { fixChannelEventsOverlap } from "../../../../components/channels/shared/channelEventsUtils";
import useTranslator from "../../../../hooks/useTranslator";

const Channels = () => {
  const t = useTranslator("views.pages.dashboard.channels")
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [channelToConfirmSave, setChannelToConfirmSave] = useState(null)
  const [channelEventToUpload, setChannelEventToUpload] = useState(null)

  const {
    channels,
    refresh: refreshChannels,
    isLoading: isLoadingChannels
  } = useChannels()

  const {
    channel: channelToUpload,
    setChannel: setChannelToUpload,
    save: saveChannelToUpload,
    isLoading: isLoadingChannelToUpload
  } = useChannel()

  const {
    channel: channelToPlay,
    setChannel: setChannelToPlay,
    isLoading: isLoadingChannelToPlay
  } = useChannel()

  const {
    channel: channelToRemove,
    setChannel: setChannelToRemove,
    remove: removeChannelToRemove,
    isLoading: isLoadingChannelToRemove
  } = useChannel()

  const {
    channel: channelToModifyData,
    setChannel: setChannelToModifyData,
    isLoading: isLoadingChannelToModifyData,
    removeEvent: removeEventChannelToModifyData,
    uploadEvent: uploadEventChannelToModifyData,
    createEvent: createEventChannelToModifyData
  } = useChannel()

  const {
    channelManagers,
    pagination: paginationChannelManagers,
    refresh: refreshChannelManagers,
    isLoading: isLoadingChannelManagers,
    totalAmount: totalAmountChannelManagers,
    changePage: changePageChannelManagers
  } = useChannelManagers()

  const {
    setChannelManager: setChannelManagerToUpload,
    channelManager: channelManagerToUpload,
    isLoading: isLoadingChannelManagerToUpload,
    save: saveChannelManagerToUpload
  } = useChannelManager()

  const {
    setChannelManager: setChannelManagerToRemove,
    channelManager: channelManagerToRemove,
    isLoading: isLoadingChannelManagerToRemove,
    remove: removeChannelManagerToRemove
  } = useChannelManager()


  useEffect(() => {
    let newChannelToModifyData = channelToModifyData
    if (!channels.find(({ url }) => channelToModifyData?.url === url)) newChannelToModifyData = null
    if (channels.length > 0 && !newChannelToModifyData) newChannelToModifyData = { ...channels[0] }
    setChannelToModifyData(newChannelToModifyData)
    // eslint-disable-next-line
  }, [channels, channelToModifyData])

  return (
    <React.Fragment>

      <RemoveChannelManagerModal
        channelManager={channelManagerToRemove}
        close={() => setChannelManagerToRemove(null)}
        isLoading={isLoadingChannelManagerToRemove}
        remove={async () => {
          return removeChannelManagerToRemove()
            .then(() => {
              setChannelManagerToRemove(null)
              refreshChannelManagers()
            })
            .catch(() => null)
        }}
      />

      <UploadChannelManagerModal
        channelManager={channelManagerToUpload}
        setChannelManager={setChannelManagerToUpload}
        onSubmit={async () => {
          return saveChannelManagerToUpload()
            .then(() => {
              setChannelManagerToUpload(null)
              refreshChannelManagers();
            })
            .catch(() => null)
        }}
        isLoading={isLoadingChannelManagerToUpload}
      />

      <PreviewChannelModal
        channel={channelToPlay}
        close={() => setChannelToPlay(null)}
        isLoading={isLoadingChannelToPlay}
      />

      <UploadChannelModal
        channel={channelToUpload}
        setChannel={setChannelToUpload}
        onSubmit={() => {
          return saveChannelToUpload()
            .then(() => {
              refreshChannels()
              if (channelToModifyData && (channelToModifyData?.url === channelToUpload?.url)) {
                setChannelToModifyData({
                  ...channelToUpload,
                  data: (channelToModifyData?.data || [loadingEventChannel])
                })
              }
              setChannelToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingChannelToUpload}
      />

      <RemoveChannelModal
        channel={channelToRemove}
        close={setChannelToRemove}
        remove={() => {
          return removeChannelToRemove()
            .then(async () => {
              refreshChannels()
                .then(() => {
                  if (channelToModifyData?.url === channelToRemove?.url) setChannelToModifyData(null)
                  setChannelToRemove(null)
                })
            })
            .catch(() => null)
        }}
        isLoading={isLoadingChannelToRemove}
      />

      <SaveChannelModal
        channel={channelToConfirmSave}
        close={() => setChannelToConfirmSave(null)}
        isLoading={isLoadingChannelToUpload || isLoadingChannelToModifyData}
        save={async () => {
          const fixedDate = fixChannelEventsOverlap(channelToConfirmSave.data).events
          return saveChannel({ ...channelToConfirmSave, data: fixedDate })
            .then(() => {
              refreshChannels()
              if (channelToModifyData?.url === channelToConfirmSave?.url) {
                setChannelToModifyData({
                  ...channelToConfirmSave,
                  data: fixedDate
                })
              }
              setChannelToConfirmSave(null)
            })
            .catch(() => null)
        }}
      />

      <UploadChannelEventModal
        channelEvent={channelEventToUpload}
        setChannelEvent={setChannelEventToUpload}
        remove={() => {
          removeEventChannelToModifyData(channelEventToUpload.id)
          setChannelEventToUpload(null)
        }}
        submit={() => {
          uploadEventChannelToModifyData(channelEventToUpload)
          setChannelEventToUpload(null)
        }}
      />

      <Modal.Info
        titleInfo={t("info.title")}
        closeText={t("info.closeText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("info.text")}
      </Modal.Info>

      <Header.BreadCrumb color="secondary" isDark={false} buttons={[
        { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
      ]}>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={4}>
            <ShowChannelsCard
              channels={channels}
              onClickChannel={(channel) => {
                if (channelToModifyData?.url !== channel?.url) setChannelToModifyData(channel)
              }}
              setChannelToPlay={setChannelToPlay}
              setChannelToUpload={setChannelToUpload}
              setChannelToRemove={setChannelToRemove}
              isLoading={isLoadingChannels}
            />
            <ShowChannelManagersCard
              channelManagers={channelManagers}
              setChannelManagerToUpload={setChannelManagerToUpload}
              setChannelManagerToRemove={setChannelManagerToRemove}
              onClickChannelManager={setChannelManagerToUpload}
              totalAmount={totalAmountChannelManagers}
              page={paginationChannelManagers.page}
              pageAmount={paginationChannelManagers.amount}
              changePage={changePageChannelManagers}
              isLoading={isLoadingChannelManagers}
            />
          </Col>
          <Col xs={12} md={8}>
            <ShowChannelEventsCalendarCard
              channel={channelToModifyData}
              setChannel={setChannelToModifyData}
              createChannel={() => setChannelToUpload({})}
              setChannelEventToUpload={setChannelEventToUpload}
              save={() => setChannelToConfirmSave(channelToModifyData)}
              createEvent={createEventChannelToModifyData}
              isLoading={isLoadingChannelToModifyData || isLoadingChannels}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Channels;