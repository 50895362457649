import { useMemo } from "react";
import useTranslator from "../useTranslator";
import { roleList } from "../../utils/roles";

const roles = roleList

const useTranslatedRoles = () => {
  const t = useTranslator("roles")

  const translatedRoles = useMemo(() => getTranslatedRoles(t), [t])
  const roleOptions = useMemo(() => getRoleOptions(t), [t])
  const getRoleOption = (v) => findRoleOption(t, v)

  return {
    roles,
    translatedRoles,
    roleOptions,
    getRoleOption
  }
}

const getTranslatedRoles = (t) => {
  return roles.reduce((translated, role) => {
    translated[role] = t(role) || role
    return translated
  }, {})
}

const getRoleOptions = (t) => {
  return roles.map((role) => ({ value: role, label: t(role) || role }))
}

const findRoleOption = (t, value) => {
  return roles.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedRoles;
export { roles, getTranslatedRoles, getRoleOptions, findRoleOption }