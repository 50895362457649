import React from "react";
import TrainingPlayer from "../../views/pages/dashboard/trainings/TrainingPlayer";
import TrainingPreview from "../../views/pages/dashboard/trainings/TrainingPreview";
import TrainingConstructor from "../../views/pages/dashboard/trainings/TrainingConstructor";
import { Navigate } from "react-router";

const subRoutes = [
  {
    path: "preview",
    component: <TrainingPreview/>
  },
  {
    path: "player",
    component: <TrainingPlayer/>
  },
  {
    path: "builder",
    component: <TrainingConstructor/>
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard"} replace={true}/>,
  }
]

const index = {
  path: "/dashboard/cycling",
  subRoutes: subRoutes
}

export default index;