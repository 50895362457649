import React, { useState } from "react";
import { Table, TableCard, TableOutput } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowTagsCard = ({
                        tags,
                        setTagToUpload,
                        setTagToRemove,
                        onClickTag,
                        isLoading
                      }) => {
  const t = useTranslator("components.tag.showTagsCard")
  const [page, setPage] = useState(1)
  const [amount, setAmount] = useState(10)

  const columns = [
    {
      name: t("name"),
      property: "name",
      isTh: true
    },
    {
      name: "",
      formattedContent: (tag) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setTagToUpload({ ...tag }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setTagToRemove({ ...tag })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          {
            children: <AddButtonChildren children={t("buttonAddText")}/>,
            onClick: () => setTagToUpload({})
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={tags.slice(((page - 1) * amount), ((page - 1) * amount) + amount)}
        onRowClick={(tag) => onClickTag({ ...tag })}
        isLoading={isLoading}
        noContentMessage={t("emptyTags")}
        rowAmount={amount}
      />
      <TableCard.Footer
        setAmount={setAmount}
        amount={amount}
        totalEntries={tags.length}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowTagsCard;