import React from "react";
import { utils, Modal, FormInput } from "@general-backoffice/core";
import moment from "moment";
import getDefaultUser from "./shared/getDefaultUser";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getCountries from "../../utils/getCountries";


const UploadUserModal = ({
                           user = null,
                           setUser = utils.noop,
                           onSubmit = utils.noop,
                           isLoading
                         }) => {
  const t = useTranslator("components.users.uploadUserModal")
  const baseT = useTranslator("")

  const {
    id,
    address,
    birthdate,
    city,
    country,
    firstName,
    lastName,
    phone,
    postalCode,
    genderType,
    email
  } = { ...getDefaultUser(), ...(user || {}) }

  const { changeState, onChangeInput: onChange } = useChangingComplexState(setUser, isLoading)


  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubmit()
  }

  const countryOptions = getCountries(t("language")).map(({ name, countryShortCode }) => ({
    label: name,
    value: countryShortCode
  }))

  const genderTypeOptions = [
    { label: baseT("genders.male"), value: "male" },
    { label: baseT("genders.female"), value: "female" },
    { label: baseT("genders.do_not_say"), value: "do_not_say" }
  ]

  return (
    <Modal.FormContainer
      isOpen={!!user}
      toggleOpen={() => setUser(null)}
      title={t("title", !!id, email)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >
      <FormInput.Email
        onChange={onChange}
        value={email}
        name="email"
        label={t("emailLabel") + "*"}
        disabled={!!id}
        required
      />

      <FormInput.Input
        onChange={onChange}
        value={firstName}
        name="firstName"
        label={t("nameLabel") + "*"}
        required
      />

      <FormInput.Input
        onChange={onChange}
        value={lastName}
        name="lastName"
        label={t("lastNameLabel") + "*"}
        required
      />

      <FormInput.Select
        label={t("genderLabel") + "*"}
        name={"genderType"}
        onChange={(option) => changeState("genderType", option?.value || option)}
        options={genderTypeOptions}
        value={genderTypeOptions.find(({ value }) => value === genderType)}
        required={true}
      />

      <FormInput.Phone
        onChange={onChange}
        value={phone}
        name="phone"
        label={t("phoneLabel")}
      />

      <FormInput.Select
        label={t("countryLabel") + "*"}
        name={"country"}
        onChange={(option) => changeState("country", option?.value || option)}
        options={countryOptions}
        value={countryOptions.find(({ value }) => value === country)}
        required={true}
      />

      <FormInput.Input
        onChange={onChange}
        value={city}
        name="city"
        label={t("cityLabel")}
      />

      <FormInput.Input
        onChange={onChange}
        value={address}
        name="address"
        label={t("addressLabel")}
      />

      <FormInput.Input
        onChange={onChange}
        value={postalCode}
        name="postalCode"
        label={t("postalCodeLabel")}
      />

      <FormInput.DatePicker
        onChange={(date) => changeState("birthdate", moment(date).format("YYYY-MM-DD"))}
        value={birthdate ? moment(birthdate) : null}
        name="birthdate"
        timeFormat={false}
        label={t("birthdateLabel") + "*"}
        required={true}
      />
    </Modal.FormContainer>
  );
}

export default UploadUserModal;