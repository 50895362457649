import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveNotificationModal = ({
                                   notification,
                                   close,
                                   remove,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.notifications.removeNotificationModal")
  const { id, title } = (notification || {});

  return (
    <Modal.DeleteConfirm
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", title)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveNotificationModal;