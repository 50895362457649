const getDefaultChannelManager = () => ({
  address: "",
  birthdate: null,
  city: "",
  country: "",
  firstName: "",
  lastName: "",
  phone: "",
  postalCode: "",
  roleList: [],
})

export default getDefaultChannelManager;