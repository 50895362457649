import React, { useEffect } from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import TransVideoCard from "../../../../components/trans/TransVideoCard";
import ShowTransCard from "../../../../components/trans/ShowTransCard";
import useTrans from "../../../../components/trans/hooks/useTrans";
import PreviewVideoModal from "../../../../components/shared/PreviewVideoModal";
import RemoveTransModal from "../../../../components/trans/RemoveTransModal";
import useOneTrans from "../../../../components/trans/hooks/useOneTrans";
import useProcessingTrans from "../../../../components/trans/hooks/useProcessingTrans";
import { useTranscodeContext } from "../../../../contexts/TranscodeWrapper";
import usePrevious from "../../../../hooks/usePrevious";

const Trans = () => {

  const { files } = useTranscodeContext()
  const prevFiles = usePrevious(files)

  const {
    trans,
    isLoading: isLoadingTrans,
    refresh: refreshTrans
  } = useTrans()

  const {
    trans: progressingTrans,
    isLoading: isLoadingProgressingTrans,
    refresh: refreshProgressingTrans
  } = useProcessingTrans()

  const {
    trans: transToPreview,
    setTrans: setTransToPreview,
  } = useOneTrans()

  const {
    trans: transToRemove,
    setTrans: setTransToRemove,
    isLoading: isLoadingTransToRemove,
    remove: removeTransToRemove
  } = useOneTrans()

  useEffect(() => {
    const prev = prevFiles || []
    const curr = files || []
    if (prev.length > curr.length) {
      refreshProgressingTrans().then(() => null)
      refreshTrans().then(() => null)
    }
    // eslint-disable-next-line
  }, [files])

  return (
    <React.Fragment>

      <PreviewVideoModal
        title={transToPreview?.filename}
        url={transToPreview?.url}
        onClose={() => setTransToPreview(null)}
        allowCopyUrl={true}
      />

      <RemoveTransModal
        trans={transToRemove}
        close={() => setTransToRemove(null)}
        remove={async () => {
          return removeTransToRemove()
            .then(() => {
              setTransToRemove(null)
              refreshTrans()
              refreshProgressingTrans()
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTransToRemove}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <TransVideoCard/>
          </Col>
          <Col xs={12}>
            <ShowTransCard
              trans={[...progressingTrans, ...trans]}
              isLoading={isLoadingTrans || isLoadingProgressingTrans}
              refresh={() => Promise.all([refreshTrans(), refreshProgressingTrans()])}
              setTransToPreview={setTransToPreview}
              setTransToRemove={setTransToRemove}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Trans;