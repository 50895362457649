import { useState } from "react";

const defaultRequestParams = {
  pageNumber: 1,
  pageSize: 10
}

const useRequestParams = (initialRequestParams = defaultRequestParams) => {
  const [requestParams, setRequestParams] = useState(initialRequestParams)


  const changePageNumber = (page = defaultRequestParams.pageNumber) => {
    setRequestParams((cpp) => ({ ...cpp, pageNumber: page }))
  }
  const changePageSize = (size = defaultRequestParams.pageSize) => {
    setRequestParams((cpp) => ({ ...cpp, pageSize: size }))
  }
  const changeRequestParam = (key, value) => {
    setRequestParams((cpp) => ({ ...cpp, [key]: value }))
  }


  return {
    requestParams,
    changePageNumber,
    changePageSize,
    changeRequestParam
  }

}

export default useRequestParams;