import React, { useState } from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import moment from "moment";
import AddButtonChildren from "../shared/AddButtonChildren";
import { useSelector } from "react-redux";
import useTranslator from "../../hooks/useTranslator";
import { userRequests } from "../../api/requests";
import { Spinner } from "reactstrap";

const ShowUsersCard = ({
                         users,
                         setUserToUpload,
                         setUserToShowHistory,
                         setUserToSubscribe,
                         setFiltersToUpload,
                         onClickUser,
                         totalAmount,
                         page,
                         changePage,
                         pageAmount,
                         isLoading
                       }) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const t = useTranslator("components.users.showUsersCard")

  const { club: userClub } = useSelector((state) => state.sessionState)

  const columns = [
    {
      name: t("email"),
      property: "email",
      isTh: true
    },
    {
      name: t("name"),
      formattedContent: ({ firstName = "", lastName }) => `${firstName || ""} ${lastName || ""}`,
    },
    {
      name: t("registerDate"),
      formattedContent: ({ registerDate }) => {
        return moment(registerDate).format("DD/MM/YYYY");
      }
    },
    {
      name: t("status"),
      formattedContent: ({ verified }) => (
        <TableOutput.Status
          status={t("statusText", verified)}
          color={verified ? "success" : "danger"}
        />
      )
    },
    {
      name: "",
      formattedContent: (user) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setUserToUpload({ ...user }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-history"/>,
            description: t("showHistoryDescription"),
            onClick: () => setUserToShowHistory({ ...user })
          }
        ]
        if (userClub?.clubProperties?.allowedToPayInCash) {
          actions.push({
            icon: <i className="fas fa-cash-register"/>,
            description: t("subscribeUserDescription"),
            onClick: () => setUserToSubscribe({ ...user })
          })
        }
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card>
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle", totalAmount)}
        buttons={[
          {
            children: isDownloading ? <Spinner size="sm"/> : <i className="fas fa-file-excel"/>,
            onClick: () => {
              setIsDownloading(true)
              userRequests.downloadCsv().finally(() => setIsDownloading(false))
            },
            disabled: isDownloading
          },
          { children: t("filterButtonText"), onClick: () => setFiltersToUpload(true) },
          { children: <AddButtonChildren children={t("addButtonText")}/>, onClick: () => setUserToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={users}
        onRowClick={(user) => onClickUser({ ...user })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={pageAmount}
      />
      <TableCard.Footer
        amount={pageAmount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => changePage((page + 1))}
        isLoading={isLoading}
      />

    </Card>
  );
}

export default ShowUsersCard;