import Api from "../Api";
import fileDownload from "js-file-download"

const getUser = (id) => Api.get(`/admin/users/${id}`);
const getUserInfo = (id) => Api.get(`/admin/users/${id}/info`);
const getUsersList = (params) => Api.get(`/admin/users`, { params });
const deleteUser = (id) => Api.delete(`/admin/users/${id}`);
const putUser = (id, body) => Api.put(`/admin/users/${id}`, body);
const postUsers = (userList) => Api.post(`/admin/users/load-users`, { importedUserList: userList || [] });
const postUser = (body) => Api.post(`/admin/users`, body);
const subscribeUser = (body) => Api.put(`/admin/subscriptions/subscription/users/pay-in-cash`, body);
const downloadUserCsv = () => {
  return Api.get(`/admin/users/download/user-list-csv`, { responseType: "blob", headers: { "Accept": "*" } })
    .then((some) => fileDownload(some, "users.csv"))
}


const userRequests = {
  get: getUser,
  getInfo: getUserInfo,
  getList: getUsersList,
  delete: deleteUser,
  put: putUser,
  postUsers: postUsers,
  post: postUser,
  subscribe: subscribeUser,
  downloadCsv: downloadUserCsv
}

export default userRequests