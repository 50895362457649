import React, { useEffect } from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import UploadProductModal from "../../../../components/product/UploadProductModal";
import useCategories from "../../../../components/category/hooks/useCategories";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import usePlans from "../../../../components/plans/hooks/usePlans";
import UploadPlanModal from "../../../../components/plans/UploadPlanModal";
import useProduct from "../../../../components/product/hooks/useProduct";
import usePlan from "../../../../components/plans/hooks/usePlan";
import useCategory from "../../../../components/category/hooks/useCategory";
import useTags from "../../../../components/tags/hooks/useTags";
import useTag from "../../../../components/tags/hooks/useTag";
import UploadTagModal from "../../../../components/tags/UploadTagModal";
import useCollections from "../../../../components/collection/hooks/useCollections";
import useCollection from "../../../../components/collection/hooks/useCollection";
import { useNavigate, useParams } from "react-router";
import UploadCollectionModal from "../../../../components/collection/UploadCollectionModal";
import ShowProductListCard from "../../../../components/product/ShowProductListCard";
import useProducts from "../../../../components/product/hooks/useProducts";

const CollectionProducts = () => {
  const navigate = useNavigate()
  const { idCollection } = useParams()

  useEffect(() => {
    if (!idCollection) navigate(-1)
    // eslint-disable-next-line
  }, [idCollection])

  const {
    products,
    refresh: refreshProducts,
    isLoading: isLoadingProducts,
  } = useProducts();

  const {
    collection,
    isLoading: isLoadingCollection,
    sortProducts: sortCollectionProducts,
    refresh: refreshCollection
  } = useCollection(parseInt(idCollection))

  const {
    collection: collectionToUpload,
    isLoading: isLoadingCollectionToUpload,
    setCollection: setCollectionToUpload,
    save: saveCollectionToUpload,
  } = useCollection()

  const {
    product: productToUpload,
    setProduct: setProductToUpload,
    isLoading: isLoadingProductToUpload,
    save: saveProductToUpload
  } = useProduct()

  const {
    plans,
    refresh: refreshPlans
  } = usePlans();

  const {
    plan: planToUpload,
    setPlan: setPlanToUpload,
    isLoading: isLoadingPlanToUpload,
    save: savePlanToUpload
  } = usePlan();

  const {
    categories,
    refresh: refreshCategories,
  } = useCategories();

  const {
    category: categoryToUpload,
    setCategory: setCategoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    save: saveCategoryToUpload
  } = useCategory()

  const {
    tags,
    refresh: refreshTags
  } = useTags()

  const {
    tag: tagToUpload,
    save: saveTagToUpload,
    setTag: setTagToUpload,
    isLoading: isLoadingTagToUpload
  } = useTag()

  const {
    collections,
    refresh: refreshCollections
  } = useCollections()

  const isLoading = isLoadingCollection || isLoadingProducts


  const collectionProducts = (collection?.productIdList || []).reduce((cps, idCp) => {
    const productToAdd = products.find(({ id }) => id === idCp)
    if (!!productToAdd) return [...cps, productToAdd]
    return cps
  }, [])

  const notAssociatedProducts = products.filter(({ id }) => !(collection?.productIdList || []).includes(id))

  return (
    <React.Fragment>

      <UploadPlanModal
        plan={planToUpload}
        setPlan={setPlanToUpload}
        isLoading={isLoadingPlanToUpload}
        onSubmit={async () => {
          return savePlanToUpload()
            .then(() => {
              refreshPlans()
              setPlanToUpload(null)
            })
            .catch(() => null)
        }}
      />

      <UploadCollectionModal
        collection={collectionToUpload}
        setCollection={setCollectionToUpload}
        isLoading={isLoadingCollectionToUpload}
        onSubmit={async () => {
          return saveCollectionToUpload()
            .then(() => {
              refreshCollections()
              setCollectionToUpload(null)
            })
            .catch(() => null)
        }}
        categories={categories}
        setCategoryToUpload={setCategoryToUpload}
      />

      <UploadProductModal
        product={productToUpload}
        setProduct={setProductToUpload}
        categories={categories}
        tags={tags}
        plans={plans}
        setCategoryToUpload={setCategoryToUpload}
        setTagToUpload={setTagToUpload}
        setPlanToUpload={setPlanToUpload}
        isLoading={isLoadingProductToUpload}
        collections={collections}
        setCollectionToUpload={setCollectionToUpload}
        onSubmit={async () => {
          return saveProductToUpload()
            .then(() => {
              setProductToUpload(null)
              refreshProducts()
              refreshCollection()
            })
            .catch(() => null)
        }}
      />

      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <UploadTagModal
        tag={tagToUpload}
        setTag={setTagToUpload}
        onSubmit={async () => {
          return saveTagToUpload()
            .then(() => {
              refreshTags()
              setTagToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTagToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowProductListCard
              title={collection?.title}
              products={collectionProducts}
              productOptions={notAssociatedProducts}
              isLoading={isLoading}
              setProductToUpload={setProductToUpload}
              onClickProduct={setProductToUpload}
              sortProducts={sortCollectionProducts}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CollectionProducts;