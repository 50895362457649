import React from "react";
import { motion } from 'framer-motion/dist/framer-motion'
import { colors } from "../../config"

const GroupTimeBar = ({ currentSegment, currentTime, groups, width = "100%", height = 10 }) => {
  let { start, end, zone } = groups?.[currentSegment?.groupIndex] || {}
  let passedSeconds = currentTime?.totalSeconds - start
  let coveredPercentage = Math.min((passedSeconds / (end - start)) * 100, 100)
  
  return (
    <div 
      style={{width, height, backgroundColor: zone?.colors?.main || colors.empty}} 
      className='position-relative'
    >
      {coveredPercentage >= 0 && 
        <motion.div 
          className={`position-absolute h-100`} 
          style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.4)' }}
          initial={{ width: '0%'}}
          animate={{ width: `${coveredPercentage}%` }}
          transition={passedSeconds > 0 ? { duration: 1, ease: 'linear' } : { duration: 0 }}
        />
      }
    </div>
  ) 
}

export default GroupTimeBar;