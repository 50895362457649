import moment from "moment";

const getDefaultNew = () => ({
  description: "",
  image: { url: null, data: null, contentType: null },
  publicationDate: moment().format("YYYY-MM-DD"),
  published: true,
  title: "",
  video: { hlsUrl: "" },
})

export default getDefaultNew;