import getCountries from "../utils/getCountries";
import enLocale from "@fullcalendar/core/locales/en-au";
import moment from "moment";
import React from "react";

const roles = {
  OWNER: "Owner",
  MANAGER: "Manager",
  ACCOUNT_MANAGER: "Account Manager",
  UPLOADER: "Uploader",
  TRAINER: "Trainer",
  TRAINER_PUBLISHER: "Trainer Publisher",
  CHANNEL_MANAGER: "Channel Manager"
}

const intervals = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
  LIFE: "For life"
}

const genders = {
  male: "Male",
  female: "Female",
  do_not_say: "I prefer not to say"
}

const currencies = {
  EUR: "€",
  USD: "$"
}

const complexCurrencies = {
  EUR: "EUR (€)",
  USD: "USD ($)"
}

const intensities = {
  null: "Not specified",
  BEGINNER: "Beginner",
  MEDIUM: "Intermediate",
  ADVANCED: "Advanced",
}

const levels = {
  null: "Not specified",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High"
}

const notificationTypes = {
  DEFAULT: "Default",
  PRODUCT: "Product",
  NEW: "News",
  PROFILE: "Profile",
  SUBSCRIPTION: "Subscription",
}

const planTypes = {
  REGULAR: "Online Plan",
  RENTAL: "Rental Plan",
  SINGLE_PAYMENT: "One-time Payment Plan",
  PAY_IN_CASH: "Cash Plan",
}

const categoryTypes = {
  PRODUCT: "Product",
  COLLECTION: "Collection",
  AUTOMATIC: "Automatic"
}

const collectionTypes = {
  personal: "Personal/Profile",
  product: "Standard"
}

const producerTypes = {
  TRAINER: "Trainer",
  INSTRUCTOR: "Instructor",
  NUTRITIONIST: "Nutritionist",
  PHYSIOTHERAPIST: "Physiotherapist",
  COACH: "Coach",
  DOCTOR: "Doctor",
}

const paymentMethodTypes = {
  card: "Card",
  sepa: "Bank Account"
}

const navigationItemTypes = {
  "_blank": "New tab",
  "_self": "Same tab"
}

const advertisementPosition = {
  "login": "Banner on the login page",
  "product": "Pop Up on the product page",
  "category": "Banner on the category page and slider",
  "collection": "Pop Up on the collection page",
  "footer": "Banner in the footer across the entire website"
}


const en = {
  language: "en",
  views: {
    layouts: {
      dashboard: {
        sessionOptionsHeader: "Welcome!",
        notificationsHeader: (number) => number > 0 ? `You have ${number} ${number === 1 ? "pending notification" : "pending notifications"}` : "You have no pending notifications"
      }
    },
    pages: {
      auth: {
        forgottenPassword: {
          emailPlaceholder: "Email",
          invalidEmailFeedbackText: "Enter a valid email",
          lead: "Please enter an email to request a password change",
          loginText: "Log in",
          registerText: "",
          submitText: "Request",
          title: "Request a new password",
          success: "Request for new password has been made! You will receive an email shortly",
          error: "Unable to request a new password"
        },
        login: {
          emailPlaceholder: "Email",
          forgottenPasswordText: "Forgot Password?",
          invalidEmailFeedbackText: "Enter a valid email",
          invalidPasswordFeedbackText: "Enter a valid password",
          lead: "Please log in to access the control panel",
          passwordPlaceholder: "Password",
          registerText: "",
          rememberLabel: "Remember me",
          submitText: "Log in",
          title: "Welcome!",
          success: "Welcome!",
          error: "Incorrect username or password"
        },
        resetPassword: {
          invalidPasswordConfirmFeedbackText: "Passwords do not match",
          invalidPasswordFeedbackText: "Enter a valid password",
          lead: "Please choose a new password",
          loginText: "Log in",
          passwordConfirmPlaceholder: "Confirm password",
          passwordPlaceholder: "Password",
          registerText: "",
          submitText: "Change password",
          title: "Change Password",
          codePlaceholder: "Code received by email. e.g. 141n5l8v",
          buttonModalConfirmationText: "Reset password",
          success: "Password changed successfully",
          error: "Unable to change the password"
        }
      },
      dashboard: {
        channels: {
          info: {
            title: "Info: Channels",
            closeText: "Understood",
            text: <>
              <p>
                Here you can manage your channels and create new ones.
              </p>
              <p>
                In the calendar, you can navigate through different weeks and days by clicking on the date above.
                <br/>
                You can create an event by clicking on an empty space on the calendar or modify it by clicking on it
                or dragging it to change the dates.
              </p>
              <p>
                Once you have finished, you can click on the "Correct" button to automatically correct events that
                coincide in dates or overlap. This process is also performed when saving.
              </p>
            </>
          }
        },
        clubs: {
          stats: {
            activeTitle: "Active Clubs"
          }
        },
        clubSubscriptions: {
          buttonAddSubscription: "Subscription",
          buttonAddPayment: "Payment",
          modifyBillingInformationWithoutPaymentMethodError: "A payment method is required to create/modify billing information."
        },
        meeting: {
          leavingWarning: "Meeting abandoned",
          connectionError: "The meeting could not be obtained"
        },
        onDemand: {
          teamSections: {
            title: (teamName) => `Team sections (${teamName})`,
          },
          stats: {
            numberOfTeams: {
              title: "Teams",
              description: "Total number of teams",
              suffix: (number) => number === 1 ? " team" : " teams"
            },
            numberOfProducts: {
              title: "Products",
              description: "Total number of products",
              suffix: (number) => number === 1 ? " product" : " products"
            },
            numberOfSharedProducts: {
              title: "Shared Products",
              description: "Total number of shared products",
              suffix: (number) => number === 1 ? " product" : " products"
            },
            numberOfCategories: {
              title: "Categories",
              description: "Total number of categories",
              suffix: (number) => number === 1 ? " category" : " categories"
            }
          }
        },
        trainings: {
          trainingPlayer: {
            error: "Error getting the training"
          }
        },
        users: {
          userSubscribedSuccess: "User subscribed successfully"
        },
        advertisement: {
          save: "Save",
          advertisementImage: "Advertisement Image",
          titleLabel: "Title",
          descriptionLabel: "Description",
          urlRedirectLabel: "Redirect URL",
          scriptLabel: "External Advertising Script",
          isActiveLabel: "Is it published?",
          isActiveAnswers: ["Yes", "No"],
          isScriptLabel: "Use external advertising?",
          isScriptAnswers: ["Yes", "No"],
          saving: "Saving...",
          savingSuccess: "Saved successfully",
          savingError: "Error saving",
          advertisementPosition: advertisementPosition,
          advertisementPositionLabel: "Position",
          productsLabel: "Products",
          noProductOptionsText: "No products available",
          categoriesLabel: "Category",
          noCategoryOptionsText: "No categories available",
          collectionsLabel: "Collection",
          noCollectionOptionsText: "No collections available",
        },
        advertisements: {
          titleLoginAdvertisement: "Login advertisement",
          subtitleLoginAdvertisement: "Advertisement showed on login page",
          titleFooterAdvertisement: "Footer advertisement",
          subtitleFooterAdvertisement: "Advertisement showed on footer across the pages",
          removing: "Removing...",
          removingSuccess: "Successfully removed",
          removingError: "Error removing",
        }
      }
    }
  },
  components: {
    team: {
      hooks: {
        useTeams: {
          getTeamsError: "Error on retrieve the teams"
        },
        useTeam: {
          getTeamError: "Error on retrieve the team",
          sortTeamSectionsLoading: "Sorting sections...",
          sortTeamSectionsSucceed: "Sections sorted correctly",
          sortTeamSectionsError: "Error sorting sections",
          saveTeamLoading: (isModifying) => isModifying ? "Modifying team..." : "Creating team...",
          saveTeamSucceed: (isModifying) => isModifying ? "Team modified successfully" : "Team created successfully",
          saveTeamError: "Error creating/modifying team",
          removeTeamLoading: "Removing team...",
          removeTeamSucceed: "Team removed successfully",
          removeTeamError: "Could not remove team",
        }
      },
      showTeamsCard: {
        team: "Team",
        name: "Name",
        colors: "Colors",
        modifyDescription: "Edit team",
        modifyContentDescription: "Edit content",
        removeDescription: "Remove team",
        noContent: "No sections",
        numberSections: (number) => `${number} ${number === 1 ? "Section" : "Sections"}`,
        title: "Teams",
        addTeamButtonText: "Team",
        addTeamButtonDescription: "Add team",
        searchPlaceholder: "Search team",
        emptyCategories: "No teams"
      },
      uploadTeamModal: {
        title: (isModifying, name) => !!isModifying ? `Edit ${name}` : "Create new team",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        shortNameLabel: "Short name",
        imageLabel: "Crest",
        jerseyHomeColorLabel: "Home jersey color",
        jerseyAwayColorLabel: "Away jersey color",
        tooLongShortNameErrorText: "Short name too long",
        tooLongShortNameError: "Name cannot contain more than 5 characters"
      }
    },
    teamSection: {
      hooks: {
        useTeamSection: {
          getTeamSectionError: "Error on retrieve team section",
          removeTeamSectionLoading: "Removing...",
          removeTeamSectionSucceed: "Coupon removed successfully",
          removeTeamSectionError: "Error on removing team section",
          saveTeamSectionLoading: (isModifying) => isModifying ? "Modifying team section..." : "Creating team section...",
          saveTeamSectionSucceed: (isModifying) => isModifying ? "Section modified" : "Section created",
          saveTeamSectionError: "Error on save team section"
        }
      },
      showTeamSectionListCard: {
        title: "Team Sections",
        subtitle: "Drag and drop the collections to sort",
        name: "Name",
        modifyDescription: "Edit section",
        removeDescription: "Remove section",
        emptySections: "No sections",
        addButtonText: "Section",
        published: "Published",
        unpublished: "No published",
        numberCollections: (number) => `${number} ${number === 1 ? "Collection" : "Collections"}`,
      },
      uploadTeamSectionModal: {
        title: (isModifying, name) => !!isModifying ? `Edit ${name}` : "Create new section",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        isPublishedLabel: "Is published?",
        isPublishedAnswers: ["Yes", "No"],
        collectionsLabel: "Collections",
        collectionCreatingFormatText: (value) => `Create new collection "${value}"`,
        noCollectionOptionsText: "No collections exist",
      }
    },
    coupons: {
      hooks: {
        useCoupons: {
          getCouponsError: "Error on retrieve coupons"
        },
        useCoupon: {
          getCouponError: "Error on retrieve coupon",
          removeCouponLoading: "Removing...",
          removeCouponSucceed: "Coupon removed successfully",
          removeCouponError: "Error on removing coupon",
          saveCouponLoading: (isModifying) => isModifying ? "Modifying coupon..." : "Creating coupon...",
          saveCouponSucceed: (isModifying) => isModifying ? "Coupon modified" : "Coupon created",
          saveCouponError: "Error on save coupon"
        }
      },
      showCouponListCard: {
        title: "Coupons",
        subtitle: "Create and control discount coupons",
        empty: "No coupons available",
        code: "Code",
        name: "Name",
        discount: "Discount",
        duration: "Duration",
        durationForever: "Forever",
        durationOnce: "Once",
        durationRepeating: (months) => `${months} months`,
        modifyDescription: "Edit coupon",
        removeDescription: "Remove coupon",
        addCouponButtonText: "Coupon",
        addPlanButtonDescription: "Add a coupon",
        timesRedeemed: "Used"
      },
      uploadCouponModal: {
        title: (isModifying, name) => isModifying ? `Edit ${name}` : "Create new coupon",
        closeText: "Cancel",
        buttonSaveText: "Save",
        codeLabel: "Code",
        nameLabel: "Nombre",
        isPercentageLabel: "Is a percentage?",
        isPercentageAnswer: ["Yes", "No"],
        amountOffLabel: "Amount",
        percentOffLabel: "Percentage (%)",
        durationLabel: "Duration",
        foreverDuration: "Forever",
        onceDuration: "Once",
        repeatingDuration: "Repeating",
        durationInMonthsLabel: "Duration in months",
        maxRedemptionsLabel: "Num max of redemptions"
      }
    },
    advertising: {
      showAdvertisementsCard: {
        advertisement: "Advertisement",
        description: "Description",
        location: "Location",
        type: "Type",
        status: "Status",
        statusText: (published) => published ? "Published" : "Not published",
        modifyDescription: "Edit advertisement",
        removeDescription: "Remove advertisement",
        title: "Advertisements",
        addButtonText: "Advertisement",
        empty: "No advertisements",
        locationValue: (loc) => advertisementPosition[loc]
      },
      advertisementCard: {
        edit: "Modify",
        remove: "Remove",
        unused: "Unused",
        loading: "Loading...",
        statusText: (published) => published ? "Publicado" : "No publicado",
      }
    },
    collection: {
      hooks: {
        useCollections: {
          getCollectionsError: "Error while fetching collections"
        },
        useCollection: {
          getCollectionError: "Error while fetching collection",
          saveCollectionLoading: (isModifying) => isModifying ? "Modifying collection..." : "Creating collection...",
          saveCollectionSucceed: (isModifying) => isModifying ? "Collection modified" : "Collection created",
          saveCollectionError: "Error while modifying/creating collection",
          removeCollectionLoading: "Removing collection...",
          removeCollectionSucceed: "Collection removed successfully",
          removeCollectionError: "Error while removing collection",
        }
      },
      showCollectionListCard: {
        collectionTitle: "Collection",
        modifyDescription: "Modify collection",
        removeDescription: "Remove collection from this list",
        showProductsDescription: "Show products",
        title: (title) => title ? `Collections of ${title}` : "Collections",
        subtitle: "Drag and drop the collections to sort",
        collectionCreatingFormatText: (inputValue) => `Create new collection "${inputValue}"`,
        noCollectionOptionsText: "There are no collections, write to create a new collection",
        selectCollectionPlaceholder: "Add or create",
        emptyCollections: "No collections found on this list"
      },
      showCollectionsCard: {
        collectionTitle: "Collection",
        numProducts: "Num. products",
        modifyDescription: "Modify collection",
        removeDescription: "Remove collection",
        showProductsDescription: "Show products",
        title: "Collections",
        buttonAddText: "Collection",
        emptyCollections: "No collections found"
      },
      uploadCollectionModal: {
        thumbnailTooltip: "Vertical collection thumbnail can appear as cover art in categories.",
        imageTooltip: "The collection image can appear on the collection page as a cover",
        title: (isModifying, name) => isModifying ? `Modify ${name}` : "Create collection",
        closeText: "Cancel",
        titleLabel: "Title",
        subtitleLabel: "Subtitle",
        descriptionLabel: "Description",
        buttonSaveText: "Save",
        tooLongTitleError: "Title too long",
        tooLongTitleErrorText: "The title cannot exceed 30 characters",
        tooLongSubtitleError: "Subtitle too long",
        tooLongSubtitleErrorText: "The subtitle cannot exceed 30 characters",
        typeLabel: "Interface",
        categoriesLabel: "Categories",
        categoryCreatingFormatText: (value) => `Create new category "${value}"`,
        noCategoryOptionsText: "There are no categories, write to create a new category",
        thumbnailLabel: "Thumbnail portrait",
        imageLabel: "Image",
      },
      removeCollectionModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (collectionTitle = "") => `You are about to permanently delete ${collectionTitle}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      }
    },
    slider: {
      hooks: {
        useSlides: {
          getSlidesError: "Error getting slides",
          sortSliderError: "Error sorting slides",
          sortSliderLoading: "Sorting slides...",
          sortSliderSucceed: "Slides sorted successfully"
        },
        useTag: {
          getSlideError: "Error while getting the slide",
          saveSlideLoading: (isModifying) => isModifying ? "Modifying slide..." : "Creating slide...",
          saveSlideSucceed: (isModifying) => isModifying ? "Slide modified successfully" : "Slide created successfully",
          saveSlideError: "Error while modifying/creating the slide",
          removeSlideLoading: "Removing slide...",
          removeSlideSucceed: "Slide removed successfully",
          removeSlideError: "Error while removing the slide"
        }
      },
      showSlidesCard: {
        name: "Title",
        subtitle: "Drag and drop the slides to sort",
        modifyDescription: "Modify slide",
        removeDescription: "Remove slide",
        title: "Slides",
        images: "Images",
        buttonAddText: "Slide",
        emptyContent: "No slides available"
      },
      uploadSlideModal: {
        tooLongTitleError: "The title must contain a maximum of 30 characters",
        tooLongSubtitleError: "The subtitle must contain a maximum of 30 characters",
        title: (isModifying, name) => isModifying ? `Modify ${name}` : "Create New Content",
        closeText: "Cancel",
        buttonSaveText: "Save",
        titleLabel: "Title",
        titleErrorNumMaxChars: "The title must contain a maximum of 30 characters",
        subtitleLabel: "Subtitle",
        subtitleErrorNumMaxChar: "The subtitle must contain a maximum of 50 characters",
        imageLabel: "Image",
        imageTooltip: "The content's image will appear on the homepage slider on computers and tablets.",
        imagePortraitLabel: "Vertical Image",
        imagePortraitTooltip: "The vertical image of the content will appear on the homepage slider on mobile devices and the app.",
        urlLabel: "Redirect link"
      },
      removeSlideModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (SlideTitle = "") => `You are about to permanently delete ${SlideTitle}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      }
    },
    tag: {
      hooks: {
        useTags: {
          getTagsError: "Error getting categories"
        },
        useTag: {
          getTagError: "Error while getting the tag",
          saveTagLoading: (isModifying) => isModifying ? "Modifying tag..." : "Creating tag...",
          saveTagSucceed: (isModifying) => isModifying ? "Tag modified successfully" : "Tag created successfully",
          saveTagError: "Error while modifying/creating the tag",
          removeTagLoading: "Removing tag...",
          removeTagSucceed: "Tag removed successfully",
          removeTagError: "Error while removing the tag"
        }
      },
      showTagsCard: {
        name: "Name",
        modifyDescription: "Modify tag",
        removeDescription: "Remove tag",
        title: "Tags",
        buttonAddText: "Tag",
        emptyTags: "No tags found"
      },
      uploadTagModal: {
        tooLongNameError: "Name too long",
        title: (isModifying, name) => isModifying ? `Modify ${name}` : "Create new tag",
        closeText: "Cancel",
        nameLabel: "Name",
        buttonSaveText: "Save",
        tooLongNameErrorText: "The name cannot exceed 30 characters"
      },
      removeTagModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (tagName = "") => `You are about to permanently delete ${tagName}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      }
    },
    shared: {
      removeModal: {
        title: "Delete Confirmation",
        content: "You are about to proceed with the deletion of this item. This action is irreversible. Please confirm your decision to continue.",
        cancel: "Cancel",
        accept: "Accept and Delete"
      },
      qrModal: {
        downloadQr: "Download QR"
      },
      previewVideoModal: {
        copyUrl: "Copy URL",
        copyUrlSucceed: "URL copied to clipboard",
      },
      resizeImageFileModal: {
        adaptImageLoading: "Adapting image...",
        adaptImageSucceed: "Image adapted successfully!",
        adaptImageError: "Error adapting the image",
        title: (width, height) => `Crop image (${width} x ${height})`,
        closeText: "Cancel",
        buttonSaveText: "Save"
      },
      uploadStatsPeriodModal: {
        customPeriod: "Custom Period",
        lastWeek: "Last week",
        last10Days: "Last 10 days",
        last2Weeks: "Last 2 weeks",
        last3Weeks: "Last 3 weeks",
        last4Weeks: "Last 4 weeks",
        lastMonth: "Last month",
        lastYear: "Last year",
        initOfTimes: "Beginning of times",
        DAY: "Day",
        WEEK: "Week",
        MONTH: "Month",
        YEAR: "Year",
        periodAppliedSucceed: "Period applied successfully",
        title: "Modify period",
        closeText: "Close",
        buttonSaveText: "Apply period",
        periodLabel: "Period",
        intervalLabel: "Interval",
        initLabel: "Start",
        endLabel: "End"
      },
      videoCall: {
        accessVideoCallLoading: "Loading video call...",
        accessVideoCallError: "Couldn't access the video call",
      }
    },
    billingData: {
      hooks: {
        useBillingData: {
          getBillingDataError: "Error loading billing data",
          updateBillingDataLoading: "Updating billing data...",
          updateBillingDataError: "Error updating billing data",
          updateBillingDataSucceed: "Billing data updated successfully"
        }
      },
      showBillingDataCard: {
        title: "Billing data",
        addModifyButton: (isModifying) => isModifying ? "Modify" : "Add",
        companyName: "Company name",
        nif: "Tax ID",
        address1: "Address line 1",
        address2: "Address line 2",
        city: "City",
        state: "State/Province",
        country: "Country",
        postalCode: "Postal code"
      },
      updateBillingDataModal: {
        requiredFieldError: "Required field",
        title: "Billing data",
        closeText: "Close",
        buttonSaveText: "Save",
        companyNameLabel: "Company name",
        nifLabel: "Tax ID",
        address1Label: "Address line 1",
        address2Label: "Address line 2 (optional)",
        cityLabel: "City",
        stateLabel: "State/Province",
        countryLabel: "Country",
        postalCodeLabel: "Postal code"
      }
    },
    navigation: {
      hooks: {
        useNavigationItems: {
          getNavigationItemsError: "Error fetching menu links",
          sortNavigationItemsLoading: "Sorting menu...",
          sortNavigationItemsSucceed: "Menu sorted",
          sortNavigationItemsError: "Error sorting menu"
        },
        useNavigationItem: {
          getNavigationItemError: "Error retrieving link",
          saveNavigationItemLoading: "Saving link...",
          saveNavigationItemSucceed: (isUpdating) => isUpdating ? "Link updated" : "Link saved",
          saveNavigationItemError: "Error saving link",
          removeNavigationItemLoading: "Removing link...",
          removeNavigationItemSucceed: "Link removed",
          removeNavigationItemError: "Error removing link",
        }
      },
      showNavigationItemsCard: {
        title: "Navigation Menu",
        subtitle: "Create, modify, and reorder menu links",
        emptyNavigationItems: "No links in menu",
        text: "Text",
        url: "URL",
        type: "Type",
        types: navigationItemTypes,
        modifyDescription: "Modify link",
        removeDescription: "Remove link",
        published: "Published",
        unpublished: "Unpublished",
        predefined: "This link is predefined in the system and cannot be deleted.",
        buttonAddText: "Add Link"
      },
      uploadNavigationItemModal: {
        title: (isModifying, text) => !!isModifying ? `Edit ${text}` : "Create New Link",
        closeText: "Cancel",
        buttonSaveText: "Save",
        textLabel: "Text",
        typeLabel: "Type",
        urlLabel: "URL",
        isPublishedLabel: "Is published?",
        isPublishedAnswers: ["Yes", "No"],
      },
      removeNavigationItemModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (navigationLinkText = "") => `You are about to permanently delete ${navigationLinkText}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      }
    },
    category: {
      hooks: {
        useCategories: {
          getCategoriesError: "Error getting categories",
          sortCategoriesError: "Error sorting categories",
          sortCategoriesLoading: "Sorting categories...",
          sortCategoriesSucceed: "Categories sorted successfully"
        },
        useCategory: {
          getCategoryError: "Could not get category",
          sortCategoryProductsLoading: "Sorting productos...",
          sortCategoryProductsSucceed: "Products sorted correctly",
          sortCategoryProductsError: "Error sorting products",
          sortCategoryCollectionsLoading: "Sorting colecciones...",
          sortCategoryCollectionsSucceed: "Collections sorted correctly",
          sortCategoryCollectionsError: "Error sorting collections",
          saveCategoryLoading: (isModifying) => isModifying ? "Modifying category..." : "Creating category...",
          saveCategorySucceed: (isModifying) => isModifying ? "Category modified successfully" : "Category created successfully",
          saveCategoryError: "Error creating/modifying category",
          removeCategoryLoading: "Removing category...",
          removeCategorySucceed: "Category removed successfully",
          removeCategoryError: "Could not remove category",
        }
      },
      removeCategoryModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (categoryName = "") => `You are about to permanently delete ${categoryName}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showCategoriesCard: {
        name: "Name",
        published: "Published",
        unpublished: "No published",
        modifyDescription: "Edit category",
        modifyContentDescription: "Edit content",
        removeDescription: "Remove category",
        type: "Type",
        types: (type) => categoryTypes[type],
        noContent: "No content",
        numberProducts: (number) => `${number} ${number === 1 ? "Product" : "Products"}`,
        numberCollections: (number) => `${number} ${number === 1 ? "Collection" : "Collections"}`,
        title: "Categories",
        subtitle: "Drag and drop to sort",
        buttonAddText: "Category",
        emptyCategories: "No categories"
      },
      uploadCategoryModal: {
        tooLongNameError: "Name too long",
        title: (isModifying, name) => !!isModifying ? `Edit ${name}` : "Create new category",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        typeLabel: "Type",
        typeProduct: "Product",
        typeCollection: "Collection",
        isPublishedLabel: "Is published?",
        isPublishedAnswers: ["Yes", "No"],
        tooLongNameErrorText: "Name cannot contain more than 30 characters",
        tooltipTypeUpdateWithContent: "Category type cannot be modified if it contains products or collections"
      }
    },
    channelManagers: {
      hooks: {
        useChannelManager: {
          getChannelManagerError: "Could not get channel manager",
          saveChannelManagerLoading: (isModifying) => isModifying ? "Modifying channel manager..." : "Creating channel manager...",
          saveChannelManagerSucceed: (isModifying) => isModifying ? "Channel manager modified successfully" : "Channel manager created successfully",
          saveChannelManagerError: "Error creating/modifying channel manager",
          removeChannelManagerLoading: "Removing channel manager...",
          removeChannelManagerSucceed: "Channel manager removed successfully",
          removeChannelManagerError: "Error removing channel manager"
        },
        useChannelManagers: {
          getChannelManagersError: "Error getting channel managers"
        }
      },
      removeChannelManagerModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (email = "") => `You are about to remove channel manager privileges for ${email}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showChannelManagersCard: {
        email: "Email",
        name: "Name",
        modifyDescription: "Edit channel manager",
        removeDescription: "Remove channel manager privileges",
        title: "Channel Managers",
        buttonAddAdminText: "Add",
        emptyAdmins: "No channel managers"
      },
      uploadChannelManagerModal: {
        title: (isModifying, email) => !!isModifying ? `Edit ${email}` : "Create new channel manager",
        closeText: "Cancel",
        buttonSaveText: "Save",
        emailLabel: "Email",
        nameLabel: "Name",
        lastNameLabel: "Last Name"
      }
    },
    channels: {
      hooks: {
        useChannel: {
          getChannelError: "Failed to retrieve channel",
          saveChannelLoading: (isModifying) => !!isModifying ? "Modifying channel..." : "Creating channel...",
          saveChannelSucceed: (isModifying) => !!isModifying ? "Channel modified successfully" : "Channel created successfully",
          saveChannelError: "Error creating/modifying channel",
          removeChannelLoading: "Removing channel...",
          removeChannelSucceed: "Channel removed successfully",
          removeChannelError: "Error removing channel"
        },
        useChannels: {
          getChannelsError: "Error retrieving channels"
        }
      },
      previewChannelModal: {
        title: (name) => `${name} live`
      },
      removeChannelModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (name = "") => `You are about to permanently delete ${name || ""}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      saveChannelModal: {
        titleSave: "Are you sure you want to save?",
        descriptionSave: "By saving, events will go through a correction process that avoids overlaps. Some events may be moved, do you want to continue?",
        closeText: "Cancel",
        buttonSaveText: "Correct and Save"
      },
      showChannelEventsCalendarCard: {
        fixOverlapChannelEventsSucceed: (number) => `Fixed ${number} events`,
        buttonFixText: "Fix",
        buttonUploadCsv: "Add CSV",
        buttonSaveText: "Save",
        buttonNowText: "Now",
        buttonCreateChannelText: "Create Channel",
        errorOnUploadCsv: (number = 0) => `${number} ${number === 1 ? "file" : "files"} not found:`,
        errorDescriptionOnUploadCsv: "Transcode the missing videos and re-upload the csv",
        uploadCsvSucceed: "Channel modified. Check the content and save to avoid losing progress.",
        uploadCsvError: "Error on upload CSV. Check the csv document before the upload",
        textTemplateCsv: (template) => <div>Download <a download={"template.csv"} href={template}>here</a> the CSV
          template. Do not delete the first line with the definition of the columns.</div>
      },
      showChannelsCard: {
        name: "Name",
        nameNew: "New",
        playDescription: "Play Channel",
        modifyDescription: "Edit Properties",
        removeDescription: "Remove Channel",
        title: "Channels",
        subtitle: "Click to modify content",
        buttonAddChannel: "Channel",
        emptyChannels: "No channels",
        copyUrlButtonText: "Copy URL",
        copiedUrl: "URL copied to clipboard",
        saveToContinue: "Generating..."
      },
      uploadChannelEventModal: {
        getDurationError: "Failed to retrieve transcoding duration",
        previewTitle: "Preview",
        title: (isModifying) => !!isModifying ? "Edit event" : "Create event",
        closeText: "Close",
        buttonSaveText: "Save",
        videoLabel: "Video",
        initialTimeLabel: "Start",
        finalTimeLabel: "End",
        loadingFinalTimeText: "Calculating duration..."
      },
      uploadChannelModal: {
        title: (isModifying) => isModifying ? "Modify channel" : "Create channel",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        placeholderUrlLabel: "Screensaver URL",
        isLoopLabel: "Is a loop?",
        isLoopAnswer: ["Yes", "No"]
      }
    },
    clubConfig: {
      save: "Save",
      hooks: {
        useClubConfig: {
          getClubConfigError: "Oops! could not access configuration",
          saveClubConfigLoading: "Modifying configuration...",
          saveClubConfigSucceed: "Club configuration modified successfully",
          saveClubConfigError: "Error modifying club configuration"
        }
      },
      changeClubStructureCard: {
        title: "Parameters",
        subtitle: "Modify some parameters of the website",
        saveButton: "Save",
        showAddedRecentlyCategoryLabel: "Show recently added?",
        showAddedRecentlyCategoryAnswer: ["Yes", "No"],
        showTitlesOnCollectionThumbnailLabel: "Show titles on collection thumbnails?",
        showTitlesOnCollectionThumbnailAnswer: ["Yes", "No"],
        showTitlesOnNewThumbnailLabel: "Show titles on news thumbnails?",
        showTitlesOnNewThumbnailAnswer: ["Yes", "No"],
        showTitlesOnPanoramicLabel: "Show titles on panoramic (sliders)?",
        showTitlesOnPanoramicAnswer: ["Yes", "No"],
        showTitlesOnProductThumbnailLabel: "Show titles on product thumbnails?",
        showTitlesOnProductThumbnailAnswer: ["Yes", "No"],
        showTitlesOnTrainerThumbnailLabel: "Show titles on trainer thumbnails?",
        showTitlesOnTrainerThumbnailAnswer: ["Yes", "No"]
      },
      changeClubSeoCard: {
        title: "Seo",
        subtitle: "Modify the information for the search engines",
        faviconLabel: "favicon (.ico)",
        faviconTooltip: "It is the logo that appears next to the title in the browser tab",
        titleLabel: "Title",
        titleTooltip: "Short text that describes the main topic of a website",
        descriptionLabel: "Description",
        descriptionTooltip: "Text that appears below the blue link for a result on the results page. Its purpose is to describe the content of the page to make it easier for the searcher to choose your site over another.",
        googleAnalyticsMeasurementIdLabel: "Google analytics ID",
        googleAnalyticsMeasurementIdTooltip: "Identifier provided by Google that allows Google Analytics to track the website (Statistics of entries, devices, country of visit, ...)",
        imageOptimizationLoading: "Optimizing image...",
        imageOptimizationSucceed: "Image optimized!",
      },
      changeClubLogosCard: {
        title: "Logos",
        subtitle: "Modify the logos that appears on the platform",
        imageLight: "Light logo",
        imageDark: "Dark logo",
        imageSquareLight: "Square light logo",
        imageSquareDark: "Square dark logo",
        imageCompressionLoading: "Compressing image...",
        imageCompressionSucceed: "Image compressed!",
        imageLightTooltip: "Rectangular logo in .png format of the club with a transparent background. Colors should contrast with a black background. It is mainly used as a navigation logo in the top left of the website.",
        imageSquareLightTooltip: "Square logo in .png format of the club with a transparent background. Colors should contrast with a black background. It is mainly used as a presentation image on the login, registration, ... page.",
        imageDarkTooltip: "Rectangular logo in .png format of the club with a transparent background. Colors should contrast with a white background. It is mainly used as a logo in automated email delivery.",
        imageDarkLightTooltip: "Square logo in .png format of the club with a transparent background. Colors should contrast with a white background. It is used as an alternative in some cases."
      },
      changeClubColorsCard: {
        title: "Colors",
        subtitle: "Modify the colors that appears on the platform",
        useDarkModeLabel: "Use dark mode?",
        useDarkModeAnswer: ["Yes", "No"],
        primaryColorLabel: "Primary color",
        primaryColorTooltip: "The selected color will appear as the primary color in buttons, selectors, and some other components of the website."
      }
    },
    clubs: {
      hooks: {
        useClub: {
          getClubError: "Failed to retrieve the club",
          removeClubLoading: "Removing club...",
          removeClubSucceed: "Club removed successfully",
          removeClubError: "Failed to remove the club",
          saveClubLoading: (isModifying) => isModifying ? "Modifying club..." : "Creating club...",
          saveClubSucceed: (isModifying) => isModifying ? "Club modified successfully" : "Club created successfully",
          saveClubError: "Error creating/modifying the club",
        },
        useClubs: {
          getClubsError: "Error retrieving clubs",
          changeUserClubLoading: "Switching club...",
          changeUserClubSucceed: (clubName) => `Welcome to ${clubName}`
        }
      },
      removeClubModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (clubName = "") => `You are about to permanently delete ${clubName}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showClubsCard: {
        club: "Club",
        principalClub: "Main club",
        status: "Status",
        statusText: (active) => active ? "Active" : "Inactive",
        changeCurrentClubDescription: "Change the club being managed",
        modifyClubDescription: "Edit Club",
        removeClubDescription: "Remove Club",
        title: "Current clubs",
        subtitle: (numberOfClubs) => `Total: ${numberOfClubs}`,
        buttonAddClub: "Club",
        emptyClubs: "No clubs available"
      },
      showUserClubsModal: {
        name: "Name",
        roles: "Roles",
        changeCurrentClubSucceed: (clubName) => `Welcome to ${clubName}`,
        title: "Clubs",
        closeText: "Close"
      },
      uploadClubModal: {
        title: (isModifying, clubName) => isModifying ? `Edit ${clubName}` : "Create new club",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        isActiveLabel: "Is club active?",
        activeClubAnswers: ["Yes", "No"],
        isPrincipalClubLabel: "Is club main?",
        principalClubAnswers: ["Yes", "No"],
        isSharingContentLabel: "Show shared content?",
        sharingContentAnswers: ["Yes", "No"],
        languageLabel: "Club language",
        domainLabel: "Domain",
        noReplyMailAddressLabel: "Sender email",
        contact: "Contact",
        emailLabel: "Contact email",
        phoneLabel: "Contact phone"
      }
    },
    managers: {
      hooks: {
        useManager: {
          getManagerError: "Could not retrieve manager",
          saveManagerLoading: (isModifying) => isModifying ? "Modifying manager..." : "Creating manager...",
          saveManagerSucceed: (isModifying) => isModifying ? "Manager modified successfully" : "Manager created successfully",
          saveManagerError: "Error creating/modifying manager",
          removeManagerLoading: "Removing manager...",
          removeManagerSucceed: "Manager removed successfully",
          removeManagerError: "Error removing manager"
        },
        useManagers: {
          getManagersError: "Error retrieving managers"
        }
      },
      removeManagerModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (email = "") => `You are about to remove manager privileges from ${email}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showManagersCard: {
        email: "Email",
        name: "Name",
        bankAccountManager: "Bank account manager",
        modifyDescription: "Edit manager",
        removeDescription: "Remove manager privileges",
        title: "Managers",
        buttonAddText: "Manager",
        empty: "No managers"
      },
      uploadManagerModal: {
        title: (isModifying, email) => isModifying ? `Edit ${email}` : "Create new manager",
        closeText: "Cancel",
        buttonSaveText: "Save",
        emailLabel: "Email",
        nameLabel: "Name",
        lastName: "Last name",
        isBankAccountManagerLabel: "Is bank account manager?",
        isBankAccountManagerAnswers: ["Yes", "No"],
        isBankAccountManagerDescription: "The bank account manager is unique, non-transferable and immutable."
      }
    },
    meetings: {
      hooks: {
        useMeeting: {
          getMeetingError: "Failed to get meeting",
        },
        useMeetingCategories: {
          getMeetingCategoriesError: "Error getting meeting categories",
        },
        useMeetings: {
          getMeetingsError: "Error getting meetings",
        },
        useMeetingsTemplate: {
          getMeetingTemplateError: "Failed to get meeting template",
          saveMeetingTemplateLoading: (isModifying) => isModifying ? "Modifying template..." : "Creating template...",
          saveMeetingTemplateSucceed: (isModifying) => isModifying ? "Template modified successfully" : "Template created successfully",
          saveMeetingTemplateError: "Error creating/modifying template"
        },
        useMeetingsTemplates: {
          getMeetingTemplatesError: "Error getting meeting templates"
        }
      },
      showMeetingCallCard: {
        errorText: "There was an error with the video call",
        title: "Meeting",
        subtitle: (numParticipants, intensity, start) => `${numParticipants} participants -  ${intensities[intensity]} - ${moment(start).format("DD/MM/YYYY HH:mm")}`,
        leaveMeetingButtonText: "Leave Meeting",
        userName: "Trainer"
      },
      showMeetingModal: {
        title: "Meeting",
        closeText: "Close",
        enterButtonText: "Enter",
        start: "Start",
        end: "End",
        participants: "Participants",
        level: "Level",
        levelText: (intensity) => intensities[intensity],
        accessTimeInfo: "Access is available 24 hours before the start of the meeting"
      },
      showMeetingTemplatesModal: {
        name: "Name",
        level: "Level",
        participants: "Participants",
        price: "Price",
        modifyDescription: "Edit Template",
        title: "Meeting Templates",
        closeText: "Close",
        addButtonText: "Template",
        empty: "There are no templates"
      },
      uploadMeetingTemplateModal: {
        title: (isModifying, templateName) => isModifying ? `Edit template: ${templateName}` : "Create new template",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Template name",
        categoryLabel: "Category",
        intensityLabel: "Intensity",
        descriptionLabel: "Description",
        complementaryDescriptionLabel: "Complementary description",
        imageLabel: "Image",
        numMaxParticipantsLabel: "Maximum number of participants",
        numMaxParticipantsDescription: (maxParticipants) => `Session ${maxParticipants <= 2 ? `individual: 1 participant` : `group: ${maxParticipants - 1} participants`}`,
        priceLabel: "Price"
      }
    },
    news: {
      hooks: {
        useNew: {
          getNewError: "Could not retrieve the news",
          saveNewLoading: (isModifying) => isModifying ? "Modifying news..." : "Creating news...",
          saveNewSucceed: (isModifying) => isModifying ? "News modified successfully" : "News created successfully",
          saveNewError: "Error creating/modifying the news",
          removeNewLoading: "Removing news...",
          removeNewSucceed: "News removed successfully",
          removeNewError: "Could not remove the news",
        },
        useNews: {
          getNewsError: "Error retrieving the news"
        }
      },
      removeNewModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (newName = "") => `You are about to permanently delete ${newName}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showNewsCard: {
        name: "News",
        status: "Status",
        statusText: (publicated) => publicated ? "Published" : "Not published",
        publicationDate: "Publication date",
        modifyDescription: "Edit news",
        removeDescription: "Remove news",
        title: "News",
        addButtonText: "News",
        empty: "No news"
      },
      uploadNewModal: {
        title: (isModifying, title) => isModifying ? `Edit ${title}` : "Create new news",
        closeText: "Cancel",
        buttonSaveText: "Save",
        titleLabel: "Title",
        isPublishedLabel: "Is news published?",
        isPublishedAnswers: ["Yes", "No"],
        publicationDateLabel: "Publication date",
        contentLabel: "Content",
        imageLabel: "Image",
        videoLabel: "Video"
      }
    },
    notifications: {
      hooks: {
        useNotification: {
          getNotificationError: "Could not retrieve notification",
          saveNotificationLoading: (isModifying) => isModifying ? "Modifying notification..." : "Creating notification...",
          saveNotificationSucceed: (isModifying) => isModifying ? "Notification modified successfully" : "Notification created successfully",
          saveNotificationError: "Error creating/modifying notification",
          sendNotificationLoading: "Sending notification...",
          sendNotificationSucceed: "Notification sent successfully",
          sendNotificationError: "Error sending notification",
          removeNotificationLoading: "Deleting notification...",
          removeNotificationSucceed: "Notification deleted successfully",
          removeNotificationError: "Could not delete notification"
        },
        useNotifications: {
          getNotificationsError: "Error retrieving notifications"
        }
      },
      showNotificationsCard: {
        notificationTitle: "Title",
        status: "Status",
        statusText: (active) => active ? "Active" : "Inactive",
        type: "Type",
        typeText: (type) => notificationTypes[type],
        enterToSendFormDescription: "Send Form",
        modifyDescription: "Edit Notification",
        removeDescription: "Remove notification",
        title: "Notifications",
        addButtonText: "Notification",
        empty: "No notifications"
      },
      removeNotificationModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (notificationTitle = "") => `You are about to permanently delete ${notificationTitle}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      uploadNotificationModal: {
        title: (isModifying, title) => isModifying ? `Edit ${title}` : "Create new notification",
        closeText: "Cancel",
        buttonSaveText: "Save",
        titleLabel: "Title",
        isActiveLabel: "Is notification active?",
        isActiveAnswers: ["Yes", "No"],
        messageLabel: "Message",
        notificationTypeLabel: "Notification type",
        productLabel: "Product",
        newLabel: "News",
        daysBeforeSubscriptionEndLabel: "Days before subscription end",
        daysBeforeSubscriptionEndDescription: (daysBefore) => `This notification will be sent to all users ${daysBefore} days before the end of their subscription`
      },
      uploadNotificationSendFiltersModal: {
        title: "Send notification",
        closeText: "Cancel",
        buttonSendText: "Send",
        infoEmailUsed: "The use of email disables the rest of the filters",
        reset: "Reset",
        emailLabel: "Email",
        entryDateStartLabel: "Start entry date",
        entryDateStartDescription: "All users who have registered after this date",
        entryDateEndLabel: "End entry date",
        entryDateEndDescription: "All users who have registered before this date",
        subscriptionEndDateStartLabel: "Start subscription end date",
        subscriptionEndDateStartDescription: "All users whose subscription has ended after this date",
        subscriptionEndDateEndLabel: "End subscription end date",
        subscriptionEndDateEndDescription: "All users whose subscription has ended before this date",
        subscriptionPlanLabel: "Subscription",
        subscriptionAny: "Any",
        noSubscription: "Without subscription"
      }
    },
    plans: {
      hooks: {
        usePlan: {
          getPlanError: "Failed to get the plan",
          saveManagerLoading: (isModifying) => isModifying ? "Modifying plan..." : "Creating plan...",
          saveManagerSucceed: (isModifying) => isModifying ? "Plan modified successfully" : "Plan created successfully",
          saveManagerError: "Error creating/modifying the plan",
          removeManagerLoading: "Removing plan...",
          removeManagerSucceed: "Plan removed successfully",
          removeManagerError: "Failed to remove the plan"
        },
        usePlans: {
          getPlansError: "Error getting the plans"
        }
      },
      removePlanModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (name = "") => `You are about to permanently delete ${name || ""}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showPlansCard: {
        name: "Name",
        price: "Price",
        priceCurrency: (currency) => `${currencies[currency]}`,
        priceInterval: (interval) => `/${intervals[interval]}`,
        type: "Type",
        typeText: (type) => planTypes[type],
        modifyDescription: "Edit Plan",
        removeDescription: "Remove Plan",
        title: "Plans",
        addPlanButtonText: "Plan",
        addPlanButtonDescription: "Create an online payment plan",
        empty: "There are no plans"
      },
      uploadPlanModal: {
        title: (isModifying, name) => isModifying ? `Edit ${name}` : "Create new plan",
        closeText: "Cancel",
        buttonSaveText: "Save",
        nameLabel: "Name",
        descriptionLabel: "Description",
        descriptionPlaceholder: "Ej: Suscríbete a nuestro plan más económico y disfruta de todo el contenido. Dispones de 7 días de prueba gratis",
        priceLabel: "Subscription price",
        planTypeLabel: "Plan type",
        intervalLabel: "Billing interval",
        currencyLabel: "Currency",
        trialDaysLabel: "Test days",
        trialDaysDescription: (days) => `${days} ${days === 1 ? "day" : "days"} of free trial. Will be charged at the end of the days.`
      }
    },
    products: {
      hooks: {
        useLiveStreams: {
          getLiveStreamsError: "Error getting live servers"
        },
        useProduct: {
          getProductError: "Could not get product",
          saveProductLoading: (isModifying) => isModifying ? "Modifying product..." : "Creating product...",
          saveProductSucceed: (isModifying) => isModifying ? "Product modified successfully" : "Product created successfully",
          saveProductError: "Error creating/modifying product"
        },
        useProducts: {
          getProductsError: "Error getting products"
        }
      },
      showProductsCard: {
        copyUrlButtonText: "Copy",
        copiedUrl: "Url copied to clipboard",
        code: "Code",
        product: "Product",
        premiere: "Premiere",
        noPremiere: "No premiere",
        unpublished: "Unpublished",
        published: "Published",
        unspecifiedPublication: "Unspecified",
        // property: "Property",
        // propertyText: (isOwner) => isOwner ? "Owner" : "Shared",
        modifyDescription: "Edit Product",
        qrDescription: "See QR",
        title: "Products",
        images: "Images",
        productUrl: "Path",
        addButtonText: "Product",
        searchPlaceholder: "Search by code or title",
        empty: "No products"
      },
      showProductListCard: {
        code: "Code",
        product: "Product",
        premiere: "Premiere",
        unpublished: "Unpublished",
        published: "Published",
        unspecifiedPublication: "Unspecified",
        modifyDescription: "Edit Product",
        removeDescription: "Remove Product from this list",
        title: (title) => title ? `Products of ${title}` : "Products",
        subtitle: "Drag and drop the products to sort",
        empty: "No products on this list",
        productCreatingFormatText: (inputValue) => `Create new product "${inputValue}"`,
        noProductOptionsText: "There are no products, write to create a new product",
        selectProductPlaceholder: "Add or create"
      },
      uploadSPDProductModal: {
        title: (isModifying, code) => isModifying ? `Edit ${code}` : "Create new product",
        closeText: "Cancel",
        buttonSaveText: "Save",
        titleLabel: "Title",
        collectionLabel: "Collection",
        collectionCreatingFormatText: (value) => `Create new collection "${value}"`,
        noCollectionOptionsText: "No collections exist",
        plansLabel: "Plans",
        descriptionLabel: "Description",
        planCreatingFormatText: (value) => `Create new plan "${value}"`,
        noPlanOptionsText: "There are no plans, write to create a new plan",
        plansTooltip: "You can associate multiple plans. The user will need to be subscribed to one of the plans to view the content. In the case of not associating any plan, the content will be accessible for free",
        startDateLabel: "Start date",
        startTimeLabel: "Start time",
        endTimeLabel: "End time",
        beforeStartLabel: "Turn on before start",
        durationLabel: "Duration",
        homeTeamLabel: "Home team",
        awayTeamLabel: "Away team",
        cameraLabel: "Camera",
        storyboardLabel: "Storyboard",
        graphicPackageLabel: "Graphic package",
        sportLabel: "Sport",
        football: "Football",
        handball: "Handball",
        thumbnailTooltip: "The product thumbnail can appear as a cover in categories, related products, etc.",
        thumbnailPortraitTooltip: "The vertical product thumbnail can appear as a cover on categories, related products, etc.",
        imageTooltip: "The product image can appear on the product page or on the home page if it is a premiere.",
        imagePortraitTooltip: "The vertical image of the product can appear, on small devices, on the product page or on the home page if is premiere.",
        thumbnailLabel: "Thumbnail",
        thumbnailPortraitLabel: "Thumbnail portrait",
        imageLabel: "Image",
        imagePortraitLabel: "Image portrait",
      },
      uploadProductModal: {
        thumbnailTooltip: "The product thumbnail can appear as a cover in categories, related products, etc.",
        thumbnailPortraitTooltip: "The vertical product thumbnail can appear as a cover on categories, related products, etc.",
        imageTooltip: "The product image can appear on the product page or on the home page if it is a premiere.",
        imagePortraitTooltip: "The vertical image of the product can appear, on small devices, on the product page or on the home page if is premiere.",
        title: (isModifying, code) => isModifying ? `Edit ${code}` : "Create new product",
        closeText: "Cancel",
        buttonSaveText: "Save",
        badgeSharedProduct: "Shared product",
        codeLabel: "Identifying code",
        codePlaceholder: "eg: EX0009, EX0010, EX0011,...",
        titleLabel: "Title",
        titleErrorNumMaxChars: "Title cannot be longer than 30 characters",
        subtitleLabel: "Subtitle",
        live: "directo",
        segmentation: "Segmentation",
        collectionsLabel: "Collections",
        collectionCreatingFormatText: (value) => `Create new collection "${value}"`,
        noCollectionOptionsText: "No collections exist",
        subtitleErrorNumMaxChar: "Subtitle cannot be longer than 50 characters",
        isPublishedLabel: "Is product published?",
        isPublishedAnswers: ["Yes", "No"],
        endPublicationIconDescription: "Specify end date of publication",
        publicationDateLabel: "Publication start date",
        publicationEndDateLabel: "Publication end date",
        isPremiereLabel: "Is it a premiere?",
        isPremiereAnswers: ["Yes", "No"],
        endPremiereIconDescription: "Specify end date of premiere",
        premiereEndDateLabel: "Premiere end date",
        isRentalLabel: "Allow rental?",
        isRentalAnswer: ["Yes", "No"],
        priceRentalLabel: "Rental price",
        cameraLabel: "Camera",
        cameraTooltip: "Select the live camera. The live server will turn on 5 minutes before the start date and turn off 5 minutes after the end date. The camera URL will not work outside these dates.",
        noLiveStreamOption: "Without live stream",
        fakeLiveStreamOption: "Fake live stream",
        emptyLiveServers: "No active servers",
        copyUrlCamText: "Copy camera link",
        copyUrlCamSucceed: "Camera URL copied to clipboard",
        liveScheduleStartLabel: "Start of live event",
        liveScheduleEndLabel: "End of live event",
        plansLabel: "Plans",
        planCreatingFormatText: (value) => `Create new plan "${value}"`,
        noPlanOptionsText: "There are no plans, write to create a new plan",
        plansTooltip: "You can associate multiple plans. The user will need to be subscribed to one of the plans to view the content. In the case of not associating any plan, the content will be accessible for free",
        categoriesLabel: "Categories",
        categoryCreatingFormatText: (value) => `Create new category "${value}"`,
        noCategoryOptionsText: "There are no categories, write to create a new category",
        tagsLabel: "Tags",
        tagCreatingFormatText: (value) => `Create new tag "${value}"`,
        noTagOptionsText: "There are no tags, write to create a new tag",
        separatorLineImages: "Product Images",
        thumbnailLabel: "Thumbnail",
        thumbnailPortraitLabel: "Thumbnail portrait",
        imageLabel: "Image",
        imagePortraitLabel: "Image portrait",
        separatorLineDescription: "Product Description",
        descriptionLabel: "Description",
        generalLevelLabel: "General Level",
        technicalLevelLabel: "Technical Level",
        mentalLevelLabel: "Mental Level",
        physicalLevelLabel: "Physical Level",
        separatorLineContent: "Content",
        videoUrlLabel: "Video",
        trailerUrlLabel: "Trailer",
        progressTranscodePending: "Paused. Upload the file again to continue",
        badgeTranscodifyingVideo: "Transcodifying video...",
        badgeTranscodifyingTrailer: "Transcodifying trailer..."
      }
    },
    stats: {
      statsCategoriesVisualizations: {
        getStatsError: "Error retrieving statistics",
        infoTitle: "Info: Category visualizations",
        infoCloseText: "Got it!",
        infoContent: (
          <>
            <p>
              Bars: Indicates the number of visualizations each category has had during the selected date range.
              Only categories with at least one visualization will appear.
            </p>
            <p>
              Line: Indicates an average in minutes of the time the user has spent watching a video in each
              category during the selected date range.
            </p>
          </>
        ),
        title: "Category Visualizations",
        empty: "Not enough data",
        visualizationsLabel: "Visualizations",
        timeLabel: "Time (min)"
      },
      statsProductsLikes: {
        getStatsError: "Error getting statistics",
        infoTitle: "Info: Likes per product",
        infoCloseText: "Understood!",
        infoContent: "Indicates the number of likes each video has received during the selected date range, ordered from most likes to least.",
        title: "Likes per product",
        empty: "Not enough data",
        like: "Like",
        dislike: "Dislike"
      },
      statsProductsVisualizations: {
        getStatsError: "Error getting statistics",
        infoTitle: "Info: Visualizations per product",
        infoCloseText: "Understood!",
        infoContent: "Indicates the number of visualizations each video has received during the selected date range, ordered from most visualizations to least.",
        title: "Visualizations per product",
        empty: "Not enough data",
        label: "Visualizations"
      },
      statsTrainersIncome: {
        getStatsError: "Error getting statistics",
        title: "Trainer earnings",
        empty: "Not enough data",
        label: "EUR (€)"
      },
      statsTrainersMeetings: {
        getStatsError: "Error getting statistics",
        title: "Number of training sessions",
        empty: "Not enough data",
        label: "Training sessions"
      },
      statsUsersLocation: {
        getStatsError: "Error getting statistics",
        title: "User map",
        subtitle: (numUnknowns) => !!numUnknowns && `Users with unknown location: ${numUnknowns}`,
        empty: "Not enough data",
      },
      statsUsersPlans: {
        getStatsError: "Error while getting statistics",
        title: "Plans",
        empty: "Not enough data",
        label: "Users"
      },
      statsUsersSubscriptions: {
        getStatsError: "Error while getting statistics",
        infoTitle: "Info: Registrations",
        infoCloseText: "Got it!",
        infoContent: "Shows the number of registered users during the selected time frame",
        title: "Registrations",
        empty: "Not enough data",
        labelY: "Registrations",
        labelX: "Users"
      },
      statsUsersTotalActiveNow: {
        getStatsError: "Error while getting statistics",
        suffix: (number) => number === 1 ? " user" : " users",
        title: "Connected users now",
        description: "Number of users currently on the website"
      },
      statsUsersTotalActive: {
        shortCutToday: "Today",
        shortCut7Days: "7 days",
        shortCut14Days: "14 days",
        shortCut21Days: "21 days",
        shortCut30Days: "30 days",
        getStatsError: "Error while getting statistics",
        suffix: (number) => number === 1 ? " user" : " users",
        title: (total) => `Active users of ${total}`,
        description: (shortCut) => `Shows the number of active users (${shortCut})`
      },
      statsVideoTimeVisualization: {
        getStatsError: "Error while getting statistics",
        infoTitle: "Info: Audience retention",
        infoCloseText: "Got it!",
        infoContent: (
          <>
            <p>
              Shows the percentage of users (position on the chart's y-axis) relative to the percentage of video viewed
              (position on the chart's x-axis).
            </p>
            If the point is between 70% of users and 30% of video viewed, it means that 30% of users have dropped out of
            the video before the 30% mark.
          </>
        ),
        title: "Audience retention",
        empty: "Not enough data",
        selectProducts: "Select a product to compare",
        labelTitle: "Retention",
        labelFinalized: (label, percent) => `${label}: ${percent.toFixed(1)}% of users finished the video`,
        labelAbandonment: (label, percent, percentVideo) => `${label}: ${(100 - percent).toFixed(1)}% abandonment at ${percentVideo}% of the video`
      }
    },
    trainers: {
      hooks: {
        useTrainer: {
          getTrainerError: "Error getting trainer data",
          removeTrainerLoading: "Removing trainer...",
          removeTrainerSucceed: "Trainer successfully removed",
          removeTrainerError: "Error removing trainer",
          saveTrainerLoading: (isModifying) => isModifying ? "Modifying trainer..." : "Creating trainer...",
          saveTrainerSucceed: (isModifying) => isModifying ? "Trainer successfully modified" : "Trainer successfully created",
          saveTrainerError: "Error creating/modifying trainer"
        },
        useTrainers: {
          getTrainers: "Error getting trainers"
        }
      },
      removeTrainerModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: (name = "") => `You are about to permanently delete ${name || ""}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showTrainersCard: {
        name: "Name",
        status: "Status",
        statusText: (active) => active ? "Active" : "Inactive",
        modifyDescription: "Edit Trainer",
        MeetingsTemplatesDescription: "Meeting Templates",
        calendarDescription: "Calendar",
        removeDescription: "Remove Trainer",
        title: "Trainers",
        addButtonText: "Trainer",
        empty: "No trainers available"
      },
      uploadTrainerModal: {
        thumbnailTooltip: "The vertical trainer thumbnail can appear as an art in the trainers category or on the trainers page.",
        imageTooltip: "The trainer's image can appear on the trainer's page as a cover. This image may be cropped depending on the device.",
        title: (isModifying, name) => isModifying ? `Update Trainer: ${name}` : "Create Trainer",
        closeText: "Cancel",
        buttonSaveText: "Save",
        emilLabel: "Email",
        nameLabel: "Name",
        isActiveLabel: "Is Trainer Active?",
        isActiveAnswers: ["Yes", "No"],
        descriptionLabel: "Description",
        thumbnailLabel: "Thumbnail portrait",
        imageLabel: "Image",
      }
    },
    trainerSchedule: {
      hooks: {
        useTrainerAvailabilitySchedule: {
          getTrainerAvailabilityScheduleError: "Failed to retrieve trainer availability",
          getMyTrainerAvailabilityScheduleError: "Failed to retrieve availability",
          saveTrainerAvailabilityScheduleLoading: (isModifying) => isModifying ? "Modifying availability..." : "Creating availability...",
          saveTrainerAvailabilityScheduleSucceed: (isModifying) => isModifying ? "Availability modified successfully" : "Availability created successfully",
          saveTrainerAvailabilityScheduleError: "Error creating/modifying availability"
        },
      },
      showTrainerScheduleCard: {
        subtitle: "Click on a time slot to indicate your availability",
        buttonSaveText: "Save",
        buttonNowText: "Now",
      },
      uploadAvailabilityModal: {
        title: (isModifying) => isModifying ? "Modify availability" : "Create availability",
        closeText: "Cancel",
        buttonSaveText: (isModifying) => isModifying ? "Modify" : "Add",
        buttonRemoveText: "Delete",
        templateLabel: "Template",
        isRecurringLabel: "Is it weekly?",
        isRecurringAnswers: ["Yes", "No"]
      }
    },
    trans: {
      hooks: {
        useOneTrans: {
          removeTransLoading: "Removing transcoding...",
          removeTransSucceed: "Transcoding removed successfully",
          removeTransError: "Error removing transcoding"
        },
        useTrans: {
          getTransError: "Error getting transcoding"
        }
      },
      contexts: {
        transcodeWrapper: {
          fileUploadedError: (fileName = "File") => `${fileName} not uploaded.`,
          fileUploadedSucceed: (fileName = "File") => `${fileName} uploaded successfully. Transcoding process may take a few hours`,
          uploadingFile: "Uploading video...",
          pausingFile: "Pausing upload...",
          fileUploadedCancelled: "Uploaded cancelled",
          fileUploadedPaused: "Uploaded paused",
          errorNameVideo: "Some videos do not have an appropriate name",
        }
      },
      removeTransModal: {
        titleDelete: "Are you sure?",
        descriptionDelete: "You are about to permanently delete the transcoding",
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showTransCard: {
        urlCopySucceed: "URL copied to clipboard",
        file: "File",
        uploadDate: "Upload date",
        url: "URL",
        urlNotAvailable: "Url not available",
        status: "Status",
        statusText: (isProgressing) => isProgressing ? "Transcodifying..." : "Finalized",
        copyDescription: "Copy URL",
        previewDescription: "Preview",
        removeDescription: "Remove transcoding",
        title: "Transcoded videos",
        searchPlaceholder: "Search by file name",
        subtitle: "Click to copy URL",
        buttonRefreshDescription: "Refresh transcoding",
        empty: "No transcoding available"
      },
      uploadVideoCard: {
        title: "Transcodify",
        file: "File",
        size: "Size",
        status: "Status",
        statusText: (isUploading) => isUploading ? "Uploading video..." : "Paused upload",
        percentage: "Percentage",
        progressPending: "Upload the file again to continue",
        pauseDescription: "Pause the upload",
        removeDescription: "Cancel the upload",
        infoTitle: "Transcodify",
        infoCloseText: "Understood",
        infoContent: () => (
          <p className="mt-2">
            Drag and drop videos to start transcoding process. <strong>Do not close the window while content is being
            uploaded.</strong> After uploading, the transcoded url may take a few hours to
            appear.
          </p>
        )
      }
    },
    userInvoice: {
      hooks: {
        useUserInvoice: {
          downloadInvoiceError: "Unable to download the invoice"
        },
        useUserInvoices: {
          getUserInvoicesError: "Error getting invoices"
        }
      },
      showUserInvoicesCard: {
        invoice: "Invoice",
        creationDate: "Creation Date",
        amount: "Amount",
        downloadInvoiceDescription: "Download invoice",
        title: "Invoices",
        buttonRefreshDescription: "Refresh invoices",
        empty: "No invoices"
      }
    },
    userPaymentMethods: {
      hooks: {
        usePaymentMethod: {
          changeDefaultPaymentMethodLoading: "Changing payment method...",
          changeDefaultPaymentMethodSucceed: "Payment method changed successfully",
          changeDefaultPaymentMethodError: "Error changing payment method",
          removePaymentMethodLoading: "Removing payment method...",
          removePaymentMethodSucceed: "Payment method removed successfully",
          removePaymentMethodError: "Error removing payment method",
        },
        useUserPaymentMethods: {
          getUserPaymentMethodsError: "Error getting payment methods"
        }
      },
      changeDefaultUserPaymentMethodModal: {
        titleSave: `Are you sure?`,
        descriptionSave: (last4 = "") => `You are about to permanently remove ${last4 || ""}`,
        closeText: "Cancel",
        buttonSaveText: "Yes, I'm sure"
      },
      createPaymentMethodModalWrapped: {
        creatingLoading: "Creating new payment method",
        creatingErrorType: "Please select a valid payment method type",
        creatingSucceed: "Payment method created successfully",
        creatingError: "Error creating payment method",
        creatingErrorNoAccountManager: "No account manager user found",
        title: "Create new payment method",
        closeText: "Cancel",
        buttonSaveText: "Save",
        typeSelectorPlaceholder: "Select...",
        cardNumberLabel: "Card number",
        cardExpirationLabel: "Expiration date",
        cardCVCLabel: "CVC",
        sepaLabel: "SEPA",
        info: (companyCode) => (
          <>
            By providing your payment information and confirming this payment, you authorize (A) {companyCode} and
            Stripe,
            our payment service provider and/or PPRO, your local service provider, to send instructions to your bank to
            debit
            your account and (B) your bank to debit your account in accordance with those instructions. As part of your
            rights,
            you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank.
            A
            refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights
            are
            explained in a statement that you can obtain from your bank. You agree to receive notifications for future
            debits up to 2 days before they occur.
          </>
        ),
        errorInvalidType: "Invalid payment method type"
      },
      removePaymentMethodModal: {
        titleDelete: `Are you sure?`,
        descriptionDelete: (last4 = "") => `You are about to permanently remove the payment method ending in ${last4 || ""}`,
        closeText: "Cancel",
        buttonDeleteText: "Delete"
      },
      showUserPaymentMethodsCard: {
        number: "Number",
        expiration: "Expiration",
        defaultDescription: (isDefault) => isDefault ? "Default method" : "Select as default payment method",
        removeDescription: "Remove payment method",
        title: "Payment methods",
        subtitle: "Add or set as default",
        addButtonText: "New",
        empty: "No payment methods"
      }
    },
    users: {
      hooks: {
        useUser: {
          getUserError: "Could not get user",
          subscribeUserError: "Could not subscribe to user",
          saveUserLoading: (isModifying) => isModifying ? "Modifying user..." : "Creating user...",
          saveUserSucceed: (isModifying) => isModifying ? "User modified successfully" : "User created successfully",
          saveUserError: "Error creating/modifying user"
        },
        getUsers: {
          getUsersError: "Error getting users"
        }
      },
      showUserHistoryModal: {
        plan: "Plan",
        duration: "Duration",
        durationWithoutInit: "Without start",
        durationWithoutEnd: "Not finished",
        title: "History",
        closeText: "Close",
        empty: "No history available"
      },
      showUsersCard: {
        email: "Email",
        name: "Name",
        registerDate: "Register date",
        status: "Status",
        statusText: (verified) => verified ? "Verified" : "Not verified",
        modifyDescription: "Edit user",
        showHistoryDescription: "Show history",
        subscribeUserDescription: "Subscribe user",
        title: "Users",
        subtitle: (numUsers) => `Total users: ${numUsers}`,
        filterButtonText: "Filters",
        addButtonText: "User",
        empty: "No users available"
      },
      subscribeUserModal: {
        title: "Subscribe user",
        closeText: "Cancel",
        buttonSaveText: "Subscribe",
        planLabel: "Plan",
        subscriptionStart: "Start date",
        subscriptionEnd: "End date"
      },
      uploadUserModal: {
        title: (isModifying, email) => !!isModifying ? `Edit ${email}` : "Create new user",
        closeText: "Cancel",
        buttonSaveText: "Save",
        emailLabel: "Email",
        nameLabel: "Name",
        lastNameLabel: "Last name",
        phoneLabel: "Phone",
        countryLabel: "Country",
        cityLabel: "City",
        genderLabel: "Gender",
        addressLabel: "Address",
        postalCodeLabel: "Postal code",
        birthdateLabel: "Birthdate",
      },
      uploadUsersCSVCard: {
        uploadUsersLoading: "Loading users...",
        uploadUsersSucceed: "Users updated successfully",
        uploadUsersErrorNoUsers: "No users found in the file",
        uploadUsersError: (email, error) => <div><span>Error: {email}</span><p>{error}</p></div>,
        title: "Upload users",
        downloadTemplateButtonText: "Template",
        info: (templateUsers) => (
          <>
            Drag a file <strong>(.xlsx or .csv)</strong> with users here or click to upload it.
            You have access to the template <a href={templateUsers}>here</a>. ( Dates must be entered in
            the format <strong>YYYY-MM-DD</strong> )
          </>
        )
      },
      uploadUsersFiltersModal: {
        title: "Filters",
        closeText: "Cancel",
        buttonSaveText: "Apply",
        reset: "Reset",
        searchLabel: "Search by email or name",
        entryDateStartLabel: "Start registration date",
        entryDateStartDescription: "All users who have registered from this date",
        entryDateEndLabel: "End registration date",
        entryDateEndDescription: "All users who have registered until this date",
        subscriptionEndDateStartLabel: "Start subscription end date",
        subscriptionEndDateStartDescription: "All users whose subscription has ended after this date",
        subscriptionEndDateEndLabel: "End subscription end date",
        subscriptionEndDateEndDescription: "All users whose subscription has ended before this date",
        registerStateLabel: "Registration state",
        verificationStateAll: "All",
        verificationStateChecked: "Verified",
        verificationStateUnchecked: "Not verified",
        subscriptionPlanLabel: "Subscription",
        subscriptionAny: "All"
      }
    }
  },
  notifications: {
    needsLinkClubStripe: "Stripe needs to be linked with the club's account",
    needsConfigureClubStripe: "Club's Stripe needs to be configured",
    needsLinkCompanyStripe: "Stripe needs to be linked with the company's account",
    needsConfigureCompanyStripe: "Company's Stripe needs to be configured",
    urgent: "Urgent"
  },
  routes: {
    company: "Company",
    club: "Club",
    trainer: "Coaching",
    clubManagement: "Club Management",
    onDemand: "On Demand",
    slider: "Slider",
    products: "Products",
    categories: "Categories",
    categoryProducts: "Category products",
    categoryCollections: "Category collections",
    collections: "Collections",
    tags: "Tags",
    collectionProducts: "Collection products",
    teams: "Teams",
    teamSections: "Team sections",
    subscriptions: "Subscriptions",
    plans: "Plans",
    coupons: "Coupons",
    transcodification: "Transcoding",
    linealChannel: "Linear Channel",
    advertising: "Advertising",
    advertisement: "Advertisement",
    newAdvertisement: "New advertisement",
    magazine: "Magazine",
    users: "Users",
    services: "Services",
    trainers: "Trainers",
    trainerSchedule: "Trainer Schedule",
    mySchedule: "My Schedule",
    meeting: "Meeting",
    notifications: "Notifications",
    stats: "Statistics",
    statsOnDemand: "On Demand",
    statsInRealTime: "In real time",
    statsUsers: "Users",
    statsTrainers: "Trainers",
    myLicences: "My Licenses",
    configuration: "Configuration",
    clubConfig: "Club",
    structureConfig: "Structure",
    seoConfig: "SEO"
  },
  countries: getCountries("en"),
  fullcalendarLocale: enLocale,
  roles: roles,
  intervals: intervals,
  genders: genders,
  currencies: currencies,
  complexCurrencies: complexCurrencies,
  intensities: intensities,
  levels: levels,
  advertisementPosition: advertisementPosition,
  notificationTypes: notificationTypes,
  navigationItemTypes: navigationItemTypes,
  planTypes: planTypes,
  categoryTypes: categoryTypes,
  collectionTypes: collectionTypes,
  producerTypes: producerTypes,
  paymentMethodTypes: paymentMethodTypes
}

export default en