import React from "react";
import { Modal, FormInput, FormDecoration } from "@general-backoffice/core";
import { Collapse } from "reactstrap";
import getDefaultClub from "./shared/getDefaultClub";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator, { languages } from "../../hooks/useTranslator";


const languageOptions = Object.entries(languages).map(([key, { name }]) => ({ value: key, label: name }))
const UploadClubModal = ({
                           club,
                           setClub,
                           onSubmit,
                           isLoading
                         }) => {
  const t = useTranslator("components.clubs.uploadClubModal")
  const { changeState, onChangeInput: onChange } = useChangingComplexState(setClub, isLoading)

  const {
    id,
    name,
    active = true,
    contactEmail,
    contactPhone,
    clubProperties
  } = { ...getDefaultClub(), ...(club || {}) };

  const {
    principalClub,
    showShareContent,
    language,
    baseUrl,
    noReplyMailAddress
  } = (clubProperties || {})

  return (
    <Modal.FormContainer
      isOpen={!!club}
      toggleOpen={() => setClub(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <FormInput.Input {...{
        onChange,
        value: name,
        name: "name",
        label: t("nameLabel"),
        required: true
      }}/>
      <FormInput.Switch {...{
        onChange,
        checked: active,
        name: "active",
        label: t("isActiveLabel"),
        labels: t("activeClubAnswers")
      }}/>
      <FormInput.Switch {...{
        onChange,
        checked: principalClub,
        name: "clubProperties.principalClub",
        label: t("isPrincipalClubLabel"),
        labels: t("principalClubAnswers")
      }}/>

      <Collapse isOpen={!principalClub}>
        <FormInput.Switch {...{
          onChange,
          checked: showShareContent,
          name: "clubProperties.showShareContent",
          label: t("isSharingContentLabel"),
          labels: t("sharingContentAnswers"),
          disabled: !!principalClub
        }}/>
      </Collapse>

      <FormInput.Select
        label={t("languageLabel")}
        name={"language"}
        isMulti={false}
        onChange={(option) => changeState("clubProperties.language", option.value)}
        options={languageOptions}
        value={languageOptions.find(({ value }) => value === language)}
      />

      <FormInput.Input
        onChange={onChange}
        name={"clubProperties.baseUrl"}
        value={baseUrl}
        label={t("domainLabel")}
        required
      />

      <FormInput.Input
        onChange={onChange}
        name={"clubProperties.noReplyMailAddress"}
        value={noReplyMailAddress}
        label={t("noReplyMailAddressLabel")}
        required
      />

      <FormDecoration.LineTitle title="Contacto"/>

      <FormInput.Email {...{
        onChange,
        value: contactEmail,
        name: "contactEmail",
        label: t("emailLabel"),
        required: true,
      }}/>
      <FormInput.Phone {...{
        onChange,
        value: contactPhone,
        name: "contactPhone",
        label: t("phoneLabel"),
        required: true
      }}/>

    </Modal.FormContainer>
  );
}

export default UploadClubModal;