import Api from "../Api";

const getAdvertisement = (id) => Api.get(`/service/advertising/${id}`);
const getAdvertisementsList = (params) => Api.get(`/service/advertising/list`, { params });
const deleteAdvertisement = (id) => Api.delete(`/service/advertising/${id}`);
const putAdvertisement = (body) => Api.put(`/service/advertising`, body);
const postAdvertisement = (body) => Api.post(`/service/advertising`, body);

const advertisingRequests = {
  get: getAdvertisement,
  getList: getAdvertisementsList,
  delete: deleteAdvertisement,
  put: putAdvertisement,
  post: postAdvertisement
}

export default advertisingRequests