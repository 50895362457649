import { useEffect, useState } from "react";
import { newRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultNew from "../shared/getDefaultNew";
import { hardTranslator } from "../../../hooks/useTranslator";

const useNew = (id) => {

  const [theNew, setTheNew] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const newId = theNew?.id || id

  useEffect(() => {
    if (!!newId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [newId])

  const refresh = async () => {
    setIsLoading(true)
    return getNew(newId)
      .then((user) => {
        setTheNew(user)
        return user
      })
      .catch((e) => {
        setTheNew(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveNew(theNew)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!theNew?.id) return;
    setIsLoading(true)
    return removeNew(theNew.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    theNew,
    setTheNew,
    save,
    remove,
    isLoading
  }
}

export const getNew = async (newId) => {
  const t = hardTranslator("components.news.hooks.useNew")

  try {
    return await newRequests.get(newId)
  } catch (e) {
    const message = e?.message || t("getNewError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveNew = async (theNew) => {
  const t = hardTranslator("components.news.hooks.useNew")
  const notification = notify.loading(t("saveNewLoading", theNew?.id))
  try {
    const cleanNew = { ...getDefaultNew(), ...theNew }
    if (!!theNew?.id) {
      await newRequests.put(theNew.id, cleanNew)
      notifyLoadingToSuccess(notification, t("saveNewSucceed", true))
    } else {
      await newRequests.post(cleanNew)
      notifyLoadingToSuccess(notification, t("saveNewSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveNewError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export const removeNew = async (newId) => {
  const t = hardTranslator("components.news.hooks.useNew")
  const notification = notify.loading(t("removeNewLoading"))
  try {
    const companyPlan = await newRequests.delete(newId)
    notifyLoadingToSuccess(notification, t("removeNewSucceed"))
    return companyPlan
  } catch (e) {
    const message = e?.message || t("removeNewError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useNew;