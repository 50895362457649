import moment from "moment";
import { defaultAvailabilitySchedule, weekDays } from "../hooks/useTrainerAvailabilitySchedule";

const convertLocalDateToTime = (date) => moment(moment(date).format("HH:mm:ss"), "HH:mm:ss").utc().format("HH:mm:ss")


const convertFullCalendarEventsToAvailabilitySchedule = (events) => {
  if (!events) return JSON.parse(JSON.stringify({ ...defaultAvailabilitySchedule }));
  const eventsAvailabilitySchedule = JSON.parse(JSON.stringify({ ...defaultAvailabilitySchedule }));
  const lastUpdate = moment().utc().format()
  events.forEach(({ end, start, extendedProps, id }) => {
    const { templateId, daysOfWeek } = extendedProps;
    const isRecurrent = !!daysOfWeek;
    if (!moment(start).isValid() || !moment(end).isValid()) return null;

    if (isRecurrent) {
      const startTime = convertLocalDateToTime(start);
      const endTime = convertLocalDateToTime(end);
      const dayName = weekDays[moment(start).utc().day().toString()]

      if (dayName) {
        if (!Array.isArray(eventsAvailabilitySchedule[dayName]))
          eventsAvailabilitySchedule[dayName] = []
        eventsAvailabilitySchedule[dayName].push({ start: startTime, end: endTime, templateId, lastUpdate, id })
      }
    } else {
      const formatStart = moment(start).utc().format();
      const formatEnd = moment(end).utc().format()
      eventsAvailabilitySchedule.oneTime.push({ start: formatStart, end: formatEnd, templateId, lastUpdate, id })
    }
  })
  return eventsAvailabilitySchedule
}

export default convertFullCalendarEventsToAvailabilitySchedule;
