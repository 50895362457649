import Api from "../Api";

const getCompanyStripeStatus = () => Api.get(`/admin/company/connections/status`, { params: { club: undefined } });
const getClubStripeStatus = () => Api.get(`/admin/clubs/connections/status`);
const connectCompanyStripe = () => Api.post(`/admin/company/connections/stripe`, undefined, { params: { club: undefined } });
const updateCompanyStripe = () => Api.put(`/admin/company/connections/stripe`, undefined, { params: { club: undefined } });
const connectClubStripe = () => Api.post(`/admin/clubs/connections/stripe`);
const updateClubStripe = () => Api.put(`/admin/clubs/connections/stripe`);

const stripeRequests = {
  getClubStatus: getClubStripeStatus,
  connectClubStripe: connectClubStripe,
  updateClubStripe: updateClubStripe,
  getCompanyStatus: getCompanyStripeStatus,
  connectCompanyStripe: connectCompanyStripe,
  updateCompanyStripe: updateCompanyStripe
}

export default stripeRequests