import Api from "../Api";

const getPlan = (id) => getPlansList().then(({ planList }) => planList.find((iterPlan) => iterPlan.id === id));
const getPlansListForFilters = () => Api.get(`/admin/plans/list`);
const getPlansList = () => Api.get(`/admin/plans/list`).then(({ planList }) => ({ planList: planList.filter((iterPlan) => iterPlan.id >= 0) }));
const deletePlan = (id) => Api.delete(`/admin/plans/${id}`);
const putPlan = (id, body) => Api.put(`/admin/plans/${id}`, body);
const postPlan = (body) => Api.post(`/admin/plans`, body);

const planRequests = {
  get: getPlan,
  getList: getPlansList,
  getListForFilters: getPlansListForFilters,
  delete: deletePlan,
  put: putPlan,
  post: postPlan,
}

export default planRequests