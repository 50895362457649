import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFormInputValidation } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import getDefaultCategory from "./shared/getDefaultCategory";
import useTranslatedCategoryTypes from "../../hooks/translations/useTranslatedCategoryTypes";


const UploadCategoryModal = ({ category, setCategory, onSubmit, isLoading }) => {
  const t = useTranslator("components.category.uploadCategoryModal")
  const { categoryTypeOptions, getCategoryTypeOption } = useTranslatedCategoryTypes()
  const { onChangeInput: onChange, changeState } = useChangingComplexState(setCategory, isLoading)
  const {
    name,
    id,
    published,
    productIdList,
    collectionIdList,
    type
  } = { ...getDefaultCategory(), ...(category || {}) };

  const {
    inputRef: nameInputRef,
    throwError: throwErrorName
  } = useFormInputValidation(name)

  const onSubmitForm = () => {
    if ((name || "").length > 30) return throwErrorName(t("tooLongNameError"))
    onSubmit()
  }

  const hasContent = [...productIdList, ...collectionIdList].length > 0

  return (
    <Modal.FormContainer
      isOpen={!!category}
      toggleOpen={() => setCategory(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >
      <FormInput.Input
        innerRef={nameInputRef}
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
        error={(name || "").length > 30 ? t("tooLongNameErrorText") : ""}
      />

      <FormInput.Select
        label={t("typeLabel")}
        value={getCategoryTypeOption(type)}
        options={categoryTypeOptions}
        onChange={({ value }) => changeState("type", value)}
        isDisabled={hasContent || isLoading}
        isLoading={isLoading}
        tooltip={hasContent ? t("tooltipTypeUpdateWithContent") : undefined}
      />

      <FormInput.Switch
        onChange={onChange}
        checked={published}
        name={"published"}
        label={t("isPublishedLabel")}
        labels={t("isPublishedAnswers")}
      />
    </Modal.FormContainer>
  );
}

export default UploadCategoryModal;