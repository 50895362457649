import React from "react";
import { Header, StatsCard } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import ShowClubsCard from "../../../../components/clubs/ShowClubsCard";
import UploadClubModal from "../../../../components/clubs/UploadClubModal";
import RemoveClubModal from "../../../../components/clubs/RemoveClubModal";
import useClubs from "../../../../components/clubs/hooks/useClubs";
import useClub from "../../../../components/clubs/hooks/useClub";
import useTranslator from "../../../../hooks/useTranslator";


const Clubs = () => {
  const t = useTranslator("views.pages.dashboard.clubs")
  const {
    clubs,
    isLoading: isLoadingClubs,
    refresh: refreshClubs,
    changeActualUserClub
  } = useClubs();

  const {
    club: clubToUpload,
    setClub: setClubToUpload,
    save: saveClubToUpload,
    isLoading: isLoadingClubToUpload
  } = useClub()

  const {
    club: clubToRemove,
    setClub: setClubToRemove,
    remove: removeClubToRemove,
    isLoading: isLoadingClubToRemove
  } = useClub()

  const numberOfActiveClubs = clubs.filter(({ active }) => active).length

  return (
    <React.Fragment>

      <UploadClubModal
        club={clubToUpload}
        setClub={setClubToUpload}
        isLoading={isLoadingClubToUpload}
        onSubmit={async () => {
          return saveClubToUpload()
            .then(() => {
              refreshClubs()
              setClubToUpload(null)
            })
            .catch(() => null)
        }}
      />

      <RemoveClubModal
        club={clubToRemove}
        close={() => setClubToRemove(null)}
        remove={async () => {
          return removeClubToRemove()
            .then(() => {
              refreshClubs()
              setClubToRemove(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingClubToRemove}
      />

      <div>
        <Header.BreadCrumb color="secondary" isDark={false}>
          <Col xs={12} md={6} xl={3}>
            <StatsCard.Progress
              title={t("stats.activeTitle")}
              maxValue={clubs.length}
              value={numberOfActiveClubs}
              color={"default"}
            />
          </Col>
        </Header.BreadCrumb>
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="12">
              <ShowClubsCard
                clubs={clubs}
                setClubToRemove={setClubToRemove}
                setClubToUpload={setClubToUpload}
                isLoading={isLoadingClubs}
                changeUserClub={(club) => changeActualUserClub(club.id)}
              />
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
}

export default Clubs;