import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";

// Components
import StatsUsersTotalActiveNow from "../../../../components/stats/StatsUsersTotalActiveNow"
import StatsUsersTotalActive from "../../../../components/stats/StatsUsersTotalActive"

const RealTimeStats = () =>
  <React.Fragment>
    <Header.BreadCrumb color="secondary" isDark={false}/>
    <Container className="mt--6" fluid>
      <Row>
        <Col xs={12} md={6}>
          <StatsUsersTotalActiveNow/>
        </Col>
        <Col xs={12} md={6}>
          <StatsUsersTotalActive/>
        </Col>
      </Row>
    </Container>
  </React.Fragment>

export default RealTimeStats;