import React from "react";
import { FormInput, Modal } from "@general-backoffice/core";
import getDefaultTrainer from "./shared/getDefaultTrainer";
import { ResizeImageFileModals } from "../shared/ResizeImageFileModal";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import useApiFileImages from "../../hooks/useApiFileImages";
import { Row, Col } from "reactstrap";
import { FormDecoration } from "@general-backoffice/core/index";

const imagesStructure = {
  thumbnail: {
    size: [640, 960], // AR: 2:3 Portrait
    MB: 0.2
  },
  image: {
    size: [1920, 540], // AR: 32:9
    MB: 0.6
  },
}

const UploadTrainerModal = ({
                              trainer,
                              setTrainer,
                              isLoading = false,
                              onSubmit
                            }) => {
  const t = useTranslator("components.trainers.uploadTrainerModal")
  const { changeState, onChangeInput: onChange } = useChangingComplexState(setTrainer, isLoading)

  const {
    id,
    email,
    active,
    description,
    image,
    thumbnail,
    name
  } = { ...getDefaultTrainer(), ...(trainer || {}) }

  const {
    imagesToResize,
    setImagesToResize,
    files,
    setFiles,
    isLoadingFiles
  } = useApiFileImages({ image, thumbnail }, [id])

  const onSubmitForm = async (event) => {
    event.preventDefault()
    onSubmit();
  }

  const someIsLoading = (isLoading || isLoadingFiles);

  return (
    <React.Fragment>

      <ResizeImageFileModals
        structure={imagesStructure}
        imagesToResize={imagesToResize}
        changeImagesToResize={setImagesToResize}
        onSave={(key, file, image64) => {
          setFiles({ ...files, [key]: file })
          changeState(key, { id: null, data: image64 })
          setImagesToResize({ ...imagesToResize, [key]: null })
        }}
      />

      <Modal.FormContainer
        isOpen={!!trainer}
        toggleOpen={() => setTrainer(null)}
        title={t("title", !!id, name)}
        closeText={t("closeText")}
        buttonSave={{ children: t("buttonSaveText") }}
        onSubmit={onSubmitForm}
        isLoading={someIsLoading}
        size={"lg"}
      >
        <Row>
          <Col>
            <FormInput.Email
              onChange={onChange}
              value={email}
              name={"email"}
              label={t("emilLabel")}
              required={true}
              disabled={!!id}
            />

            <FormInput.Input
              onChange={onChange}
              value={name}
              name={"name"}
              label={t("nameLabel")}
              required={true}
            />

            <FormInput.Switch
              onChange={onChange}
              checked={active}
              name={"active"}
              label={t("isActiveLabel")}
              labels={t("isActiveAnswers")}
            />

            <FormInput.Input
              type={"textarea"}
              onChange={onChange}
              value={description}
              name={"description"}
              label={t("descriptionLabel")}
            />
          </Col>
          <Col xs={12} md={"auto"} className="px-0">
            <FormDecoration.Line vertical={true}/>
          </Col>
          <Col>
            <FormInput.SingleDropzone
              label={t("thumbnailLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.thumbnail.size.join(" x ")}
              tooltip={t("thumbnailTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.thumbnail}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, thumbnail: file })}
            />
            <FormInput.SingleDropzone
              label={t("imageLabel") + " (JPG, PNG)"}
              smallLabel={imagesStructure.image.size.join(" x ")}
              tooltip={t("imageTooltip")}
              accept={".jpg, .jpeg, .png"}
              file={files.image}
              onDrop={(file) => setImagesToResize({ ...imagesToResize, image: file })}
            />
          </Col>
        </Row>

      </Modal.FormContainer>
    </React.Fragment>
  );
}

export default UploadTrainerModal;