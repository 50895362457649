import React, { useEffect, useRef } from "react";
import { notify } from "@general-backoffice/core";
import { Col } from "reactstrap";
import AudioWaves from "../../shared/AudioWaves";

const PlayerWaves = ({ 
                       audioUrl, 
                       seconds, 
                       isPlaying, 
                       lightMode = false,
                      //  stop, 
                       goToSecond, 
                       setDuration, 
                       defaultDuration,
                       setLoading,
                       isLoading,
                       onMount,
                      }) => {
  const audioWavesRef = useRef();

  const onMountAudioWaves = (waveSurfer) => {
    onMount(waveSurfer)
    audioWavesRef.current = waveSurfer;
    audioWavesRef.current?.on("ready", () => {

      // Fix for waves size when parent hidden
      document.getElementById("player-waves-col").style.display = '';
      audioWavesRef.current?.drawBuffer();
      document.getElementById("player-waves-col").style.display = 'none';

      const duration = audioWavesRef.current?.getDuration();
      setLoading(false)
      setDuration(Math.max(defaultDuration, parseInt(duration)))
    });
    audioWavesRef.current?.on("error", e => {
      notify.error(e || "Error al cargar la música")
      setLoading(false)
    });
    audioWavesRef.current?.on("seek", () => {
      const currTime = audioWavesRef.current?.getCurrentTime();
      goToSecond(currTime)
    }); // FIXME: this has a bugg when playing, it pauses

  };

  useEffect(() => {
    if (audioWavesRef.current) {
      if (audioUrl) {
        setLoading(true)

        const myAudio = new Audio(audioUrl);
        myAudio.crossOrigin = 'myAudio';

        fetch(`${audioUrl}.json`)
          .then(response => {
            // eslint-disable-next-line
            if (!response.ok) throw "Error al cargar metadata de audio";
            return response.json();
          })
          .then(peaks => audioWavesRef.current?.load(myAudio, peaks.data))
          .catch(e => {
            notify.error(e.message || e)
            audioWavesRef.current?.load(myAudio)
          });

      }
    }
    return () => audioWavesRef.current && audioWavesRef.current?.empty()
    // eslint-disable-next-line
  }, [audioUrl])

  useEffect(() => {
    if(audioWavesRef.current){
      audioWavesRef.current.play(seconds) //So it is the same time event when paused
      if(!isPlaying) audioWavesRef.current.pause()
    }
    // eslint-disable-next-line
  }, [isPlaying])

  useEffect(() => {
    if(audioWavesRef.current && seconds === 0 && !isPlaying){
      audioWavesRef.current.stop()
    }
  }, [isPlaying, seconds])


  const showWaves = audioUrl && !isLoading
  return (
    <Col 
      id="player-waves-col" 
      className="position-relative p-0 mx-2"
      style={{display: showWaves ? "block" : "none", zIndex: 0}}
    >
      <AudioWaves isDark={!lightMode} onMount={onMountAudioWaves} responsive useCursor/>
    </Col>
  )
}

export default PlayerWaves;