import { useEffect, useState } from "react";
import { userRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import getDefaultUser from "../shared/getDefaultUser";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const useUser = (id) => {

  const { club } = useSelector((state) => state.sessionState)

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userId = user?.id || id

  useEffect(() => {
    if (!!userId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [userId])

  const refresh = async () => {
    setIsLoading(true)
    return getUser(userId)
      .then((user) => {
        setUser(user)
        return user
      })
      .catch((e) => {
        setUser(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveUser(user)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const subscribe = async (planId, subscriptionStart, subscriptionEnd) => {
    setIsLoading(true)
    return subscribeUser({
      userId: user.id,
      planId,
      clubId: (club.id || club.clubId),
      subscriptionStart,
      subscriptionEnd
    })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    user,
    setUser,
    refresh,
    subscribe,
    save,
    isLoading
  }
}

export const getUser = async (userId) => {
  const t = hardTranslator("components.users.hooks.useUser")

  try {
    return await userRequests.getInfo(userId)
  } catch (e) {
    const message = e?.message || t("getUserError")
    notify.error(message)
    throw new Error(message)
  }
}

export const subscribeUser = async (body) => {
  const t = hardTranslator("components.users.hooks.useUser")
  try {
    await userRequests.subscribe(body)
  } catch (e) {
    const message = e?.message || t("subscribeUserError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveUser = async (user) => {
  const t = hardTranslator("components.users.hooks.useUser")
  const notification = notify.loading(t("saveUserLoading", user?.id))
  try {
    const { subscriptionList, ...cleanUser } = { ...getDefaultUser(), ...user }
    if (!!user?.id) {
      await userRequests.put(user.id, cleanUser)
      notifyLoadingToSuccess(notification, t("saveUserSucceed", true))
    } else {
      await userRequests.post(cleanUser)
      notifyLoadingToSuccess(notification, t("saveUserSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveUserError", user?.id)
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useUser;