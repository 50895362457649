import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { couponRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";
import usePaginationParamRequest from "../../../hooks/usePaginationParamRequest";

const useCoupons = (initialParamsRequest = {}) => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [coupons, setCoupons] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { paramsRequest, changeParam, ...ppr } = usePaginationParamRequest(initialParamsRequest)


  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [paramsRequest, clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getCoupons(paramsRequest)
      .then(({ couponList, count }) => {
        setCoupons(couponList)
        setTotalElements(count)
        return couponList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    coupons,
    isLoading,
    paramsRequest,
    totalElements,
    ...ppr,
    refresh
  }
}

export const getCoupons = async (paramsRequest) => {
  const t = hardTranslator("components.coupons.hooks.useCoupons")

  try {
    const { content = [], totalElements } = await couponRequests.getList(paramsRequest)
    return { couponList: content, count: totalElements }
  } catch (e) {
    const message = (e?.message || t("getCouponsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useCoupons;