import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useNotifications from "../../../../components/notifications/hooks/useNotifications";
import ShowNotificationsCard from "../../../../components/notifications/ShowNotificationsCard";
import UploadNotificationModal from "../../../../components/notifications/UploadNotificationModal";
import UploadNotificationSendFiltersModal
  from "../../../../components/notifications/UploadNotificationSendFiltersModal";
import useNotification from "../../../../components/notifications/hooks/useNotification";
import RemoveNotificationModal from "../../../../components/notifications/RemoveNotificationModal";

const Notifications = () => {

  const {
    notifications,
    changePageNumber: changePageNotifications,
    requestParams: paginationNotifications,
    totalAmount: totalNotifications,
    isLoading: isLoadingNotifications,
    refresh: refreshNotifications
  } = useNotifications()

  const {
    notification: notificationToUpload,
    setNotification: setNotificationToUpload,
    isLoading: isLoadingNotificationToUpload,
    save: saveNotificationToUpload
  } = useNotification()

  const {
    notification: notificationToSend,
    setNotification: setNotificationToSend,
    isLoading: isLoadingNotificationToSend,
    send: sendNotificationToSend
  } = useNotification()

  const {
    notification: notificationToRemove,
    setNotification: setNotificationToRemove,
    isLoading: isLoadingNotificationToRemove,
    remove: removeNotificationToRemove
  } = useNotification()

  return (
    <React.Fragment>

      <UploadNotificationModal
        notification={notificationToUpload}
        setNotification={setNotificationToUpload}
        isLoading={isLoadingNotificationToUpload}
        onSubmit={async () => {
          return saveNotificationToUpload()
            .then(() => {
              refreshNotifications()
              setNotificationToUpload(null)
            })
            .catch(() => null)
        }}
      />

      <RemoveNotificationModal
        notification={notificationToRemove}
        close={() => setNotificationToRemove(null)}
        isLoading={isLoadingNotificationToRemove}
        remove={async () => {
          return removeNotificationToRemove()
            .then(() => {
              refreshNotifications()
              setNotificationToRemove(null)
            })
            .catch(() => null)
        }}
      />

      <UploadNotificationSendFiltersModal
        notification={notificationToSend}
        close={() => setNotificationToSend(null)}
        isLoading={isLoadingNotificationToSend}
        onSubmit={async (filters) => {
          return sendNotificationToSend(filters)
            .then(() => {
              refreshNotifications()
              setNotificationToSend(null)
            })
            .catch(() => null)
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>

      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowNotificationsCard
              notifications={notifications}
              setNotificationToUpload={setNotificationToUpload}
              setNotificationToSend={setNotificationToSend}
              setNotificationToRemove={setNotificationToRemove}
              onClickNotification={setNotificationToUpload}
              page={paginationNotifications?.pageNumber}
              amount={paginationNotifications?.pageSize}
              setPage={changePageNotifications}
              isLoading={isLoadingNotifications}
              totalEntries={totalNotifications}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Notifications;