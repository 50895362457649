import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import { useSelector } from "react-redux";
import useTranslator from "../../hooks/useTranslator";

const ShowTrainersCard = ({
                            trainers,
                            setTrainerToUpload,
                            setTrainerToRemove,
                            setTrainerToShowMeetingTemplates,
                            setTrainerToShowSchedule,
                            onClickTrainer,
                            isLoading
                          }) => {
  const t = useTranslator("components.trainers.showTrainersCard")
  const { club: userClub } = useSelector(({ sessionState }) => sessionState)
  const { companyCode } = userClub || {}
  const isFBT = companyCode === "FBT"

  const columns = [
    {
      name: t("name"),
      formattedContent: ({ name, thumbnail }) => <TableOutput.ImageText text={name} src={thumbnail?.url}/>,
      isTh: true
    },
    {
      name: t("status"),
      formattedContent: ({ active }) => (
        <TableOutput.Status
          status={t("statusText", active)}
          color={active ? "success" : "danger"}
        />
      )
    },
    {
      name: "",
      formattedContent: (trainer) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setTrainerToUpload({ ...trainer }),
            description: t("modifyDescription")
          },

          {
            icon: <i className="fas fa-chalkboard-teacher" hidden={isFBT}/>,
            description: t("MeetingsTemplatesDescription"),
            onClick: () => setTrainerToShowMeetingTemplates({ ...trainer })
          },

          {
            icon: <i className="fas fa-calendar-alt" hidden={isFBT}/>,
            description: t("calendarDescription"),
            onClick: () => setTrainerToShowSchedule({ ...trainer })
          },


          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setTrainerToRemove({ ...trainer })
          },
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("addButtonText")}/>, onClick: () => setTrainerToUpload({}) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={trainers}
        onRowClick={(trainer) => onClickTrainer({ ...trainer })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
      />
    </Card>
  );
}

export default ShowTrainersCard;