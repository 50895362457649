import { useEffect, useState } from "react";
import { productRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";
import usePaginationParamRequest from "../../../hooks/usePaginationParamRequest";

const defaultParamsRequest = {
  search: undefined
}

const useProducts = (initialParamsRequest = defaultParamsRequest) => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [products, setProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const { paramsRequest, changeParam, ...ppr } = usePaginationParamRequest({ ...defaultParamsRequest, ...initialParamsRequest })
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [paramsRequest, clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getProducts(paramsRequest)
      .then(({ productList = [], count = 0 }) => {
        setProducts(productList)
        setTotalAmount(count)
      })
      .finally(() => setIsLoading(false))
  }

  const changeSearch = (search) => changeParam("search", search)

  return {
    products,
    changeSearch,
    paramsRequest,
    changeParam,
    ...ppr,

    totalAmount,
    isLoading,
    refresh
  }
}

export const getProducts = async (paramsRequest) => {
  const t = hardTranslator("components.products.hooks.useProducts")

  try {
    const { content = [], totalElements } = await productRequests.getList(paramsRequest)
    return { productList: content, count: totalElements }
  } catch (e) {
    const message = (e?.message || t("getProductsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useProducts;