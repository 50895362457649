import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveCollectionModal = ({
                                   collection,
                                   close,
                                   remove,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.collection.removeCollectionModal")
  const { title, id } = (collection || {});

  return (
    <Modal.DeleteConfirm
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", title)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveCollectionModal;