import { useState, useEffect } from "react";

const useCountdown = ({seconds: startSeconds = 3, callback = () => null}) => {
  const [seconds, setSeconds] = useState(-1)

  useEffect(() => {
    if(seconds >= 0) {
      const timer = setTimeout(() => {
        let nextSec = seconds - 1
        if(nextSec > 0) 
          setSeconds(nextSec)
        else {
          setSeconds(-1)
          callback()
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [seconds]);

  return {
    startCountdown: () => {
      if(seconds < 0) setSeconds(startSeconds)
    }, 
    countdownSeconds: seconds,
    countingdown: seconds >= 0
  }
}

export default useCountdown;