import { useEffect, useMemo, useState } from "react";
import { newRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const defaultPagination = { page: 1, amount: 10 }

const useNews = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [news, setNews] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination)
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const paramsRequest = useMemo(() => ({
    page: pagination.page,
    size: pagination.amount
  }), [pagination])

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [paramsRequest, clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getNews(paramsRequest)
      .then(({ news, totalAmount }) => {
        setNews(news)
        setTotalAmount(totalAmount)
        return news
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const changePage = (page) => setPagination((currentPagination) => ({ ...currentPagination, page }))
  const changeAmount = (amount) => setPagination((currentPagination) => ({ ...currentPagination, amount }))

  return {
    news,
    isLoading,
    pagination,
    changePage,
    changeAmount,
    totalAmount,
    refresh
  }
}

export const getNews = async (paramsRequest) => {
  const t = hardTranslator("components.news.hooks.useNews")

  try {
    const { newsList = [], count = 0 } = await newRequests.getList(paramsRequest)
    return { news: newsList, totalAmount: count }
  } catch (e) {
    const message = (e?.message || t("getNewsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useNews;