import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { notify, Card, Pagination, Loading, Modal, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { successColor, errorColor } from './config/colors'
import UploadStatsPeriodModal, {
  format,
  formatHuman,
  initialEndDate,
  initialStartDate
} from "../shared/UploadStatsPeriodModal";
import { statMedium } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";

let statsPerPage = 10

const StatsProductsLikes = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsProductsLikes")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [isLoading, setLoading] = useState(true)
  const [{ startDate, endDate }, setLocalDates] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [isModalInfoOpen, setModalInfoOpen] = useState(false)
  const [productLikeList, setProductLikeList] = useState([]);
  const [productsCount, setProductsCount] = useState(10)
  const [page, setPage] = useState(0);

  const refresh = () => {
    statsRequests.getTop10Likes(format(startDate), format(endDate), page + 1)
      .then(({ productLikeList, count }) => {
        setProductLikeList(productLikeList)
        setProductsCount(count)
      })
      .catch((e) => notify.error(e.message || t("getStatsError")))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [clubId, startDate, endDate, page])

  let showPagination = productsCount / statsPerPage >= 1
  const hasInfo = productLikeList.length > 0;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />

      <Modal.Info
        titleInfo={t("infoTitle")}
        closeText={t("infoCloseText")}
        isOpen={isModalInfoOpen}
        toggleOpen={setModalInfoOpen}>
        {t("infoContent")}
      </Modal.Info>

      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-question"/>, color: "transparent", onClick: () => setModalInfoOpen(true) },
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        <CardBody>
          {isLoading && <Loading.BouncingDots className="my-5"/>}
          {(!hasInfo && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          {(hasInfo && !isLoading) && (
            <div style={{ position: "relative", height: statMedium }}>
              <Bar
                type={"bar"}
                data={{
                  labels: productLikeList.map(({ title }) => title),
                  datasets: [{
                    label: t("like"),
                    data: productLikeList.map(({ likes }) => likes),
                    borderRadius: 5,
                    backgroundColor: successColor,
                  }, {
                    label: t("dislike"),
                    data: productLikeList.map(({ dislikes }) => dislikes),
                    borderRadius: 5,
                    backgroundColor: errorColor,
                  }],
                }}
                options={{
                  maintainAspectRatio: false,
                  indexAxis: 'y',
                  responsive: true,
                  scales: {
                    y: {
                      stacked: true,
                      display: !simple,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    },
                    x: {
                      stacked: true,
                      display: !simple,
                      grid: {
                        drawBorder: false
                      },
                      ticks: {
                        suggestedMin: 0,
                        stepSize: 5,
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  interaction: {
                    intersect: false,
                    mode: 'y',
                  },
                }}
              />
            </div>
          )}
        </CardBody>
        {showPagination &&
        <Card.Footer>
          <Pagination totalEntries={productsCount} amount={statsPerPage} page={page} onChangePage={setPage}/>
        </Card.Footer>
        }
      </ReactstrapCard>
    </React.Fragment>
  );
}


export default StatsProductsLikes;