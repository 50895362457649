const getDefaultNotification = () => ({
  active: true,
  daysBefore: 0,
  entityId: 0,
  local: true,
  message: "",
  notificationType: "DEFAULT",
  schedule: "",
  sent: false,
  title: ""
})

export default getDefaultNotification;