import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const RemoveCategoryModal = ({
                                   category,
                                   close,
                                   remove,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.category.removeCategoryModal")
  const { name, id } = (category || {});

  return (
    <Modal.DeleteConfirm
      isOpen={!!id}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete={t("titleDelete")}
      descriptionDelete={t("descriptionDelete", name)}
      closeText={t("closeText")}
      buttonDelete={{ children: t("buttonDeleteText"), onClick: remove }}
    />
  );
}

export default RemoveCategoryModal;