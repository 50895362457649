import React, { useState } from "react";
import { Table, TableCard, TableOutput } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowSlidesCard = ({
                          slides,
                          sortSlides,
                          setSlideToUpload,
                          setSlideToRemove,
                          onClickSlide,
                          isLoading
                        }) => {
  const t = useTranslator("components.slider.showSlidesCard")
  const [page, setPage] = useState(1)
  const [amount, setAmount] = useState(10)

  const onDataChange = async (sortedSlides, trigger) => {
    if (trigger === "sortable") {
      sortSlides(sortedSlides)
    }
  }

  const columns = [
    {
      name: t("name"),
      property: "title",
      isTh: true
    },
    {
      name: t("images"),
      formattedContent: ({ image, imagePortrait }) => {
        const images = [image, imagePortrait].map((i) => ({ src: i?.url }))
        return <TableOutput.Images images={images}/>
      },
    },
    {
      name: "URL",
      formattedContent: ({ externalUrl }) => !!externalUrl ? (externalUrl.substring(0, 30) + "...") : "",
    },
    {
      name: "",
      formattedContent: (tag) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setSlideToUpload({ ...tag }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setSlideToRemove({ ...tag })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle")}
        buttons={[
          {
            children: <AddButtonChildren children={t("buttonAddText")}/>,
            onClick: () => setSlideToUpload({})
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        sortable={true}
        onDataChange={onDataChange}
        columns={columns}
        rows={slides.slice(((page - 1) * amount), ((page - 1) * amount) + amount)}
        onRowClick={(tag) => onClickSlide({ ...tag })}
        isLoading={isLoading}
        noContentMessage={t("emptyContent")}
        rowAmount={amount}
      />
      <TableCard.Footer
        setAmount={setAmount}
        amount={amount}
        totalEntries={slides.length}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowSlidesCard;