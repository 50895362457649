import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import favicon from "../media/branding/favicon.ico"
import faviconFBT from "../media/branding/FBT/favicon.ico"
import faviconMCT from "../media/branding/MCT/favicon.ico"
import faviconSPT from "../media/branding/SPT/favicon.ico"
import faviconKNC from "../media/branding/KNC/favicon.ico"
import faviconTorole from "../media/branding/KNC/TOROLE/favicon.ico"
import faviconTaiChi from "../media/branding/KNC/TAI_CHI/favicon.ico"

import logoSquadFBT from "../media/branding/FBT/logo-dark-squad.png"
import logoSquadMCT from "../media/branding/MCT/logo-dark-squad.png"
import logoSquadKNC from "../media/branding/KNC/logo-dark-squad.png"
import logoSquadSPT from "../media/branding/SPT/logo-dark-squad.png"
import logoSquadTTT from "../media/branding/KTT/logo-dark-squad.png"
import logoSquadTorole from "../media/branding/KNC/TOROLE/logo-dark-squad.png"
import logoSquadTaiChi from "../media/branding/KNC/TAI_CHI/logo-dark-squad.png"

import logoLightFBT from "../media/branding/FBT/logo-light.png"
import logoLightMCT from "../media/branding/MCT/logo-light.png"
import logoLightKNC from "../media/branding/KNC/logo-light.png"
import logoLightSPT from "../media/branding/SPT/logo-light.png"
import logoLightTorole from "../media/branding/KNC/TOROLE/logo-light.png"
import logoLightTaiChi from "../media/branding/KNC/TAI_CHI/logo-light.png"

import logoDarkFBT from "../media/branding/FBT/logo-dark.png"
import logoDarkFSPT from "../media/branding/SPT/logo-dark.png"
import logoDarkMCT from "../media/branding/MCT/logo-dark.png"
import logoDarkKNC from "../media/branding/KNC/logo-dark.png"
import logoDarkTorole from "../media/branding/KNC/TOROLE/logo-dark.png"
import logoDarkTaiChi from "../media/branding/KNC/TAI_CHI/logo-dark.png"

const companies = [
  {
    code: "000",
    domain: "SYSTEM.COM",
    SEO: {
      title: "Panel de control",
      description: "Panel de control",
      favicon: favicon
    },
    images: {
      // logoSquad: logoSquadMCT,
      // logoLight: logoLightMCT,
      // logoDark: logoDarkMCT
    },
    colors: {
      "default": "#172b4d",
      "default-grad": "#0e1f3b",
      "default-dark": "#0e1f3b",
      "primary": "#1e549d",
      "primary-grad": "#173e72",
      "primary-dark": "#173e72"
    }
  },
  {
    code: "MCT",
    domain: "myclub.tv",
    SEO: {
      title: "My Club - Panel de control",
      description: "My Club - Panel de control",
      favicon: faviconMCT
    },
    images: {
      logoSquad: logoSquadMCT,
      logoLight: logoLightMCT,
      logoDark: logoDarkMCT
    },
    colors: {
      "default": "#172b4d",
      "default-grad": "#0e1f3b",
      "default-dark": "#0e1f3b",
      "primary": "#1e549d",
      "primary-grad": "#173e72",
      "primary-dark": "#173e72"
    }
  },
  {
    code: "SPT",
    domain: "backoffice-pre.captain1.senyprojects.com",
    SEO: {
      title: "Sprinter - Panel de control",
      description: "Sprinter - Panel de control",
      favicon: faviconSPT
    },
    images: {
      logoSquad: logoSquadSPT,
      logoLight: logoLightSPT,
      logoDark: logoDarkFSPT
    },
    colors: {
      "default": "#0a3523",
      "default-grad": "#072518",
      "default-dark": "#072518",
      "primary": "#1a8c58",
      "primary-grad": "#136641",
      "primary-dark": "#136641"
    }
  },
  {
    code: "SPT",
    domain: "pass.sprintersports.com",
    SEO: {
      title: "Sprinter - Panel de control",
      description: "Sprinter - Panel de control",
      favicon: faviconSPT
    },
    images: {
      logoSquad: logoSquadSPT,
      logoLight: logoLightSPT,
      logoDark: logoDarkFSPT
    },
    colors: {
      "default": "#0a3523",
      "default-grad": "#072518",
      "default-dark": "#072518",
      "primary": "#1a8c58",
      "primary-grad": "#136641",
      "primary-dark": "#136641"
    }
  },
  {
    code: "FBT",
    domain: "club.infinityride.tv",
    SEO: {
      title: "Infinity Ride - Panel de control",
      description: "Infinity Ride - Panel de control",
      favicon: faviconFBT
    },
    images: {
      logoSquad: logoSquadFBT,
      logoLight: logoLightFBT,
      logoDark: logoDarkFBT
    },
    colors: {
      "default": "#2c2c2c",
      "default-grad": "#1c1b1b",
      "default-dark": "#1c1b1b",
      "primary": "#e30613",
      "primary-grad": "#9b050e",
      "primary-dark": "#9b050e"
    }
  },
  {
    code: "KNC",
    domain: "kinacu.com",
    SEO: {
      title: "Kinacu - Panel de control",
      description: "Kinacu - Panel de control",
      favicon: faviconKNC
    },
    images: {
      logoSquad: logoSquadKNC,
      logoLight: logoLightKNC,
      logoDark: logoDarkKNC
    },
    colors: {
      "default": "#01303b",
      "default-grad": "#01232b",
      "default-dark": "#01232b",
      "primary": "#02687f",
      "primary-grad": "#034250",
      "primary-dark": "#034250"
    }
  },
  {
    code: "KNC",
    domain: "app.mislancesdecaza.es",
    SEO: {
      title: "Kinacu - Panel de control",
      description: "Kinacu - Panel de control",
      favicon: faviconKNC
    },
    images: {
      logoSquad: logoSquadKNC,
      logoLight: logoLightKNC,
      logoDark: logoDarkKNC
    },
    colors: {
      "default": "#01303b",
      "default-grad": "#01232b",
      "default-dark": "#01232b",
      "primary": "#02687f",
      "primary-grad": "#034250",
      "primary-dark": "#034250"
    }
  },
  {
    code: "KNC",
    domain: "go.torole.tv",
    SEO: {
      title: "Torolé - Panel de control",
      description: "Torolé - Panel de control",
      favicon: faviconTorole
    },
    images: {
      logoSquad: logoSquadTorole,
      logoLight: logoLightTorole,
      logoDark: logoDarkTorole
    },
    colors: {
      "default": "#942867",
      "default-grad": "#6d1f4c",
      "default-dark": "#6d1f4c",
      "primary": "#f543a8",
      "primary-grad": "#942867",
      "primary-dark": "#942867"
    }
  },
  {
    code: "KNC",
    domain: "taichi.fight-time.com",
    SEO: {
      title: "Tai Chi - Panel de control",
      description: "Tai Chi - Panel de control",
      favicon: faviconTaiChi
    },
    images: {
      logoSquad: logoSquadTaiChi,
      logoLight: logoLightTaiChi,
      logoDark: logoDarkTaiChi
    },
    colors: {
      "default": "#a57327",
      "default-grad": "#624417",
      "default-dark": "#624417",
      "primary": "#eda73c",
      "primary-grad": "#a57327",
      "primary-dark": "#a57327"
    }
  },
  {
    code: "TTT",
    domain: "ttt.kinacu.com",
    SEO: {
      title: "Kinacu TEST - Panel de control",
      description: "Kinacu TEST - Panel de control",
      favicon: faviconKNC
    },
    images: {
      logoSquad: logoSquadTTT,
      logoLight: logoLightKNC,
      logoDark: logoDarkKNC
    },
    colors: {
      "default": "#01303b",
      "default-grad": "#01232b",
      "default-dark": "#01232b",
      "primary": "#02687f",
      "primary-grad": "#034250",
      "primary-dark": "#034250"
    }
  },
]

const getCompanyByLocation = () => companies.find(({ domain }) => domain.includes(window.location.host) || window.location.host.includes(domain))
const getCompanyByCode = (code) => companies.find((company) => company.code === code)

const useClubStyles = () => {

  const club = useSelector(({ sessionState }) => sessionState.club)
  const { companyCode } = (club || [])

  const company = getCompanyByLocation() || getCompanyByCode(companyCode) || companies[0]

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--default", company.colors["default"]);
    root?.style.setProperty("--default-grad", company.colors["default-grad"]);
    root?.style.setProperty("--default-dark", company.colors["default-dark"]);
    root?.style.setProperty("--primary", company.colors["primary"]);
    root?.style.setProperty("--primary-grad", company.colors["primary-grad"]);
    root?.style.setProperty("--primary-dark", company.colors["primary-dark"]);
  }, [company]);

  const helmet = (
    <Helmet>
      <title>{company.SEO.title}</title>
      <meta name="description" content={company.SEO.description}/>
      <link rel="icon" href={company.SEO.favicon}/>
    </Helmet>
  )

  return {
    helmet,
    logoSquad: company.images.logoSquad,
    logoLight: company.images.logoLight,
    logoDark: company.images.logoDark
  };
}


export default useClubStyles;
