import { useMemo } from "react";
import useTranslator from "../useTranslator";

const navigationItemTypes = ["_self", "_blank"]
const useTranslatedNavigationItemTypes = () => {
  const t = useTranslator("navigationItemTypes")

  const translatedNavigationItemTypes = useMemo(() => getTranslatedNavigationItemTypes(t), [t])
  const navigationItemTypeOptions = useMemo(() => getNavigationItemTypeOptions(t), [t])
  const getNavigationItemTypeOption = (v) => findNavigationItemTypeOption(t, v)

  return {
    navigationItemTypes,
    translatedNavigationItemTypes,
    navigationItemTypeOptions,
    getNavigationItemTypeOption
  }
}

const getTranslatedNavigationItemTypes = (t) => {
  return navigationItemTypes.reduce((translated, navigationItemType) => {
    translated[navigationItemType] = t(navigationItemType) || navigationItemType
    return translated
  }, {})
}

const getNavigationItemTypeOptions = (t) => {
  return navigationItemTypes.map((navigationItemType) => ({
    value: navigationItemType,
    label: t(navigationItemType) || navigationItemType
  }))
}

const findNavigationItemTypeOption = (t, value) => {
  return navigationItemTypes.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedNavigationItemTypes;
export {
  navigationItemTypes,
  getTranslatedNavigationItemTypes,
  getNavigationItemTypeOptions,
  findNavigationItemTypeOption
}