import React from "react";
import { colors } from "../../../config"

const BarTitle = ({text = "", style, className}) => {
  return(<>
    {text && 
      <div 
        className={"text-center metropolis " + className}
        style={{
          color: colors.light,
          fontWeight: 700,
          lineHeight: 1.2,
          letterSpacing: 1.05,  
          fontSize: 'calc(0.6vw + 7px)',
          ...style
        }}
      >
        {text}
      </div>
    }
  </>
  )
}


export default BarTitle;