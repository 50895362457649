import React from "react";
import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject"
import clock from "../../../utils/clock"
import BigTimer from "./shared/BigTimer"

const validateTime = time => formatSecondsToTimeObject(time >= 0 ? time : null)

const GroupTime = ({ currentSegment, currentTime, groups, countdown = false, scale }) => { 
  let { start, end } = groups?.[currentSegment?.groupIndex] || {}
  let passedSeconds = currentTime?.totalSeconds - start
  let duration = end - start
  
  return <BigTimer 
    text={<>
      <div style={{marginBottom: 2}}>TIEMPO</div>
      <div>EN ZONA</div>
    </>}
    time={clock(validateTime(
      countdown ? 
       (duration - passedSeconds)
      : 
        passedSeconds
      , duration
    ))}
    scale={scale}
  />
}

export default GroupTime;