import React from "react";
import { Header } from "@general-backoffice/core/index";
import { Col, Container, Row } from "reactstrap";
// import moment from "moment";

// Components
import StatsUsersLocation from "../../../../components/stats/StatsUsersLocation"
import StatsUsersSubscriptions
  from "../../../../components/stats/StatsUsersSubscriptions"
import StatsUsersPlans from "../../../../components/stats/StatsUsersPlans"


const UsersStats = () => {
  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <StatsUsersLocation/>
          </Col>
          <Col xs={12} md={6}>
            <StatsUsersSubscriptions/>
          </Col>
          <Col xs={12} md={6}>
            <StatsUsersPlans/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default UsersStats;