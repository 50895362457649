import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { planRequests } from "../../../api/requests";
import { hardTranslator } from "../../../hooks/useTranslator";

const usePlans = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [clubId])

  const refresh = async () => {
    setIsLoading(true)
    return getPlans()
      .then((planList) => {
        setPlans(planList)
        return planList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    plans,
    isLoading,
    refresh
  }
}

export const getPlans = async () => {
  const t = hardTranslator("components.plans.hooks.usePlans")

  try {
    const { planList = [] } = await planRequests.getList()
    return planList
  } catch (e) {
    const message = (e?.message || t("getPlansError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default usePlans;