import React from "react";
import zoneSquare from "../media/zoneSquares/zoneSquare.svg"
import zoneSquare1 from "../media/zoneSquares/zoneSquare1.svg"
import zoneSquare2 from "../media/zoneSquares/zoneSquare2.svg"
import zoneSquare3 from "../media/zoneSquares/zoneSquare3.svg"
import zoneSquare4 from "../media/zoneSquares/zoneSquare4.svg"
import zoneSquare5 from "../media/zoneSquares/zoneSquare5.svg"
import zoneSquare5MAX from "../media/zoneSquares/zoneSquare5MAX.svg"
import zoneSquare6 from "../media/zoneSquares/zoneSquare6.svg"
import zoneSquare7 from "../media/zoneSquares/zoneSquare7.svg"
import { Button } from "reactstrap";
import classNames from "classnames";


const zoneSquareImages = {
  "1": zoneSquare1,
  "2": zoneSquare2,
  "3": zoneSquare3,
  "4": zoneSquare4,
  "5": zoneSquare5,
  "5MAX": zoneSquare5MAX,
  "6": zoneSquare6,
  "7": zoneSquare7
}

const ZoneSquare = ({ zone, ...props }) => <img {...props} src={(zoneSquareImages[zone] || zoneSquare)} alt=""/>

const ZoneSquareButton = ({ zone, className, ...props }) => {
  return (
    <Button className={classNames("zone-square-button", className)} {...props}>
      <ZoneSquare zone={zone}/>
    </Button>
  );
}

export default ZoneSquare;
export { ZoneSquareButton }