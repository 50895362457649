import { useMemo } from "react";
import { useSelector } from "react-redux";
import lodash from "lodash"
import es from "./../locale/es"
import en from "./../locale/en"

const languages = {
  es: { name: "Español", dictionary: es },
  en: { name: "English", dictionary: en }
}
const defaultLanguage = "en"

const translator = (language, initialKey, ...initialKeyParams) => {
  const dictionary = Object.keys(languages).includes(language) ? languages[language].dictionary : languages[defaultLanguage].dictionary
  let initialKeyValue = lodash.get(dictionary, initialKey, dictionary)

  if (typeof initialKeyValue === "function") {
    initialKeyValue = initialKeyValue(...initialKeyParams)
  }
  const translate = (key, ...params) => {
    const response = lodash.get(initialKeyValue, key, undefined)
    if (typeof response === "function") return response(...params);
    return response;
  }

  return translate
}

const useTranslator = (initialKey, ...initialKeyParams) => {
  const { club } = useSelector((state) => state.sessionState)
  const language = club?.clubProperties?.language?.ietfTag || defaultLanguage

  // eslint-disable-next-line
  return useMemo(() => translator(language, initialKey, ...initialKeyParams), [language, initialKey, ...initialKeyParams])
}

const hardTranslator = (initialKey, ...initialKeyParams) => {
  let language = defaultLanguage
  try {
    const session = JSON.parse(localStorage.getItem("persist:sessionAdmin"))
    const club = JSON.parse(session.club)
    language = club.clubProperties.language.ietfTag
  } catch (e) {
    language = defaultLanguage
  }
  return translator(language, initialKey, ...initialKeyParams)
}

export default useTranslator;
export { languages, hardTranslator, defaultLanguage }