import React from "react";
import { Navigate } from "react-router";
import Plans from "../../../views/pages/dashboard/subscriptions/Plans";
import Coupons from "../../../views/pages/dashboard/subscriptions/Coupons";


const subRoutes = (t) => [
  {
    name: t("plans"),
    miniName: t("plans").charAt(0).toUpperCase(),
    path: "plans",
    showOnNav: true,
    component: <Plans/>,
  },
  {
    name: t("coupons"),
    miniName: t("coupons").charAt(0).toUpperCase(),
    path: "coupons",
    showOnNav: true,
    component: <Coupons/>,
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard/subscriptions/plans"} replace={true}/>,
  }
]

export { subRoutes };