import Api from "../Api";

const getClub = (id) => Api.get(`/admin/company/club/${id}`, { params: { club: undefined } });
const getClubList = () => Api.get(`/admin/company/clubList`, { params: { club: undefined } });
const deleteClub = (id) => Api.delete(`/admin/company/club/delete/${id}`, { params: { club: undefined } });
const putClub = (body) => Api.put(`/admin/company/club/edit`, body, { params: { club: undefined } });
const postClub = (body) => Api.post(`/admin/company/club/create`, body, { params: { club: undefined } });
const getClubsUser = () => Api.get(`/admin/admins/clubs`, { params: { club: undefined } });
const getClubConfig = () => Api.get(`/admin/configuration/club`);
const getClubSeo = () => Api.get(`/admin/configuration/seo`);
const changeClubConfig = (body) => Api.put(`/admin/configuration/admin/club`, body);
const changeClubSeo = (body) => Api.put(`/admin/configuration/seo`, body);

const clubRequests = {
  get: getClub,
  getList: getClubList,
  getClubsUser: getClubsUser,
  delete: deleteClub,
  put: putClub,
  post: postClub,
  getConfig: getClubConfig,
  getSeo: getClubSeo,
  changeConfig: changeClubConfig,
  changeSeo: changeClubSeo
}

export default clubRequests