import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { channelRequests } from "../../../api/requests";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { v4 as uuid } from "uuid";
import { fixChannelEventsOverlap } from "../shared/channelEventsUtils";
import { hardTranslator } from "../../../hooks/useTranslator";

export const loadingEventChannel = {
  end: "1997-11-04T00:00:00+01:00",
  start: "1997-11-04T00:00:08+01:00",
  type: "streaming",
  url: "https://media1.ultrik.com/vod/OUTPUT/2022/473_loading_loop/playlist.m3u8",
  id: uuid()
}

const useChannel = (url) => {
  const [channel, setChannel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const channelUrl = channel?.url || url;

  useEffect(() => {
    if (!!channelUrl) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [channelUrl])

  const refresh = async () => {
    setIsLoading(true)
    return getChannel(channelUrl)
      .then((channel) => {
        setChannel(channel)
        return channel
      })
      .catch((e) => {
        setChannel(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveChannel(channel)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    setIsLoading(true)
    return removeChannel(channelUrl)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const removeEvent = (id) => {
    if (!id) return;
    setChannel((currentChannel) => {
      const { data = [] } = currentChannel
      if (data.length <= 1) {
        notify.error("No está permitido eliminar el último evento")
        return { ...currentChannel }
      }
      const filterEvents = data.filter((iterEvent) => id !== iterEvent.id);
      return { ...currentChannel, data: [...filterEvents] }
    })
  }

  const uploadEvent = (event) => {
    if (!event?.id) return;
    const { creating, ...cleanEvent } = event;
    setChannel((currentChannel) => {
      const { data = [] } = currentChannel
      const eventExists = data.find(({ id }) => id === cleanEvent.id);
      if (eventExists) {
        const newEvents = [];
        data.forEach((iterEvent) => {
          if (iterEvent.id === cleanEvent.id) newEvents.push({ ...cleanEvent })
          else newEvents.push({ ...iterEvent })
        })
        return { ...currentChannel, data: [...newEvents] }
      } else {
        return {
          ...currentChannel,
          data: [...data, { ...cleanEvent }]
        }
      }
    })
  }

  const createEvent = (event) => {
    if (!event?.id) return;
    setChannel((currentChannel) => {
      const { data = [] } = currentChannel
      return { ...currentChannel, data: [...data, { ...event }] }
    })
  }

  return {
    channel,
    setChannel,
    isLoading,
    save,
    remove,
    removeEvent,
    uploadEvent,
    createEvent
  }
}

export const getChannel = async (url) => {
  const t = hardTranslator("components.channels.hooks.useChannel")
  try {
    return await channelRequests.get(url)
  } catch (e) {
    const message = e?.message || t("getChannelError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveChannel = async (channel) => {
  const t = hardTranslator("components.channels.hooks.useChannel")
  const notification = notify.loading(t("saveChannelLoading", !!channel?.url))
  try {
    const { data, isLoop, ...cleanChannel } = channel
    const fixedData = fixChannelEventsOverlap(data)
    if (!!channel?.url) {
      await channelRequests.put(channel.url, { ...cleanChannel, data: fixedData.events })
      notifyLoadingToSuccess(notification, t("saveChannelSucceed", true))
    } else {
      await channelRequests.post({ ...cleanChannel, data: isLoop ? [] : [loadingEventChannel] })
      notifyLoadingToSuccess(notification, t("saveChannelSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveChannelError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export const removeChannel = async (url) => {
  const t = hardTranslator("components.channels.hooks.useChannel")
  const notification = notify.loading(t("removeChannelLoading"))
  try {
    await channelRequests.delete(url)
    notifyLoadingToSuccess(notification, t("removeChannelSucceed"))
  } catch (e) {
    const message = e?.message || t("removeChannelError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useChannel;