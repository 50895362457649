import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { authRequests } from "../../../api/requests";
import { login } from "../../../redux/reducers/session/actions"
import useTranslator from "../../../hooks/useTranslator";

const Login = () => {
  const t = useTranslator("views.pages.auth.login")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = async ({ email, password }) => {
    try {
      const { token, user, club } = await authRequests.login(email, password)
      dispatch(login(token, user, club))
      notify.success(t("success"), { icon: <i className="fas fa-handshake ni-lg"/> })
    } catch (e) {
      notify.error(e?.message || t("error"))
    }
  }

  return <Page.Login
    emailPlaceholder={t("emailPlaceholder")}
    forgottenPasswordText={t("forgottenPasswordText")}
    invalidEmailFeedbackText={t("invalidEmailFeedbackText")}
    invalidPasswordFeedbackText={t("invalidPasswordFeedbackText")}
    lead={t("lead")}
    onClickForgottenPassword={() => navigate("/auth/forgotten-password")}
    onClickRegister={() => navigate("/auth/register")}
    onSubmit={onSubmit}
    passwordPlaceholder={t("passwordPlaceholder")}
    registerText={t("registerText")}
    rememberLabel={t("rememberLabel")}
    submitText={t("submitText")}
    title={t("title")}
  />;
}

export default Login;