import React from "react";
import Dashboard from "../../views/layouts/Dashboard";
import { Navigate } from "react-router";
import Trans from "../../views/pages/dashboard/transcodification";
import Clubs from "../../views/pages/dashboard/clubs";
import Magazine from "../../views/pages/dashboard/magazine";
import Users from "../../views/pages/dashboard/users";
import Notifications from "../../views/pages/dashboard/notifications";
import Schedule from "../../views/pages/dashboard/schedule";
import { subRoutes as statsSubRoutes } from "./stats"
import { subRoutes as servicesSubRoutes } from "./services"
import { subRoutes as onDemandSubRoutes } from "./onDemand"
import { subRoutes as clubConfigSubRoutes } from "./clubConfig"
import { subRoutes as subscriptionsSubRoutes } from "./subscriptions"
import permissions from "../../utils/permissions";
import Meeting from "../../views/pages/dashboard/meetings/Meeting";

const redirects = [
  { permission: permissions.clubManager, redirect: "clubs-management" },
  { permission: permissions.onDemand, redirect: "on-demand" },
  { permission: permissions.transcodification, redirect: "transcodification" },
  { permission: permissions.magazine, redirect: "magazine" },
  { permission: permissions.users, redirect: "users" },
  { permission: [permissions.trainers, permissions.linealChannel], redirect: "services" },
  { permission: permissions.schedule, redirect: "my-schedule" },
  { permission: permissions.notifications, redirect: "notifications" },
  { permission: permissions.statistics, redirect: "stats" },
]

const redirectsRoutes = redirects.map(({ permission, redirect }) => {
  return {
    path: "*",
    permissions: Array.isArray(permission) ? permission : [permission],
    index: true,
    component: <Navigate to={`/dashboard/${redirect}`} replace={true}/>,
  }
})


const subRoutes = (t) => [
  {
    path: "clubs-management",
    permissions: [permissions.clubManager],
    showOnNav: t("company"),
    icon: "fas fa-users-cog text-default",
    name: t("clubManagement"),
    component: <Clubs/>
  },
  {
    path: "on-demand",
    permissions: [permissions.onDemand],
    showOnNav: t("club"),
    icon: "fas fa-tv text-primary",
    name: t("onDemand"),
    subRoutes: onDemandSubRoutes(t)
  },
  {
    path: "subscriptions",
    showOnNav: t("club"),
    icon: "fas fa-hand-holding-usd text-primary",
    name: t("subscriptions"),
    subRoutes: subscriptionsSubRoutes(t)
  },
  {
    path: "transcodification",
    permissions: [permissions.transcodification],
    showOnNav: t("club"),
    icon: "fas fa-upload text-default",
    name: t("transcodification"),
    component: <Trans/>
  },
  {
    path: "magazine",
    permissions: [permissions.magazine],
    showOnNav: t("club"),
    icon: "fas fa-newspaper text-default",
    name: t("magazine"),
    component: <Magazine/>
  },
  {
    path: "users",
    permissions: [permissions.users],
    showOnNav: t("club"),
    icon: "fas fa-users text-primary",
    name: t("users"),
    component: <Users/>
  },
  {
    path: "services",
    permissions: [permissions.trainers, permissions.linealChannel, permissions.advertising],
    showOnNav: t("club"),
    icon: "fas fa-concierge-bell text-default",
    name: t("services"),
    subRoutes: servicesSubRoutes(t)
  },
  {
    path: "my-schedule",
    permissions: [permissions.schedule],
    showOnNav: t("trainer"),
    icon: "fas fa-calendar-alt text-primary",
    name: t("mySchedule"),
    component: <Schedule/>
  },
  {
    path: "meeting/:idMeeting",
    permissions: [permissions.meeting],
    icon: "fas fa-webcam text-primary",
    name: t("meeting"),
    component: <Meeting/>
  },
  {
    path: "notifications",
    permissions: [permissions.notifications],
    showOnNav: t("club"),
    icon: "fas fa-bell text-default",
    name: t("notifications"),
    component: <Notifications/>
  },
  {
    name: t("stats"),
    permissions: [permissions.statistics],
    path: "stats",
    icon: "fas fa-chart-pie text-primary",
    showOnNav: t("club"),
    subRoutes: statsSubRoutes(t)
  },
  {
    name: t("configuration"),
    path: "configuration",
    permissions: [permissions.clubConfig],
    icon: "fas fa-cog text-primary",
    showOnNav: t("club"),
    subRoutes: clubConfigSubRoutes(t)
  },

  ...redirectsRoutes
]

const index = (t) => ({
  path: "/dashboard",
  component: <Dashboard/>,
  subRoutes: subRoutes(t),
})

export default index;