import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import moment from "moment";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowNewsCard = ({
                        news,
                        setNewToUpload,
                        setNewToRemove,
                        onClickNew,
                        totalAmount,
                        page,
                        setPage,
                        amount,
                        isLoading
                      }) => {
  const t = useTranslator("components.news.showNewsCard")

  const columns = [
    {
      name: t("name"),
      formattedContent: ({ title, image }) => {
        return <TableOutput.ImageText text={title} src={image?.url}/>
      },
      isTh: true,
    },
    {
      name: t("status"),
      formattedContent: ({ published }) => {
        return (
          <TableOutput.Status
            status={t("statusText", published)}
            color={published ? "success" : "danger"}
          />
        )
      }
    },
    {
      name: t("publicationDate"),
      formattedContent: ({ publicationDate }) => moment(publicationDate).format("dddd, MMMM Do YYYY")
    },
    {
      name: "",
      formattedContent: (theNew) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setNewToUpload({ ...theNew }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setNewToRemove({ ...theNew })
          },
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("addButtonText")}/>, onClick: () => setNewToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={news}
        onRowClick={(product) => onClickNew({ ...product })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={amount}
      />
      <TableCard.Footer
        amount={amount}
        totalEntries={totalAmount}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowNewsCard;