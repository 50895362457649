import React, { useState } from "react";
import { Container } from "reactstrap";
import {
  SidebarContainer,
  RoutesInside,
  Footer,
  Header,
  useFinalRoutes
} from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/session/actions";
import ShowUserClubsModal from "../../components/clubs/ShowUserClubsModal";
import useSessionNotifications from "../../hooks/useSessionNotifications";
import useClubStyles from "../../hooks/useClubStyles";
import useTranslator from "../../hooks/useTranslator";

const Dashboard = () => {
  const t = useTranslator("views.layouts.dashboard")
  const { logoSquad } = useClubStyles()
  const routes = useFinalRoutes();
  const club = useSelector((state) => state.sessionState.club)
  const { clubName } = (club || {})
  const notifications = useSessionNotifications()
  const [isOpenSidebar, setOpenSidebar] = useState(true)
  const [isOpenClubs, setOpenClubs] = useState(false)
  const dispatch = useDispatch();

  const sessionOptions = [
    {
      title: "Clubs",
      onClick: () => setOpenClubs(true),
      icon: "fas fa-dice-d6",
      // useDivider: true
    },
    {
      title: "Logout",
      onClick: () => dispatch(logout()),
      icon: "fas fa-running",
      useDivider: true
    }
  ]

  return (
    <React.Fragment>
      <ShowUserClubsModal isOpen={isOpenClubs} setOpen={setOpenClubs}/>
      <Container fluid className="p-0 m-0 min-vh-100">
        <SidebarContainer
          routes={routes}
          isOpen={isOpenSidebar}
          setOpen={setOpenSidebar}
          logoSrc={logoSquad}
        >
          <Header.Session
            isOpen={isOpenSidebar}
            setOpen={setOpenSidebar}
            sessionName={clubName}
            notifications={notifications || []}
            isDark={false}
            color={"secondary"}
            sessionOptions={sessionOptions}
            sessionOptionsHeader={t("sessionOptionsHeader")}
            notificationsHeader={t("notificationsHeader", notifications?.length || 0)}
          />
          <RoutesInside/>
          <Footer.Session/>
        </SidebarContainer>
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;