import { useMemo } from "react";
import useTranslator from "../useTranslator";

const intensities = [null, "BEGINNER", "MEDIUM", "ADVANCED"]
const useTranslatedIntensities = () => {
  const t = useTranslator("intensities")

  const translatedIntensities = useMemo(() => getTranslatedIntensities(t), [t])
  const intensityOptions = useMemo(() => getIntensityOptions(t), [t])
  const getIntensityOption = (v) => findIntensityOption(t, v)

  return {
    intensities,
    translatedIntensities,
    intensityOptions,
    getIntensityOption
  }
}

const getTranslatedIntensities = (t) => {
  return intensities.reduce((translated, intensity) => {
    translated[intensity] = t(intensity) || intensity
    return translated
  }, {})
}

const getIntensityOptions = (t) => {
  return intensities.map((intensity) => ({ value: intensity, label: t(intensity) || intensity }))
}

const findIntensityOption = (t, value) => {
  return intensities.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedIntensities;
export { intensities, getTranslatedIntensities, getIntensityOptions, findIntensityOption }