import Api from "../Api";

const getTagList = () => Api.get(`/admin/tags/list`);
const putTag = (body) => Api.put(`/admin/tags`, body);
const postTag = (body) => Api.post(`/admin/tags`, body);
const deleteTag = (id) => Api.delete(`/admin/tags/delete/${id}`);

const tagRequests = {
  getList: getTagList,
  post: postTag,
  put: putTag,
  delete: deleteTag
}

export default tagRequests