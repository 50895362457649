export const LOGIN = "LOGIN";
export const CHANGE_CLUB = "CHANGE_CLUB";
export const LOGOUT = "LOGOUT";

export const login = (token, user, club) => ({
  type: LOGIN,
  payload: {
    token: token,
    user: user,
    club: club
  }
});

export const changeClub = (club) => ({
  type: CHANGE_CLUB,
  payload: {
    club: club
  }
});

export const logout = () => ({
  type: LOGOUT,
  payload: null
});