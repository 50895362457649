import { useMemo } from "react";
import useTranslator from "../useTranslator";

const notificationTypes = ["DEFAULT", "PRODUCT", "NEW", "PROFILE", "SUBSCRIPTION"]
const useTranslatedNotificationTypes = () => {
  const t = useTranslator("notificationTypes")

  const translatedNotificationTypes = useMemo(() => getTranslatedNotificationTypes(t), [t])
  const notificationTypeOptions = useMemo(() => getNotificationTypeOptions(t), [t])
  const getNotificationTypeOption = (v) => findNotificationTypeOption(t, v)

  return {
    notificationTypes,
    translatedNotificationTypes,
    notificationTypeOptions,
    getNotificationTypeOption
  }
}

const getTranslatedNotificationTypes = (t) => {
  return notificationTypes.reduce((translated, notificationType) => {
    translated[notificationType] = t(notificationType) || notificationType
    return translated
  }, {})
}

const getNotificationTypeOptions = (t) => {
  return notificationTypes.map((notificationType) => ({
    value: notificationType,
    label: t(notificationType) || notificationType
  }))
}

const findNotificationTypeOption = (t, value) => {
  return notificationTypes.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedNotificationTypes;
export { notificationTypes, getTranslatedNotificationTypes, getNotificationTypeOptions, findNotificationTypeOption }