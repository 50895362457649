import React, { useState } from "react";
import { Modal, FormInput, utils, notify } from "@general-backoffice/core";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../utils/notify/notifyLoadingUpdate";
import useTranslator from "../../hooks/useTranslator";

const ResizeImageFileModal = ({
                                imageFile,
                                setImageFile,
                                onSave,
                                width,
                                height,
                                maxSizeMB = 0.5
                              }) => {
  const t = useTranslator("components.shared.resizeImageFileModal")
  const [isLoadingLocally, setIsLoadingLocally] = useState(false)

  const save = async () => {
    const notification = notify.loading(t("adaptImageLoading"))
    setIsLoadingLocally(true)
    try {
      const compressedFile = await utils.compressImage(imageFile, maxSizeMB)
      const image64 = await utils.fileToBase64(compressedFile)
      onSave(compressedFile, image64.split(',')[1])
      notifyLoadingToSuccess(notification, t("adaptImageSucceed"))
    } catch (e) {
      notifyLoadingToError(notification, e?.message || t("adaptImageError"))
      setImageFile(null)
    }
    setIsLoadingLocally(false)
  }

  return (
    <Modal.Simple
      isOpen={!!imageFile}
      isLoading={isLoadingLocally}
      toggleOpen={() => setImageFile(null)}
      title={t("title", width, height)}
      closeText={t("closeText")}
      buttons={[
        { children: t("buttonSaveText"), color: "success", onClick: save }
      ]}
    >
      <div className="mx--2">
        <FormInput.ImageResizer value={imageFile} width={width} height={height} onChange={setImageFile}/>
      </div>
    </Modal.Simple>
  );
}

const ResizeImageFileModals = ({ structure, imagesToResize, changeImagesToResize, onSave }) => {
  return (
    <React.Fragment>
      {Object.entries(structure).map(([key, { size, MB }]) => {
        return (
          <ResizeImageFileModal
            key={key}
            imageFile={imagesToResize[key]}
            setImageFile={(file) => changeImagesToResize({ ...imagesToResize, [key]: file })}
            onSave={(file, image64) => onSave(key, file, image64)}
            width={size[0]}
            height={size[1]}
            maxSizeMB={MB}
          />
        )
      })}
    </React.Fragment>
  )
}

export default ResizeImageFileModal;
export { ResizeImageFileModals }