import Api from "../Api";
import clubRequests from "./clubRequests";
import { notify } from "@general-backoffice/core";

const showNotificationClubBlocked = () => {
  notify(<div>
    <p>
      Por razones administrativas, el acceso al backoffice ha sido bloqueado. Esto significa que no podrás acceder a la
      interfaz de administración o control de la aplicación. Si necesitas realizar alguna tarea específica o tienes
      alguna pregunta, te recomiendo que te pongas en contacto con el equipo de soporte o los administradores para
      obtener más detalles y posibles soluciones.
    </p>
    <p>
      Recuerda que la seguridad y la gestión adecuada de los permisos son esenciales para proteger los datos y
      garantizar un funcionamiento eficiente de los sistemas. Si tienes alguna inquietud o necesitas más información, no
      dudes en consultar con los responsables de la administración de la aplicación.
    </p>
  </div>, { autoClose: false, closeOnClick: true })
}

const login = async (email, password) => {
  try {
    const { id, token, verified, owner, clubBackOfficeBlocked } = await Api.post("/login-admin", {
      email,
      password,
      platform: "BACKOFFICE"
    })
    if (clubBackOfficeBlocked) {
      showNotificationClubBlocked()
      throw new Error("¡Ups!, Ha habido un error al iniciar sesión")
    }
    Api.token = token
    const { clubList = [] } = await clubRequests.getClubsUser();
    Api.token = null
    const club = clubList[0] || null
    return { token, user: { verified, clubList: clubList, id, email, isOwner: owner }, club }
  } catch (e) {
    throw e || new Error("Usuario o contraseña incorrectos");
  }
};

const forgottenPassword = (email) => Api.get(`/forgotten-password?email=${email}`)
const resetPassword = (body) => Api.post(`/reset-password`, body)

const authRequests = {
  login,
  forgottenPassword,
  resetPassword
}
export default authRequests