import React from "react";
import { Header } from "@general-backoffice/core";
import { Container } from "reactstrap";
import useTags from "../../../../components/tags/hooks/useTags";
import useTag from "../../../../components/tags/hooks/useTag";
import ShowTagsCard from "../../../../components/tags/ShowTagsCard";
import UploadTagModal from "../../../../components/tags/UploadTagModal";
import RemoveTagModal from "../../../../components/tags/RemoveTagModal";


const Tags = () => {

  const {
    tags,
    isLoading: isLoadingTags,
    refresh: refreshTags
  } = useTags()

  const {
    tag: tagToUpload,
    save: saveTagToUpload,
    setTag: setTagToUpload,
    isLoading: isLoadingTagToUpload
  } = useTag()

  const {
    tag: tagToRemove,
    remove: removeTagToRemove,
    setTag: setTagToRemove,
    isLoading: isLoadingTagToRemove
  } = useTag()

  return (
    <React.Fragment>

      <RemoveTagModal
        tag={tagToRemove}
        close={() => setTagToRemove(null)}
        isLoading={isLoadingTagToRemove}
        remove={async () => {
          return removeTagToRemove()
            .then(() => {
              refreshTags()
              setTagToRemove(null)
            })
            .catch(() => null)
        }}
      />

      <UploadTagModal
        tag={tagToUpload}
        setTag={setTagToUpload}
        onSubmit={async () => {
          return saveTagToUpload()
            .then(() => {
              refreshTags()
              setTagToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTagToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <ShowTagsCard
          tags={tags}
          setTagToUpload={setTagToUpload}
          setTagToRemove={setTagToRemove}
          onClickTag={setTagToUpload}
          isLoading={isLoadingTags}
        />
      </Container>
    </React.Fragment>
  );
}

export default Tags;