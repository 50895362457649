import { useState } from "react";
import { useNavigate } from "react-router";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import trainingDemo from "./../cycling/demo.json"

const useTraining = () => {
  const navigate = useNavigate();
  const [training, setTraining] = useState(trainingDemo)
  const [isLoading, setIsLoading] = useState(false);

  const save = async () => {
    setIsLoading(true)
    return saveTraining(training)
      .finally(() => {
        setIsLoading(false)
      })

  }

  const play = () => navigate(`/dashboard/cycling/player`)
  const build = () => navigate(`/dashboard/cycling/builder`)
  const preview = () => navigate(`/dashboard/cycling/preview`)

  return {
    training,
    setTraining,
    play,
    build,
    preview,
    save,
    isLoading
  }
}

export const saveTraining = async (trainingToSave) => {
  if (!trainingToSave) return false;
  const notification = notify.loading("Modificando entrenamiento...")
  await new Promise((resolve) => setTimeout(() => resolve(), 1000))
  notifyLoadingToSuccess(notification, `Entrenamiento modificado correctamente`)
}

export default useTraining;