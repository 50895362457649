import React from "react";
import { Navigate } from "react-router";
import { subRoutes as trainerSubRoutes } from "./trainers";
import permissions from "../../../utils/permissions";
import Channels from "../../../views/pages/dashboard/channels";
import Advertisements from "../../../views/pages/dashboard/advertising/Advertisements";
import Advertisement from "../../../views/pages/dashboard/advertising/Advertisement";


const subRoutes = (t) => [
  {
    path: "trainers",
    permissions: [permissions.trainers],
    showOnNav: true,
    icon: "fas fa-sign-language text-default",
    name: t("trainers"),
    subRoutes: trainerSubRoutes(t)
  },
  {
    path: "lineal-channels",
    permissions: [permissions.linealChannel],
    icon: "fas fa-satellite-dish text-primary",
    name: t("linealChannel"),
    showOnNav: true,
    component: <Channels/>,
  },
  {
    path: "advertisements",
    permissions: [permissions.advertising],
    icon: "fas fa-ad text-primary",
    name: t("advertising"),
    showOnNav: true,
    subRoutes: [
      {
        name: t("advertising"),
        path: "",
        showOnNav: false,
        component: <Advertisements/>,
      },
      {
        name: t("newAdvertisement"),
        path: "new",
        showOnNav: false,
        component: <Advertisement/>,
      },
      {
        name: t("advertisement"),
        path: ":advertisementId",
        showOnNav: false,
        component: <Advertisement/>,
      }
    ]
  },
  {
    path: "*",
    permissions: [permissions.trainers],
    index: true,
    component: <Navigate to={"/dashboard/services/trainers"} replace={true}/>,
  },
  {
    path: "*",
    permissions: [permissions.linealChannel],
    index: true,
    component: <Navigate to={"/dashboard/services/lineal-channels"} replace={true}/>,
  }
]

export { subRoutes };