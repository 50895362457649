import { useEffect, useState } from "react";
import { notificationRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";
import useRequestParams from "../../../hooks/useRequestParams";

const useNotifications = () => {
  const { requestParams, changePageSize, changePageNumber } = useRequestParams()
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [notifications, setNotifications] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [requestParams, clubId])

  const refresh = async () => {
    console.log("refreshh")
    setIsLoading(true)
    return getNotifications(requestParams)
      .then(({ notifications, totalAmount }) => {
        console.log({ notifications, totalAmount })
        setNotifications(notifications)
        setTotalAmount(totalAmount)
        return notifications
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    notifications,
    isLoading,
    requestParams,
    changePageNumber,
    changePageSize,
    totalAmount,
    refresh
  }
}

export const getNotifications = async (requestParams) => {
  const t = hardTranslator("components.notifications.hooks.useNotifications")

  try {
    const { content = [], totalElements = 0 } = await notificationRequests.getList(requestParams)
    return { notifications: content, totalAmount: totalElements }
  } catch (e) {
    console.log("Error")
    const message = (e?.message || t("getNotificationsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useNotifications;