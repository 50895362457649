import { useEffect, useState } from "react";
import { stripeRequests } from "../api/requests";
import { useSelector } from "react-redux";
import { notify } from "@general-backoffice/core";
import { Badge } from "reactstrap";
import { roles } from "../utils/roles";
import useTranslator from "./useTranslator";

const useSessionNotifications = () => {
  const t = useTranslator("notifications")

  const { club, user } = useSelector((state) => state.sessionState)
  const { clubId, roleList = [] } = (club || {})
  const { isOwner } = (user || {})
  const [notificationsClubStripe, setNotificationsClubStripe] = useState([]);
  const [notificationsCompanyStripe, setNotificationsCompanyStripe] = useState([]);

  const notifications = {
    connectClubStripe: {
      title: "Stripe",
      description: t("needsLinkClubStripe"),
      subtitle: <Badge color="warning" children={t("urgent")}/>,
      onClick: () => stripeRequests.connectClubStripe().then(({ connectUrl }) => window.location.href = connectUrl).catch(notify.error)
    },
    upgradeClubStripe: {
      title: "Stripe",
      description: t("needsConfigureClubStripe"),
      onClick: () => stripeRequests.updateClubStripe().then(({ connectUrl }) => window.location.href = connectUrl).catch(notify.error)
    },
    connectCompanyStripe: {
      title: "Stripe",
      description: t("needsLinkCompanyStripe"),
      subtitle: <Badge color="warning" children={t("urgent")}/>,
      onClick: () => stripeRequests.connectCompanyStripe().then(({ connectUrl }) => window.location.href = connectUrl).catch(notify.error)
    },
    upgradeCompanyStripe: {
      title: "Stripe",
      description: t("needsConfigureCompanyStripe"),
      onClick: () => stripeRequests.updateCompanyStripe().then(({ connectUrl }) => window.location.href = connectUrl).catch(notify.error)
    }
  }

  useEffect(() => {
    setNotificationsClubStripe([])
    if (!clubId || !roleList.includes(roles.MANAGER)) return;

    stripeRequests.getClubStatus()
      .then(({ stripeId, stripeRequirementsNeeded }) => {
        if (stripeRequirementsNeeded) {
          setNotificationsClubStripe([(stripeId ? notifications.upgradeClubStripe : notifications.connectClubStripe)])
        }
      })
    // eslint-disable-next-line
  }, [clubId])


  useEffect(() => {
    setNotificationsCompanyStripe([])
    if (!isOwner) return;
    stripeRequests.getCompanyStatus()
      .then(({ stripeId, stripeRequirementsNeeded }) => {
        if (stripeRequirementsNeeded) {
          setNotificationsCompanyStripe([(stripeId ? notifications.upgradeCompanyStripe : notifications.connectCompanyStripe)])
        }
      })
    // eslint-disable-next-line
  }, [!!isOwner])


  return [...notificationsCompanyStripe, ...notificationsClubStripe];
}

export default useSessionNotifications;