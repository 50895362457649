import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { notify, Card, Loading, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { successColor } from './config/colors'
import UploadStatsPeriodModal, { format, formatHuman, initialEndDate, initialStartDate } from "../shared/UploadStatsPeriodModal";
import { statMedium, statSmall } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";

const StatsTrainersMeetings = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsTrainersMeetings")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [isLoading, setLoading] = useState(true)
  const [{ startDate, endDate }, setLocalDates] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [trainerIncomeList, setTrainerIncomeList] = useState([])

  const refresh = () => {
    return statsRequests.getTrainersIncome(format(startDate), format(endDate))
      .then(({ trainerIncomeList = [] }) => setTrainerIncomeList(trainerIncomeList))
      .catch((e) => notify.error(e?.message || t("getStatsError")))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, [clubId, startDate, endDate])

  const countTrainers = trainerIncomeList.length;
  const hasTrainers = countTrainers > 0;
  const height = (simple || countTrainers <= 2) ? statSmall : statMedium;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />

      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        <CardBody>
          {isLoading && <Loading.BouncingDots className="my-5"/>}
          {(!hasTrainers && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          {(hasTrainers && !isLoading) && (
            <div style={{ position: "relative", height: height }}>
              <Bar
                type={"bar"}
                data={{
                  labels: trainerIncomeList.map(({ trainerName }) => trainerName),
                  datasets: [{
                    label: t("label"),
                    data: trainerIncomeList.map(({ meetings }) => meetings),
                    borderRadius: 5,
                    backgroundColor: successColor,
                  }],
                }}
                options={{
                  maintainAspectRatio: false,
                  indexAxis: 'y',
                  responsive: true,
                  scales: {
                    y: {
                      display: !simple,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    },
                    x: {
                      display: !simple,
                      grid: {
                        drawBorder: false
                      },
                      ticks: {
                        suggestedMin: 0,
                        stepSize: 10,
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  interaction: {
                    intersect: false,
                    mode: 'y',
                  },
                }}
              />
            </div>
          )}
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsTrainersMeetings;