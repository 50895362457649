import React from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { Card, FormInput } from "@general-backoffice/core/index";
import useTranslator from "../../hooks/useTranslator";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import getDefaultClubConfig from "./shared/getDefaultClubConfig";

const ChangeClubStructureCard = ({ clubConfig, setClubConfig, isLoading, onSave }) => {
  const t = useTranslator("components.clubConfig.changeClubStructureCard")
  const { onChangeInput: onChange } = useChangingComplexState(setClubConfig)
  const { personalizationClub } = { ...getDefaultClubConfig(), ...(clubConfig || {}) }
  const {
    showAddedRecentlyCategory,
    showTitlesOnCollectionThumbnail,
    showTitlesOnNewThumbnail,
    showTitlesOnPanoramic,
    showTitlesOnProductThumbnail,
    showTitlesOnTrainerThumbnail
  } = personalizationClub

  return (
    <React.Fragment>
      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={t("subtitle")}
          buttons={[
            {
              children: t("saveButton"),
              color: "success",
              onClick: onSave,
              disabled: isLoading
            }
          ]}
        />
        <CardBody>
          <FormInput.Switch
            onChange={onChange}
            checked={showAddedRecentlyCategory}
            name={"personalizationClub.showAddedRecentlyCategory"}
            label={t("showAddedRecentlyCategoryLabel")}
            labels={t("showAddedRecentlyCategoryAnswer")}
            disabled={isLoading}
          />
          <FormInput.Switch
            onChange={onChange}
            checked={showTitlesOnPanoramic}
            name={"personalizationClub.showTitlesOnPanoramic"}
            label={t("showTitlesOnPanoramicLabel")}
            labels={t("showTitlesOnPanoramicAnswer")}
            disabled={isLoading}
          />
          <FormInput.Switch
            onChange={onChange}
            checked={showTitlesOnProductThumbnail}
            name={"personalizationClub.showTitlesOnProductThumbnail"}
            label={t("showTitlesOnProductThumbnailLabel")}
            labels={t("showTitlesOnProductThumbnailAnswer")}
            disabled={isLoading}
          />
          <FormInput.Switch
            onChange={onChange}
            checked={showTitlesOnCollectionThumbnail}
            name={"personalizationClub.showTitlesOnCollectionThumbnail"}
            label={t("showTitlesOnCollectionThumbnailLabel")}
            labels={t("showTitlesOnCollectionThumbnailAnswer")}
            disabled={isLoading}
          />
          <FormInput.Switch
            onChange={onChange}
            checked={showTitlesOnTrainerThumbnail}
            name={"personalizationClub.showTitlesOnTrainerThumbnail"}
            label={t("showTitlesOnTrainerThumbnailLabel")}
            labels={t("showTitlesOnTrainerThumbnailAnswer")}
            disabled={isLoading}
          />
          <FormInput.Switch
            onChange={onChange}
            checked={showTitlesOnNewThumbnail}
            name={"personalizationClub.showTitlesOnNewThumbnail"}
            label={t("showTitlesOnNewThumbnailLabel")}
            labels={t("showTitlesOnNewThumbnailAnswer")}
            disabled={isLoading}
          />
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default ChangeClubStructureCard;