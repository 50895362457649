import React from "react";

import formatCyclingFBT from "../../../../components/trainings/cycling/player/formatTraining";
import Player from "../../../../components/trainings/cycling/player/Player";
import useTraining from "../../../../components/trainings/hooks/useTraining";

const TrainingPlayer = () => {
  const { training } = useTraining()
  return <Player trainingToPlay={formatCyclingFBT(training)}/>
}

export default TrainingPlayer;