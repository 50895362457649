import React from "react";
import { colors } from "../../../config"

const DarkContainer = ({children, className, style}) => 
  <div
    className={"metropolis d-flex align-items-center text-center flex-wrap " + className}
    style={{
      gap: 23, 
      color: colors.light,
      fontWeight: 700,
      lineHeight: 1.2,
      backgroundColor: 'rgba(0,0,0,0.3)',
      maxHeight: '100%',
      ...style
    }}
  >
    {children}
  </div>

export default DarkContainer;
