import React, { useEffect, useState } from "react";
import { Modal, Loading, notify } from "@general-backoffice/core";
import VideoPlayer from "./VideoPlayer"
import useTranslator from "../../hooks/useTranslator";


const PreviewVideoModal = ({
                             url,
                             onClose,
                             title = "",
                             allowCopyUrl = true,
                             isLoading
                           }) => {
  const t = useTranslator("components.shared.previewVideoModal")

  const [isLoadingLocally, setLoadingLocally] = useState(true)
  const someIsLoading = isLoadingLocally || isLoading

  useEffect(() => {
    if (!!url) {
      setLoadingLocally(true)
      setTimeout(() => setLoadingLocally(false), 1000)
    }
  }, [url])

  const copyUrl = async () => {
    await navigator.clipboard.writeText(url)
    notify.success(t("copyUrlSucceed"))
  }

  return (
    <Modal.Simple
      isOpen={!!url}
      toggleOpen={onClose}
      title={title}
      isLoading={someIsLoading}
      buttons={allowCopyUrl ? [{ children: t("copyUrl"), onClick: copyUrl }] : []}
    >
      {someIsLoading ?
        <Loading.BouncingDots className="my-5"/> :
        <VideoPlayer url={url}/>}
    </Modal.Simple>
  );
}

export default PreviewVideoModal;