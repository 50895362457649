import React from "react";
import { Header, Loading } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useClubConfig from "../../../../components/clubConfig/hooks/useClubConfig";
import ChangeClubLogosCard from "../../../../components/clubConfig/ChangeClubLogosCard";
import { useSelector } from "react-redux";
import useTranslator from "../../../../hooks/useTranslator";
import ChangeClubColorsCard from "../../../../components/clubConfig/ChangeClubColorsCard";

const ClubConfig = () => {
  const t = useTranslator("views.pages.dashboard.clubConfig")
  const { club: userClub } = useSelector((state) => state.sessionState)

  const {
    clubConfig,
    setClubConfig,
    isLoading,
    save
  } = useClubConfig(userClub.clubId)

  return (
    <React.Fragment>
      {isLoading && <Loading.Line/>}
      <Header.BreadCrumb
        color="secondary"
        isDark={false}
        buttons={[{
          children: t("save"),
          color: "success",
          size: "md",
          onClick: () => save().catch(() => null),
          disabled: isLoading
        }]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={7}>
            <ChangeClubLogosCard clubConfig={clubConfig} setClubConfig={setClubConfig} isLoading={isLoading}/>
          </Col>
          <Col xs={12} md={5}>
            <ChangeClubColorsCard clubConfig={clubConfig} setClubConfig={setClubConfig} isLoading={isLoading}/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ClubConfig;