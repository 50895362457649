import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion/dist/framer-motion'
import { getZoneFromFTP } from "../player/formatTraining"
import { colors } from "../config"
import { StandingTriangle, RPMChangeTriangle } from "./Triangles";

const TrainingPreview = ({ structure = [], currentTime, style, className, useFullHeight, height = '100%', width = '100%', triangleHeight}) => {
  const [structureWithZone, setStructureWithZone] = useState([])

  useEffect(() => {
    const maxFTP = structure.reduce((r, segment) => Math.max(r, ...segment.ftp), 0)
    setStructureWithZone(structure?.map(({ftp, ...other}) => ({
      ...other,
      zone: getZoneFromFTP(ftp[0]), 
      percentage: Math.round((ftp[0]/maxFTP).toFixed(2) * 100)
    })) || [])
  }, [structure])

  return (
    <div style={{height, width, ...style, paddingTop: 13, gap: 1}} className={"d-flex align-items-end " + className}>
      {structureWithZone.map(({ percentage, duration, zone, start, end, standing, rpm }, key) => {

        // RPM-changes icon
        let rpmChange;
        if(Array.isArray(rpm) && rpm.length === 2) rpmChange = rpm[0] - rpm[1]

        // Darkening
        let currentlyPlayed = false;
        let coveredPercentage = null;
        if(currentTime && currentTime >= start) {
          if(currentTime < end) {
            coveredPercentage = Math.min(100, (((currentTime - start)+1) / duration) * 100)
            currentlyPlayed = true
          } else coveredPercentage = 100
        } 

        return (
          <div 
            key={key}
            className='position-relative'
            style={{ 
              backgroundColor: zone?.colors?.main || colors.empty,
              height: `${useFullHeight ? 100 : percentage}%`, 
              flexGrow: duration
            }}
          >
            {standing && <React.Fragment>
              <StandingTriangle style={{fill: zone?.colors?.main || colors.empty}} height={triangleHeight} separation={1}/>
              {coveredPercentage >= 100 && 
                <StandingTriangle style={{fill: 'rgba(0,0,0,0.5)'}} height={triangleHeight} separation={1}/>}
            </React.Fragment>}


            {rpmChange > 0 && <RPMChangeTriangle side="right"/>}
            {rpmChange < 0 && <RPMChangeTriangle side="left"/>}

            {coveredPercentage !== null && 
              <motion.div 
                className={`position-absolute h-100`} 
                style={{ top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)' }}
                initial={{ width: '0%'}}
                animate={{ width: `${coveredPercentage}%` }}
                transition={currentlyPlayed ? { duration: 1.05, ease: 'linear' } : { duration: 0 }}
              />
            }
          </div>
        )
      })}
    </div>
  );
}

export default TrainingPreview;
