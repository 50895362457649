import React from "react";
import { Router as RouterCore } from "@general-backoffice/core";
import routes from "./routes";
import { useSelector } from "react-redux";
import configuration from "./config/configuration";
import useClubStyles from "./hooks/useClubStyles";
import useTranslator from "./hooks/useTranslator";

const { basename } = configuration

const processUserPermissions = (userPermissions = []) => userPermissions;

const validateRoute = (route, state) => {
  const { token, club } = state;
  const { userPermissions = [] } = (club || {})
  const processedPermissions = processUserPermissions(userPermissions)
  const routeAccessPermissions = route.permissions || [];

  if (route.fullPath.includes("auth") && !!token) return false;
  if (route.fullPath.includes("dashboard") && !token) return false;
  let allowedUserByPermissions = routeAccessPermissions.length <= 0;

  if (routeAccessPermissions.length > 0) {
    allowedUserByPermissions = processedPermissions.find((userPermission) => routeAccessPermissions.find((allowedPermissions) => userPermission === allowedPermissions));
  }

  return !!allowedUserByPermissions;
}

const Router = () => {
  const t = useTranslator("routes")
  const { helmet } = useClubStyles();
  const state = useSelector((state) => state.sessionState)
  return (
    <React.Fragment>
      {helmet}
      <RouterCore
        basename={basename}
        scrollToTop
        routes={routes(t)}
        validateRoute={(route) => validateRoute(route, state)}
      />
    </React.Fragment>
  );
}

export default Router;