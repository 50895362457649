import moment from "moment";

const getDefaultProduct = () => ({
  categoryIdList: [],
  collectionIdList: [],
  trainerIdList: [],
  code: "",
  description: "",
  duration: 0,
  expedition: false,
  image: { url: null, data: null, contentType: null },
  imagePortrait: { url: null, data: null, contentType: null },
  level: null,
  mentalLevel: null,
  technicalLevel: null,
  physicalLevel: null,
  liveSchedule: null,
  premiere: false,
  price: null,
  publicationDate: moment().format("YYYY-MM-DD"),
  publicationEndDate: null,
  premiereEndDate: null,
  published: true,
  tagList: [],
  subscriptionPlanIdList: [],
  thumbnail: { url: null, data: null, contentType: null },
  thumbnailPortrait: { url: null, data: null, contentType: null },
  title: "",
  subtitle: "",
  trailer: { hlsUrl: "" },
  video: { hlsUrl: "" }
})

export default getDefaultProduct;