import React from "react";
import { Button, ButtonGroup  } from "reactstrap";

const TimeControls = ({ isPlaying, toggle, stop, reset, disabled, fullscreen, toggleFullscreen }) => 
  <div>
    <ButtonGroup size="sm">
      {toggle &&
        <Button outline color="white" onClick={toggle} disabled={disabled}> 
          {isPlaying ? 
            <i className="fas fa-pause"/> : 
            <i className="fas fa-play"/>}
        </Button>}
      {stop && 
        <Button outline color="white" onClick={stop} disabled={disabled}> 
          <i className="fas fa-stop"/>
        </Button>}
      {reset && 
        <Button outline color="white" onClick={reset} disabled={disabled}> 
          <i className="fas fa-undo-alt"/>
        </Button>}
      {toggleFullscreen &&
        <Button outline color="white" onClick={toggleFullscreen} disabled={disabled}> 
          {fullscreen ? 
            <i className="fas fa-compress"/> : 
            <i className="fas fa-expand"/>}
        </Button>}
    </ButtonGroup>
  </div>

export default TimeControls;