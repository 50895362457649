import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowNotificationsCard = ({
                                 notifications,
                                 setNotificationToUpload,
                                 setNotificationToSend,
                                 setNotificationToRemove,
                                 onClickNotification,
                                 totalEntries,
                                 page,
                                 setPage,
                                 amount,
                                 isLoading
                               }) => {
  const t = useTranslator("components.notifications.showNotificationsCard")

  const columns = [
    {
      name: t("notificationTitle"),
      property: "title",
      isTh: true
    },
    {
      name: t("status"),
      formattedContent: ({ active }) => {
        return (
          <TableOutput.Status
            status={t("statusText", active)}
            color={active ? "success" : "danger"}
          />
        )
      }
    },
    {
      name: t("type"),
      formattedContent: ({ notificationType }) => t("typeText", notificationType),
    },
    {
      formattedContent: (notification) => {
        const { notificationType } = notification
        const sendOption = {
          icon: <i className="fas fa-paper-plane"/>,
          onClick: () => setNotificationToSend({ ...notification }),
          description: t("enterToSendFormDescription")
        }

        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setNotificationToUpload({ ...notification }),
            description: t("modifyDescription")
          },
          ...(notificationType !== "SUBSCRIPTION" ? [sendOption] : []),
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            onClick: () => setNotificationToRemove({ ...notification }),
            description: t("modifyDescription")
          }
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    },

  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          { children: <AddButtonChildren children={t("addButtonText")}/>, onClick: () => setNotificationToUpload({}) },
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={notifications}
        onRowClick={(user) => onClickNotification({ ...user })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={amount}
      />
      <TableCard.Footer
        amount={amount}
        totalEntries={totalEntries}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowNotificationsCard;