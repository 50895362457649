import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { useFormInputValidation } from "@general-backoffice/core/index";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";


const UploadTagModal = ({ tag, setTag, onSubmit, isLoading }) => {
  const t = useTranslator("components.tag.uploadTagModal")
  const { onChangeInput: onChange } = useChangingComplexState(setTag, isLoading)
  const { name, id } = (tag || {});

  const {
    inputRef: nameInputRef,
    throwError: throwErrorName
  } = useFormInputValidation(name)

  const onSubmitForm = () => {
    if ((name || "").length > 30) return throwErrorName(t("tooLongNameError"))
    onSubmit()
  }

  return (
    <Modal.FormContainer
      isOpen={!!tag}
      toggleOpen={() => setTag(null)}
      title={t("title", !!id, name)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmitForm}
      isLoading={isLoading}
    >
      <FormInput.Input
        innerRef={nameInputRef}
        onChange={onChange}
        value={name}
        name={"name"}
        label={t("nameLabel")}
        required={true}
        error={(name || "").length > 30 ? t("tooLongNameErrorText") : ""}
      />
    </Modal.FormContainer>
  );
}

export default UploadTagModal;