import { useEffect, useState } from "react";
import { managerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { hardTranslator } from "../../../hooks/useTranslator";

const defaultPagination = { page: 1, amount: 5 }

const useManagers = () => {
  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [managers, setManagers] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination)
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [clubId, pagination])

  const refresh = async () => {
    setIsLoading(true)
    return getManagers({ page: pagination.page, size: pagination.amount })
      .then(({ managers, totalAmount }) => {
        setManagers(managers)
        setTotalAmount(totalAmount)
        return managers
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const changePage = (page) => setPagination((currentPagination) => ({ ...currentPagination, page }))
  const changeAmount = (amount) => setPagination((currentPagination) => ({ ...currentPagination, size: amount }))

  return {
    managers,
    pagination,
    changePage,
    changeAmount,
    totalAmount,
    isLoading,
    refresh
  }
}

export const getManagers = async (paramsRequest) => {
  const t = hardTranslator("components.managers.hooks.useManagers")

  try {
    const { userList = [], count = 0 } = await managerRequests.getList(paramsRequest)
    return { managers: userList, totalAmount: count }
  } catch (e) {
    const message = (e?.message || t("getManagersError"))
    notify.error(message)
    throw new Error(message)
  }
}

export default useManagers;