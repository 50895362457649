import React from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { Card, Loading, TableNoContentMessage } from "@general-backoffice/core";
import { colors } from "../config";
import TrainingPreview from "../shared/TrainingPreview";


const StatsTrainingCyclingPreview = ({ structure = [], isLoading, title = "Previsualización" }) => {

  let totalDuration = 0;
  structure.forEach(({ duration }) => {
    totalDuration += duration
  })

  const hasPreview = totalDuration > 0;

  return (
    <React.Fragment>
      <ReactstrapCard style={{ backgroundColor: colors.dark }}>
        <Card.Header
          isDark={true}
          title={title}
        />
        <CardBody className="pt-0">
          {isLoading && <Loading.BouncingDots className="my-5" color={"light"}/>}
          {(!hasPreview && !isLoading) &&  <TableNoContentMessage children={"No hay datos suficientes"}/>}
          {(hasPreview && !isLoading) && <TrainingPreview structure={structure} height={300}/>}
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsTrainingCyclingPreview;