import { v4 as uuid } from "uuid";
import Api from "../ApiUltrik";

const getChannels = async () => Api.get(`/channels`).then((channels) => channels.sort((a, b) => a.name.localeCompare(b.name)));
const getChannel = (url) => Api.get(`/channels/${url}`).then(({ data, ...channel }) => ({
  ...channel,
  data: (data || []).map((event) => ({ ...event, id: uuid() }))
}));
const putChannel = (url, body) => Api.post(`/channels/${url}`, body);
const postChannel = (body) => Api.post(`/channels`, body);
const deleteChannel = (url) => Api.delete(`/channels/${url}`);
const debug = () => Api.get(`debug_channels`, { params: { clubId: undefined } });

const channelRequests = {
  getList: getChannels,
  get: getChannel,
  post: postChannel,
  put: putChannel,
  delete: deleteChannel,
  debug: debug
}

export default channelRequests