
const getMeetingFullCalendarEvent = (props) => {
  const { description, title, url, currParticipants, maxParticipants, ...otherProps } = props;
  return ({
    title: "🏁 " + (currParticipants - 1) + "/" + (maxParticipants - 1),
    classNames: ["bg-gradient-default", "text-white", "cursor-pointer"],
    editable: false,
    extendedProps: {
      availability: false,
      url
    },
    currParticipants,
    maxParticipants,
    ...otherProps
  })
}

const convertMeetingsToFullCalendarEvents = (meetings = []) => {
  return meetings.map((meeting) => getMeetingFullCalendarEvent(meeting))
}

export default convertMeetingsToFullCalendarEvents;
