import React, { useState } from "react";
import { Header, StatsCard } from "@general-backoffice/core";
import { Col, Container } from "reactstrap";
import useTranslator from "../../../../hooks/useTranslator";
import { useNavigate } from "react-router";
import useTeams from "../../../../components/team/hooks/useTeams";
import ShowTeamsCard from "../../../../components/team/ShowTeamsCard";
import useTeam from "../../../../components/team/hooks/useTeam";
import UploadTeamModal from "../../../../components/team/UploadTeamModal";
import RemoveModal from "../../../../components/shared/RemoveModal";
import { teamRequests } from "../../../../api/requests";


const Teams = () => {
  const navigate = useNavigate()
  const t = useTranslator("views.pages.dashboard.onDemand")
  const [teamIdToRemove, setTeamIdToRemove] = useState();


  const {
    teams,
    isLoading: isLoadingTeams,
    refresh: refreshTeams,
    totalElements: totalElementsTeams,
    paramsRequest: paramRequestTeams,
    changePageNumber: changePageNumberTeams,
    changePageSize: changePageSizeTeams,
    changeFilter: changeFilterTeams,
  } = useTeams({ pageNumber: 1, pageSize: 10 });

  const {
    team: teamToUpload,
    setTeam: setTeamToUpload,
    save: saveTeamToUpload,
    isLoading: isLoadingTeamToUpload
  } = useTeam()

  return (
    <React.Fragment>

      <UploadTeamModal
        team={teamToUpload}
        isLoading={isLoadingTeamToUpload}
        setTeam={setTeamToUpload}
        onSubmit={async () => {
          await saveTeamToUpload()
          await refreshTeams()
          setTeamToUpload(null)
        }}
      />

      <RemoveModal
        isOpen={!!teamIdToRemove}
        onClose={() => setTeamIdToRemove(null)}
        onSubmit={() => {
          teamRequests.delete(teamIdToRemove)
            .then(() => refreshTeams())
            .then(() => setTeamIdToRemove(null))
            .catch(() => null)
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>
        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="primary"
            isDark={true}
            number={totalElementsTeams}
            suffix={t("stats.numberOfTeams.suffix", totalElementsTeams)}
            title={t("stats.numberOfTeams.title")}
            icon="fas fa-futbol"
            description={t("stats.numberOfTeams.description")}
          />
        </Col>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <ShowTeamsCard
          isLoading={isLoadingTeams}
          teams={teams}
          setTeamToUpload={setTeamToUpload}
          onClickTeam={setTeamToUpload}
          setTeamToRemove={({ id }) => setTeamIdToRemove(id)}
          onClickTeamToShowContent={({ id }) => navigate(`/dashboard/on-demand/teams/${id}/sections`)}
          pagination={{
            ...paramRequestTeams,
            changeFilter: changeFilterTeams,
            changePageNumber: changePageNumberTeams,
            changePageSize: changePageSizeTeams,
            totalElements: totalElementsTeams
          }}
        />
      </Container>
    </React.Fragment>
  );
}

export default Teams;