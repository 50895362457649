import React from "react";
import Constructor from "../../../../components/trainings/cycling/Constructor";
import useTraining from "../../../../components/trainings/hooks/useTraining";

const TrainingConstructor = () => {
  const { training, setTraining, isLoading, } = useTraining()

  return (
    <div className="mt-3">
      <Constructor
        training={training}
        setTraining={setTraining}
        isLoading={isLoading}
      />
    </div>
  );
}

export default TrainingConstructor;