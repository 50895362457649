import { useState } from "react";

const useRequestControl = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const initiateRequest = () => {
    setIsLoading(true)
    setError(false)
  }

  const completeRequest = (error = false) => {
    setIsLoading(false)
    setError(error)
  }

  return {
    isLoading,
    error,
    initiateRequest,
    completeRequest
  }
}

export default useRequestControl