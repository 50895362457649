import React from "react";
import { Header } from "@general-backoffice/core";
import { Container } from "reactstrap";
import ShowSlidesCard from "../../../../components/slider/ShowSlidesCard";
import useSlides from "../../../../components/slider/hooks/useSlides";
import RemoveSlideModal from "../../../../components/slider/RemoveSlideModal";
import useSlide from "../../../../components/slider/hooks/useSlide";
import UploadSlideModal from "../../../../components/slider/UploadSlideModal";


const Slider = () => {

  const {
    slides,
    isLoading: isLoadingSlides,
    sort: sortSlides,
    refresh: refreshSlides
  } = useSlides()

  const {
    slide: slideToRemove,
    setSlide: setSlideToRemove,
    isLoading: isLoadingSlideToRemove,
    remove: removeSlideToRemove,
  } = useSlide()

  const {
    slide: slideToUpload,
    setSlide: setSlideToUpload,
    isLoading: isLoadingSlideToUpload,
    save: saveSlideToUpload,
  } = useSlide()

  return (
    <React.Fragment>

      <RemoveSlideModal
        slide={slideToRemove}
        close={() => setSlideToRemove(null)}
        isLoading={isLoadingSlideToRemove}
        remove={async () => {
          return removeSlideToRemove()
            .then(() => {
              refreshSlides()
              setSlideToRemove(null)
            })
            .catch(() => null)
        }}
      />

      <UploadSlideModal
        slide={slideToUpload}
        isLoading={isLoadingSlideToUpload}
        setSlide={setSlideToUpload}
        onSubmit={async () => {
          return saveSlideToUpload()
            .then(() => {
              refreshSlides()
              setSlideToUpload(null)
            })
            .catch(() => null)
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <ShowSlidesCard
          slides={slides}
          isLoading={isLoadingSlides}
          sortSlides={sortSlides}
          setSlideToRemove={setSlideToRemove}
          setSlideToUpload={setSlideToUpload}
          onClickSlide={setSlideToUpload}
        />
      </Container>
    </React.Fragment>
  );
}

export default Slider;