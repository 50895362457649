import Api from "../Api";

const getMeeting = (id) => Api.get(`/admin/meetings/${id}`);
const getMeetingCategories = () => Api.get(`/admin/meetings/categories`);
const getMeetingsList = (params = {}) => Api.get(`/admin/meetings`, { params });
const deleteMeeting = (id) => Api.delete(`/admin/meetings/${id}`);
const putMeeting = (id, body) => Api.put(`/admin/meetings/${id}`, body);
const postMeeting = (body) => Api.post(`/admin/meetings`, body);
const postMeetingTemplate = (body) => Api.post(`/admin/meetings/template`, body);

const meetingRequests = {
  get: getMeeting,
  getCategories: getMeetingCategories,
  getList: getMeetingsList,
  delete: deleteMeeting,
  put: putMeeting,
  post: postMeeting,
  postTemplate: postMeetingTemplate
}

export default meetingRequests