import { useMemo } from "react";
import useTranslator from "../useTranslator";

const allIntervals = ["MONTH", "YEAR", "LIFE"]
const useTranslatedIntervals = (intervals = allIntervals) => {
  const t = useTranslator("intervals")

  const translatedIntervals = useMemo(() => getTranslatedIntervals(t, intervals), [t, intervals])
  const intervalOptions = useMemo(() => getIntervalOptions(t, intervals), [t, intervals])
  const getIntervalOption = (v) => findIntervalOption(t, v)

  return {
    intervals,
    translatedIntervals,
    intervalOptions,
    getIntervalOption
  }
}

const getTranslatedIntervals = (t, intervals) => {
  return intervals.reduce((translated, interval) => {
    translated[interval] = t(interval) || interval
    return translated
  }, {})
}

const getIntervalOptions = (t, intervals) => {
  return intervals.map((interval) => ({ value: interval, label: t(interval) || interval }))
}

const findIntervalOption = (t, value) => {
  return allIntervals.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedIntervals;
export { allIntervals, getTranslatedIntervals, getIntervalOptions, findIntervalOption }