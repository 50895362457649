import React from "react";
import { TableOutput, Table, TableCard, Card, FormInput } from "@general-backoffice/core";
import { Card as ReactstrapCard } from "reactstrap";
import moment from "moment";
import useTranslator from "../../hooks/useTranslator";

const ShowProductListCard = ({
                               title,
                               products = [],
                               productOptions = [],
                               setProductToUpload,
                               onClickProduct,
                               sortProducts,
                               isLoading
                             }) => {
  const t = useTranslator("components.products.showProductListCard")


  const productIdList = products.map(({ id }) => id)
  const options = productOptions.map(({ id, title }) => ({ value: id, label: title }))

  const columns = [
    {
      name: t("code"),
      property: "code",
      isTh: true
    },
    {
      name: t("product"),
      formattedContent: ({ title = "", thumbnail, premiere }) => {
        const titleEllipsis = title.substring(0, 30).trim() + (title.length > 30 ? "..." : "")
        const { url } = (thumbnail || {})
        const text = <TableOutput.TitleSubtitle title={titleEllipsis}/>
        return <TableOutput.ImageText src={url} text={text}/>
      },
    },
    {
      formattedContent: ({ premiere, published, publicationDate, publicationEndDate }) => {
        const bookmarks = []

        // Publication
        if (published) {
          let title = t("published")
          if (!!publicationDate || !!publicationEndDate) {
            const initial = publicationDate ? moment(publicationDate).format("DD/MM/YYYY") : t("unspecifiedPublication")
            const final = publicationEndDate ? moment(publicationEndDate).format("DD/MM/YYYY") : t("unspecifiedPublication")
            title = `${initial} - ${final}`
          }
          bookmarks.push({ icon: <i className="fas fa-eye"/>, title: title, color: "primary" })
        } else bookmarks.push({ icon: <i className="fas fa-eye-slash"/>, title: t("unpublished"), color: "primary" })

        // Premiere
        if (premiere) bookmarks.push({ icon: <i className="fas fa-star"/>, title: t("premiere"), color: "primary" })
        else bookmarks.push({ icon: <i className="far fa-star"/>, title: t("noPremiere"), color: "primary" })

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      formattedContent: (product) => {
        const productIdListWithoutThis = (productIdList || []).filter((id) => id !== product.id)
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setProductToUpload({ ...product }),
              description: t("modifyDescription")
            },
            {
              icon: <i className="fas fa-trash table-action-delete"/>,
              onClick: () => sortProducts(productIdListWithoutThis),
              description: t("removeDescription")
            }
          ]}
        />
      }
    },
  ]

  const onDataChange = async (sortedProducts, trigger) => {
    if (trigger === "sortable") {
      sortProducts(sortedProducts.map(({ id }) => id))
    }
  }

  return (
    <ReactstrapCard className="overflow-hidden">
      <TableCard.Header
        title={t("title", title)}
        subtitle={t("subtitle")}
        isLoading={isLoading}
      >
        <div className="mb--4" style={{ width: 250, maxWidth: "100%" }}>
          <FormInput.Select
            value={null}
            onChange={({ value }) => sortProducts([value, ...(productIdList || [])])}
            options={options}
            isDisabled={isLoading}
            onCreateOption={(inputValue) => setProductToUpload({ title: inputValue })}
            isCreatable={true}
            formatCreateLabel={(inputValue) => t("productCreatingFormatText", inputValue)}
            noOptionsMessage={() => t("noProductOptionsText")}
            placeholder={t("selectProductPlaceholder")}
            icon={<i className="fas fa-plus px-2 text-primary"/>}
          />
        </div>
      </TableCard.Header>
      <Table
        sortable={true}
        onDataChange={onDataChange}
        columns={columns}
        rows={products}
        onRowClick={(product) => onClickProduct({ ...product })}
        isLoading={isLoading}
        noContentMessage={t("empty")}
        rowAmount={10}
        rowHeight={81}
      />
      <Card.Footer/>
    </ReactstrapCard>
  );
}

export default ShowProductListCard;