import React from "react";
import { utils, Modal, FormInput } from "@general-backoffice/core";
import getDefaultNotification from "./shared/getDefaultNotification";
import { Collapse } from "reactstrap";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedNotificationTypes from "../../hooks/translations/useTranslatedNotificationTypes";
import useProducts from "../product/hooks/useProducts";
import useNews from "../news/hooks/useNews";


const UploadNotificationModal = ({
                                   notification = null,
                                   setNotification = utils.noop,
                                   isLoading,
                                   onSubmit
                                 }) => {
  const t = useTranslator("components.notifications.uploadNotificationModal")
  const { notificationTypeOptions, getNotificationTypeOption } = useTranslatedNotificationTypes()
  const { changeState, onChangeInput: onChange } = useChangingComplexState(setNotification, isLoading)

  const {
    products,
    isLoading: isLoadingProducts
  } = useProducts()

  const {
    news,
    isLoading: isLoadingNews
  } = useNews()


  const isLoadingOptions = isLoadingProducts || isLoadingNews
  const {
    id,
    active,
    daysBefore,
    entityId,
    message,
    notificationType,
    title
  } = { ...getDefaultNotification(), ...(notification || {}) }

  const productOptions = products.map(({ id, title, ...product }) => ({ value: id, label: title, ...product }))
  const newsOptions = news.map(({ id, title, ...theNew }) => ({ value: id, label: title, ...theNew }))

  const showNotificationProduct = notificationType === "PRODUCT"
  const showNotificationNew = notificationType === "NEW"
  const showNotificationSubscription = notificationType === "SUBSCRIPTION"

  return (
    <Modal.FormContainer
      isOpen={!!notification}
      toggleOpen={() => setNotification(null)}
      title={t("title", !!id, title)}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSaveText") }}
      onSubmit={onSubmit}
      isLoading={isLoading || isLoadingOptions}
    >
      <FormInput.Input
        type={"text"}
        onChange={onChange}
        value={title}
        name={"title"}
        label={t("titleLabel")}
        required={true}
      />

      <FormInput.Switch
        onChange={onChange}
        checked={active}
        name={"active"}
        label={t("isActiveLabel")}
        labels={t("isActiveAnswers")}
      />

      <FormInput.Input
        type={"textarea"}
        onChange={onChange}
        value={message}
        name={"message"}
        label={t("messageLabel")}
        required={true}
      />

      <FormInput.Select
        label={t("notificationTypeLabel")}
        name={"notificationType"}
        icon={<i className="far fa-bell mx-2"/>}
        onChange={(option) => changeState("notificationType", option.value)}
        options={notificationTypeOptions}
        value={getNotificationTypeOption(notificationType)}
        required={true}
      />

      <Collapse isOpen={showNotificationProduct}>
        <FormInput.Select
          label={t("productLabel")}
          name={"entityId"}
          onChange={(option) => changeState("entityId", option.value)}
          options={productOptions}
          value={productOptions.find(({ value }) => value === entityId)}
          required={showNotificationProduct}
        />
      </Collapse>

      <Collapse isOpen={showNotificationNew}>
        <FormInput.Select
          label={t("newLabel")}
          name={"entityId"}
          onChange={(option) => changeState("entityId", option.value)}
          options={newsOptions}
          value={newsOptions.find(({ value }) => value === entityId)}
          required={showNotificationNew}
        />
      </Collapse>


      <Collapse isOpen={showNotificationSubscription}>
        <FormInput.Input
          type={"number"}
          value={daysBefore}
          name={"daysBefore"}
          min={0}
          onChange={onChange}
          label={t("daysBeforeSubscriptionEndLabel")}
          description={t("daysBeforeSubscriptionEndDescription", daysBefore)}
          required={showNotificationSubscription}
        />
      </Collapse>
    </Modal.FormContainer>
  );
}

export default UploadNotificationModal;