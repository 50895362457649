const colors = [
  "#fb6340",
  "#2dce89",
  "#5e72e4",
  "#11cdef",
  "#f5365c",
  "#f5ee7d",
  "#f58bde",
  "#7846f5"
];
const successColorHard = "#1e885c";
const successColor = "#2dce89";
const successColorLow = "#a6f8d2";
const successColorOp = "rgba(45, 206, 137, 0.5)";
const errorColor = "#f5365c";
const infoColor = "#11cdef";
const infoColorOp = "rgba(17,205,239,0.5)";

export { colors, successColor, errorColor, successColorOp, successColorHard, successColorLow, infoColor, infoColorOp }

export default colors;
