const getDefaultClubConfig = () => ({
  clubTheme: "dark",
  logosImageClub: {
    imageDark: { url: null, data: null, contentType: null },
    imageLight: { url: null, data: null, contentType: null },
    imageSquareDark: { url: null, data: null, contentType: null },
    imageSquareLight: { url: null, data: null, contentType: null },
  },
  personalizationClub: {
    showAddedRecentlyCategory: true,
    showTitlesOnCollectionThumbnail: true,
    showTitlesOnNewThumbnail: true,
    showTitlesOnPanoramic: true,
    showTitlesOnProductThumbnail: true,
    showTitlesOnTrainerThumbnail: true
  },
  seo: {
    description: "",
    title: "",
    favicon: { url: null, data: null, contentType: null },
    googleAnalyticsMeasurementId: "",
  },
  primaryColor: null,
})

export default getDefaultClubConfig;