import React, { useState } from "react";
import { Table, TableCard, TableOutput, utils } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowCategoriesCard = ({
                              categories,
                              sortCategories = utils.noop,
                              setCategoryToUpload,
                              setCategoryToRemove,
                              onClickCategory = utils.noop,
                              onClickCategoryToShowContent,
                              isLoading
                            }) => {
  const t = useTranslator("components.category.showCategoriesCard")
  const [page, setPage] = useState(1)
  const [amount, setAmount] = useState(10)

  const onDataChange = async (sortedCategories, trigger) => {
    if (trigger === "sortable") {
      sortCategories(sortedCategories)
    }
  }

  const columns = [
    {
      name: t("name"),
      property: "name",
      isTh: true
    },
    {
      name: t("type"),
      formattedContent: ({ type }) => t("types", type)
    },
    {
      formattedContent: ({ published, type, productIdList = [], collectionIdList = [] }) => {
        const typeP = type === "PRODUCT"
        const typeC = type === "COLLECTION"
        const typeA = type === "AUTOMATIC"
        const numberOfContent = typeP ? (productIdList || []).length : typeC ? (collectionIdList || []).length : 0
        const hasContent = numberOfContent > 0

        const bookmarks = [
          {
            icon: published ? <i className="fas fa-eye"/> : <i className="fas fa-eye-slash"/>,
            title: published ? t("published") : t("unpublished"),
            color: "primary"
          },
          {
            icon: typeA ? <i className="fas fa-robot"/> : numberOfContent,
            title: typeA ? t("types", type) : hasContent ? t(typeP ? "numberProducts" : "numberCollections", numberOfContent) : t("noContent"),
            color: "primary"
          }
        ]

        return <TableOutput.Bookmarks bookmarks={bookmarks}/>
      }
    },
    {
      name: "",
      formattedContent: (category) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setCategoryToUpload({ ...category }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-th-list"/>,
            onClick: () => onClickCategoryToShowContent({ ...category }),
            description: t("modifyContentDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setCategoryToRemove({ ...category })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        subtitle={t("subtitle")}
        buttons={[
          {
            children: <AddButtonChildren children={t("buttonAddText")}/>,
            onClick: () => setCategoryToUpload({})
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        sortable={true}
        onDataChange={onDataChange}
        columns={columns}
        rows={categories.slice(((page - 1) * amount), ((page - 1) * amount) + amount)}
        onRowClick={(category) => onClickCategory({ ...category })}
        isLoading={isLoading}
        noContentMessage={t("emptyCategories")}
        rowAmount={amount}
      />
      <TableCard.Footer
        setAmount={setAmount}
        amount={amount}
        totalEntries={categories.length}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowCategoriesCard;