import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";

const Redirect = ({ authRedirect, unAuthRoute }) => {
  const token = useSelector((state) => state.sessionState.token)
  if (token) return <Navigate to={authRedirect} replace={true}/>
  if (!token) return <Navigate to={unAuthRoute} replace={true}/>
}

export default Redirect;