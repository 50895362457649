import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormText, Row } from "reactstrap";
import { FormInput, Modal, notify } from "@general-backoffice/core";
import { v4 as uuid } from "uuid";
import { maxFtp, minDuration, minFtp } from "../config";
import moment from "moment";
import useChangingComplexState from "../../../../hooks/useChangingComplexState";

const idForm = uuid()

const UploadTrainingCyclingStructureGraphSegmentModal = ({
                                                           segmentToUpload,
                                                           setSegmentToUpload,
                                                           onSave
                                                         }) => {
  const [rpmAnchored, setRpmAnchored] = useState(false)
  const {
    rpm,
    ftp,
    id,
    standing,
    duration,
    startDuration
  } = (segmentToUpload || {})
  const [initFtp] = (ftp || []);
  const [initRpm, endRpm] = (rpm || []);

  const { changeState, onChangeInput: onChange } = useChangingComplexState(setSegmentToUpload)

  const onClickSave = (e) => {
    e.preventDefault();
    onSave(segmentToUpload);
    setSegmentToUpload(null)
  }

  useEffect(() => {
    if (!!segmentToUpload && rpmAnchored && (!!initRpm || initRpm === 0)) changeState("rpm[1]", initRpm)
    // eslint-disable-next-line
  }, [initRpm, rpmAnchored])

  useEffect(() => {
    if (!!segmentToUpload && rpmAnchored && (!!endRpm || endRpm === 0)) changeState("rpm[0]", endRpm)
    // eslint-disable-next-line
  }, [endRpm])

  const repeatRPM = (!rpm);
  return (
    <Modal.Simple
      title={`${id ? "Editar" : "Añadir"} segmento`}
      size="sm"
      isOpen={!!segmentToUpload}
      toggleOpen={() => setSegmentToUpload(null)}
      buttons={[
        { children: (id ? "Editar" : "Añadir"), form: idForm, type: "submit", color: "success" }
      ]}
    >
      <Form id={idForm} onSubmit={onClickSave}>

        <FormInput.Input
          label="FTP"
          type={"number"}
          min={minFtp}
          max={maxFtp}
          value={initFtp}
          name={"ftp"}
          required={true}
          onChange={({ target: { value } }) => changeState("ftp", [parseFloat(value), parseFloat(value)])}
        />

        <FormInput.Time
          label={"Duración"}
          value={moment().startOf("day").add(duration, "seconds")}
          required
          onChange={(value) => {
            const newDuration = moment(value).diff(moment().startOf('day'), 'seconds', true);
            if (newDuration >= minDuration) changeState("duration", newDuration)
            else {
              notify.warning(`La duración mínima es de ${minDuration}s.`)
              changeState("duration", minDuration)
            }
          }}
          description={`Desde ${moment.utc(startDuration * 1000).format('HH:mm:ss:S')} hasta ${moment.utc((startDuration + duration) * 1000).format('HH:mm:ss:S')}`}
        />

        <FormInput.Switch
          label="¿De pie?"
          checked={standing}
          name={"standing"}
          labels={["Sí", "No"]}
          onChange={onChange}
        />
        <FormInput.Switch
          label="¿Mantener RPM?"
          checked={repeatRPM}
          labels={["Sí", "No"]}
          onChange={({ target }) => changeState("rpm", (target.checked ? null : [0, 0]))}
        />
        {!repeatRPM && (
          <Row className="flex-content-center">
            <Col>
              <FormInput.Input
                label="RPM inicial"
                type={"number"}
                min={0}
                max={3000}
                value={initRpm}
                name={"rpm[0]"}
                onChange={onChange}
              />
            </Col>

            <Col xs={"auto"} className="cursor-pointer p-0">
              <Button className="bg-transparent border-0 px-3" onClick={(e) => {
                e.stopPropagation()
                setRpmAnchored(!rpmAnchored)
              }}>
                {rpmAnchored ? <i className="fas fa-lock"/> : <i className="fas fa-unlock"/>}
              </Button>
            </Col>

            <Col>
              <FormInput.Input
                label="RPM final"
                type={"number"}
                min={0}
                max={3000}
                value={endRpm}
                name={"rpm[1]"}
                onChange={onChange}
              />
            </Col>
            <Col xs={12} className="mt--4">
              <FormText>
                {rpmAnchored ? "RPM invariable" : "RPM variable"}
              </FormText>
            </Col>
          </Row>
        )}
      </Form>
    </Modal.Simple>
  );
}

export default UploadTrainingCyclingStructureGraphSegmentModal;