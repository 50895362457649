import convertTransUrlToObject from "../../../utils/convertTransUrlToObject";

const createStreamingCalendarEvent = (event) => {
  const { url, id, start, end } = event
  const { filename } = convertTransUrlToObject(url)
  return {
    id: id,
    start: start,
    end: end,
    title: filename,
    classNames: ["bg-gradient-default", "text-white", "cursor-pointer"],
    extendedProps: {
      type: "streaming",
      ...event
    },
  }
}

const convertChannelEventsToFullCalendarEvents = (events = []) => {
  const calendarEvents = [];
  (events || []).forEach((channelEvent) => {
    const calendarEvent = convertChannelEventToFullCalendarEvent(channelEvent)
    if (!!calendarEvent) calendarEvents.push(calendarEvent)
  })
  return calendarEvents
}

const convertChannelEventToFullCalendarEvent = (channelEvent) => {
  return createStreamingCalendarEvent(channelEvent)
}

const convertFullCalendarEventsToChannelEvents = (fullCalendarEvents = []) => {
  return fullCalendarEvents.map(convertFullCalendarEventToChannelEvent)
}

const convertFullCalendarEventToChannelEvent = ({ extendedProps, start, end }) => ({ ...extendedProps, start, end })

export {
  createStreamingCalendarEvent,
  convertChannelEventsToFullCalendarEvents,
  convertChannelEventToFullCalendarEvent,
  convertFullCalendarEventsToChannelEvents,
  convertFullCalendarEventToChannelEvent
}