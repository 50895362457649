import React from "react";
import { Navigate } from "react-router";
import Club from "../../../views/pages/dashboard/clubConfig/Club";
import Structure from "../../../views/pages/dashboard/clubConfig/Structure";
import Seo from "../../../views/pages/dashboard/clubConfig/Seo";


const subRoutes = (t) => [
  {
    name: t("clubConfig"),
    miniName: t("clubConfig").charAt(0).toUpperCase(),
    path: "",
    showOnNav: true,
    component: <Club/>,
  },
  {
    name: t("structureConfig"),
    miniName: t("structureConfig").charAt(0).toUpperCase(),
    path: "structure",
    showOnNav: true,
    component: <Structure/>,
  },
  {
    name: t("seoConfig"),
    miniName: t("seoConfig").charAt(0).toUpperCase(),
    path: "seo",
    showOnNav: true,
    component: <Seo/>,
  },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/configuration/club"} replace={true}/>,
  }
]

export { subRoutes };