import React from "react";
import { TableOutput, Table, Modal } from "@general-backoffice/core";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";
import useTranslatedIntensities from "../../hooks/translations/useTranslatedIntensities";

const ShowMeetingTemplatesModal = ({
                                     meetingTemplates = [],
                                     isOpen,
                                     close,
                                     setMeetingTemplateToUpload,
                                     onClickMeetingTemplate,
                                     isLoading
                                   }) => {
  const t = useTranslator("components.meetings.showMeetingTemplatesModal")
  const { translatedIntensities } = useTranslatedIntensities()

  const columns = [
    {
      name: t("name"),
      property: "templateName",
      isTh: true
    },
    {
      name: t("level"),
      formattedContent: ({ level }) => translatedIntensities[level],
    },
    {
      name: t("participants"),
      formattedContent: ({ maxParticipants }) => `${maxParticipants ? (maxParticipants - 1) : 0} Max.`,
    },
    {
      name: t("price"),
      formattedContent: ({ price }) => `${price}€`,
    },
    {
      name: "",
      formattedContent: (meeting) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setMeetingTemplateToUpload({ ...meeting }),
            description: t("modifyDescription")
          },
        ]
        return <TableOutput.CustomActions actions={actions}/>
      }
    }
  ]

  return (

    <Modal.Simple
      isOpen={!!isOpen}
      isLoading={isLoading}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      buttons={[
        {
          children: <AddButtonChildren children={t("addButtonText")}/>,
          onClick: () => setMeetingTemplateToUpload({}),
          color: "primary"
        }
      ]}
    >
      <div className="mb-0 mx--4 mb--4">
        <Table
          columns={columns}
          rows={meetingTemplates}
          onRowClick={(template) => onClickMeetingTemplate({ ...template })}
          isLoading={isLoading}
          noContentMessage={t("empty")}
        />
      </div>
    </Modal.Simple>
  );
}

export default ShowMeetingTemplatesModal;