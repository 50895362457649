import DarkContainer from "./shared/DarkContainer"
import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject"
import clock from "../../../utils/clock"
import ZoneSquare from "../../shared/ZoneSquare"
import classNames from "classnames";

const textSize = "0.9vw + 0.8vh - 2px"
const numberSize = "1.1vw + 1.1vh"
// const textSize = 23
// const numberSize = 35

const SegmentBar = ({title = null, segment, showDuration, className, scale = 1, ...props}) => {
  const { standing, currentFTP, ftp, rpm, duration, zone } = segment || {}

  let rpmText = (rpm?.length === 2 ? (rpm[0] === rpm[1] ? rpm[0] : rpm.join(' - ')) :  '-').toString()

  let scaledTextSize = `(${textSize})*${scale}`
  let scaledNumberSize = `(${numberSize})*${scale}`

  return(
    <DarkContainer {...props} className={classNames({"hide-contents": !segment}, className)}>
      {title}

      
      <div className="px-3">
        <ZoneSquare zone={zone?.zone} style={{ width: '4vw', minWidth: 35 }}/>
      </div>

      <div className="my-2" style={{fontSize: `calc(${scaledTextSize}*0.9)`, minWidth: `calc(${scaledTextSize}*0.9*5.5)`, whiteSpace: 'nowrap'}}>
        {standing ? 'DE PIE' : 'SENTADO'}
      </div>
      <SimpleData scale={scale} text="RPE" value={zone?.rpe || '-'} />
      <SimpleData scale={scale} text="%FTP" value={currentFTP || ftp?.[0] || '-'} />

      <div className="my-auto" style={{whiteSpace: 'nowrap', lineHeight: 1}}>
        <span className="mb-2" style={{fontSize: `calc(${scaledTextSize})`}}>RPM</span><br/>
        <div style={{
          fontSize: `calc(${scaledNumberSize}*${scale})`, 
          width: `calc(${scaledNumberSize}*${scale}*4.5)`,
          lineHeight: `calc(${scaledNumberSize})`
        }}>
          {rpmText}
        </div>
      </div>

      {showDuration && 
        <SimpleData scale={scale} text="TIEMPO" value={clock(formatSecondsToTimeObject(duration || 0))} />}
    </DarkContainer>
  )
}

const SimpleData = ({text, value, scale}) => 
  <div style={{whiteSpace: 'nowrap', lineHeight: 1}}>
    <span className="mb-2" style={{fontSize: `calc((${textSize})*${scale})`}}>{text}</span><br/>
    <div style={{fontSize: `calc((${numberSize})*${scale})`, width: `calc((${numberSize})*${scale}*3)`}}>{value}</div>
  </div>


export default SegmentBar;