import React, { useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { Card, FormInput, notify, utils } from "@general-backoffice/core/index";
import useTranslator from "../../hooks/useTranslator";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import { notifyLoadingToSuccess } from "../utils/notify/notifyLoadingUpdate";
import useApiFileImages from "../../hooks/useApiFileImages";
import getDefaultClubSeo from "./shared/getDefaultClubSeo";

const ChangeClubSeoCard = ({ clubSeo, setClubSeo, isLoading }) => {
  const t = useTranslator("components.clubConfig.changeClubSeoCard")
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const {
    clubId,
    description,
    title,
    favicon,
    googleAnalyticsMeasurementId
  } = { ...getDefaultClubSeo(), ...(clubSeo || {}) }
  const { changeState, onChangeInput: onChange } = useChangingComplexState(setClubSeo, isLoading)

  const { files, setFiles, isLoadingFiles } = useApiFileImages({ favicon }, [clubId])
  const someIsLoading = isLoading || isLoadingLocal || isLoadingFiles

  return (
    <React.Fragment>
      <ReactstrapCard>
        <Card.Header title={t("title")} subtitle={t("subtitle")}/>
        <CardBody>
          <FormInput.SingleDropzone
            label={t("faviconLabel")}
            accept={".ico"}
            tooltip={t("faviconTooltip")}
            file={files.favicon}
            onDrop={async (file) => {
              setIsLoadingLocal(true)
              const notification = notify.loading(t("imageOptimizationLoading"))
              setFiles({ favicon: file })
              const image64 = await utils.fileToBase64(file)
              changeState(`favicon`, { id: null, data: image64.split(',')[1] })
              setIsLoadingLocal(false)
              notifyLoadingToSuccess(notification, t("imageOptimizationSucceed"))
            }}
            disabled={someIsLoading}
          />
          <FormInput.Input
            onChange={onChange}
            value={title}
            name="title"
            label={t("titleLabel")}
            tooltip={t("titleTooltip")}
            required
          />
          <FormInput.Input
            type={"textarea"}
            onChange={onChange}
            value={description}
            name="description"
            label={t("descriptionLabel")}
            tooltip={t("descriptionTooltip")}
            required
          />
          <FormInput.Input
            onChange={onChange}
            value={googleAnalyticsMeasurementId}
            name="googleAnalyticsMeasurementId"
            label={t("googleAnalyticsMeasurementIdLabel")}
            tooltip={t("googleAnalyticsMeasurementIdTooltip")}
          />
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default ChangeClubSeoCard;