import React, { createRef, useMemo, useState } from "react";
import { Card as ReactstrapCard } from "reactstrap";
import { Card, Loading } from "@general-backoffice/core";
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from "@fullcalendar/interaction";
import convertMeetingsToFullCalendarEvents from "../meetings/shared/convertMeetingsToFullCalendarEvents";
import moment from "moment";
import convertAvailabilityScheduleToFullCalendarEvents
  from "./shared/convertAvailabilityScheduleToFullCalendarEvents";
import convertFullCalendarEventsToAvailabilitySchedule
  from "./shared/convertFullCalendarEventsToAvailabilitySchedule";
import useTranslator from "../../hooks/useTranslator";

const ShowTrainerScheduleCard = ({
                                   meetings = [],
                                   availabilitySchedule,
                                   setMeetingToShow,
                                   setAvailabilityToUpload,
                                   saveAvailabilitySchedule,
                                   isLoading
                                 }) => {
  const t = useTranslator("components.trainerSchedule.showTrainerScheduleCard")

  const [title, setTitle] = useState("")
  const calendarRef = createRef()

  const getApi = () => calendarRef.current.getApi();
  const prev = () => getApi().prev()
  const now = () => {
    getApi().changeView("timeGridWeek")
    getApi().today()
  }
  const next = () => getApi().next()

  const updateAvailability = ({ event }) => {
    const { extendedProps, id, startStr } = event;
    if (extendedProps?.availability) {
      for (let [key, availabilities] of Object.entries(availabilitySchedule)) {
        const av = availabilities.find(av => av.id.toString() === id.toString())
        if (av) {
          setAvailabilityToUpload({ ...av, focusedDateTime: startStr, isRecurrent: key !== "oneTime" })
          break;
        }
      }
    } else {
      const meeting = meetings.find((meeting) => meeting.id.toString() === id.toString())
      setMeetingToShow(meeting)
    }
  }

  const createNewAvailability = ({ dateStr }) => {
    setAvailabilityToUpload({
      focusedDateTime: dateStr,
      start: moment(dateStr).utc().format(),
      end: moment(dateStr).add(1, "hour").utc().format(),
      isRecurrent: false
    })
  }

  const getNewAvailabilitiesSchedule = () => {
    const calendarApi = getApi()
    const curCalendarDate = calendarApi.getDate()
    const currAvailabilityEvents = calendarApi.getEvents().filter(({ start, extendedProps, id }) => {
      if (extendedProps?.availability) {
        if (Boolean(extendedProps.daysOfWeek)) {
          if (moment(start).isBetween(
            moment(curCalendarDate).startOf('week'),
            moment(curCalendarDate).endOf('week'),
            undefined, '[]')
          ) return true
          else return false
        } else return true
      } else return false
    });
    return convertFullCalendarEventsToAvailabilitySchedule(currAvailabilityEvents)
  }
  const eventMove = () => {
    const newAvailabilitiesSchedule = { ...getNewAvailabilitiesSchedule() }
    saveAvailabilitySchedule(newAvailabilitiesSchedule)
  }
  const save = () => saveAvailabilitySchedule({ ...getNewAvailabilitiesSchedule() })


  const changeTitle = ({ start, end }) => {
    const mStart = moment(start)
    const mEnd = moment(end).subtract(1, "second")
    if (mStart.isSame(mEnd, "day")) return setTitle(mStart.format("DD/MM/YYYY"))
    else setTitle(`${mStart.format("DD/MM/YYYY")} - ${mEnd.format("DD/MM/YYYY")}`)
  }

  const meetingsEvents = useMemo(() => convertMeetingsToFullCalendarEvents(meetings), [meetings])
  const availabilitiesEvents = useMemo(() => convertAvailabilityScheduleToFullCalendarEvents(availabilitySchedule), [availabilitySchedule])
  return (
    <React.Fragment>

      <ReactstrapCard>
        <Card.Header
          title={title}
          subtitle={t("subtitle")}
          buttons={[
            { children: t("buttonSaveText"), color: "success", onClick: save },
            { children: <i className="fas fa-angle-left px-2"/>, onClick: prev },
            { children: t("buttonNowText"), onClick: now },
            { children: <i className="fas fa-angle-right px-2"/>, onClick: next }
          ]}
          isLoading={isLoading}
        />
        {isLoading && <Loading.Line/>}
        <div className="calendar position-relative" id="calendar" style={{ height: '60vh', minHeight: 400 }}>
          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            locale={esLocale}

            stickyHeaderDates
            height="100%"

            headerToolbar=""
            slotDuration="00:30:00"

            editable={true}
            allDaySlot={false}
            navLinks={true}
            nowIndicator={true}
            displayEventTime={false}

            eventDrop={eventMove}
            dateClick={createNewAvailability}
            eventClick={updateAvailability}
            datesSet={changeTitle}
            events={[...meetingsEvents, ...availabilitiesEvents]}
          />
        </div>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default ShowTrainerScheduleCard;