import React from "react";
import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject"
import clock from "../../../utils/clock"
import BigTimer from "./shared/BigTimer"

const TotalTime = ({ currentTime, duration, scale, countdown= false }) => 
  <BigTimer 
    text={<>
      <div style={{marginBottom: 2}}>TIEMPO</div>
      <div>TOTAL</div>
    </>}
    time={clock(
      countdown ? 
        formatSecondsToTimeObject(duration - currentTime.totalSeconds) 
      : 
        currentTime
      , duration
    )}
    scale={scale}
  />

export default TotalTime;