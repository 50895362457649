import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

// Components
import StatsVideoTimeVisualization from "../../../../components/stats/StatsVideoTimeVisualization"
import StatsCategoriesVisualizations from "../../../../components/stats/StatsCategoriesVisualizations"
import StatsProductsVisualizations from "../../../../components/stats/StatsProductsVisualizations"
import StatsProductsLikes from "../../../../components/stats/StatsProductsLikes"


const OnDemandStats = () => {
  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <StatsVideoTimeVisualization/>
          </Col>
          <Col xs={12}>
            <StatsCategoriesVisualizations/>
          </Col>
          <Col xs={12} md={6}>
            <StatsProductsVisualizations/>
          </Col>
          <Col xs={12} md={6}>
            <StatsProductsLikes/>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default OnDemandStats;