import React from "react"
import getCountries from "../utils/getCountries";
import esLocale from "@fullcalendar/core/locales/es";
import 'moment/locale/es';
import moment from "moment";

const roles = {
  OWNER: "Propietario",
  MANAGER: "Manager",
  ACCOUNT_MANAGER: "Gestor de cuenta",
  UPLOADER: "Técnico",
  TRAINER: "Entrenador",
  TRAINER_PUBLISHER: "Entrenador publicador",
  CHANNEL_MANAGER: "Administrador de canales"
}

const intervals = {
  DAY: "Día",
  WEEK: "Semana",
  MONTH: "Mes",
  YEAR: "Año",
  LIFE: "De por vida"
}

const genders = {
  male: "Hombre",
  female: "Mujer",
  do_not_say: "Prefiero no decirlo"
}

const currencies = {
  EUR: "€",
  USD: "$"
}

const complexCurrencies = {
  EUR: "EUR (€)",
  USD: "USD ($)"
}

const intensities = {
  null: "No especificado",
  BEGINNER: "Principiante",
  MEDIUM: "Intermedio",
  ADVANCED: "Avanzado"
}

const levels = {
  null: "No especificado",
  LOW: "Bajo",
  MEDIUM: "Medio",
  HIGH: "Alto"
}

const notificationTypes = {
  DEFAULT: "Normal",
  PRODUCT: "Producto",
  NEW: "Noticia",
  PROFILE: "Perfil",
  SUBSCRIPTION: "Subscripción",
}

const planTypes = {
  REGULAR: "Plan online (subscripción)",
  RENTAL: "Plan de alquiler",
  SINGLE_PAYMENT: "Plan de pago único",
  PAY_IN_CASH: "Plan en efectivo",
}

const categoryTypes = {
  PRODUCT: "Producto",
  COLLECTION: "Colección",
  AUTOMATIC: "Automático"
}

const collectionTypes = {
  personal: "Personal/Perfil",
  product: "Estándar"
}

const producerTypes = {
  TRAINER: "Entrenador",
  INSTRUCTOR: "Instructor",
  NUTRITIONIST: "Nutricionista",
  PHYSIOTHERAPIST: "Fisioterapeuta",
  COACH: "Coach",
  DOCTOR: "Doctor",
}

const paymentMethodTypes = {
  card: "Tarjeta",
  sepa: "Cuenta Bancaria"
}

const navigationItemTypes = {
  "_blank": "Nueva pestaña",
  "_self": "Misma pestaña"
}

const advertisementPosition = {
  "login": "Banner en la página de inicio de sesión",
  "product": "Pop Up en la página de producto",
  "category": "Banner en la página y slider de categoría",
  "collection": "Pop Up en la página de colección",
  "footer": "Banner en el footer de toda la web"
}

const es = {
  language: "es",
  views: {
    layouts: {
      dashboard: {
        sessionOptionsHeader: "¡Bienvenido!",
        notificationsHeader: (number) => number > 0 ? `Tienes ${number} ${number === 1 ? "notificación pendiente" : "notificaciones pendientes"}` : "No tienes notificaciones pendientes"
      }
    },
    pages: {
      auth: {
        forgottenPassword: {
          emailPlaceholder: "Email",
          invalidEmailFeedbackText: "Introduce un email válido",
          lead: "Por favor, introduce un email para solicitar el cambio de contraseña",
          loginText: "Iniciar sesión",
          registerText: "",
          submitText: "Solicitar",
          title: "Solicitar nueva contraseña",
          success: "Solicitud de nueva contraseña realizada! en breve recibirás un email",
          error: "No se ha podido solicitar una nueva contraseña"
        },
        login: {
          emailPlaceholder: "Email",
          forgottenPasswordText: "Contraseña olvidada?",
          invalidEmailFeedbackText: "Introduce un email válido",
          invalidPasswordFeedbackText: "Introduce una contraseña válida",
          lead: "Por favor, inicia sesión para acceder al panel de control",
          passwordPlaceholder: "Contraseña",
          registerText: "",
          rememberLabel: "Recuerdame",
          submitText: "Inicia sesión",
          title: "Bienvenido!",
          success: "¡Bienvenido!",
          error: "Usuario o contraseña incorrectos"
        },
        resetPassword: {
          invalidPasswordConfirmFeedbackText: "Las contraseñas no coinciden",
          invalidPasswordFeedbackText: "Introduce una contraseña válida",
          lead: "Por favor, elige una nueva contraseña",
          loginText: "Inicia sesión",
          passwordConfirmPlaceholder: "Confirma la contraseña",
          passwordPlaceholder: "Contraseña",
          registerText: "",
          submitText: "Cambiar contraseña",
          title: "Cambiar la contraseña",
          codePlaceholder: "Codigo recibido por email. ej: 141n5l8v",
          buttonModalConfirmationText: "Restalecer contraseña",
          success: "Contraseña modificada correctamente",
          error: "No se ha podido modificar la contraseña"
        }
      },
      dashboard: {
        channels: {
          info: {
            title: "Info: Canales",
            closeText: "Entendido",
            text: <>
              <p>
                Aquí podrás gestionar los canales que te pertenecen al igual que crearlos.
              </p>
              <p>
                En el calendario podrás navegar entre las distintas semanas y días haciendo clic en el la fecha
                superiór. <br/>
                Puedes crear un evento haciendo clic en algún espacio vacío del calendario o modificarlo haciendo clic
                sobre el o arrastrandolo para cambiar las fechas.
              </p>
              <p>
                Una vez hayas finalizado puedes hacer clic en el botón de "Corregir" para que automáticamente
                se corrija los eventos que coincidan en fechas o se sobreposicionen. Este proceso también se realiza al
                guardar.
              </p>
            </>
          }
        },
        clubs: {
          stats: {
            activeTitle: "Clubs activos"
          }
        },
        clubSubscriptions: {
          buttonAddSubscription: "Suscripción",
          buttonAddPayment: "Pago",
          modifyBillingInformationWithoutPaymentMethodError: "Es necesario disponer de un método de pago para crear/modificar los datos de facturación"
        },
        meeting: {
          leavingWarning: "Meeting abandonado",
          connectionError: "No se ha podido obtener el meeting"
        },
        onDemand: {
          teamSections: {
            title: (teamName) => `Secciones de equipo (${teamName})`,
          },
          stats: {
            numberOfTeams: {
              title: "Equipos",
              description: "Número total de equipos",
              suffix: (number) => number === 1 ? " equipo" : " equipos"
            },
            numberOfProducts: {
              title: "Productos",
              description: "Número total de productos",
              suffix: (number) => number === 1 ? " producto" : " productos"
            },
            numberOfSharedProducts: {
              title: "P. Compartidos",
              description: "Número total de productos compartidos",
              suffix: (number) => number === 1 ? " producto" : " productos"
            },
            numberOfCategories: {
              title: "Categorías",
              description: "Número total de categorías",
              suffix: (number) => number === 1 ? " categoría" : " categorías"
            }
          }
        },
        trainings: {
          trainingPlayer: {
            error: "Error al obtener el entrenamiento"
          }
        },
        users: {
          userSubscribedSuccess: "Usuario suscrito correctamente"
        },
        advertisement: {
          save: "Guardar",
          advertisementImage: "Imagen del anuncio",
          titleLabel: "Título",
          descriptionLabel: "Descripción",
          urlRedirectLabel: "URL de redirección",
          scriptLabel: "Script de publicidad externa",
          isActiveLabel: "¿Está publicado?",
          isActiveAnswers: ["Sí", "No"],
          isScriptLabel: "¿Usar publicidad externa?",
          isScriptAnswers: ["Sí", "No"],
          saving: "Guardando...",
          savingSuccess: "Guardado correctamente",
          savingError: "Error al guardar",
          advertisementPosition: advertisementPosition,
          advertisementPositionLabel: "Posición",
          productsLabel: "Productos",
          noProductOptionsText: "No existen productos",
          categoriesLabel: "Categoría",
          noCategoryOptionsText: "No existen categorías",
          collectionsLabel: "Colección",
          noCollectionOptionsText: "No existen colecciones",
        },
        advertisements: {
          titleLoginAdvertisement: "Anuncio de Inicio de Sesión",
          subtitleLoginAdvertisement: "Anuncio mostrado en la página de inicio de sesión",
          titleFooterAdvertisement: "Anuncio en el footer",
          subtitleFooterAdvertisement: "Anuncio mostrado en el footer de todas las páginas",
          removing: "Eliminando...",
          removingSuccess: "Eliminado correctamente",
          removingError: "Error al eliminar",
        }
      }
    }
  },
  components: {
    team: {
      hooks: {
        useTeams: {
          getTeamsError: "Error al recuperar los equipos"
        },
        useTeam: {
          getTeamError: "Error al recuperar el equipo",
          sortTeamSectionsLoading: "Ordenando secciones...",
          sortTeamSectionsSucceed: "Secciones ordenadas correctamente",
          sortTeamSectionsError: "Error al ordenar secciones",
          saveTeamLoading: (isModifying) => isModifying ? "Modificando equipo..." : "Creando equipo...",
          saveTeamSucceed: (isModifying) => isModifying ? "Equipo modificado con éxito" : "Equipo creado con éxito",
          saveTeamError: "Error al crear/modificar equipo",
          removeTeamLoading: "Eliminando equipo...",
          removeTeamSucceed: "Equipo eliminado con éxito",
          removeTeamError: "No se pudo eliminar el equipo",
        }
      },
      showTeamsCard: {
        team: "Equipo",
        name: "Nombre",
        colors: "Colores",
        modifyDescription: "Editar equipo",
        modifyContentDescription: "Editar contenido",
        removeDescription: "Eliminar equipo",
        noContent: "Sin secciones",
        numberSections: (number) => `${number} ${number === 1 ? "Sección" : "Secciones"}`,
        title: "Equipos",
        addTeamButtonText: "Equipo",
        addTeamButtonDescription: "Añadir equipo",
        searchPlaceholder: "Busca un equipo",
        emptyCategories: "Sin equipos"
      },
      uploadTeamModal: {
        title: (isModifying, name) => !!isModifying ? `Editar ${name}` : "Crear nuevo equipo",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        shortNameLabel: "Abreviatura",
        imageLabel: "Escudo",
        jerseyHomeColorLabel: "Color de la camiseta local",
        jerseyAwayColorLabel: "Color de la camiseta visitante",
        tooLongShortNameErrorText: "Abreviatura demasiada larga",
        tooLongShortNameError: "La abreviatura no puede contener más de 5 caracteres"
      }
    },
    teamSection: {
      hooks: {
        useTeamSection: {
          getTeamSectionError: "Error al recuperar la sección del equipo",
          removeTeamSectionLoading: "Eliminando...",
          removeTeamSectionSucceed: "Cupón eliminado con éxito",
          removeTeamSectionError: "Error al eliminar la sección del equipo",
          saveTeamSectionLoading: (isModifying) => isModifying ? "Modificando la sección del equipo..." : "Creando la sección del equipo...",
          saveTeamSectionSucceed: (isModifying) => isModifying ? "Sección modificada" : "Sección creada",
          saveTeamSectionError: "Error al guardar la sección del equipo"
        }
      },
      showTeamSectionListCard: {
        title: "Secciones de equipo",
        subtitle: "Arrastra y suelta las colecciones para ordenar",
        name: "Nombre",
        modifyDescription: "Editar sección",
        removeDescription: "Eliminar sección",
        emptySections: "Sin secciones",
        addButtonText: "Sección",
        published: "Publicado",
        unpublished: "No publicado",
        numberCollections: (number) => `${number} ${number === 1 ? "Colección" : "Colecciones"}`,
      },
      uploadTeamSectionModal: {
        title: (isModifying, name) => !!isModifying ? `Editar ${name}` : "Crear nueva sección",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        isPublishedLabel: "¿Está publicado?",
        isPublishedAnswers: ["Sí", "No"],
        collectionsLabel: "Colecciones",
        collectionCreatingFormatText: (value) => `Crear nueva colección "${value}"`,
        noCollectionOptionsText: "No existen colecciones",
      }
    },
    coupons: {
      hooks: {
        useCoupons: {
          getCouponsError: "Error al obtener los cupones"
        },
        useCoupon: {
          getCouponError: "Error al recuperar el cupón",
          removeCouponLoading: "Eliminando...",
          removeCouponSucceed: "Cupón eliminado con éxito",
          removeCouponError: "Error al eliminar el cupón",
          saveCouponLoading: (isModifying) => isModifying ? "Modificando cupón..." : "Creando cupón...",
          saveCouponSucceed: (isModifying) => isModifying ? "Cupón modificado" : "Cupón creado",
          saveCouponError: "Error al guardar el cupón"
        }
      },
      showCouponListCard: {
        title: "Cupones",
        subtitle: "Crea y controla cupones de descuento",
        empty: "No existen cupones",
        code: "Código",
        name: "Nombre",
        discount: "Descuento",
        duration: "Duración",
        durationForever: "Para Siempre",
        durationOnce: "Una Vez",
        durationRepeating: (months) => `${months} meses`,
        modifyDescription: "Modificar cupón",
        removeDescription: "Eliminar copón",
        addCouponButtonText: "Cupón",
        addPlanButtonDescription: "Crear un cupón",
        timesRedeemed: "Usos"
      },
      uploadCouponModal: {
        title: (isModifying, name) => isModifying ? `Editar ${name}` : "Crear nuevo cupón",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        codeLabel: "Código",
        nameLabel: "Nombre",
        isPercentageLabel: "¿Es un porcentaje?",
        isPercentageAnswer: ["Sí", "No"],
        amountOffLabel: "Cantidad",
        percentOffLabel: "Porcentaje (%)",
        durationLabel: "Duración",
        foreverDuration: "Para Siempre",
        onceDuration: "Una Vez",
        repeatingDuration: "Recurrente",
        durationInMonthsLabel: "Duración en meses",
        maxRedemptionsLabel: "Num máx de usos"
      }
    },
    advertising: {
      showAdvertisementsCard: {
        advertisement: "Anuncio",
        description: "Descripción",
        location: "Ubicación",
        type: "Tipo",
        status: "Estado",
        statusText: (published) => published ? "Publicado" : "No publicado",
        modifyDescription: "Editar anuncio",
        removeDescription: "Eliminar anuncio",
        title: "Anuncios",
        addButtonText: "Anuncio",
        empty: "No hay anuncios",
        locationValue: (loc) => advertisementPosition[loc]
      },
      advertisementCard: {
        edit: "Modificar",
        remove: "Eliminar",
        unused: "No está en uso",
        loading: "Cargando...",
        statusText: (published) => published ? "Publicado" : "No publicado",
      }
    },
    collection: {
      hooks: {
        useCollections: {
          getCollectionsError: "Error al obtener las colecciones"
        },
        useCollection: {
          getCollectionError: "Error al obtener la colección",
          saveCollectionLoading: (isModifying) => isModifying ? "Modificando colección..." : "Creando colección...",
          saveCollectionSucceed: (isModifying) => isModifying ? "Colección modificada" : "Colección creada",
          saveCollectionError: "Error al modificar/crear la colección",
          removeCollectionLoading: "Eliminando colección...",
          removeCollectionSucceed: "Colección eliminada correctamente",
          removeCollectionError: "Error al eliminar la colección",
        }
      },
      showCollectionListCard: {
        collectionTitle: "Colección",
        modifyDescription: "Modificar colección",
        removeDescription: "Eliminar colección de este listado",
        showProductsDescription: "Ver productos",
        title: (title) => title ? `Colecciones de ${title}` : "Colecciones",
        subtitle: "Arrastra y suelta las colecciones para ordenarlos",
        collectionCreatingFormatText: (inputValue) => `Crear nueva colección "${inputValue}"`,
        noCollectionOptionsText: "No hay colecciones, escribe para crear una colección",
        selectCollectionPlaceholder: "Añade o crea",
        emptyCollections: "No existen colecciones en este listado"
      },
      showCollectionsCard: {
        collectionTitle: "Colección",
        numProducts: "Núm. productos",
        modifyDescription: "Modificar colección",
        removeDescription: "Eliminar colección",
        showProductsDescription: "Ver productos",
        title: "Colecciones",
        buttonAddText: "Colección",
        emptyCollections: "No existen colecciones"
      },
      uploadCollectionModal: {
        thumbnailTooltip: "La miniatura vertical de la colección puede aparecer como caratula en categorías.",
        imageTooltip: "La imagen de la colección puede aparecer en la página de la colección como portada",
        title: (isModifying, title) => isModifying ? `Modificar ${title}` : "Crear colección",
        closeText: "Cancelar",
        titleLabel: "Título",
        subtitleLabel: "Subtítulo",
        descriptionLabel: "Descripción",
        buttonSaveText: "Guardar",
        tooLongTitleError: "Título demasiado largo",
        tooLongTitleErrorText: "El título no puede superar los 30 carácteres",
        tooLongSubtitleError: "Subtítulo demasiado largo",
        tooLongSubtitleErrorText: "El subtítulo no puede superar los 30 carácteres",
        typeLabel: "Interficie",
        categoriesLabel: "Categorías",
        categoryCreatingFormatText: (value) => `Crear nueva categoría "${value}"`,
        noCategoryOptionsText: "No existen categorías, escribe para crear una nueva categoría",
        thumbnailLabel: "Miniatura vertical",
        imageLabel: "Imagen",
      },
      removeCollectionModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (collectionTitle = "") => `Está a punto de eliminar definitivamente ${collectionTitle}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      }
    },
    slider: {
      hooks: {
        useSlides: {
          getSlidesError: "Error al obtener el contenido",
          sortSlidesError: "Error al ordenar el contenido",
          sortSlidesLoading: "Ordenando slider...",
          sortSlidesSucceed: "Contenido ordenado correctamente"
        },
        useSlide: {
          getSlideError: "Error al obtener el contenido",
          saveSlideLoading: (isModifying) => isModifying ? "Modificando contenido..." : "Creando contenido...",
          saveSlideSucceed: (isModifying) => isModifying ? "Contenido modificado correctamente" : "Contenido creado correctamente",
          saveSlideError: "Error al modificar/crear el contenido",
          removeSlideLoading: "Eliminando contenido...",
          removeSlideSucceed: "Contenido eliminado correctamente",
          removeSlideError: "Error al eliminar el contenido"
        }
      },
      showSlidesCard: {
        name: "Título",
        subtitle: "Arrastra y suelta el contenido para ordenar",
        modifyDescription: "Modificar contenido",
        removeDescription: "Eliminar contenido",
        title: "Contenido",
        images: "Imágenes",
        buttonAddText: "Contenido",
        emptyContent: "No hay contenido"
      },
      uploadSlideModal: {
        tooLongTitleError: "El título debe contener máximo 30 carácteres",
        tooLongSubtitleError: "El subtítulo debe contener máximo 30 carácteres",
        title: (isModifying, name) => isModifying ? `Modificar ${name}` : "Crear nuevo contenido",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        titleLabel: "Título",
        titleErrorNumMaxChars: "El título debe contener máximo 30 carácteres",
        subtitleLabel: "Subtítulo",
        subtitleErrorNumMaxChar: "El subtítulo debe contener máximo 50 carácteres",
        imageLabel: "Imagen",
        imageTooltip: "La imagen del contenido aparecerá en el slider de la página inicial en ordenadores y tablets.",
        imagePortraitLabel: "Imagen vertical",
        imagePortraitTooltip: "La imagen vertical del contenido aparecerá en el slider de la página inicial en móviles y app.",
        urlLabel: "Enlace de redirección"
      },
      removeSlideModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (SlideTitle = "") => `Está a punto de eliminar definitivamente ${SlideTitle}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      }
    },
    tag: {
      hooks: {
        useTags: {
          getTagsError: "Error al obtener los tags"
        },
        useTag: {
          getTagError: "Error al obtener el tag",
          saveTagLoading: (isModifying) => isModifying ? "Modificando tag..." : "Creando tag...",
          saveTagSucceed: (isModifying) => isModifying ? "Tag modificado correctamente" : "Tag creado correctamente",
          saveTagError: "Error al modificar/crear el tag",
          removeTagLoading: "Eliminando tag...",
          removeTagSucceed: "Tag eliminado correctamente",
          removeTagError: "Error al eliminar el tag"
        }
      },
      showTagsCard: {
        name: "Nombre",
        modifyDescription: "Modificar tag",
        removeDescription: "Eliminar tag",
        title: "Tags",
        buttonAddText: "Tag",
        emptyTags: "No hay tags"
      },
      uploadTagModal: {
        tooLongNameError: "Nombre demasiado largo",
        title: (isModifying, name) => isModifying ? `Modificar ${name}` : "Crear nuevo tag",
        closeText: "Cancelar",
        nameLabel: "Nombre",
        buttonSaveText: "Guardar",
        tooLongNameErrorText: "El nombre no puede superar los 30 carácteres"
      },
      removeTagModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (tagName = "") => `Está a punto de eliminar definitivamente ${tagName}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      }
    },
    shared: {
      removeModal: {
        title: "Confirmación de borrado",
        content: "Estás a punto de proceder con la eliminación de este elemento. Esta acción es irreversible. Confirma tu decisión para continuar.",
        cancel: "Cancelar",
        accept: "Aceptar y borrar"
      },
      qrModal: {
        downloadQr: "Descargar QR",
      },
      previewVideoModal: {
        copyUrl: "Copiar url",
        copyUrlSucceed: "URL copiada en portapapeles",
      },
      resizeImageFileModal: {
        adaptImageLoading: "Adaptando imagen...",
        adaptImageSucceed: "¡Imagen adaptada!",
        adaptImageError: "Error al adaptar la imagen",
        title: (width, height) => `Recortar imagen (${width} x ${height})`,
        closeText: "Cancelar",
        buttonSaveText: "Guardar"
      },
      uploadStatsPeriodModal: {
        customPeriod: "Periodo personalizado",
        lastWeek: "Última semana",
        last10Days: "Últimos 10 días",
        last2Weeks: "Últimas 2 semanas",
        last3Weeks: "Últimas 3 semanas",
        last4Weeks: "Últimas 4 semanas",
        lastMonth: "Último mes",
        lastYear: "Último año",
        initOfTimes: "Inicio de los tiempos",
        DAY: "Día",
        WEEK: "Semana",
        MONTH: "Mes",
        YEAR: "Año",
        periodAppliedSucceed: "Periodo aplicado correctamente",
        title: "Modificar periodo",
        closeText: "Cerrar",
        buttonSaveText: "Aplicar periodo",
        periodLabel: "Periodo",
        intervalLabel: "Intervalo",
        initLabel: "Inicio",
        endLabel: "Fin"
      },
      videoCall: {
        accessVideoCallLoading: "Cargando videollamada...",
        accessVideoCallError: "No se ha podido acceder a la videollamada",
      }
    },
    billingData: {
      hooks: {
        useBillingData: {
          getBillingDataError: "Error al cargar los datos de facturación",
          updateBillingDataLoading: "Modificando datos de facturación...",
          updateBillingDataError: "Error al actualizar los datos de facturación",
          updateBillingDataSucceed: "Datos de facturación actualizados correctamente"
        }
      },
      showBillingDataCard: {
        title: "Datos de facturación",
        addModifyButton: (isModifying) => isModifying ? "Modificar" : "Añadir",
        companyName: "Nombre de compañía",
        nif: "NIF",
        address1: "Linea de dirección 1",
        address2: "Linea de dirección 2",
        city: "Población",
        state: "Región",
        country: "País",
        postalCode: "Código Postal"
      },
      updateBillingDataModal: {
        requiredFieldError: "Campo obligatorio",
        title: "Datos de facturación",
        closeText: "Cerrar",
        buttonSaveText: "Guardar",
        companyNameLabel: "Nombre de compañía",
        nifLabel: "NIF",
        address1Label: "Linea de dirección 1",
        address2Label: "Linea de dirección 2 (opcional)",
        cityLabel: "Población",
        stateLabel: "Región",
        countryLabel: "País",
        postalCodeLabel: "Código Postal"
      }
    },
    navigation: {
      hooks: {
        useNavigationItems: {
          getNavigationItemsError: "Error al obtener los links del menú",
          sortNavigationItemsLoading: "Reordenando menú...",
          sortNavigationItemsSucceed: "Menú reordenado",
          sortNavigationItemsError: "Error al reordenar menú"
        },
        useNavigationItem: {
          getNavigationItemError: "Error al recuperar el link",
          saveNavigationItemLoading: "Guardando el link...",
          saveNavigationItemSucceed: (isUpdating) => isUpdating ? "Link actualizado" : "Link guardado",
          saveNavigationItemError: "Error al guardar el link",
          removeNavigationItemLoading: "Eliminando el link...",
          removeNavigationItemSucceed: "Link eliminado",
          removeNavigationItemError: "Error al eliminar el link",
        }
      },
      showNavigationItemsCard: {
        title: "Menú de navegación",
        subtitle: "Crea, modifica y ordena los links del menú",
        emptyNavigationItems: "No hay links en el menú",
        text: "Texto",
        url: "URL",
        type: "Tipo",
        types: navigationItemTypes,
        modifyDescription: "Modificar link",
        removeDescription: "Eliminar link",
        published: "Publicado",
        unpublished: "No publicado",
        predefined: "Este link está predefinido en el sistema y no se puede eliminar.",
        buttonAddText: "Link"
      },
      uploadNavigationItemModal: {
        title: (isModifying, text) => !!isModifying ? `Editar ${text}` : "Crear nuevo link",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        textLabel: "Texto",
        typeLabel: "Tipo",
        urlLabel: "URL",
        isPublishedLabel: "¿Está publicado?",
        isPublishedAnswers: ["Sí", "No"],
      },
      removeNavigationItemModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (navigationLinkText = "") => `Está a punto de eliminar definitivamente ${navigationLinkText}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      }
    },
    category: {
      hooks: {
        useCategories: {
          getCategoriesError: "Error al obtener las categorías",
          sortCategoriesError: "Error al ordenar las categorías",
          sortCategoriesLoading: "Ordenando categorías...",
          sortCategoriesSucceed: "Categorías ordenadas correctamente"
        },
        useCategory: {
          getCategoryError: "No se ha podido obtener la categoría",
          sortCategoryProductsLoading: "Reordenando productos...",
          sortCategoryProductsSucceed: "Productos reordenados correctamente",
          sortCategoryProductsError: "Error al reordenar los productos",
          sortCategoryCollectionsLoading: "Reordenando colecciones...",
          sortCategoryCollectionsSucceed: "Colecciones reordenadas correctamente",
          sortCategoryCollectionsError: "Error al reordenar las colecciones",
          saveCategoryLoading: (isModifying) => isModifying ? "Modificando categoría..." : "Creando categoría...",
          saveCategorySucceed: (isModifying) => isModifying ? "Categoría modificada correctamente" : "Categoría creada correctamente",
          saveCategoryError: "Error al crear/modificar la categoría",
          removeCategoryLoading: "Eliminando categoría...",
          removeCategorySucceed: "Categoría eliminada correctamente",
          removeCategoryError: "No se ha podido eliminar la categoría",
        }
      },
      removeCategoryModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (categoryName = "") => `Está a punto de eliminar definitivamente ${categoryName}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showCategoriesCard: {
        name: "Nombre",
        published: "Publicado",
        unpublished: "No publicado",
        type: "Tipo",
        types: (type) => categoryTypes[type],
        noContent: "No hay contenido",
        numberProducts: (number) => `${number} ${number === 1 ? "Producto" : "Productos"}`,
        numberCollections: (number) => `${number} ${number === 1 ? "Colección" : "Colecciones"}`,
        modifyDescription: "Editar categoría",
        modifyContentDescription: "Editar contenido",
        removeDescription: "Eliminar categoría",
        title: "Categorías",
        subtitle: "Arrastra y suelta para ordenar",
        buttonAddText: "Categoría",
        emptyCategories: "No hay categorías"
      },
      uploadCategoryModal: {
        tooLongNameError: "Nombre demasiado largo",
        title: (isModifying, name) => !!isModifying ? `Editar ${name}` : "Crear nueva categoría",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        typeLabel: "Tipo",
        typeProduct: "Producto",
        typeCollection: "Colección",
        isPublishedLabel: "¿Está publicado?",
        isPublishedAnswers: ["Sí", "No"],
        tooLongNameErrorText: "El nombre no puede contener más de 30 carácteres",
        tooltipTypeUpdateWithContent: "El tipo de categoría no se puede modificar si contiene productos o colecciones"
      }
    },
    channelManagers: {
      hooks: {
        useChannelManager: {
          getChannelManagerError: "No se ha podido obtener el administrador de canales",
          saveChannelManagerLoading: (isModifying) => isModifying ? "Modificando administrador de canales..." : "Creando administrador de canales...",
          saveChannelManagerSucceed: (isModifying) => isModifying ? "Administrador de canales modificado correctamente" : "Administrador de canales creado correctamente",
          saveChannelManagerError: "Error al crear/modificar el administrador de canales",
          removeChannelManagerLoading: "Eliminando administrador de canales...",
          removeChannelManagerSucceed: "Administrador de canales eliminado correctamente",
          removeChannelManagerError: "Error al eliminar el administrador de canales"
        },
        useChannelManagers: {
          getChannelManagersError: "Error al obtener los administradores del canales"
        }
      },
      removeChannelManagerModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (email = "") => `Está a punto de quitar los privilegios de administrador de canales a ${email}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showChannelManagersCard: {
        email: "Email",
        name: "Name",
        modifyDescription: "Editar Administrador de canales",
        removeDescription: "Eliminar privilegios de administrador",
        title: "Administradores de canales",
        buttonAddAdminText: "Admin",
        emptyAdmins: "No hay administradores de canales"
      },
      uploadChannelManagerModal: {
        title: (isModifying, email) => !!isModifying ? `Editar ${email}` : "Crear nuevo administrador de canales",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        emailLabel: "Email",
        nameLabel: "Name",
        lastNameLabel: "Apellido"
      }
    },
    channels: {
      hooks: {
        useChannel: {
          getChannelError: "No se ha podido obtener el canal",
          saveChannelLoading: (isModifying) => !!isModifying ? "Modificando canal..." : "Creando canal...",
          saveChannelSucceed: (isModifying) => !!isModifying ? "Canal modificado correctamente" : "Canal creado correctamente",
          saveChannelError: "Error al crear/modificar el canal",
          removeChannelLoading: "Eliminando canal...",
          removeChannelSucceed: "Canal eliminado correctamente",
          removeChannelError: "Error al eliminar el canal"
        },
        useChannels: {
          getChannelsError: "Error al obtener los canales"
        }
      },
      previewChannelModal: {
        title: (name) => `${name} en directo`
      },
      removeChannelModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (name = "") => `Está a punto de eliminar definitivamente ${name || ""}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      saveChannelModal: {
        titleSave: `¿Seguro que desea guardar?`,
        descriptionSave: "Al guardar los eventos pasarán por un proceso de corrección que evita sobreposiciones. Puede que algunos eventos se desplacen, ¿desea continuar?",
        closeText: "Cancelar",
        buttonSaveText: "Corregir y Guardar"
      },
      showChannelEventsCalendarCard: {
        fixOverlapChannelEventsSucceed: (number) => `Se han corregido ${number} eventos`,
        buttonFixText: "Corregir",
        buttonUploadCsv: "Añadir CSV",
        buttonSaveText: "Guardar",
        buttonNowText: "Ahora",
        buttonCreateChannelText: "Crear Canal",
        errorOnUploadCsv: (number = 0) => `${number} ${number === 1 ? "archivo" : "archivos"} no encontrados:`,
        errorDescriptionOnUploadCsv: "Transcodifica los videos faltantes y vuelva a subir el csv",
        uploadCsvSucceed: "Canal modificado. Compruebe el contenido y guarde para evitar perder el progreso.",
        uploadCsvError: "Error al subir el CSV. Compruebe que no existan errores en el documento antes de subirlo",
        textTemplateCsv: (template) => <div>Descarga <a download={"template.csv"} href={template}>aquí</a> la plantilla
          CSV. No elimine la primera linea con la definición de las columnas.</div>
      },
      showChannelsCard: {
        name: "Nombre",
        nameNew: "Nuevo",
        playDescription: "Reproducir Canal",
        modifyDescription: "Editar Propiedades",
        removeDescription: "Eliminar Canal",
        title: "Canales",
        subtitle: "Haz clic para modificar el contenido",
        buttonAddChannel: "Canal",
        emptyChannels: "No hay canales",
        copyUrlButtonText: "Copiar URL",
        copiedUrl: "URL copiada al portapapeles",
        saveToContinue: "Generando..."
      },
      uploadChannelEventModal: {
        getDurationError: "No se han podido recuperar la duración de la transcodificación",
        previewTitle: "Previsualización",
        title: (isModifying) => !!isModifying ? `Editar evento` : `Crear evento`,
        closeText: "Cerrar",
        buttonSaveText: "Guardar",
        videoLabel: "Video",
        initialTimeLabel: "Inicio",
        finalTimeLabel: "Final",
        loadingFinalTimeText: "Calculando duración..."
      },
      uploadChannelModal: {
        title: (isModifying) => isModifying ? "Modificar canal" : "Crear canal",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        placeholderUrlLabel: "URL salvapantallas",
        isLoopLabel: "¿Es un bucle?",
        isLoopAnswer: ["Sí", "No"]
      }
    },
    clubConfig: {
      save: "Guardar",
      hooks: {
        useClubConfig: {
          getClubConfigError: "Ups! no se ha podido acceder a la configuración",
          saveClubConfigLoading: "Modificando configuración...",
          saveClubConfigSucceed: "Configuración de club modificada correctamente",
          saveClubConfigError: "Error al modificar la configuración de club"
        }
      },
      changeClubStructureCard: {
        title: "Parámetros",
        subtitle: "Modifica algunos parámetros de la web",
        saveButton: "Guardar",
        showAddedRecentlyCategoryLabel: "¿Mostrar recientemente añadidos?",
        showAddedRecentlyCategoryAnswer: ["Sí", "No"],
        showTitlesOnCollectionThumbnailLabel: "¿Mostrar títulos en las carátulas de colecciones?",
        showTitlesOnCollectionThumbnailAnswer: ["Sí", "No"],
        showTitlesOnNewThumbnailLabel: "¿Mostrar títulos en las carátulas de noticias?",
        showTitlesOnNewThumbnailAnswer: ["Sí", "No"],
        showTitlesOnPanoramicLabel: "¿Mostrar títulos en las panorámicas (sliders)?",
        showTitlesOnPanoramicAnswer: ["Sí", "No"],
        showTitlesOnProductThumbnailLabel: "¿Mostrar títulos en las carátulas de productos?",
        showTitlesOnProductThumbnailAnswer: ["Sí", "No"],
        showTitlesOnTrainerThumbnailLabel: "¿Mostrar títulos en las carátulas de entrenadores?",
        showTitlesOnTrainerThumbnailAnswer: ["Sí", "No"]
      },
      changeClubSeoCard: {
        title: "SEO",
        subtitle: "Modifica la información para motores de búsqueda",
        faviconLabel: "favicon (.ico)",
        faviconTooltip: "Es el logotipo que aparece junto al título en la pestaña del navegador",
        titleLabel: "Título",
        titleTooltip: "Texto corto que describe el tema principal del que trata un sitio web",
        descriptionLabel: "Descripción",
        descriptionTooltip: "Texto que aparece debajo del enlace azul de un resultado en la página de resultados. Su propósito es describir el contenido de la página para facilitarle la persona que está haciendo la búsqueda elegir tu sitio, en vez de otro",
        googleAnalyticsMeasurementIdLabel: "Google analytics ID",
        googleAnalyticsMeasurementIdTooltip: "Identificador proporcionado por Google que permite a Google Analytics realizar el seguimiento de la web (Estadísticas de entradas, dispositivos, pais de visita, ...)",
        imageOptimizationLoading: "Optimizando imagen...",
        imageOptimizationSucceed: "¡Imagen optimizada!",
      },
      changeClubLogosCard: {
        title: "Logos",
        subtitle: "Modificar los logos que aparecen en la plataforma.",
        imageLight: "Logo claro",
        imageDark: "Logo oscuro",
        imageSquareLight: "Logo claro cuadrado",
        imageSquareDark: "Logo oscuro cuadrado",
        imageCompressionLoading: "Comprimiendo imagen...",
        imageCompressionSucceed: "¡Imagen comprimida!",
        imageLightTooltip: "Logo rectangular en formato .png del club con fondo transparente. Los colores deben contrastar con fondo negro. Se usa principalmente como logotipo de navegación en la parte superior izquierda de la web",
        imageSquareLightTooltip: "Logo cuadrado en formato .png del club con fondo transparente. Los colores deben contrastar con fondo negro. Se usa principalmente como imagen de presentación en la pagina de inicio de sesión, registro, ...",
        imageDarkTooltip: "Logo rectangular en formato .png del club con fondo transparente. Los colores deben contrastar con fondo blanco. Se usa principalmente como logotipo en el envío de emails automatizados",
        imageDarkLightTooltip: "Logo cuadrado en formato .png del club con fondo transparente. Los colores deben contrastar con fondo blanco. Se usa como alternativa en algunos casos"
      },
      changeClubColorsCard: {
        title: "Colores",
        useDarkModeLabel: "¿Usar modo oscuro?",
        useDarkModeAnswer: ["Sí", "No"],
        subtitle: "Modificar los colores que aparecen en la plataforma.",
        primaryColorLabel: "Color primario",
        primaryColorTooltip: "El color seleccionado aparecerá como color primario en botones, selectores y algunos componentes más de la web."
      }
    },
    clubs: {
      hooks: {
        useClub: {
          getClubError: "No se ha podido obtener el club",
          removeClubLoading: "Eliminando club...",
          removeClubSucceed: "Club eliminado correctamente",
          removeClubError: "No se ha podido eliminar el club",
          saveClubLoading: (isModifying) => isModifying ? "Modificando club..." : "Creando club...",
          saveClubSucceed: (isModifying) => isModifying ? "Club modificado correctamente" : "Club creado correctamente",
          saveClubError: "Error al crear/modificar el club",
        },
        useClubs: {
          getClubsError: "Error al obtener los clubs",
          changeUserClubLoading: "Cambiando club...",
          changeUserClubSucceed: (clubName) => `Bienvenido a ${clubName}`
        }
      },
      removeClubModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (clubName = "") => `Está a punto de eliminar definitivamente ${clubName}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showClubsCard: {
        club: "Club",
        principalClub: "Club principal",
        status: "Estado",
        statusText: (active) => active ? "Activo" : "Inactivo",
        changeCurrentClubDescription: "Cambiar club a gestionar",
        modifyClubDescription: "Editar Club",
        removeClubDescription: "Eliminar Club",
        title: "Clubs actuales",
        subtitle: (numberOfClubs) => `Total: ${numberOfClubs}`,
        buttonAddClub: "Club",
        emptyClubs: "No hay clubs"
      },
      showUserClubsModal: {
        name: "Nombre",
        roles: "Roles",
        changeCurrentClubSucceed: (clubName) => `Bienvenido a ${clubName}`,
        title: "Clubs",
        closeText: "Cerrar"
      },
      uploadClubModal: {
        title: (isModifying, clubName) => isModifying ? `Editar ${clubName}` : "Crear nuevo club",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        isActiveLabel: "¿Club activo?",
        activeClubAnswers: ["Sí", "No"],
        isPrincipalClubLabel: "¿Club principal?",
        principalClubAnswers: ["Sí", "No"],
        isSharingContentLabel: "¿Mostrar contenido compartido?",
        sharingContentAnswers: ["Sí", "No"],
        languageLabel: "Idioma del club",
        domainLabel: "Dominio",
        noReplyMailAddressLabel: "Email del remitente",
        contact: "Contacto",
        emailLabel: "Email de contacto",
        phoneLabel: "Teléfono de contacto"
      }
    },
    clubSinglePayments: {
      hooks: {
        useClubSinglePaymentPlan: {
          paySinglePaymentPlanLoading: "Procesando pago...",
          paySinglePaymentPlanSucceed: (namePlan) => `pago de "${namePlan}" realizada correctamente`,
          paySinglePaymentPlanError: "Error al realizar el pago"
        },
        useClubSinglePaymentPlans: {
          getClubSinglePaymentPlansError: "Error al obtener planes"
        }
      },
      addSinglePaymentToClubModal: {
        title: "Añadir pago único",
        closeText: "Cancelar",
        buttonSaveText: "Añadir",
        paymentLabel: "Pago único"
      },
      paySinglePaymentPlanModal: {
        closeText: "Cancelar",
        buttonConfirmText: "Confirmar pago",
        title: "Confirmar pago",
        text: (price, planName) => `Se le cobrará ${price?.toFixed(2)}€ por "${planName}"`,
        paymentMethodUsed: "Método de pago utilizado",
      },
      showClubSinglePaymentCard: {
        payment: "Pago",
        status: "Estado",
        statusText: (paid) => paid ? "Pagado" : "Pendiente",
        price: "Precio",
        priceSubtext: "IVA incluido",
        PaymentDate: "Fecha de pago",
        buttonPayText: "Realizar pago",
        buttonPayDescription: "Realizar pago pendiente",
        title: "Pagos únicos",
        subtitle: "Pagos únicos realizados y pendientes",
        empty: "No hay pagos únicos disponibles"
      }
    },
    clubSubscriptions: {
      hooks: {
        useClubPlan: {
          subscribeToPlanLoading: "Procesando suscripción...",
          subscribeToPlanSucceed: "Suscripción realizada correctamente",
          subscribeToPlanError: "Error al suscribirse",
          cancelSubscriptionLoading: "Procesando cancelación...",
          cancelSubscriptionSucceed: "Suscripción cancelada correctamente",
          cancelSubscriptionError: "Error al cancelar suscripción",
          resumeSubscriptionLoading: "Procesando reanudación...",
          resumeSubscriptionSucceed: "Suscripción reanudada correctamente",
          resumeSubscriptionError: "Error al reanudar suscripción",
        },
        useClubPlans: {
          getClubPlansError: "Error al obtener planes"
        }
      },
      addPlanToClubModal: {
        title: "Añadir plan",
        closeText: "Cancelar",
        buttonSaveText: "Añadir",
        plansLabel: "Planes",
      },
      cancelSubscriptionModal: {
        title: "Cancelación",
        closeText: "Cerrar",
        buttonCancelText: "Confirmar cancelación",
        contentTitle: "¿Estás seguro de que deseas cancelar?",
        content: (planName, price, interval) => (
          <>
            <p className="mb-1">
              Se le cancelarán las suscripciónes al plan "{planName}" por {price?.toFixed(2)}€
              <span className="small"> /{intervals[interval]}</span>.
            </p>
            <p>Seguirá disfrutando de los beneficios de su suscripción hasta el final del periódo pagado.</p>
          </>
        )
      },
      resumeSubscriptionModal: {
        title: "Reanudación",
        closeText: "Cerrar",
        buttonSaveText: "Confirmar reanudación",
        contentTitle: "Confirmar reanudación",
        content: (planName, price, interval) => (
          <p className="mb-3">
            Se reanudará el cobro de una suscripción periódica al plan "{planName}" por {price?.toFixed(2)}€
            <span style={{ fontSize: '0.7rem' }}> /{intervals[interval]}</span>.
          </p>
        ),
        usedPaymentMethod: "Método de pago utilizado"
      },
      showClubSubscriptionsCard: {
        buttonCancelText: "Cancelar",
        buttonCancelDescription: "Cancelar suscripción",
        buttonResumeText: "Reanudar",
        buttonResumeDescription: "Reanudar suscripción",
        buttonSubscribeText: "Subscribirme",
        buttonSubscribeDescription: "Suscribirme al plan",
        plan: "Plan",
        status: "Estado",
        statusText: () => ["Activa", "Cancelada", "Pendiente"],
        price: "Precio",
        priceSubtitle: (interval) => `/${intervals[interval]} IVA incluido`,
        initialDate: "Fecha inicio",
        finalDate: "Fecha fin",
        title: "Suscripciones",
        subtitle: "Suscripciones activas, pendientes y canceladas",
        empty: "No hay planes disponibles"
      },
      subscribeToClubPlanModal: {
        closeText: "Cancelar",
        buttonSubscribeText: "Confirmar suscripción",
        title: "Confirmar suscripción",
        content: (planName, price, interval) => (
          <>
            Se le cobrará una suscripción periódica al plan "{planName}" por {price?.toFixed(2)}€
            <span style={{ fontSize: '0.7rem' }}> /{intervals[interval]}</span>.
          </>
        ),
        usedPaymentMethod: "Método de pago utilizado"
      }
    },
    companyPlans: {
      hooks: {
        useCompanyPlan: {
          getCompanyPlanError: "No se ha podido obtener el plan/pago",
          removeCompanyPlanLoading: "Eliminando plan/pago...",
          removeCompanyPlanSucceed: "Plan/Pago eliminado correctamente",
          removeCompanyPlanError: "No se ha podido eliminar el plan/pago",
          saveCompanyPlanLoading: (isModifying) => isModifying ? "Modificando plan/pago..." : "Creando plan/pago...",
          saveCompanyPlanSucceed: (isModifying) => isModifying ? "Plan/Pago modificado correctamente" : "Plan/Pago creado correctamente",
          saveCompanyPlanError: "Error al crear/modificar el plan/pago"
        },
        useCompanyPlans: {
          getCompanyPlansError: "Error al obtener los planes y pagos"
        }
      },
      removeCompanyPlanModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (planName = "") => `Está a punto de eliminar definitivamente el plan/pago ${planName}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showCompanyPlansCard: (isPayment) => ({
        name: "Nombre",
        nameBase: "Plan gratuito",
        price: "Precio",
        priceSubtitle: (interval) => `/${intervals[interval]} IVA incluido`,
        modifyDescription: isPayment ? "Editar Pago" : "Editar Plan",
        removeDescription: isPayment ? "Eliminar Pago" : "Eliminar Plan",
        title: isPayment ? "Pagos únicos para clubs" : "Planes para clubs",
        addButtonText: isPayment ? "Pago único" : "Plan",
        empty: isPayment ? "No hay pagos únicos de compañía" : "No hay planes de compañía"
      }),
      showCompanySingePaymentPlansCard: {
        name: "Nombre",
        nameBase: "Plan gratuito",
        price: "Precio",
        priceSubtitle: (interval) => `/${intervals[interval]} IVA incluido`,
        modifyDescription: "Editar Pago",
        removeDescription: "Eliminar Pago",
        title: "Pagos únicos para clubs",
        addButtonText: "Pago único",
        empty: "No hay pagos únicos"
      },
      uploadCompanyPlansModal: (isPayment) => ({
        title: (isModifying, planName) => !!isModifying ? `Editar ${planName}` : `Crear nuevo ${isPayment ? "pago único" : "plan"}`,
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        noEditableBadge: isPayment ? "Pago no editable" : "Plan no editable",
        isPaymentLabel: "¿Es pago único?",
        isPaymentAnswers: ["Sí", "No"],
        nameLabel: "Nombre",
        priceLabel: "Precio",
        priceDescription: (price) => isPayment ? "IVA incluído" : `${(price || 0)}€/mes IVA incluído`
      })
    },
    managers: {
      hooks: {
        useManager: {
          getManagerError: "No se ha podido obtener el manager",
          saveManagerLoading: (isModifying) => isModifying ? "Modificando manager..." : "Creando manager...",
          saveManagerSucceed: (isModifying) => isModifying ? "Manager modificado correctamente" : "Manager creado correctamente",
          saveManagerError: "Error al crear/modificar el manager",
          removeManagerLoading: "Eliminando manager...",
          removeManagerSucceed: "Manager eliminado correctamente",
          removeManagerError: "Error al eliminar el manager"
        },
        useManagers: {
          getManagersError: "Error al obtener los managers"
        }
      },
      removeManagerModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (email = "") => `Está a punto de quitar los privilegios de manager a ${email}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showManagersCard: {
        email: "Email",
        name: "Nombre",
        bankAccountManager: "Gestor de cuenta",
        modifyDescription: "Editar Manager",
        removeDescription: "Eliminar privilegios de manager",
        title: "Managers",
        buttonAddText: "Manager",
        empty: "No hay managers"
      },
      uploadManagerModal: {
        title: (isModifying, email) => isModifying ? `Editar ${email}` : "Crear nuevo manager",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        emailLabel: "Email",
        nameLabel: "Nombre",
        lastName: "Apellido",
        isBankAccountManagerLabel: "¿Es gestor de cuenta?",
        isBankAccountManagerAnswers: ["Sí", "No"],
        isBankAccountManagerDescription: "El gestor de cuenta es único, intransferible e inmutable."
      }
    },
    meetings: {
      hooks: {
        useMeeting: {
          getMeetingError: "No se ha podido obtener el meeting",
        },
        useMeetingCategories: {
          getMeetingCategoriesError: "Error al obtener las categorías de meetings",
        },
        useMeetings: {
          getMeetingsError: "Error al obtener los meetings",
        },
        useMeetingsTemplate: {
          getMeetingTemplateError: "No se ha podido obtener la plantilla del meeting",
          saveMeetingTemplateLoading: (isModifying) => isModifying ? "Modificando plantilla..." : "Creando plantilla...",
          saveMeetingTemplateSucceed: (isModifying) => isModifying ? "Plantilla modificada correctamente" : "Plantilla creada correctamente",
          saveMeetingTemplateError: "Error al crear/modificar la plantilla"
        },
        useMeetingsTemplates: {
          getMeetingTemplatesError: "Error al obtener las plantillas de meetings"
        }
      },
      showMeetingCallCard: {
        errorText: "Ha ocurrido un error con la videollamada",
        title: "Meeting",
        subtitle: (numParticipants, intensity, start) => `${numParticipants} participantes -  ${intensities[intensity]} - ${moment(start).format("DD/MM/YYYY HH:mm")}`,
        leaveMeetingButtonText: "Abandonar Meeting",
        userName: "Entrenador"
      },
      showMeetingModal: {
        title: "Meeting",
        closeText: "Cerrar",
        enterButtonText: "Acceder",
        start: "Inicio",
        end: "Fin",
        participants: "Participantes",
        level: "Nivel",
        levelText: (intensity) => intensities[intensity],
        accessTimeInfo: "El acceso está disponible 24 horas antes del inicio del meeting"
      },
      showMeetingTemplatesModal: {
        name: "Nombre",
        level: "Nivel",
        participants: "Participantes",
        price: "Precio",
        modifyDescription: "Edital Plantilla",
        title: "Plantillas de meeting",
        closeText: "Cerrar",
        addButtonText: "Plantilla",
        empty: "No estisten plantillas"
      },
      uploadMeetingTemplateModal: {
        title: (isModifying, templateName) => isModifying ? `Editar plantilla: ${templateName}` : "Crear nueva plantilla",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre de plantilla",
        categoryLabel: "Categoría",
        intensityLabel: "Intensidad",
        descriptionLabel: "Descripción",
        complementaryDescriptionLabel: "Descripción complementaria",
        imageLabel: "Imagen",
        numMaxParticipantsLabel: "Número máximo de participantes",
        numMaxParticipantsDescription: (maxParticipants) => `Sesión ${maxParticipants <= 2 ? `individual: 1 participante` : `grupal: ${maxParticipants - 1} participantes`}`,
        priceLabel: "Precio"
      }
    },
    news: {
      hooks: {
        useNew: {
          getNewError: "No se ha podido obtener la noticia",
          saveNewLoading: (isModifying) => isModifying ? "Modificando noticia..." : "Creando noticia...",
          saveNewSucceed: (isModifying) => isModifying ? "Noticia modificada correctamente" : "Noticia creada correctamente",
          saveNewError: "Error al crear/modificar la noticia",
          removeNewLoading: "Eliminando noticia...",
          removeNewSucceed: "Noticia eliminada correctamente",
          removeNewError: "No se ha podido eliminar la noticia",
        },
        useNews: {
          getNewsError: "Error al obtener las noticias"
        }
      },
      removeNewModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (newName = "") => `Está a punto de eliminar definitivamente ${newName}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showNewsCard: {
        name: "Noticia",
        status: "Estado",
        statusText: (publicated) => publicated ? "Publicado" : "No publicado",
        publicationDate: "Fecha de publicación",
        modifyDescription: "Editar noticia",
        removeDescription: "Eliminar noticia",
        title: "Noticias",
        addButtonText: "Noticia",
        empty: "No hay noticias"
      },
      uploadNewModal: {
        title: (isModifying, title) => isModifying ? `Editar ${title}` : "Crear nueva noticia",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        titleLabel: "Title",
        isPublishedLabel: "¿Noticia publicada?",
        isPublishedAnswers: ["Sí", "No"],
        publicationDateLabel: "Fecha de publicación",
        contentLabel: "Contenido",
        imageLabel: "Imagen",
        videoLabel: "Video"
      }
    },
    notifications: {
      hooks: {
        useNotification: {
          getNotificationError: "No se ha podido obtener la notificación",
          saveNotificationLoading: (isModifying) => isModifying ? "Modificando notificación..." : "Creando notificación...",
          saveNotificationSucceed: (isModifying) => isModifying ? "Notificación modificada correctamente" : "Notificación creada correctamente",
          saveNotificationError: "Error al crear/modificar la notificación",
          sendNotificationLoading: "Enviando notificación...",
          sendNotificationSucceed: "Notificación enviada correctamente",
          sendNotificationError: "Error al enviar la notificación",
          removeNotificationLoading: "Eliminando notificación...",
          removeNotificationSucceed: "Notificación eliminada correctamente",
          removeNotificationError: "No se ha podido eliminar la notificación"
        },
        useNotifications: {
          getNotificationsError: "Error al obtener las notificaciones"
        }
      },
      showNotificationsCard: {
        notificationTitle: "Título",
        status: "Estado",
        statusText: (active) => active ? "Activo" : "Inactivo",
        type: "Tipo",
        typeText: (type) => notificationTypes[type],
        enterToSendFormDescription: "Formulario de envío",
        modifyDescription: "Editar Notificación",
        removeDescription: "Eliminar notificación",
        title: "Notificaciones",
        addButtonText: "Notificación",
        empty: "No hay notificaciones"
      },
      removeNotificationModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (notificationTitle = "") => `Está a punto de eliminar definitivamente ${notificationTitle}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      uploadNotificationModal: {
        title: (isModifying, title) => isModifying ? `Editar ${title}` : "Crear nueva notificación",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        titleLabel: "Título",
        isActiveLabel: "¿Notificación activa?",
        isActiveAnswers: ["Sí", "No"],
        messageLabel: "Mensaje",
        notificationTypeLabel: "Tipo de notificación",
        productLabel: "Producto",
        newLabel: "Noticia",
        daysBeforeSubscriptionEndLabel: "Días antes del fin de suscripción",
        daysBeforeSubscriptionEndDescription: (daysBefore) => `Esta notificación se enviará a todos los usuarios ${daysBefore} días antes del fin de su suscripción`
      },
      uploadNotificationSendFiltersModal: {
        title: "Enviar notificación",
        closeText: "Cancelar",
        buttonSendText: "Enviar",
        infoEmailUsed: "El uso del email inhabilita el resto de filtros",
        reset: "Reset",
        emailLabel: "Correo electrónico",
        entryDateStartLabel: "Inicio fecha alta",
        entryDateStartDescription: "Todos los usuarios que se han dado de alta a partir de esta fecha",
        entryDateEndLabel: "Fín fecha alta",
        entryDateEndDescription: "Todos los usuarios que se han dado de alta hasta esta fecha",
        subscriptionEndDateStartLabel: "Inicio fecha fín suscripción",
        subscriptionEndDateStartDescription: "Todos los usuarios cuya suscripción ha finalizado después de esta fecha",
        subscriptionEndDateEndLabel: "Fín fecha fín suscripción",
        subscriptionEndDateEndDescription: "Todos los usuarios cuya suscripción ha finalizado antes de esta fecha",
        subscriptionPlanLabel: "Suscripción",
        subscriptionAny: "Cualquiera",
        noSubscription: "Sin subscripción"
      }
    },
    plans: {
      hooks: {
        usePlan: {
          getPlanError: "No se ha podido obtener el plan",
          saveManagerLoading: (isModifying) => isModifying ? "Modificando plan..." : "Creando plan...",
          saveManagerSucceed: (isModifying) => isModifying ? "Plan modificado correctamente" : "Plan creado correctamente",
          saveManagerError: "Error al crear/modificar el plan",
          removeManagerLoading: "Eliminando plan...",
          removeManagerSucceed: "Plan eliminado correctamente",
          removeManagerError: "No se ha podido eliminar el plan"
        },
        usePlans: {
          getPlansError: "Error al obtener los planes"
        }
      },
      removePlanModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (name = "") => `Está a punto de eliminar definitivamente ${name || ""}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showPlansCard: {
        name: "Nombre",
        price: "Precio",
        priceCurrency: (currency) => `${currencies[currency]}`,
        priceInterval: (interval) => `/${intervals[interval]}`,
        type: "Tipo",
        typeText: (type) => planTypes[type],
        modifyDescription: "Editar Plan",
        removeDescription: "Eliminar Plan",
        title: "Planes",
        addPlanButtonText: "Plan",
        addPlanButtonDescription: "Crear un plan de pago online",
        empty: "No hay planes"
      },
      uploadPlanModal: {
        title: (isModifying, name) => isModifying ? `Editar ${name}` : "Crear nuevo plan",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        nameLabel: "Nombre",
        descriptionLabel: "Descripción",
        descriptionPlaceholder: "Ej: Suscríbete a nuestro plan más económico y disfruta de todo el contenido. Dispones de 7 días de prueba gratis",
        priceLabel: "Precio de la suscripción",
        planTypeLabel: "Tipo de plan",
        intervalLabel: "Intervalo de cobro",
        currencyLabel: "Moneda",
        trialDaysLabel: "Días de prueba",
        trialDaysDescription: (days) => `${days} ${days === 1 ? "día" : "días"} de prueba gratis. Se cobrará al finalizar los días.`
      }
    },
    products: {
      hooks: {
        useLiveStreams: {
          getLiveStreamsError: "Error al obtener los servidores de live"
        },
        useProduct: {
          getProductError: "No se ha podido obtener el producto",
          saveProductLoading: (isModifying) => isModifying ? "Modificando producto..." : "Creando producto...",
          saveProductSucceed: (isModifying) => isModifying ? "Producto modificado correctamente" : "Producto creado correctamente",
          saveProductError: "Error al crear/modificar el producto"
        },
        useProducts: {
          getProductsError: "Error al obtener los productos"
        }
      },
      showProductsCard: {
        copyUrlButtonText: "Copiar",
        copiedUrl: "Url copiada al portapapeles",
        code: "Código",
        product: "Producto",
        premiere: "Estreno",
        noPremiere: "No estreno",
        unpublished: "No publicado",
        published: "Publicado",
        unspecifiedPublication: "Sin especificar",
        // property: "Propiedad",
        // propertyText: (isOwner) => isOwner ? "Propietario" : "Compartido",
        modifyDescription: "Editar Producto",
        qrDescription: "Ver QR",
        title: "Productos",
        images: "Imágenes",
        productUrl: "Ruta",
        addButtonText: "Producto",
        searchPlaceholder: "Busqueda por código o titulo",
        empty: "No hay productos"
      },
      showProductListCard: {
        code: "Código",
        product: "Producto",
        premiere: "Estreno",
        unpublished: "No publicado",
        published: "Publicado",
        unspecifiedPublication: "Sin especificar",
        modifyDescription: "Editar Producto",
        empty: "No hay productos en este listado",
        removeDescription: "Eliminar producto de este listado",
        title: (title) => title ? `Productos de ${title}` : "Productos",
        subtitle: "Arrastra y suelta los productos para ordenarlos",
        productCreatingFormatText: (inputValue) => `Crear nuevo producto "${inputValue}"`,
        noProductOptionsText: "No hay productos, escribe para crear un producto",
        selectProductPlaceholder: "Añade o crea"
      },
      uploadSPDProductModal: {
        title: (isModifying, code) => isModifying ? `Editar ${code}` : "Crear nuevo producto",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        titleLabel: "Título",
        collectionLabel: "Colección",
        collectionCreatingFormatText: (value) => `Crear nueva colección "${value}"`,
        noCollectionOptionsText: "No existen colecciones",
        plansLabel: "Planes",
        descriptionLabel: "Descripción",
        planCreatingFormatText: (value) => `Crear nuevo plan "${value}"`,
        noPlanOptionsText: "No hay planes, escribe para crear un nuevo plan",
        plansTooltip: "Puedes asociar múltiples planes. El usuario necesitará estar suscrito a uno de los planes para ver el contenido. En caso de no asociar ningún plan, el contenido será accesible de forma gratuita",
        startDateLabel: "Fecha de inicio",
        startTimeLabel: "Hora de inicio",
        endTimeLabel: "Hora de fin",
        beforeStartLabel: "Encendido antes del inicio",
        durationLabel: "Duración",
        homeTeamLabel: "Equipo local",
        awayTeamLabel: "Equipo visitante",
        cameraLabel: "Cámara",
        storyboardLabel: "Storyboard",
        graphicPackageLabel: "Paquete gráfico",
        sportLabel: "Deporte",
        football: "Fútbol",
        handball: "Balonmano",
        thumbnailTooltip: "La miniatura del producto puede aparecer como caratula en categorías, productos relacionados, etc.",
        thumbnailPortraitTooltip: "La miniatura vertical del producto puede aparecer como caratula en categorías, productos relacionados, etc.",
        imageTooltip: "La imagen del producto puede aparecer en la página del producto o en la página de inicio si es un estreno.",
        imagePortraitTooltip: "La imagen vertical del producto puede aparecer, en dispositivos pequeños, en la página del producto o en la página de inicio si es un estreno.",
        thumbnailLabel: "Miniatura",
        thumbnailPortraitLabel: "Miniatura vertical",
        imageLabel: "Imagen",
        imagePortraitLabel: "Imagen vertical"
      },
      uploadProductModal: {
        thumbnailTooltip: "La miniatura del producto puede aparecer como caratula en categorías, productos relacionados, etc.",
        thumbnailPortraitTooltip: "La miniatura vertical del producto puede aparecer como caratula en categorías, productos relacionados, etc.",
        imageTooltip: "La imagen del producto puede aparecer en la página del producto o en la página de inicio si es un estreno.",
        imagePortraitTooltip: "La imagen vertical del producto puede aparecer, en dispositivos pequeños, en la página del producto o en la página de inicio si es un estreno.",
        title: (isModifying, code) => isModifying ? `Editar ${code}` : "Crear nuevo producto",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        badgeSharedProduct: "Producto compartido",
        codeLabel: "Código identificativo",
        codePlaceholder: "ej: EX0009, EX0010, EX0011,...",
        titleLabel: "Título",
        titleErrorNumMaxChars: "El título no puede medir más de 30 carácteres",
        subtitleLabel: "Subtítulo",
        live: "live",
        segmentation: "Segmentación",
        collectionsLabel: "Colecciones",
        collectionCreatingFormatText: (value) => `Crear nueva colección "${value}"`,
        noCollectionOptionsText: "No existen colecciones",
        subtitleErrorNumMaxChar: "El subtítulo no puede medir más de 50 carácteres",
        isPublishedLabel: "¿Producto publicado?",
        isPublishedAnswers: ["Sí", "No"],
        endPublicationIconDescription: "Especificar fecha fin de publcación",
        publicationDateLabel: "Fecha inicio de publicación",
        publicationEndDateLabel: "Fecha fin de publicación",
        isPremiereLabel: "¿Es un estreno?",
        isPremiereAnswers: ["Sí", "No"],
        endPremiereIconDescription: "Especificar fecha fin de estreno",
        premiereEndDateLabel: "Fecha fin de estreno",
        isRentalLabel: "¿Permitir alquiler?",
        isRentalAnswer: ["Sí", "No"],
        priceRentalLabel: "Precio del alquiler",
        cameraLabel: "Camara",
        cameraTooltip: "Selecciona la camara del directo. El servidor del directo se encenderá 5 minutos antes de la fecha de inicio y se apagará 5 minutos después de la fecha fin del directo. La URL de la cámara no funcionará fuera de estas fechas.",
        noLiveStreamOption: "Sin emisión en directo",
        fakeLiveStreamOption: "Directo falso",
        emptyLiveServers: "No existen servidores activos",
        copyUrlCamText: "Copiar enlace cámara",
        copyUrlCamSucceed: "URL de camara copiada en portapapeles",
        liveScheduleStartLabel: "Inicio del directo",
        liveScheduleEndLabel: "Fín del directo",
        plansLabel: "Planes",
        planCreatingFormatText: (value) => `Crear nuevo plan "${value}"`,
        noPlanOptionsText: "No existen planes, escribe para crear un nuevo plan",
        plansTooltip: "Puedes asociar multiples planes. El usuario requerirá estar suscrito a uno de los planes para ver el contenido. En el caso de no asociar ningún plan el contenido será accesible gratuitamente",
        categoriesLabel: "Categorías",
        categoryCreatingFormatText: (value) => `Crear nueva categoría "${value}"`,
        noCategoryOptionsText: "No existen categorías, escribe para crear una nueva categoría",
        tagsLabel: "Tags",
        tagCreatingFormatText: (value) => `Crear nuevo tag "${value}"`,
        noTagOptionsText: "No existen tags, escribe para crear un nuevo tag",
        separatorLineImages: "Imágenes del producto",
        thumbnailLabel: "Miniatura",
        thumbnailPortraitLabel: "Miniatura vertical",
        imageLabel: "Imagen",
        imagePortraitLabel: "Imagen vertical",
        separatorLineDescription: "Descripción del producto",
        descriptionLabel: "Descripción",
        generalLevelLabel: "Nivel general",
        technicalLevelLabel: "Nivel técnico",
        mentalLevelLabel: "Nivel mental",
        physicalLevelLabel: "Nivel físico",
        separatorLineContent: "Contenido",
        videoUrlLabel: "Video",
        trailerUrlLabel: "Trailer",
        progressTranscodePending: "Subida pausada. Sube el archivo nuevamente para continuar",
        badgeTranscodifyingVideo: "Transcodificando video...",
        badgeTranscodifyingTrailer: "Transcodificando trailer..."
      }
    },
    stats: {
      statsCategoriesVisualizations: {
        getStatsError: "Error al obtener las estadísticas",
        infoTitle: "Info: Visualizaciones por categorías",
        infoCloseText: "¡Entendido!",
        infoContent: (
          <>
            <p>
              Barras: Indica el numero de visualizaciones que han tenido cada categoría durante el rango de fechas
              seleccionado.
              Solo aparecerán las categorías con, al menos, una visualización.
            </p>
            <p>
              Linea: Indica una media en minutos del tiempo que el usuario ha invertido viendo un video de cada
              categoría
              durante el rango de fechas seleccionado.
            </p>
          </>
        ),
        title: "Visualizaciones por categorías",
        empty: "No hay datos suficientes",
        visualizationsLabel: "Visualizaciones",
        timeLabel: "Tiempo (min)"
      },
      statsProductsLikes: {
        getStatsError: "Error al obtener las estadísticas",
        infoTitle: "Info: Likes por producto",
        infoCloseText: "¡Entendido!",
        infoContent: "Indica el numero de likes que han tenido cada video durante el rango de fechas seleccionado, ordenado de más likes a menos.",
        title: "Likes por producto",
        empty: "No hay datos suficientes",
        like: "Me gusta",
        dislike: "No me gusta"
      },
      statsProductsVisualizations: {
        getStatsError: "Error al obtener las estadísticas",
        infoTitle: "Info: Visualizaciones por producto",
        infoCloseText: "¡Entendido!",
        infoContent: "Indica el numero de visualizaciones que han tenido cada video durante el rango de fechas seleccionado, ordenado de más visualizaciones a menos.",
        title: "Visualizaciones por producto",
        empty: "No hay datos suficientes",
        label: "Visualizaciones"
      },
      statsTrainersIncome: {
        getStatsError: "Error al obtener las estadísticas",
        title: "Facturación de entrenadores",
        empty: "No hay datos suficientes",
        label: "EUR (€)"
      },
      statsTrainersMeetings: {
        getStatsError: "Error al obtener las estadísticas",
        title: "Nº de entrenamientos",
        empty: "No hay datos suficientes",
        label: "Entrenamientos"
      },
      statsUsersLocation: {
        getStatsError: "Error al obtener las estadísticas",
        title: "Mapa de usuarios",
        subtitle: (numUnknowns) => !!numUnknowns && `Usuarios desubicados: ${numUnknowns}`,
        empty: "No hay datos suficientes",
      },
      statsUsersPlans: {
        getStatsError: "Error al obtener las estadísticas",
        title: "Planes",
        empty: "No hay datos suficientes",
        label: "Usuarios"
      },
      statsUsersSubscriptions: {
        getStatsError: "Error al obtener las estadísticas",
        infoTitle: "Info: Registros",
        infoCloseText: "¡Entendido!",
        infoContent: "Indica el número de usuarios registrados en la franja de tiempo seleccionada",
        title: "Registros",
        empty: "No hay datos suficientes",
        labelY: "Registros",
        labelX: "Usuarios"
      },
      statsUsersTotalActiveNow: {
        getStatsError: "Error al obtener las estadísticas",
        suffix: (number) => number === 1 ? " usuario" : " usuarios",
        title: "Usuarios conectados ahora",
        description: "Numero de usuarios que están en la web actualmente"
      },
      statsUsersTotalActive: {
        shortCutToday: "Hoy",
        shortCut7Days: "7 días",
        shortCut14Days: "14 días",
        shortCut21Days: "21 días",
        shortCut30Days: "30 días",
        getStatsError: "Error al obtener las estadísticas",
        suffix: (number) => number === 1 ? " usuario" : " usuarios",
        title: (total) => `Usuarios activos de ${total}`,
        description: (shortCut) => `Indica el número de usuarios activos (${shortCut})`
      },
      statsVideoTimeVisualization: {
        getStatsError: "Error al obtener las estadísticas",
        infoTitle: "Info: Retención de la audiencia",
        infoCloseText: "¡Entendido!",
        infoContent: (
          <>
            <p>
              Indica el porcentaje de usuarios (posición y del gráfico) respecto al porcentaje de video visualizado
              (posición x del gráfico)
            </p>
            Si el punto esta entre 70% de usuarios y 30% de video visualizado quiere decir que el 30% de los usuarios
            han abandonado el video antes del 30% de este.
          </>
        ),
        title: "Retención de la audiencia",
        empty: "No hay datos suficientes",
        selectProducts: "Seleccione un producto que desee comparar",
        labelTitle: "Retención",
        labelFinalized: (label, percent) => `${label}: ${(percent).toFixed(1)}% de usuarios finalizó el video`,
        labelAbandonment: (label, percent, percentVideo) => `${label}: ${(100 - percent).toFixed(1)}% de abandono al ${percentVideo}% del video`
      }
    },
    trainers: {
      hooks: {
        useTrainer: {
          getTrainerError: "Error al obtener los datos del entrenador",
          removeTrainerLoading: "Eliminando entrenador...",
          removeTrainerSucceed: "Entrenador eliminado correctamente",
          removeTrainerError: "Error al eliminar el entrenador",
          saveTrainerLoading: (isModifying) => isModifying ? "Modificando entrenador..." : "Creando entrenador...",
          saveTrainerSucceed: (isModifying) => isModifying ? "Entrenador modificado correctamente" : "Entrenador creado correctamente",
          saveTrainerError: "Error al crear/modificar el entrenador"
        },
        useTrainers: {
          getTrainers: "Error al obtener los entrenadores"
        }
      },
      removeTrainerModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (name = "") => `Está a punto de eliminar definitivamente ${name || ""}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showTrainersCard: {
        name: "Nombre",
        status: "Estado",
        statusText: (active) => active ? "Activo" : "Inactivo",
        modifyDescription: "Editar Entrenador",
        MeetingsTemplatesDescription: "Plantillas de meetings",
        calendarDescription: "Calendario",
        removeDescription: "Eliminar Entrenador",
        title: "Entrenadores",
        addButtonText: "Entrenador",
        empty: "No hay entrenadores"
      },
      uploadTrainerModal: {
        thumbnailTooltip: "La miniatura vertical del entrenador puede aparecer como carátula en la categoría entrenadores o en la página de entrenadores.",
        imageTooltip: "La imagen del entrenador puede aparecer en la página del entrenador como portada. Esta imagen puede verse recortada en función del dispositivo.",
        title: (isModifying, name) => isModifying ? `Actualizar entrenador: ${name}` : "Crear entrenador",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        emilLabel: "Email",
        nameLabel: "Nombre",
        isActiveLabel: "¿Entrenador Activo?",
        isActiveAnswers: ["Sí", "No"],
        descriptionLabel: "Descripción",
        thumbnailLabel: "Miniatura vertical",
        imageLabel: "Imagen",
      }
    },
    trainerSchedule: {
      hooks: {
        useTrainerAvailabilitySchedule: {
          getTrainerAvailabilityScheduleError: "No se ha podido obtener la disponibilidad del entrenador",
          getMyTrainerAvailabilityScheduleError: "No se ha podido obtener la disponibilidad",
          saveTrainerAvailabilityScheduleLoading: (isModifying) => isModifying ? "Modificando disponibilidad..." : "Creando disponibilidad...",
          saveTrainerAvailabilityScheduleSucceed: (isModifying) => isModifying ? "Disponibilidad modificada correctamente" : "Disponibilidad creada correctamente",
          saveTrainerAvailabilityScheduleError: "Error al crear/modificar la disponibilidad"
        },
      },
      showTrainerScheduleCard: {
        subtitle: "Haz clic a una hora para indicar tu disponibilidad",
        buttonSaveText: "Guardar",
        buttonNowText: "Ahora",
      },
      uploadAvailabilityModal: {
        title: (isModifying) => isModifying ? "Modificar disponibilidad" : "Crear disponibilidad",
        closeText: "Cancelar",
        buttonSaveText: (isModifying) => isModifying ? "Modificar" : "Añadir",
        buttonRemoveText: "Borrar",
        templateLabel: "Plantilla",
        isRecurringLabel: "¿Es semanal?",
        isRecurringAnswers: ["Sí", "No"]
      }
    },
    trans: {
      hooks: {
        useOneTrans: {
          removeTransLoading: "Eliminando transcodificación...",
          removeTransSucceed: "Transcodificación eliminada correctamente",
          removeTransError: "Error al eliminar la transcodificación"
        },
        useTrans: {
          getTransError: "Error al obtener las transcodificaciones"
        }
      },
      contexts: {
        transcodeWrapper: {
          fileUploadedSucceed: (fileName = "Archivo") => `${fileName} subido correctamente. El proceso de transcodificación puede tardar algunas horas`,
          fileUploadedError: (fileName = "Archivo") => `${fileName} no subida`,
          uploadingFile: "Subiendo video...",
          pausingFile: "Pausando subida...",
          fileUploadedCancelled: "Subida cancelada",
          fileUploadedPaused: "Subida pausada",
          errorNameVideo: "Algunos videos no tienen un nombre adecuado",
        }
      },
      removeTransModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: "Está a punto de eliminar definitivamente la transcodificación",
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showTransCard: {
        urlCopySucceed: "URL copiada en portapapeles",
        file: "Archivo",
        uploadDate: "Fecha de subida",
        url: "URL",
        urlNotAvailable: "Url no disponible",
        status: "Estado",
        statusText: (isProgressing) => isProgressing ? "Transcodificando..." : "Finalizado",
        copyDescription: "Copiar url",
        previewDescription: "Previsualizar",
        removeDescription: "Eliminar transcodificación",
        title: "Videos transcodificados",
        searchPlaceholder: "Búsqueda por nombre de archivo",
        subtitle: "Haz clic para copiar la URL",
        buttonRefreshDescription: "Recargar las transcodificaciones",
        empty: "No hay transcodificaciones"
      },
      uploadVideoCard: {
        title: "Transcodificar",
        file: "Archivo",
        size: "Peso",
        status: "Estado",
        statusText: (isUploading) => isUploading ? "Subiendo video..." : "Subida pausada",
        percentage: "Progreso",
        progressPending: "Sube el archivo nuevamente para continuar",
        pauseDescription: "Pausa la subida",
        removeDescription: "Cancela la subida",
        infoTitle: "Transcodificar",
        infoCloseText: "¡Entendido!",
        infoContent: () => (
          <p className="mt-2">
            Arrastra y suelta los videos para iniciar el proceso de transcodificación. <strong>No cierre la
            ventana mientres se sube el contenido.</strong> Tras finalizar la subida la url transcodificada
            puede tardar unas cuantas horas en aparecer.
          </p>
        )
      }
    },
    userInvoice: {
      hooks: {
        useUserInvoice: {
          downloadInvoiceError: "No se puede descargar la factura"
        },
        useUserInvoices: {
          getUserInvoicesError: "Error al obtener las facturas"
        }
      },
      showUserInvoicesCard: {
        invoice: "Factura",
        creationDate: "Fecha de creación",
        amount: "Cantidad",
        downloadInvoiceDescription: "Descargar factura",
        title: "Facturas",
        buttonRefreshDescription: "Recargar las facturas",
        empty: "No hay facturas"
      }
    },
    userPaymentMethods: {
      hooks: {
        usePaymentMethod: {
          changeDefaultPaymentMethodLoading: "Cambiando método de pago...",
          changeDefaultPaymentMethodSucceed: "Método de pago cambiado correctamente",
          changeDefaultPaymentMethodError: "Error al cambiar el método de pago",
          removePaymentMethodLoading: "Eliminando método de pago...",
          removePaymentMethodSucceed: "Método de pago eliminado correctamente",
          removePaymentMethodError: "Error al eliminar el método de pago",
        },
        useUserPaymentMethods: {
          getUserPaymentMethodsError: "Error al obtener los métodos de pago"
        }
      },
      changeDefaultUserPaymentMethodModal: {
        titleSave: `¿Seguro?`,
        descriptionSave: (last4 = "") => `Está a punto de eliminar definitivamente ${last4 || ""}`,
        closeText: "Cancelar",
        buttonSaveText: "Sí, estoy seguro"
      },
      createPaymentMethodModalWrapped: {
        creatingLoading: "Creando nuevo método de pago",
        creatingErrorType: "Seleccione un tipo de método de pago válido",
        creatingSucceed: "Método de pago creado correctamente",
        creatingError: "Error al crear el método de pago",
        creatingErrorNoAccountManager: "No se ha encontrado ningún usuario gestor de cuenta",
        title: "Crear nuevo método de pago",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        typeSelectorPlaceholder: "Selecciona...",
        cardNumberLabel: "Número de la tarjeta",
        cardExpirationLabel: "Fecha de caducidad",
        cardCVCLabel: "CVC",
        sepaLabel: "SEPA",
        info: (companyCode) => (
          <>
            Al proporcionar sus datos de pago y confirmar este pago, usted autoriza a (A) {companyCode} y Stripe,
            nuestro proveedor de servicios de pago y/o a PPRO, su proveedor de servicios local, a enviar instrucciones
            a su banco para realizar un débito en su cuenta y (B) a su banco a realizar un cargo en su cuenta de
            conformidad con dichas instrucciones. Como parte de sus derechos, usted tiene derecho a un reembolso de su
            banco conforme a los términos y condiciones del contrato con su banco. El reembolso debe reclamarse en un
            plazo de 8 semanas a partir de la fecha en la que se haya efectuado el cargo en su cuenta. Sus derechos se
            explican en un extracto que puede obtener en su banco. Usted acepta recibir notificaciones de futuros
            débitos hasta 2 días antes de que se produzcan.
          </>
        ),
        errorInvalidType: "Tipo de método de pago invalido"
      },
      removePaymentMethodModal: {
        titleDelete: `¿Seguro?`,
        descriptionDelete: (last4 = "") => `Está a punto de eliminar definitivamente el método de pago terminado en ${last4 || ""}`,
        closeText: "Cancelar",
        buttonDeleteText: "Eliminar"
      },
      showUserPaymentMethodsCard: {
        number: "Número",
        expiration: "Caducidad",
        defaultDescription: (isDefault) => isDefault ? "Método predeterminado" : "Seleccionar método de pago como predeterminado",
        removeDescription: "Eliminar Método de pago",
        title: "Métodos de pago",
        subtitle: "Añade o establece como predeterminado",
        addButtonText: "Nuevo",
        empty: "No hay métodos de pago"
      }
    },
    users: {
      hooks: {
        useUser: {
          getUserError: "No se ha podido obtener el usuario",
          subscribeUserError: "No se ha podido suscribir al usuario",
          saveUserLoading: (isModifying) => isModifying ? "Modificando usuario..." : "Creando usuario...",
          saveUserSucceed: (isModifying) => isModifying ? "Usuario modificado correctamente" : "Usuario creado correctamente",
          saveUserError: "Error al crear/modificar el usuario"
        },
        getUsers: {
          getUsersError: "Error al obtener los usuarios"
        }
      },
      showUserHistoryModal: {
        plan: "Plan",
        duration: "Duración",
        durationWithoutInit: "Sin inicio",
        durationWithoutEnd: "Sin finalizar",
        title: "Historial",
        closeText: "Cerrar",
        empty: "No existe historial"
      },
      showUsersCard: {
        email: "Email",
        name: "Nombre",
        registerDate: "Fecha registro",
        status: "Estado",
        statusText: (verified) => verified ? "Verificado" : "Sin verificar",
        modifyDescription: "Editar usuario",
        showHistoryDescription: "Ver historial",
        subscribeUserDescription: "Subscribir usuario",
        title: "Usuarios",
        subtitle: (numUsers) => `Usuarios totales: ${numUsers}`,
        filterButtonText: "Filtros",
        addButtonText: "Usuario",
        empty: "No hay usuarios"
      },
      subscribeUserModal: {
        title: "Suscribir usuario",
        closeText: "Cancelar",
        buttonSaveText: "Suscribir",
        planLabel: "Plan",
        subscriptionStart: "Fecha inicio",
        subscriptionEnd: "Fecha fin"
      },
      uploadUserModal: {
        title: (isModifying, email) => !!isModifying ? `Editar ${email}` : "Crear nuevo usuario",
        closeText: "Cancelar",
        buttonSaveText: "Guardar",
        emailLabel: "Email",
        nameLabel: "Nombre",
        lastNameLabel: "Apellido",
        phoneLabel: "Teléfono",
        countryLabel: "País",
        cityLabel: "Ciudad",
        genderLabel: "Genero",
        addressLabel: "Dirección",
        postalCodeLabel: "Código postal",
        birthdateLabel: "Fecha de nacimiento",
      },
      uploadUsersCSVCard: {
        uploadUsersLoading: "Cargando usuarios...",
        uploadUsersSucceed: "Usuarios actualizados correctamente",
        uploadUsersErrorNoUsers: "No se han encontrado usuarios en el archivo",
        uploadUsersError: (email, error) => <div><span>Error: {email}</span><p>{error}</p></div>,
        title: "Subida de usuarios",
        downloadTemplateButtonText: "Plantilla",
        info: (templateUsers) => (
          <>
            Arrastra un archivo <strong>(.xlsx o .csv)</strong> con los usuarios aquí o haz click para subirlo.
            Tienes acceso a la plantilla <a href={templateUsers}>aquí</a>. ( Las fechas deben introducirse con
            el formato <strong>YYYY-MM-DD</strong> )
          </>
        )
      },
      uploadUsersFiltersModal: {
        title: "Filtros",
        closeText: "Cancelar",
        buttonSaveText: "Aplicar",
        reset: "Reset",
        searchLabel: "Busqueda por email o nombre",
        entryDateStartLabel: "Inicio fecha alta",
        entryDateStartDescription: "Todos los usuarios que se han dado de alta a partir de esta fecha",
        entryDateEndLabel: "Fín fecha alta",
        entryDateEndDescription: "Todos los usuarios que se han dado de alta hasta esta fecha",
        subscriptionEndDateStartLabel: "Inicio fecha fín suscripción",
        subscriptionEndDateStartDescription: "Todos los usuarios cuya suscripción ha finalizado después de esta fecha",
        subscriptionEndDateEndLabel: "Fín fecha fín suscripción",
        subscriptionEndDateEndDescription: "Todos los usuarios cuya suscripción ha finalizado antes de esta fecha",
        registerStateLabel: "Estado de registro",
        verificationStateAll: "Todos",
        verificationStateChecked: "Verificado",
        verificationStateUnchecked: "Verificado",
        subscriptionPlanLabel: "Suscripción",
        subscriptionAny: "Todos"
      }
    }
  },
  notifications: {
    needsLinkClubStripe: "Stripe necesita ser enlazado con la cuenta del club",
    needsConfigureClubStripe: "Stripe del club necesita ser configurado",
    needsLinkCompanyStripe: "Stripe necesita ser enlazado con la cuenta de empresa",
    needsConfigureCompanyStripe: "El Stripe de la empresa necesita ser configurado",
    urgent: "Urgente"
  },
  routes: {
    company: "Empresa",
    club: "Club",
    trainer: "Coaching",
    clubManagement: "Gestión de clubs",
    onDemand: "On Demand",
    slider: "Slider",
    products: "Productos",
    categories: "Categorías",
    categoryProducts: "Productos de categoría",
    categoryCollections: "Colecciones de categoría",
    collections: "Colecciones",
    teams: "Equipos",
    teamSections: "Secciones de equipo",
    tags: "Tags",
    collectionProducts: "Productos de colección",
    subscriptions: "Suscripciones",
    plans: "Planes",
    coupons: "Cupones",
    transcodification: "Transcodificación",
    linealChannel: "Canal lineal",
    advertising: "Publicidad",
    advertisement: "Anuncio",
    newAdvertisement: "Nuevo anuncio",
    magazine: "Magazine",
    users: "Usuarios",
    services: "Servicios",
    trainers: "Entrenadores",
    trainerSchedule: "Calendario de entrenador",
    mySchedule: "Mi calendario",
    trainings: "Entrenamientos",
    meeting: "Meeting",
    notifications: "Notificaciones",
    stats: "Estadísticas",
    statsOnDemand: "On Demand",
    statsInRealTime: "En tiempo real",
    statsUsers: "Usuarios",
    statsTrainers: "Entrenadores",
    myLicences: "Mis licencias",
    configuration: "Configuración",
    clubConfig: "Club",
    structureConfig: "Estructura",
    seoConfig: "SEO"
  },
  countries: getCountries("es"),
  fullcalendarLocale: esLocale,
  roles: roles,
  intervals: intervals,
  genders: genders,
  currencies: currencies,
  complexCurrencies: complexCurrencies,
  intensities: intensities,
  levels: levels,
  advertisementPosition: advertisementPosition,
  notificationTypes: notificationTypes,
  planTypes: planTypes,
  navigationItemTypes: navigationItemTypes,
  categoryTypes: categoryTypes,
  collectionTypes: collectionTypes,
  producerTypes: producerTypes,
  paymentMethodTypes: paymentMethodTypes
}

export default es