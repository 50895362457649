import React, { useEffect, useState } from "react";
import { utils, Modal, FormInput, FormDecoration } from "@general-backoffice/core";
import moment from "moment";
import { planRequests } from "../../api/requests";
import { Button } from "reactstrap";
import useChangingComplexState from "../../hooks/useChangingComplexState";
import useTranslator from "../../hooks/useTranslator";

const initialFilters = {
  "subscription-plan": "all"
}

const UploadNotificationSendFiltersModal = ({
                                              notification,
                                              close = utils.noop,
                                              onSubmit,
                                              isLoading
                                            }) => {
  const t = useTranslator("components.notifications.uploadNotificationSendFiltersModal")

  const [isLoadingLocally, setIsLoadingLocally] = useState(true)
  const [actualFilters, setActualFilters] = useState(initialFilters)
  const [subscriptions, setSubscriptions] = useState([])
  const { changeState } = useChangingComplexState(setActualFilters)

  const sendToEmail = actualFilters["sendToEmail"]
  const entryDateStart = actualFilters["entry-date-start"]
  const entryDateEnd = actualFilters["entry-date-end"]
  const subscriptionEndDateStart = actualFilters["subscription-end-date-start"]
  const subscriptionEndDateEnd = actualFilters["subscription-end-date-end"]
  const subscriptionPlan = actualFilters["subscription-plan"]

  const subscriptionsOptions = subscriptions.filter(({ id }) => id > 0).map(({ id, name, ...plan }) => ({
    value: id,
    label: name,
    ...plan
  }))
  subscriptionsOptions.unshift({ value: null, label: t("noSubscription") })
  subscriptionsOptions.unshift({ value: "all", label: t("subscriptionAny") })

  const refreshStates = async () => {
    const { planList = [] } = await planRequests.getListForFilters();
    setSubscriptions(planList)
    setIsLoadingLocally(false)
  }

  useEffect(() => {
    refreshStates().then(() => null)
    // eslint-disable-next-line
  }, [])

  const reset = (e) => {
    e?.preventDefault()
    e?.stopPropagation()
    setActualFilters(initialFilters)
  }

  const selectedSubscriptionPlan = subscriptionsOptions.find(({ value }) => value === subscriptionPlan)

  return (
    <Modal.FormContainer
      isOpen={!!notification}
      toggleOpen={close}
      title={t("title")}
      closeText={t("closeText")}
      buttonSave={{ children: t("buttonSendText") }}
      onSubmit={() => onSubmit(actualFilters)}
      isLoading={isLoadingLocally || isLoading}
    >
      <div className="d-flex justify-content-end mb-2">
        <Button color="warning" onClick={reset} size="sm">
          <i className="fas fa-broom"/> {t("reset")}
        </Button>
      </div>

      <FormInput.Email
        key={sendToEmail}
        type={"sendToEmail"}
        onChange={({ target: { value } }) => {
          reset()
          changeState("sendToEmail", value)
        }}
        value={sendToEmail}
        name={"sendToEmail"}
        label={t("emailLabel")}
        description={t("infoEmailUsed")}
      />

      <FormDecoration.Line/>

      <FormInput.DatePicker
        key={entryDateStart}
        onChange={(value) => changeState("entry-date-start", moment(value).format("YYYY-MM-DD"))}
        value={entryDateStart ? moment(entryDateStart) : null}
        timeFormat={false}
        name={"entry-date-start"}
        label={t("entryDateStartLabel")}
        description={t("entryDateStartDescription")}
        disabled={!!sendToEmail}
      />

      <FormInput.DatePicker
        key={entryDateEnd}
        onChange={(value) => changeState("entry-date-end", moment(value).format("YYYY-MM-DD"))}
        value={entryDateEnd ? moment(entryDateEnd) : null}
        timeFormat={false}
        name={"entry-date-end"}
        label={t("entryDateEndLabel")}
        description={t("entryDateEndDescription")}
        disabled={!!sendToEmail}
      />

      <FormDecoration.Line/>

      <FormInput.DatePicker
        key={subscriptionEndDateStart}
        onChange={(value) => changeState("subscription-end-date-start", moment(value).format("YYYY-MM-DD"))}
        value={subscriptionEndDateStart ? moment(subscriptionEndDateStart) : null}
        timeFormat={false}
        name={"subscription-end-date-start"}
        label={t("subscriptionEndDateStartLabel")}
        description={t("subscriptionEndDateStartDescription")}
        disabled={!!sendToEmail}
      />

      <FormInput.DatePicker
        key={subscriptionEndDateEnd}
        onChange={(value) => changeState("subscription-end-date-end", moment(value).format("YYYY-MM-DD"))}
        value={subscriptionEndDateEnd ? moment(subscriptionEndDateEnd) : null}
        timeFormat={false}
        name={"subscription-end-date-end"}
        label={t("subscriptionEndDateEndLabel")}
        description={t("subscriptionEndDateEndDescription")}
        disabled={!!sendToEmail}
      />

      <FormDecoration.Line/>

      <FormInput.Select
        key={selectedSubscriptionPlan}
        label={t("subscriptionPlanLabel")}
        name={"subscription-plan"}
        icon={<i className="fas fa-tag mx-2"/>}
        onChange={(option) => changeState("subscription-plan", option.value)}
        options={subscriptionsOptions}
        value={selectedSubscriptionPlan}
        isDisabled={!!sendToEmail}
      />

    </Modal.FormContainer>
  );
}

export default UploadNotificationSendFiltersModal;