import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useRequest from "../../../../hooks/useRequest";
import advertisingRequests from "../../../../api/requests/advertisingRequests";
import usePaginationParamRequest from "../../../../hooks/usePaginationParamRequest";
import ShowAdvertisementsCard from "../../../../components/advertising/ShowAdvertisementsCard";
import { useNavigate } from "react-router";
import { getRoute } from "./Advertisement";
import RemoveModal from "../../../../components/shared/RemoveModal";
import AdvertisementCard from "../../../../components/advertising/AdvertisementCard";
import useTranslator from "../../../../hooks/useTranslator";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../../../components/utils/notify/notifyLoadingUpdate";

const declarativeLocations = ["login", "footer"]

const Advertisements = () => {
  const t = useTranslator("views.pages.dashboard.advertisements")
  const navigate = useNavigate()
  const { paramsRequest, changePageNumber } = usePaginationParamRequest({ pageSize: 10 })
  const [advertisementIdToDelete, setAdvertisementIdToDelete] = React.useState(null)

  const {
    state: { content: advertisements = [], totalElements } = {},
    isLoading: isLoadingAdvertisements,
    refresh: refreshAdvertisements,
  } = useRequest(() => {
    return advertisingRequests.getList(paramsRequest)
  }, [paramsRequest])

  const {
    state: { content: [loginAdvertisement] = [] } = {},
    isLoading: isLoadingLoginAdvertisement,
    refresh: refreshLoginAdvertisement,
  } = useRequest(() => {
    return advertisingRequests.getList({ filterBy: "location", filter: declarativeLocations[0] })
  }, [])

  const {
    state: { content: [footerAdvertisement] = [] } = {},
    isLoading: isLoadingFooterAdvertisement,
    refresh: refreshFooterAdvertisement,
  } = useRequest(() => {
    return advertisingRequests.getList({ filterBy: "location", filter: declarativeLocations[1] })
  }, [])

  const declarativeAdvertisements = [loginAdvertisement, footerAdvertisement].filter((a) => !!a)
  const associativeAdvertisements = advertisements.filter(({ location }) => !declarativeLocations.includes(location))

  console.log(declarativeAdvertisements)
  console.log(associativeAdvertisements)
  return (
    <React.Fragment>

      <RemoveModal
        isOpen={advertisementIdToDelete}
        onClose={() => setAdvertisementIdToDelete(null)}
        onSubmit={async () => {
          const notification = notify.loading(t("removing"))
          try {
            await advertisingRequests.delete(advertisementIdToDelete)
            await refreshAdvertisements()
            await refreshLoginAdvertisement()
            await refreshFooterAdvertisement()
            setAdvertisementIdToDelete(null)
            notifyLoadingToSuccess(notification, t("removingSuccess"))
          } catch (e) {
            notifyLoadingToError(notification, t("removingError"))
          }
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={6}>
            <AdvertisementCard
              {...loginAdvertisement}
              title={loginAdvertisement?.title || t("titleLoginAdvertisement")}
              subtitle={t("subtitleLoginAdvertisement")}
              image={loginAdvertisement?.image.url}
              onClick={() => navigate(getRoute(loginAdvertisement?.id, "login"))}
              onRemove={() => setAdvertisementIdToDelete(loginAdvertisement?.id)}
              isLoading={isLoadingLoginAdvertisement}
            />
          </Col>
          <Col xs={12} md={6}>
            <AdvertisementCard
              {...footerAdvertisement}
              title={footerAdvertisement?.title || t("titleFooterAdvertisement")}
              subtitle={t("subtitleFooterAdvertisement")}
              image={footerAdvertisement?.image.url}
              onClick={() => navigate(getRoute(footerAdvertisement?.id, "footer"))}
              onRemove={() => setAdvertisementIdToDelete(footerAdvertisement?.id)}
              isLoading={isLoadingFooterAdvertisement}
            />
          </Col>
          <Col xs={12}>
            <ShowAdvertisementsCard
              advertisements={associativeAdvertisements}
              isLoading={isLoadingAdvertisements}
              amount={paramsRequest.pageSize}
              page={paramsRequest.pageNumber}
              setPage={changePageNumber}
              totalAmount={totalElements - declarativeAdvertisements.length}
              onClickAdvertisement={({ id }) => navigate(getRoute(id))}
              setAdvertisementToUpload={({ id }) => navigate(getRoute(id))}
              setAdvertisementToRemove={({ id }) => setAdvertisementIdToDelete(id)}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Advertisements;