import React, { useEffect } from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useCategories from "../../../../components/category/hooks/useCategories";
import UploadCategoryModal from "../../../../components/category/UploadCategoryModal";
import useCategory from "../../../../components/category/hooks/useCategory";
import useCollections from "../../../../components/collection/hooks/useCollections";
import useCollection from "../../../../components/collection/hooks/useCollection";
import { useNavigate, useParams } from "react-router";
import UploadCollectionModal from "../../../../components/collection/UploadCollectionModal";
import ShowCollectionListCard from "../../../../components/collection/ShowCollectionListCard";

const CategoryCollections = () => {
  const navigate = useNavigate()
  const { idCategory } = useParams()

  useEffect(() => {
    if (!idCategory) navigate(-1)
    // eslint-disable-next-line
  }, [idCategory])

  const {
    collections,
    refresh: refreshCollections,
    isLoading: isLoadingCollections
  } = useCollections()

  const {
    category,
    isLoading: isLoadingCategory,
    sortCollections: sortCategoryCollections,
    refresh: refreshCategory
  } = useCategory(parseInt(idCategory))

  const {
    collection: collectionToUpload,
    isLoading: isLoadingCollectionToUpload,
    setCollection: setCollectionToUpload,
    save: saveCollectionToUpload,
  } = useCollection()

  const {
    categories,
    refresh: refreshCategories,
  } = useCategories();

  const {
    category: categoryToUpload,
    setCategory: setCategoryToUpload,
    isLoading: isLoadingCategoryToUpload,
    save: saveCategoryToUpload
  } = useCategory()

  const isLoading = isLoadingCategory || isLoadingCollections

  const categoryCollections = (category?.collectionIdList || []).reduce((ccs, idCc) => {
    const collectionsToAdd = collections.find(({ id }) => id === idCc)
    if(!!collectionsToAdd) return [...ccs, collectionsToAdd]
    return ccs
  }, [])

  const notAssociatedCollections = collections.filter(({ id }) => !(category?.collectionIdList || []).includes(id))

  return (
    <React.Fragment>

      <UploadCollectionModal
        collection={collectionToUpload}
        setCollection={setCollectionToUpload}
        isLoading={isLoadingCollectionToUpload}
        onSubmit={async () => {
          return saveCollectionToUpload()
            .then(() => {
              refreshCollections()
              refreshCategory()
              setCollectionToUpload(null)
            })
            .catch(() => null)
        }}
        categories={categories}
        setCategoryToUpload={setCategoryToUpload}
      />

      <UploadCategoryModal
        category={categoryToUpload}
        setCategory={setCategoryToUpload}
        onSubmit={async () => {
          return saveCategoryToUpload()
            .then(() => {
              refreshCategories()
              setCategoryToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategoryToUpload}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowCollectionListCard
              title={category?.name}
              collections={categoryCollections}
              collectionOptions={notAssociatedCollections}
              isLoading={isLoading}
              setCollectionToUpload={setCollectionToUpload}
              onClickCollectionToShowProducts={({ id }) => navigate(`/dashboard/on-demand/collections/${id}/products`)}
              onClickCollection={setCollectionToUpload}
              sortCollections={sortCategoryCollections}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CategoryCollections;