import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { roles } from "../../../utils/roles";
import { scheduleRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

export const defaultAvailabilitySchedule = {
  "sunday": [],
  "monday": [],
  "tuesday": [],
  "wednesday": [],
  "thursday": [],
  "friday": [],
  "saturday": [],
  "oneTime": []
}

export const weekDays = {
  "0": "sunday",
  "1": "monday",
  "2": "tuesday",
  "3": "wednesday",
  "4": "thursday",
  "5": "friday",
  "6": "saturday",
  "sunday": 0,
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6
}

const useTrainerAvailabilitySchedule = (idTrainer) => {
  const { clubId, roleList = [] } = useSelector(({ sessionState }) => sessionState.club)
  const [availabilitySchedule, setAvailabilitySchedule] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const idAvailabilityScheduleRef = useRef(null)
  const isTrainer = (roleList || []).find((role) => role === roles.TRAINER)

  useEffect(() => {
    if (isTrainer || !!idTrainer) refresh().then(() => null)
    // eslint-disable-next-line
  }, [clubId, idTrainer])

  const refresh = async () => {

    setIsLoading(true)
    return (!!idTrainer ? getTrainerAvailabilitySchedule(idTrainer) : getMyTrainerAvailabilitySchedule())
      .then(({ schedule, id }) => {
        idAvailabilityScheduleRef.current = id;
        setAvailabilitySchedule((schedule || defaultAvailabilitySchedule))
        return { schedule: (schedule || defaultAvailabilitySchedule), id }
      })
      .catch((e) => {
        idAvailabilityScheduleRef.current = null;
        setAvailabilitySchedule(null)
        throw e
      })
      .finally(() => setIsLoading(false))
  }

  const save = async () => {
    setIsLoading(true)
    return saveTrainerAvailabilitySchedule(availabilitySchedule, idAvailabilityScheduleRef.current, idTrainer)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    availabilitySchedule,
    idAvailabilitySchedule: idAvailabilityScheduleRef.current,
    setAvailabilitySchedule,
    isLoading,
    refresh,
    save
  }
}

export const getTrainerAvailabilitySchedule = async (idTrainer) => {
  const t = hardTranslator("components.trainerSchedule.hooks.useTrainerAvailabilitySchedule")

  try {
    const { schedule, id } = await scheduleRequests.getTrainerSchedule(idTrainer)
    return { schedule, id }
  } catch (e) {
    const message = e?.message || t("getTrainerAvailabilityScheduleError")
    notify.error(message)
    throw new Error(message)
  }
}

export const getMyTrainerAvailabilitySchedule = async () => {
  const t = hardTranslator("components.trainerSchedule.hooks.useTrainerAvailabilitySchedule")

  try {
    const { schedule, id } = await scheduleRequests.getMySchedule()
    return { schedule, id }
  } catch (e) {
    const message = e?.message || t("getMyTrainerAvailabilityScheduleError")
    notify.error(message)
    throw new Error(message)
  }
}

export const saveTrainerAvailabilitySchedule = async (availabilitySchedule, idAvailabilitySchedule, idTrainer) => {
  const t = hardTranslator("components.trainerSchedule.hooks.useTrainerAvailabilitySchedule")

  const notification = notify.loading(t("saveTrainerAvailabilityScheduleLoading", !!idAvailabilitySchedule))
  try {
    if (!!idAvailabilitySchedule) {
      await scheduleRequests.put(idAvailabilitySchedule, {
        schedule: JSON.stringify(availabilitySchedule),
        scheduleId: idAvailabilitySchedule
      })
      notifyLoadingToSuccess(notification, t("saveTrainerAvailabilityScheduleSucceed", true))
    } else {
      await scheduleRequests.postSchedule({
        schedule: JSON.stringify(availabilitySchedule)
      }, idTrainer)
      notifyLoadingToSuccess(notification, t("saveTrainerAvailabilityScheduleSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveTrainerAvailabilityScheduleError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useTrainerAvailabilitySchedule;