import React from "react";
import { Col } from "reactstrap";
import { motion } from 'framer-motion/dist/framer-motion'

import TimePerZoneBars from "../../shared/TimePerZoneBars";
import clock from "../../../utils/clock";
import formatSecondsToTimeObject from "../../../utils/formatSecondsToTimeObject";
import { colors, zones } from "../../config"


const toClock = secs => clock(formatSecondsToTimeObject(secs))

const Outro = ({ training, onClose }) => {

  const { structure = [] } = training?.workout || {}

  let formatedZones = [...zones].map(z => {
    let { duration, standingDuration } = structure.reduce((r, {zone, duration, standing}) => {
      if(zone.zone === z.zone) {
        r.duration += duration
        if(standing) 
          r.standingDuration += duration
      }
      return r
    }, { duration: 0, standingDuration: 0 })

    return {zone: z.zone, color: z?.colors?.main, duration, standingDuration}
  })

  let totalDuration = 0;
  let totalStandingDuration = 0;
  structure.forEach(segment => {
    totalDuration += segment.duration
    if(segment.standing) 
      totalStandingDuration += segment.duration
  })

  return (
    <motion.div 
      initial={{opacity: 0}} animate={{opacity: 1}} transition={{ ease: "easeIn", duration: 1 }}
      className="text-white position-relative metropolis flex-column-content-center h-100 w-100 pt-6 pb-3 px-3 scroll-white" 
      style={{fontWeight: 500}}
    >
      <i className="fas fa-sign-out-alt btn m-0 position-absolute p-3" style={styles.closingBtn} onClick={onClose}/>

      <Col xs="auto" className="mb-4 mb-lg-5 text-center" style={{ fontSize: "calc(1.2vw + 12px)", fontWeight: 600 }}>
        TIEMPOS TOTALES EN CADA ZONA
      </Col>

      <div className="w-100 overflow-auto" style={{maxWidth: 1500}}>
        <TimePerZoneBars zonesData={formatedZones} totalDuration={totalDuration}/>
      </div>

      <Col xs="auto" className="my-4 d-flex justify-content-center w-100" style={{ gap: 'calc(30px + 10%)', fontSize: "calc(1vw + 8px)" }}>
        <span>TOTAL SENTADO = {toClock(totalDuration - totalStandingDuration)}</span>
        <span>TOTAL DE PIE = {toClock(totalStandingDuration)}</span>
      </Col>

    </motion.div>
  );
}



const styles = {
  closingBtn: {
    color: colors.light,
    top: 'calc(2vw + 1vh)',
    right: 'calc(2vw + 1vh)',
    fontSize: 25
  }
}


export default Outro;