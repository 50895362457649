
import React from "react";
import { Col } from "reactstrap";
import ZoneSquare from "./ZoneSquare";
import clock from "../../utils/clock";
import { colors } from "../config"
import formatSecondsToTimeObject from "../../utils/formatSecondsToTimeObject";


const toClock = secs => clock(formatSecondsToTimeObject(secs))


const TimePerZoneBars = ({ zonesData, totalDuration, className, ...props }) => 
  <div className={'w-100 ' + className} {...props}>
    {[...zonesData].reverse().map(({zone, color, duration, standingDuration}, i) => 
      <Col 
        key={i} xs="auto" style={styles.barRows}
        className="w-100 mb-4 d-flex justify-content-around align-items-center" 
      >
        <div className="d-flex justify-content-end">
          <ZoneSquare zone={zone} width={60}/>
        </div>
        <PercentBar zoneColor={color} percent={(duration / totalDuration) * 100}/>
        <div className="d-flex justify-content-start">
          <Times zoneColor={color} duration={duration} standingDuration={standingDuration}/>
        </div>
      </Col>
    )}

  </div>


const PercentBar = ({zoneColor, percent = 0}) => 
  <div className="w-100 position-relative" style={{height: 10, backgroundColor: colors.empty}}>
    <div 
      className='position-absolute h-100' 
      style={{width: `${percent}%`, top: 0, left: 0, zIndex: 1, backgroundColor: zoneColor}}
    />
  </div>


const Times = ({zoneColor, duration = 0, standingDuration = 0}) => 
  <div className="text-center d-flex" style={{lineHeight: 1}}>
    <div style={{width: "calc(4.2vw + 40px)", fontSize: "calc(1.1vw + 10px)"}}>
      {toClock(duration)}
    </div>
    <div className="d-flex align-items-center" style={{width: "calc(3.5vw + 30px)", fontSize: "calc(0.9vw + 8px)"}}>
      <div className="position-relative d-inline-block">
        {standingDuration > 0 && <>
          <svg 
            viewBox="0 0 10 10" 
            preserveAspectRatio="none"
            className='position-absolute'
            style={{...styles.standingTriangle, fill: zoneColor || colors.empty}}
          >
            <polygon points="0,10 10,10 5,0" />
          </svg>
          ({toClock(standingDuration)})
        </>}
      </div>
    </div>     
  </div>



const styles = {
  closingBtn: {
    color: colors.light,
    top: '3%',
    right: '3%',
    fontSize: 25
  },
  barRows: {
    gap: '3%'
  },
  standingTriangle: {
    height: 10, 
    top: -2, 
    left: '50%',
    transform: 'translate(-50%, -100%)', 
    width: '70%'
  }
}


export default TimePerZoneBars;