import { useEffect, useState } from "react";
import { couponRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core/index";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useCoupon = (id) => {

  const [coupon, setCoupon] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const couponId = coupon?.id || id

  useEffect(() => {
    if (!!couponId) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [couponId])

  const refresh = async () => {
    setIsLoading(true)
    return getCoupon(couponId)
      .then((companyCoupon) => {
        setCoupon(companyCoupon)
        return companyCoupon
      })
      .catch((e) => {
        setCoupon(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveCoupon(coupon)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    if (!coupon?.id) return;
    setIsLoading(true)
    return removeCoupon(coupon.id)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    coupon,
    setCoupon,
    refresh,
    save,
    remove,
    isLoading
  }
}

export const getCoupon = async (couponId) => {
  const t = hardTranslator("components.coupons.hooks.useCoupon")

  try {
    const { id, ...coupon } = await couponRequests.get(couponId)
    return { ...coupon, id, code: id }
  } catch (e) {
    const message = e?.message || t("getCouponError")
    notify.error(message)
    throw new Error(message)
  }
}

export const removeCoupon = async (couponId) => {
  const t = hardTranslator("components.coupons.hooks.useCoupon")

  const notification = notify.loading(t("removeCouponLoading"))
  try {
    const companyCoupon = await couponRequests.delete(couponId)
    notifyLoadingToSuccess(notification, t("removeCouponSucceed"))
    return companyCoupon
  } catch (e) {
    const message = e?.message || t("removeCouponError")
    notifyLoadingToError(notification, message)
    throw message
  }
}


export const saveCoupon = async (coupon) => {
  const t = hardTranslator("components.coupons.hooks.useCoupon")

  const notification = notify.loading(t("saveCouponLoading", coupon?.id))
  try {
    const companyCouponToUpload = { ...coupon, id: coupon?.code || coupon?.id }

    if (coupon?.id) {
      await couponRequests.put(coupon?.id, companyCouponToUpload)
      notifyLoadingToSuccess(notification, t("saveCouponSucceed", true))
    } else {
      await couponRequests.post(companyCouponToUpload)
      notifyLoadingToSuccess(notification, t("saveCouponSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveCouponError")
    notifyLoadingToError(notification, message)
    throw new Error(message)
  }
}

export default useCoupon;