import { useEffect, useState } from 'react';

const defaultRequest = async () => undefined

const useRequest = (request = defaultRequest, deps = []) => {
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const refresh = async () => {
    setIsLoading(true)
    const response = await request()
    setState(response)
    setIsLoading(false)
  }

  useEffect(() => {
    refresh().then(() => null)
    // eslint-disable-next-line
  }, [...deps]);

  return { state, setState, isLoading, refresh }
};

export default useRequest;