import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useClubConfig from "../../../../components/clubConfig/hooks/useClubConfig";
import ChangeClubStructureCard from "../../../../components/clubConfig/ChangeClubStructureCard";
import { useSelector } from "react-redux";
import ShowNavigationItemsCard from "../../../../components/navigation/ShowNavigationItemsCard";
import useNavigationItems from "../../../../components/navigation/hooks/useNavigationItems";
import useNavigationItem from "../../../../components/navigation/hooks/useNavigationItem";
import UploadNavigationItemModal from "../../../../components/navigation/UploadNavigationItemModal";
import RemoveNavigationItemModal from "../../../../components/navigation/RemoveNavigationItemModal";

const StructureConfig = () => {
  const { club: userClub } = useSelector((state) => state.sessionState)

  const {
    clubConfig,
    setClubConfig,
    isLoading,
    save
  } = useClubConfig(userClub.clubId)

  const {
    navigationItems,
    isLoading: isLoadingNavigationItems,
    sort: sortNavigationItems,
    refresh: refreshNavigationItems,
  } = useNavigationItems()

  const {
    navigationItem: navigationItemToUpload,
    setNavigationItem: setNavigationItemToUpload,
    isLoading: isLoadingNavigationItemToUpload,
    save: saveNavigationItemToUpload,
  } = useNavigationItem()

  const {
    navigationItem: navigationItemToDelete,
    setNavigationItem: setNavigationItemToDelete,
    isLoading: isLoadingNavigationItemToDelete,
    remove: removeNavigationItemToDelete,
  } = useNavigationItem()

  console.log(navigationItems)

  return (
    <React.Fragment>

      <UploadNavigationItemModal
        navigationItem={navigationItemToUpload}
        setNavigationItem={setNavigationItemToUpload}
        isLoading={isLoadingNavigationItemToUpload}
        onSubmit={() => {
          saveNavigationItemToUpload().then(async () => {
            await refreshNavigationItems()
            setNavigationItemToUpload(null)
          })
        }}
      />

      <RemoveNavigationItemModal
        navigationItem={navigationItemToDelete}
        isLoading={isLoadingNavigationItemToDelete}
        close={() => setNavigationItemToDelete(null)}
        remove={() => {
          removeNavigationItemToDelete().then(async () => {
            await refreshNavigationItems()
            setNavigationItemToDelete(null)
          })
        }}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12} md={6}>
            <ShowNavigationItemsCard
              navigationItems={navigationItems}
              isLoading={isLoadingNavigationItems}
              sortNavigationItems={sortNavigationItems}
              setNavigationItemToUpload={setNavigationItemToUpload}
              setNavigationItemToRemove={setNavigationItemToDelete}
              onClickNavigationItem={setNavigationItemToUpload}
            />
          </Col>
          <Col xs={12} md={6}>
            <ChangeClubStructureCard
              clubConfig={clubConfig}
              setClubConfig={setClubConfig}
              isLoading={isLoading}
              onSave={save}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default StructureConfig;