import React from "react";
import { colors } from "../../../config"

const textSize = '0.5vw + 8px'
const timeSize = '1.5vw + 12px'

const BigTimer = ({ text, time, scale = 1, className, style }) => 
  <div 
    className={"metropolis d-flex align-items-center " + className} 
    style={{gap: '4%', color: colors.light, ...style}}
  >
    <div style={{
      fontSize: `calc(${textSize}*${scale})`,
      lineHeight: 1,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textAlign: 'left'
    }}>{text}</div>
    <div style={{
      lineHeight: 0.9, fontWeight: 600, 
      fontSize: `calc(${timeSize}*${scale})`, 
      width: `calc((${timeSize})*${scale}*3.35)`
    }}>{time || ''}</div>
  </div>

export default BigTimer;