import React, { useState, useMemo, createRef } from "react";
import { Button, Card as ReactstrapCard, CardBody } from "reactstrap";
import { notify, Loading, Card } from "@general-backoffice/core";
import moment from "moment";
import { v4 as uuid } from "uuid";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import templateChannel from "./../../media/files/template-channel.csv"


import {
  convertChannelEventsToFullCalendarEvents,
  convertFullCalendarEventsToChannelEvents
} from "./shared/channelEventsCalendar";
import { fixChannelEventsOverlap } from "./shared/channelEventsUtils";
import useTranslator from "../../hooks/useTranslator";
import XLSX from "xlsx";
import useTrans from "../trans/hooks/useTrans";


const ShowChannelEventsCard = ({
                                 channel,
                                 setChannel,
                                 createChannel,
                                 setChannelEventToUpload,
                                 save,
                                 createEvent,
                                 isLoading
                               }) => {
  const t = useTranslator("components.channels.showChannelEventsCalendarCard")
  const { trans, isLoading: isLoadingTrans } = useTrans();
  const tBase = useTranslator()
  const [isLoadingCsv, setIsLoadingCsv] = useState(false)
  const [subtitle, setSubtitle] = useState("")
  const { name, data = [] } = (channel || {})

  const calendarRef = createRef()

  const getApi = () => calendarRef.current.getApi();

  const prev = () => getApi().prev()
  const now = () => {
    getApi().changeView("timeGridWeek")
    getApi().today()
  }
  const next = () => getApi().next()

  const changeSubtitle = ({ start, end }) => {
    const mStart = moment(start)
    const mEnd = moment(end).subtract(1, "second")
    if (mStart.isSame(mEnd, "day")) return setSubtitle(mStart.format("DD/MM/YYYY"))
    else setSubtitle(`${mStart.format("DD/MM/YYYY")} - ${mEnd.format("DD/MM/YYYY")}`)
  }


  const fixEvents = () => {
    setChannel((currentChannel) => {
      if (currentChannel) {
        const { data = [] } = currentChannel
        const fixedResponse = fixChannelEventsOverlap(data)
        notify.info(t("fixOverlapChannelEventsSucceed", fixedResponse.numFixed))
        return { ...currentChannel, data: [...fixedResponse.events] }
      } else return currentChannel
    })
  }

  const changeChannelEventToUpload = (event = null) => {

    if (event) {
      const { id } = event
      setChannelEventToUpload({ id: uuid(), start: moment().format(), creating: !id, ...event, type: "streaming" })
    } else {
      setTimeout(() => setChannelEventToUpload(null), 200)
    }
  }

  const eventMove = () => {
    const calendarApi = getApi()
    const actualCalendarEvents = calendarApi.getEvents();
    const actualChannelEvents = convertFullCalendarEventsToChannelEvents(actualCalendarEvents)
    setChannel((currentChannel) => ({ ...currentChannel, data: [...actualChannelEvents] }))
  }

  const clickEvent = ({ event }) => {
    const { id } = event;
    const channelEventToUpload = data.find((channelEvent) => channelEvent.id.toString() === id.toString())
    changeChannelEventToUpload(channelEventToUpload || null)
  }

  const dateClick = ({ dateStr }) => {
    changeChannelEventToUpload({ start: dateStr, creating: true })
  }

  const requireCsv = () => {
    const input = document.getElementById('csvInput');
    input.click()
  }

  const uploadCsv = async (file) => {
    setIsLoadingCsv(true)
    try {
      const csv = await extractChannelEventsFromFile(file);
      console.log(csv)
      const events = [];
      const errors = [];
      (csv || []).forEach(({ start, end, type, filename: csvEventFilename, id }) => {

        const coincidence = trans.find(({ filename }) => {
          const indexLastPoint = filename.lastIndexOf('.');
          return filename.substring(0, indexLastPoint) === csvEventFilename
        })
        if (!!coincidence) events.push({ start, end, type, url: coincidence.url, id })
        else if (!errors.includes(csvEventFilename)) errors.push(csvEventFilename)
      })
      if (errors.length > 0) {
        notify(
          <div>
            <p>{t("errorOnUploadCsv", errors.length)}</p>
            {errors.slice(0, 10).map((e, i) => <p key={i} style={{ fontSize: "0.5rem", marginBottom: 0 }}>{e}</p>)}
            {errors.length > 10 && <p>...</p>}
            <strong style={{ fontSize: "0.6rem", marginBottom: 0 }}>{t("errorDescriptionOnUploadCsv")}</strong>
          </div>
          , { autoClose: false, closeOnClick: true })
      } else {
        notify.success(t("uploadCsvSucceed"))
        setChannel((currentChannel) => ({ ...currentChannel, data: [...currentChannel.data, ...events] }))
      }
    } catch (e) {
      notify.error(t("uploadCsvError"))
      setChannel(channel)
    }

    setIsLoadingCsv(false)
  }

  const channelCalendarEvents = useMemo(() => convertChannelEventsToFullCalendarEvents(data), [data])

  return (
    <React.Fragment>
      <input
        onChange={(e) => {
          uploadCsv(e.target.files[0]).then(() => null)
          e.target.value = ""
        }}
        type="file"
        id="csvInput"
        accept=".csv"
        className="d-none"
      />
      <ReactstrapCard>
        <Card.Header
          title={name}
          subtitle={subtitle}
          buttons={[
            {
              children: t("buttonUploadCsv"),
              color: "primary",
              onClick: requireCsv,
              disabled: isLoading || !channel || isLoadingCsv || isLoadingTrans,
            },
            {
              children: t("buttonFixText"),
              color: "warning",
              onClick: fixEvents,
              disabled: isLoading || !channel
            },
            {
              children: t("buttonSaveText"),
              color: "success",
              onClick: () => save({ ...channel }),
              disabled: isLoading || !channel
            },
            { children: <i className="fas fa-angle-left px-2"/>, onClick: prev, disabled: isLoading || !channel },
            { children: t("buttonNowText"), onClick: now, disabled: isLoading || !channel },
            { children: <i className="fas fa-angle-right px-2"/>, onClick: next, disabled: isLoading || !channel }
          ]}
          isLoading={isLoading}
        />

        {isLoading && <Loading.Line/>}
        {(!channel && !isLoading) && (
          <CreateChannel setChannelToCreate={createChannel}/>
        )}
        {!!channel && (
          <div className="overflow-auto calendar" id="calendar" style={{ height: '60vh', minHeight: 400 }}>
            <FullCalendar

              ref={calendarRef}
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              locale={tBase("fullcalendarLocale")}

              stickyHeaderDates
              height="100%"

              headerToolbar=""
              slotDuration="00:30:00"
              snapDuration={"00:01:00"}

              eventDurationEditable={false}
              navLinkDayClick={true}
              navLinks={true}
              editable={true}
              allDaySlot={false}
              nowIndicator={true}
              displayEventTime={true}
              droppable={true}
              eventOverlap={true}

              eventDrop={eventMove}
              dateClick={dateClick}
              eventClick={clickEvent}
              datesSet={changeSubtitle}
              events={channelCalendarEvents}

              eventContent={({ timeText, event }) => {
                const apiEvent = data.find(({ id }) => id === event.extendedProps.id)
                return (
                  <div className="fc-event-main-frame overflow-hidden" title={event.title}>
                    <div
                      className="bg-white text-default fc-event-duplicate"
                      onClick={() => {
                        const newEvent = { ...apiEvent, id: uuid() }
                        changeChannelEventToUpload(newEvent)
                        createEvent(newEvent)
                      }}
                    ><i className="fas fa-copy m-1"/></div>
                    <div className="ml-1">
                      <div className="fc-event-time">{timeText}</div>
                      <div className="fc-event-title-container">
                        <div className="fc-event-title fc-sticky">{event.title}</div>
                      </div>
                    </div>
                  </div>
                )
              }}

            />
          </div>
        )}
        <Card.Footer>
          {t("textTemplateCsv", templateChannel)}
        </Card.Footer>
      </ReactstrapCard>

    </React.Fragment>
  );
}

const extractChannelEventsFromFile = async (file) => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary', raw: true, codepage: 65001 });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const events = XLSX.utils.sheet_to_json(first_worksheet);
      const checkedEvents = events.map((row) => {
        const { FINAL, INICIO, NOMBRE, start, end, filename } = row;

        let eventStart = moment(start).utc().format()
        let eventEnd = moment(end).utc().format()

        if (INICIO) {
          eventStart = moment(row["FECHA DE INICIO"] + " " + INICIO, "dddd[,] D [de] MMMM [de] YYYY hh:mm:ss").utc().format()
        }
        if (FINAL) {
          eventEnd = moment(row["FECHA DE INICIO"] + " " + FINAL, "dddd[,] D [de] MMMM [de] YYYY hh:mm:ss").utc().format()
        }


        return {
          start: eventStart,
          end: eventEnd,
          type: "streaming",
          filename: filename || NOMBRE,
          id: uuid(),
        }
      })
      resolve(checkedEvents)
    }
    reader.readAsBinaryString(file);
  })
}

const CreateChannel = ({ setChannelToCreate }) => {
  const t = useTranslator("components.channels.showChannelEventsCalendarCard")
  return (
    <CardBody className="flex-content-center">
      <Button color={"default"} onClick={() => setChannelToCreate({})}>
        {t("buttonCreateChannelText")}
      </Button>
    </CardBody>
  )
}

export default ShowChannelEventsCard;