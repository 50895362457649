import React, { useEffect, useState } from "react";
import { Card as ReactstrapCard, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { notify, Card, Loading, TableNoContentMessage } from "@general-backoffice/core";

import { statsRequests } from "../../api/requests";
import { successColor } from './config/colors'
import UploadStatsPeriodModal, { format, formatHuman, initialEndDate, initialStartDate } from "../shared/UploadStatsPeriodModal";
import { statMedium, statSmall } from "./config/sizes";
import useTranslator from "../../hooks/useTranslator";

const StatsUsersPlans = ({ simple = false }) => {
  const t = useTranslator("components.stats.statsUsersPlans")

  const { clubId } = useSelector(({ sessionState }) => sessionState.club)
  const [isLoading, setLoading] = useState(true)
  const [{ startDate, endDate }, setLocalDates] = useState({ startDate: initialStartDate, endDate: initialEndDate })
  const [isModalPeriodOpen, setModalPeriodOpen] = useState(false)
  const [planSubscriptions, setPlanSubscriptions] = useState([])

  const refresh = () => {
    statsRequests.getUsersPerPlan(format(startDate), format(endDate))
      .then(({ planSubscriptions }) => setPlanSubscriptions(planSubscriptions))
      .catch((e) => notify.error(e?.message || t("getStatsError")))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId, startDate, endDate])

  const hasInfo = planSubscriptions.length > 0;

  return (
    <React.Fragment>

      <UploadStatsPeriodModal
        dates={{ startDate, endDate }}
        changeDates={setLocalDates}
        isOpen={isModalPeriodOpen}
        setOpen={setModalPeriodOpen}
      />


      <ReactstrapCard>
        <Card.Header
          title={t("title")}
          subtitle={`${formatHuman(startDate)} - ${formatHuman(endDate)}`}
          buttons={[
            { children: <i className="fas fa-calendar-alt"/>, onClick: () => setModalPeriodOpen(true) }
          ]}
          isLoading={isLoading}
        />
        <CardBody>
          {isLoading && <Loading.BouncingDots className="my-5"/>}
          {(!hasInfo && !isLoading) && <TableNoContentMessage children={t("empty")}/>}
          {(hasInfo && !isLoading) && (
            <div style={{ position: "relative", height: simple ? statSmall : statMedium }}>
              <Bar
                type={"bar"}
                data={{
                  labels: planSubscriptions.map(({ planName }) => planName),
                  datasets: [{
                    label: t("label"),
                    data: planSubscriptions.map(({ subscriptions }) => subscriptions),
                    backgroundColor: successColor,
                    borderRadius: 5,
                  }]
                }}
                options={{
                  maintainAspectRatio: false,
                  indexAxis: 'x',
                  responsive: true,
                  scales: {
                    y: {
                      display: !simple,
                      grid: {
                        drawBorder: false
                      },
                      ticks: {
                        suggestedMin: 0,
                        stepSize: 10,
                      }
                    },
                    x: {
                      display: !simple,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  },
                  interaction: {
                    intersect: false,
                    mode: 'x',
                  },
                }}/>
            </div>
          )}
        </CardBody>
      </ReactstrapCard>
    </React.Fragment>
  );
}

export default StatsUsersPlans;