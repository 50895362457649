import moment from "moment";

const getSPDDefaultProduct = () => ({
  title: "",
  description: "",
  collectionId: null,
  subscriptionPlanIdList: [],
  homeTeam: { id: null },
  awayTeam: { id: null },
  cameraId: null,
  storyBoardId: null,
  graphicPackageId: null,
  eventStartTime: moment().add(1, "hour").startOf("hour").utc().format(),
  eventMinutesBeforeStart: 5,
  eventMinutesAfterEnd: 90,
  sportEnum: "football",
  image: { url: null, data: null, contentType: null },
  imagePortrait: { url: null, data: null, contentType: null },
  thumbnail: { url: null, data: null, contentType: null },
  thumbnailPortrait: { url: null, data: null, contentType: null },
})

export default getSPDDefaultProduct;