import Api from "../Api";

const getSlidesList = () => Api.get(`/admin/slider/list`);
const putSlide = (body) => Api.put(`/admin/slider/update`, body);
const sortSlides = (slideIdList) => Api.put(`/admin/slider/orderlist`, {itemIdList: slideIdList});
const postSlide = (body) => Api.post(`/admin/slider/create`, body);
const deleteSlide = (id) => Api.delete(`/admin/slider/${id}`);

const sliderRequests = {
  getList: getSlidesList,
  sort: sortSlides,
  post: postSlide,
  put: putSlide,
  delete: deleteSlide
}

export default sliderRequests