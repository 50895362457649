const convertTransUrlToObject = (url) => {
  const realUrl = url || ""
  const urlSplit = realUrl.split("/")
  // const year = urlSplit[5];
  const user = (urlSplit[6] || "").split("_")[0];
  const filename = (urlSplit[6] || "").replace((user + "_"), "")
  return {
    user,
    filename,
    path: realUrl.replace("https://media1.ultrik.com/vod", ""),
    url: realUrl
  }
}

export default convertTransUrlToObject;