import React from "react";
import { Col, Container, Row } from "reactstrap";
import UploadTrainingCyclingWorkoutCard from "./constructor/UploadTrainingCyclingWorkoutCard";
import StatsTrainingCyclingZones from "./stats/StatsTrainingCyclingZones";
import { zones } from "./config";
import StatsTrainingCyclingPreview from "./stats/StatsTrainingCyclingPreview";

const Constructor = ({ training, setTraining, isLoading }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <UploadTrainingCyclingWorkoutCard
            training={training}
            setTraining={setTraining}
            isLoading={isLoading}
          />
        </Col>
        <Col xs={12} lg={6}>
          <StatsTrainingCyclingPreview
            structure={training?.workout?.structure}
            isLoading={isLoading}
          />
        </Col>
        <Col xs={12} lg={6}>
          <StatsTrainingCyclingZones
            structure={training?.workout?.structure}
            isLoading={isLoading}
            zones={zones}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Constructor;