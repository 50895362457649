import { useEffect, useState } from "react";
import { navigationRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import { hardTranslator } from "../../../hooks/useTranslator";

const useNavigationItems = () => {
  const { clubId } = useSelector((state) => state.sessionState.club)
  const [navigationItems, setNavigationItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [clubId])

  const refresh = () => {
    setIsLoading(true)
    return getNavigationItems()
      .then((navigationItemList) => {
        setNavigationItems(navigationItemList)
        return navigationItemList
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const sort = async (sortedNavigationItems = []) => {
    setNavigationItems(sortedNavigationItems)
    return sortNavigationItems(sortedNavigationItems)
      .then(() => {
        getNavigationItems().then((navigationItemList) => setNavigationItems(navigationItemList))
      })
  }


  return {
    navigationItems,
    isLoading,
    refresh,
    sort
  }
}

export const getNavigationItems = async () => {
  const t = hardTranslator("components.navigation.hooks.useNavigationItems")
  try {
    const content = await navigationRequests.getList()
    return (content || [])
  } catch (e) {
    const message = (e?.message || t("getNavigationItemsError"))
    notify.error(message)
    throw new Error(message)
  }
}

export const sortNavigationItems = async (navigationItems) => {
  const t = hardTranslator("components.navigation.hooks.useNavigationItems")
  const notificationLoading = notify.loading(t("sortNavigationItemsLoading"))
  try {
    const itemIdList = navigationItems.map(({ id }) => id)
    await navigationRequests.sort({ itemIdList })
    notifyLoadingToSuccess(notificationLoading, t("sortNavigationItemsSucceed"))
  } catch (e) {
    const message = e?.message || t("sortNavigationItemsError")
    notifyLoadingToError(notificationLoading, message)
    throw new Error(message)
  }
}

export default useNavigationItems;