import { useMemo } from "react";
import useTranslator from "../useTranslator";

const collectionTypes = [
  "personal",
  "product",
  // "AUTOMATIC"
]
const useTranslatedCollectionTypes = () => {
  const t = useTranslator("collectionTypes")

  const translatedCollectionTypes = useMemo(() => getTranslatedCollectionTypes(t), [t])
  const collectionTypeOptions = useMemo(() => getCollectionTypeOptions(t), [t])
  const getCollectionTypeOption = (v) => findCollectionTypeOption(t, v)

  return {
    collectionTypes,
    translatedCollectionTypes,
    collectionTypeOptions,
    getCollectionTypeOption
  }
}

const getTranslatedCollectionTypes = (t) => {
  return collectionTypes.reduce((translated, collectionType) => {
    translated[collectionType] = t(collectionType) || collectionType
    return translated
  }, {})
}

const getCollectionTypeOptions = (t) => {
  return collectionTypes.map((collectionType) => ({ value: collectionType, label: t(collectionType) || collectionType }))
}

const findCollectionTypeOption = (t, value) => {
  return collectionTypes.includes(value) ? { value: value, label: t(value) || value } : undefined
}

export default useTranslatedCollectionTypes;
export { collectionTypes, getTranslatedCollectionTypes, getCollectionTypeOptions, findCollectionTypeOption }