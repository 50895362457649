import React from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import useMeetings from "../../../../components/meetings/hooks/useMeetings";
import ShowTrainerScheduleCard from "../../../../components/trainerSchedule/ShowTrainerScheduleCard";
import ShowMeetingModal from "../../../../components/meetings/ShowMeetingModal";
import UploadAvailabilityModal from "../../../../components/trainerSchedule/UploadAvailabilityModal";
import useMeetingTemplates from "../../../../components/meetings/hooks/useMeetingTemplates";
import useMeeting from "../../../../components/meetings/hooks/useMeeting";
import useTrainerAvailabilitySchedule
  , { saveTrainerAvailabilitySchedule } from "../../../../components/trainerSchedule/hooks/useTrainerAvailabilitySchedule";
import useTrainerAvailability from "../../../../components/trainerSchedule/hooks/useTrainerAvailability";

const Schedule = () => {

  const {
    meetings,
    isLoading: isLoadingMeetings,
  } = useMeetings();

  const {
    meeting: meetingToShow,
    setMeeting: setMeetingToShow,
    join: joinMeetingToShow,
    isLoading: isLoadingMeetingToShow
  } = useMeeting()

  const {
    availabilitySchedule,
    setAvailabilitySchedule,
    isLoading: isLoadingAvailabilitySchedule,
    refresh: refreshAvailabilitySchedule,
    idAvailabilitySchedule
  } = useTrainerAvailabilitySchedule()

  const {
    availability: availabilityToUpload,
    setAvailability: setAvailabilityToUpload
  } = useTrainerAvailability()

  const {
    meetingTemplates,
    isLoading: isLoadingMeetingTemplates
  } = useMeetingTemplates();

  return (
    <React.Fragment>

      <ShowMeetingModal
        meeting={meetingToShow}
        close={setMeetingToShow}
        isLoading={isLoadingMeetingToShow}
        join={joinMeetingToShow}
      />

      <UploadAvailabilityModal
        availability={availabilityToUpload}
        setAvailability={setAvailabilityToUpload}
        templates={meetingTemplates}
        availabilitySchedule={availabilitySchedule}
        saveAvailabilitySchedule={async (newAvailabilitySchedule) => {
          setAvailabilitySchedule(newAvailabilitySchedule)
          return saveTrainerAvailabilitySchedule(newAvailabilitySchedule, idAvailabilitySchedule)
            .then(() => {
              refreshAvailabilitySchedule()
              setAvailabilityToUpload(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingMeetingTemplates || isLoadingAvailabilitySchedule}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>

      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ShowTrainerScheduleCard
              meetings={meetings}
              availabilitySchedule={availabilitySchedule}
              setAvailabilitySchedule={setAvailabilitySchedule}
              setMeetingToShow={setMeetingToShow}
              setAvailabilityToUpload={setAvailabilityToUpload}
              isLoading={isLoadingMeetings || isLoadingAvailabilitySchedule}
              saveAvailabilitySchedule={async (newAvailabilitySchedule) => {
                setAvailabilitySchedule(newAvailabilitySchedule)
                return saveTrainerAvailabilitySchedule(newAvailabilitySchedule, idAvailabilitySchedule)
                  .then(() => {
                    refreshAvailabilitySchedule()
                    setAvailabilityToUpload(null)
                  })
                  .catch(() => null)
              }}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Schedule;