const getDefaultMeeting = () => ({
  categoryId: null,
  description: "",
  extraDescription: "",
  participants: [],
  image: { url: null, data: null, contentType: null },
  level: "BEGINNER",
  maxParticipants: 2,
  price: 0,
  start: "",
  end: "",
  status: "READY",
  template: true,
  templateName: "",
  url: "",
})

export default getDefaultMeeting;