import { roles } from "../../../utils/roles";

const getDefaultManager = () => ({
  address: "",
  birthdate: null,
  city: "",
  country: "",
  firstName: "",
  lastName: "",
  phone: "",
  postalCode: "",
  roleList: [roles.MANAGER],
})

export default getDefaultManager;