import { useEffect, useState } from "react";
import { trainerRequests } from "../../../api/requests";
import { notify } from "@general-backoffice/core";
import { notifyLoadingToError, notifyLoadingToSuccess } from "../../utils/notify/notifyLoadingUpdate";
import getDefaultTrainer from "../shared/getDefaultTrainer";
import { hardTranslator } from "../../../hooks/useTranslator";

const useTrainer = (idTrainer) => {
  const [trainer, setTrainer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const id = trainer?.id || idTrainer

  useEffect(() => {
    if (!!id) refresh()
    // eslint-disable-next-line
  }, [id])

  const refresh = async () => {
    setIsLoading(true)
    return getTrainer(id)
      .then((trainer) => {
        setTrainer(trainer)
        return trainer
      })
      .catch((e) => {
        setTrainer(null)
        throw e
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const remove = async () => {
    setIsLoading(true)
    return removeTrainer(trainer)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const save = async () => {
    setIsLoading(true)
    return saveTrainer(trainer)
      .finally(() => {
        setIsLoading(false)
      })
  }


  return {
    trainer,
    setTrainer,
    isLoading,
    refresh,
    remove,
    save
  }
}

export const getTrainer = async (idTrainer) => {
  const t = hardTranslator("components.trainers.hooks.useTrainer")
  try {
    return await (!!idTrainer ? trainerRequests.get(idTrainer) : trainerRequests.getMyTrainer())
  } catch (e) {
    const message = (e?.message || t("getTrainerError"))
    notify.error(message)
    throw new Error(message)
  }
}

export const removeTrainer = async (trainerToRemove) => {
  const t = hardTranslator("components.trainers.hooks.useTrainer")
  const notification = notify.loading(t("removeTrainerLoading"))
  try {
    await trainerRequests.delete(trainerToRemove?.id)
    notifyLoadingToSuccess(notification, t("removeTrainerSucceed"))
  } catch (e) {
    const message = e?.message || t("removeTrainerError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export const saveTrainer = async (trainer) => {
  const t = hardTranslator("components.trainers.hooks.useTrainer")
  const notification = notify.loading(t("saveTrainerLoading", !!trainer?.id))
  try {
    if (!!trainer?.id) {
      await trainerRequests.put({ ...getDefaultTrainer(), ...trainer })
      notifyLoadingToSuccess(notification, t("saveTrainerSucceed", true))
    } else {
      await trainerRequests.post({ ...getDefaultTrainer(), ...trainer })
      notifyLoadingToSuccess(notification, t("saveTrainerSucceed", false))
    }
  } catch (e) {
    const message = e?.message || t("saveTrainerError")
    notifyLoadingToError(notification, message)
    throw message
  }
}

export default useTrainer;