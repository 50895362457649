import React, { useState } from "react";
import { Table, TableCard, TableOutput } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";
import useTranslator from "../../hooks/useTranslator";

const ShowCollectionsCard = ({
                               collections,
                               setCollectionToUpload,
                               setCollectionToRemove,
                               onClickCollectionToShowProducts,
                               onClickCollection,
                               isLoading
                             }) => {
  const t = useTranslator("components.collection.showCollectionsCard")
  const [page, setPage] = useState(1)
  const [amount, setAmount] = useState(10)

  const columns = [
    {
      name: t("collectionTitle"),
      formattedContent: ({ title, thumbnail }) => <TableOutput.ImageText src={thumbnail?.url} text={title}/>,
      isTh: true
    },
    // {
    //   name: t("numProducts"),
    //   formattedContent: ({ productIdList = [], numberOfProducts }) => {
    //     return (productIdList || []).length || numberOfProducts || 0
    //   },
    //   isTh: true
    // },
    {
      name: "",
      formattedContent: (collection) => {
        const actions = [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setCollectionToUpload({ ...collection }),
            description: t("modifyDescription")
          },
          {
            icon: <i className="fas fa-th-list"/>,
            onClick: () => onClickCollectionToShowProducts({ ...collection }),
            description: t("showProductsDescription")
          },
          {
            icon: <i className="fas fa-trash table-action-delete"/>,
            description: t("removeDescription"),
            onClick: () => setCollectionToRemove({ ...collection })
          },
        ]

        return <TableOutput.CustomActions actions={actions}/>
      },
      component: TableOutput.CustomActions
    },
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={t("title")}
        buttons={[
          {
            children: <AddButtonChildren children={t("buttonAddText")}/>,
            onClick: () => setCollectionToUpload({})
          }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={collections.slice(((page - 1) * amount), ((page - 1) * amount) + amount)}
        onRowClick={(collection) => onClickCollection({ ...collection })}
        isLoading={isLoading}
        noContentMessage={t("emptyCollections")}
        rowAmount={amount}
      />
      <TableCard.Footer
        setAmount={setAmount}
        amount={amount}
        totalEntries={collections.length}
        page={(page - 1)}
        onChangePage={(page) => setPage((page + 1))}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ShowCollectionsCard;