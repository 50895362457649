import React from "react";
import Preview from "../../../../components/trainings/cycling/Preview";
import useTraining from "../../../../components/trainings/hooks/useTraining";

const TrainingConstructor = () => {
  const { training, isLoading } = useTraining()

  return (
    <div className="mt-3">
      <Preview training={training} isLoading={isLoading}/>
    </div>
  );
}

export default TrainingConstructor;