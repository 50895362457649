import React from "react";
import { Modal } from "@general-backoffice/core";
import useTranslator from "../../hooks/useTranslator";

const SaveChannelModal = ({
                            channel,
                            close,
                            save,
                            isLoading
                          }) => {
  const t = useTranslator("components.channels.saveChannelModal")
  return (
    <Modal.SaveConfirm
      isOpen={!!channel}
      toggleOpen={close}
      titleSave={t("titleSave")}
      descriptionSave={t("descriptionSave")}
      closeText={t("closeText")}
      isLoading={isLoading}
      buttonSave={{ children: t("buttonSaveText"), onClick: save }}
    />
  );
}

export default SaveChannelModal;